<template>
  <div>
    <manufacturing-cycle-filters></manufacturing-cycle-filters>
    <manufacturing-cycle-list-table></manufacturing-cycle-list-table>
  </div>
</template>

<script>
import ManufacturingCycleListTable from "./ManufacturingCycleListTable.vue";
import ManufacturingCycleFilters from "./Filters.vue";

import DateTime from "../../mixins/DateTime";
import _ from "lodash";
export default {
  mixins: [DateTime],
  components: {
    ManufacturingCycleFilters,
    ManufacturingCycleListTable,
  },
};
</script>

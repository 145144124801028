<template>
  <div>
    <div>
      <el-dialog
        :visible="visible"
        @update:visible="$emit('update:visible', $event)"
        :title="title"
        :width="width"
      >
        <el-row>
          <el-radio-group
            v-model="availabilityType"
            @change="clearModels"
            style="width: 100%"
          >
            <el-radio-button :label="1">Single</el-radio-button>
            <el-radio-button :label="2">Calendar</el-radio-button>
          </el-radio-group>
        </el-row>
        <el-row v-if="showSingleAvailability">
          <single-availability-form
            :model="singleAvailabilityModel"
          ></single-availability-form>
        </el-row>
        <el-row v-else>
          <calendar-availability-form
            ref="calendarAvailabilityForm"
            :designer="designer"
            :model="calendarAvailabilityModel"
            :saved-schedule.sync="choosenSavedSchedule"
          ></calendar-availability-form>
        </el-row>
        <div slot="footer">
          <el-button
            type="danger"
            v-if="isEditMode"
            v-on:click="openDeleteConfirmDialog"
            >Delete</el-button
          >
          <el-button @click="cancel">Cancel</el-button>
          <el-button type="primary" @click="saveAvailability">OK</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { OperationResultType } from "../../enums/enums";
import SingleAvailability from "../../models/design/SingleAvailability";
import CalendarAvailability from "../../models/design/CalendarAvailability";

import SingleAvailabilityForm from "./SingleAvailabilityForm.vue";
import CalendarAvailabilityForm from "./CalendarAvailabilityForm.vue";

import { AvailabilityType } from "../../enums/design/AvailabilityType";

import { designerAvailabilityService } from "../../services/design/designeravailability.service";

export default Vue.extend({
  components: {
    SingleAvailabilityForm,
    CalendarAvailabilityForm,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    designer: null,
  },
  data() {
    return {
      OperationResultType,
      isAvailabilityEditMode: false,
      showDeleteAvailabilityConfirmModal: false,
      singleAvailabilityModel: new SingleAvailability(),
      calendarAvailabilityModel: new CalendarAvailability(),
      showWeekends: false,
      choosenSavedSchedule: null,
      availabilityType: AvailabilityType.Single,
    };
  },
  computed: {
    isEditMode() {
      return (
        this.singleAvailabilityModel.id !== null ||
        this.calendarAvailabilityModel.id !== null
      );
    },
    showSingleAvailability() {
      return this.availabilityType === AvailabilityType.Single;
    },
    title() {
      return this.isEditMode ? "EDIT AVAILABILITY" : "ADD AVAILABILITY";
    },
    width() {
      if (this.availabilityType === AvailabilityType.Single) {
        return "30%";
      }
      return "60%";
    },
  },
  methods: {
    loadAvailability(id) {
      designerAvailabilityService.getAvailability(id).then((data) => {
        if (data.data.type === AvailabilityType.Single) {
          this.availabilityType = AvailabilityType.Single;
          this.singleAvailabilityModel = new SingleAvailability(
            data.data,
            this.designer.timezone
          );
          this.$emit("update:visible", true);
        } else {
          this.availabilityType = AvailabilityType.Recurring;
          this.calendarAvailabilityModel = new CalendarAvailability(
            data.data,
            this.designer.timezone
          );
          this.$emit("update:visible", true);
        }
      });
    },
    saveAvailability() {
      let currentModel =
        this.availabilityType == AvailabilityType.Single
          ? this.singleAvailabilityModel
          : this.calendarAvailabilityModel;

      if (currentModel.id !== null) {
        this.updateAvailability(currentModel);
      } else {
        this.addAvailability(currentModel);
      }
    },
    addAvailability(model) {
      let promise = null;

      if (model.type === AvailabilityType.Single) {
        promise = designerAvailabilityService.addSingleAvailability(
          this.designer,
          model
        );
      } else {
        promise = designerAvailabilityService.addCalendarAvailability(
          this.designer,
          model
        );
      }

      promise.then((data) => {
        if (data.data.result === OperationResultType.Success) {
          if (model.type === AvailabilityType.Recurring) {
            this.choosenSavedSchedule = null;
            this.$refs.calendarAvailabilityForm.loadSavedSchedules();
          }

          this.clearModels();
          this.$emit("added");
          this.$emit("update:visible", false);
          return;
        }

        this.$message({
          message: data.data.messages.join(" "),
          type: "error",
          showClose: true,
        });
      });
    },
    updateAvailability(model) {
      let promise = null;

      if (model.type === AvailabilityType.Single) {
        promise = designerAvailabilityService.updateSingleAvailability(
          this.designer,
          model
        );
      } else {
        promise = designerAvailabilityService.updateCalendarAvailability(
          this.designer,
          model
        );
      }

      promise.then((data) => {
        if (data.data.result === OperationResultType.Success) {
          this.clearModels();
          this.$emit("updated");
          this.$emit("update:visible", false);
          this.choosenSavedSchedule = null;
          this.$refs.calendarAvailabilityForm.loadSavedSchedules();
          return;
        }

        this.$message({
          message: data.data.messages.join(" "),
          type: "error",
          showClose: true,
        });
      });
    },
    deleteAvailability() {
      let id =
        this.availabilityType == AvailabilityType.Single
          ? this.singleAvailabilityModel.id
          : this.calendarAvailabilityModel.id;

      designerAvailabilityService.deleteAvailability(id).then((data) => {
        if (data.data.result === OperationResultType.Success) {
          this.clearModels();
          this.$emit("deleted");
          this.$emit("update:visible", false);
          this.$message({
            type: "success",
            message: "Delete completed",
          });
          return;
        }

        this.$message({
          message: data.data.messages.join(" "),
          type: "error",
          showClose: true,
        });
      });
    },
    clearModels() {
      this.singleAvailabilityModel = new SingleAvailability(
        null,
        this.designer.timezone
      );
      this.calendarAvailabilityModel = new CalendarAvailability(
        null,
        this.designer.timezone
      );
      this.choosenSavedSchedule = null;
    },
    cancel() {
      this.$emit("update:visible", false);
      this.clearModels();
    },
    openDeleteConfirmDialog() {
      this.$confirm("Do you want to delete this availability?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.deleteAvailability();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
  },
});
</script>

<template>
  <el-container v-loading.fullscreen.lock="loading">
    <el-header>
      <h1 class="details-header">Manufacturing Cycle Definitions</h1>
    </el-header>
    <el-main>
      <el-row>
        <el-button class="crud-add-button" @click="onAdd" plain
          >Create New</el-button
        >
      </el-row>
      <el-table
        :data="
          records.filter(
            (data) =>
              !search ||
              data.symbol.toLowerCase().includes(search.toLowerCase())
          )
        "
        style="width: 100%"
        stripe
      >
        <el-table-column prop="name" label="Name"> </el-table-column>
        <el-table-column label="Operations" width="120">
          <template slot-scope="scope">
            <el-button size="mini" @click="onChange(scope.row.id)" plain
              >Edit</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>
<script>
import Vue from "vue";

import _ from "lodash";
import { manufacturingCycleDefinitionService } from "../../services/manufacturingCycleDefinition.service";

export default Vue.extend({
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      columns: ["name"],
      search: "",
    };
  },
  methods: {
    getRecords() {
      manufacturingCycleDefinitionService.getRecords().then((response) => {
        this.records = response.data.map((item) => ({
          ...item,
        }));
        this.loading = false;
      });
    },
    onAdd() {
      this.$router.push({ path: "/ManufacturingCycleDefinition/Details/0" });
    },
    onChange(id) {
      this.$router.push({
        path: "/ManufacturingCycleDefinition/Details/" + id,
      });
    },
  },
});
</script>

export default class WorkflowStepModel {
  id: number;
  name: string;
  stepId: number;
  successName: string;
  successStepId: number;
  failureName: string;
  failureStepId: number;
  productionOrderStepNumber: string;
  checkSyncStatusOnly: boolean;

  constructor(data: any = null) {
    this.id = null;
    this.name = null;
    this.stepId = null;
    this.successName = "";
    this.failureName = "";
    this.successStepId = null;
    this.failureStepId = null;
    this.productionOrderStepNumber = null;
    this.checkSyncStatusOnly = false;

    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.stepId = data.stepId;
      this.successName = data.successName;
      this.failureName = data.failureName;
      this.successStepId = data.successStepId;
      this.failureStepId = data.failureStepId;
      this.productionOrderStepNumber = data.productionOrderStepNumber;
      this.checkSyncStatusOnly = data.checkSyncStatusOnly;
    }
  }
}

<template>
  <el-dialog
    width="25%"
    title="Edit document revision"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="editedItem"
      :rules="rules"
      ref="revisionForm"
      label-width="150px"
    >
      <el-form-item label="Name" prop="name">
        <el-input v-model="editedItem.name"></el-input>
      </el-form-item>
      <el-form-item label="Issue date" prop="issueDate">
        <el-date-picker
          v-model="editedItem.issueDate"
          style="width: 100%"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="Select date"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="Incompatible"
        prop="incompatible"
        v-if="isDocumentVersioned"
      >
        <el-checkbox
          v-model="editedItem.incompatible"
          :disabled="
            isFirstVersionInDocument ||
            ((item.isPartOfKeyCode || item.hasAssociatedProductVersions) &&
              item.incompatible)
          "
        ></el-checkbox>
      </el-form-item>
      <el-form-item
        label="Version Name"
        prop="versionName"
        v-if="
          (isDocumentVersioned && isFirstVersionInDocument) ||
          (isFirstRevisionInVersion && editedItem.incompatible) ||
          (!isFirstRevisionInVersion && editedItem.incompatible)
        "
      >
        <el-input
          v-model="editedItem.versionName"
          :disabled="!isFirstRevisionInVersion && !editedItem.incompatible"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Save</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import _ from "lodash";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isFirstRevisionInVersion: {
      type: Boolean,
      default: false,
    },
    isFirstVersionInDocument: {
      type: Boolean,
      default: false,
    },
    isDocumentVersioned: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          id: null,
          name: null,
          issueDate: null,
          incompatible: false,
          versionName: null,
          hasAssociatedProductVersions: false,
          isPartOfKeyCode: false,
        };
      },
    },
  },
  data() {
    return {
      editedItem: {
        type: Object,
        default: {
          id: null,
          name: null,
          issueDate: null,
          incompatible: false,
          versionName: null,
        },
      },
      rules: {
        name: [{ required: true, message: "Name is required" }],
        issueDate: [{ required: true, message: "Issue date is required" }],
        versionName: [{ required: true, message: "Version Name is required" }],
      },
    };
  },
  computed: {},
  methods: {
    submitForm() {
      this.$refs.revisionForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.editedItem);
        }
        return false;
      });
    },
    closeForm() {
      this.$refs.revisionForm.resetFields();
      this.$emit("update:visible", false);
      this.editedItem = {
        id: null,
        name: null,
        issueDate: null,
        incompatible: false,
        versionName: null,
      };
    },
  },
  watch: {
    visible: function (isVisible) {
      if (isVisible) {
        this.editedItem = _.cloneDeep(this.item);
      }
    },
  },
});
</script>

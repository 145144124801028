import WorkProcedureModel from "./WorkProcedureModel";

export default class ManufacturingCycleDefinitionModel {
  id: number;
  name: string;
  workProcedures: WorkProcedureModel[];

  constructor(data: any = null) {
    this.id = null;
    this.name = null;
    this.workProcedures = [];

    if (data != null) {
      this.id = data.id;
      this.name = data.name;

      for (const workProcedure of data.workProcedures) {
        const workProcedureModel = new WorkProcedureModel(workProcedure);
        this.workProcedures.push(workProcedureModel);
      }
    }
  }
}

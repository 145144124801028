import moment from "moment";
import "moment-timezone";

export default class DesignerDetails {
  id: number = null;
  firstName = "";
  lastName = "";
  locationId: number;
  sessionsPerDay: number;
  location = "";
  locationTimezoneName = "";
  timezone: string = moment.tz.guess();
}

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">DOCUMENT IMPORT</h1>
      </div>
    </el-row>
    <el-row>
      <el-form
        :model="item"
        :rules="rules"
        ref="importForm"
        label-width="140px"
      >
        <el-form-item label="File to import" prop="file">
          <div>
            <el-button
              style="margin-bottom: 5px"
              @click="$refs.fileInput.click()"
              >Choose file</el-button
            >
            <span>{{ chosenFileName }}</span>
            <input type="file" @change="onFileChange" ref="fileInput" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button style="margin-bottom: 5px" @click="onSubmit"
            >Submit</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { documentImportService } from "../../../services/edoc/documentImport.service";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  data() {
    return {
      item: {
        file: null,
      },
      rules: {
        file: [{ required: true, message: "File is required" }],
      },
      chosenFileName: "",
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      if (this.item.file == null) {
        this.$refs.importForm.validate((valid) => {
          //Do nothing
        });
      } else {
        this.importDocumentRevision();
      }
    },
    onFileChange(event) {
      this.item.file = event.target.files[0];

      if (this.item.file != null) {
        this.chosenFileName = this.item.file.name;
      } else {
        this.chosenFileName = "";
        this.$refs.fileInput.value = "";
      }
    },
    importDocumentRevision() {
      this.loading = true;
      documentImportService.importDocument(this.item.file).then(
        (response) => {
          this.$router.push({
            path: `/Documentation/DocumentImport/${response.data}`,
          });
        },
        (error) => {
          this.$alert(error.response.data, "Validation Error", {
            confirmButtonText: "OK",
            type: "error",
          });
          this.loading = false;
          this.item.file = null;
          this.chosenFileName = "";
          this.$refs.fileInput.value = "";
        }
      );
    },
  },
});
</script>
<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
</style>

<template>
  <div>
    <el-dialog
      width="35%"
      title="Edit keycode"
      :visible.sync="visible"
      :append-to-body="true"
      :before-close="closeForm"
    >
      <el-form :model="editedItem" ref="keyCodeForm" label-width="150px">
        <el-form-item label="Keycode" prop="code">
          <el-input disabled v-model="editedItem.code"></el-input>
        </el-form-item>
        <el-form-item label="Products" prop="productVersionIds">
          <el-select
            multiple
            filterable
            v-model="editedItem.productVersionIds"
            style="width: 100%"
          >
            <el-option
              v-for="product in products"
              :key="'product-' + product.id + 'version-' + product.versionName"
              :label="product.gtin + ' (' + product.versionName + ')'"
              :value="product.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeForm">Cancel</el-button>
        <el-button type="primary" @click="submitForm">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import { keyCodeService } from "../../../services/edoc/keycode.service";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          id: null,
          code: "",
          productVersionIds: [],
        };
      },
    },
  },
  data() {
    return {
      editedItem: {
        type: Object,
        default: {
          id: null,
          code: "",
          productVersionIds: [],
        },
      },
      products: [],
    };
  },
  methods: {
    submitForm() {
      this.$refs.keyCodeForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            keyCodeId: this.editedItem.keyCodeId,
            productVersionIds: this.editedItem.productVersionIds,
          });
        }
        return false;
      });
    },
    closeForm() {
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.$refs.keyCodeForm.resetFields();
      this.editedItem = {
        id: null,
        code: "",
        productVersionIds: [],
      };
    },
  },
  watch: {
    visible: function (isVisible) {
      if (isVisible) {
        this.editedItem = {
          keyCodeId: this.item.id,
          code: this.item.code,
          productVersionIds: this.item.products.map((p) => p.id),
        };

        keyCodeService
          .getAvailableProducts(this.editedItem.keyCodeId)
          .then((response) => {
            this.products = response.data;
          });
      }
    },
  },
});
</script>

<template>
  <el-card>
    <div slot="header">
      <h4>CHANGES</h4>
    </div>
    <el-table :data="changes" stripe>
      <el-table-column label="Field" prop="changeType" align="center">
        <template slot-scope="scope">{{
          getFormattedChangeType(scope.row)
        }}</template>
      </el-table-column>
      <el-table-column label="Old value" prop="oldValue" align="center">
        <template slot-scope="scope">
          <div class="long-text">{{ scope.row.oldValue }}</div>
        </template>
      </el-table-column>
      <el-table-column label="New value" prop="newValue" align="center">
        <template slot-scope="scope">
          <div class="long-text">{{ scope.row.newValue }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Date" prop="time" align="center">
        <template slot-scope="scope">{{
          scope.row.time | formatDateTimeOffset
        }}</template>
      </el-table-column>
      <el-table-column label="User" prop="userId" align="center">
        <template slot-scope="scope">{{
          getUserInfo(scope.row.userId)
        }}</template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { getUsers } from "../../../store/modules/mutationTypes";

export default Vue.extend({
  created() {
    this.getUsers();
  },
  props: {
    changes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState("users", ["users"]),
  },
  methods: {
    ...mapActions("users", [getUsers]),
    getUserInfo(userId) {
      const user = this.users.find((u) => u.id === userId);
      if (user == null) {
        return "";
      }

      return `${user.firstName} ${user.lastName}`;
    },
    getFormattedChangeType(change) {
      if (change.additionalInfo && change.additionalInfo !== "") {
        return `${change.changeType} (${change.additionalInfo})`;
      } else {
        return change.changeType;
      }
    },
  },
});
</script>
<style lang="scss">
h4 {
  padding-left: 20px;
  display: inline-block;
}

.long-text {
  word-wrap: break-word;
}
</style>

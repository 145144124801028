<template>
  <div>
    <router-link :to="'/Users'"
      ><el-button
        type="primary"
        icon="el-icon-arrow-left"
        style="margin-bottom: 10px"
        >Previous Page</el-button
      ></router-link
    >
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card>
          <div slot="header">
            <h4 style="padding-left: 20px">User Details</h4>
          </div>
          <user-info :model="userInfo" @save="saveUserInfo"></user-info>
          <el-button @click="resetPassword">Reset password</el-button>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header">
            <h4 style="padding-left: 20px">Roles</h4>
          </div>
          <user-roles
            :roles.sync="userRoles"
            @save="saveUserRoles"
          ></user-roles>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header">
            <h4 style="padding-left: 20px">Permissions</h4>
          </div>
          <user-permissions
            :permissions.sync="userPermissions"
            @save="saveUserPermissions"
          ></user-permissions>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12" v-if="eDocRoles && eDocRoles.length > 0">
        <el-card class="box-card">
          <div slot="header">
            <h4 style="padding-left: 20px">eDOC Roles</h4>
          </div>
          <edoc-user-roles
            :roles.sync="eDocRoles"
            @save="saveEDocRoles"
          ></edoc-user-roles>
        </el-card>
      </el-col>
      <el-col :span="12" v-if="eDocPermissions && eDocPermissions.length > 0">
        <el-card class="box-card">
          <div slot="header">
            <h4 style="padding-left: 20px">eDOC Permissions</h4>
          </div>
          <edoc-user-permissions
            :permissions.sync="eDocPermissions"
            @save="saveEDocPermissions"
          ></edoc-user-permissions>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="Reset password"
      :visible.sync="resetPasswordInfo.dialogVisible"
    >
      <span
        >Password reset token has been generated. Please send it to user to
        complete password reset process.</span
      >
      <div style="margin-top: 15px">
        <div style="display: inline-block; width: 80%">
          <el-input size="small" v-model="resetPasswordInfo.url"></el-input>
        </div>
        <div style="display: inline-block">
          <el-tooltip content="Copy to clipboard" placement="top-start">
            <el-button
              icon="el-icon-document-copy"
              v-clipboard:copy="resetPasswordInfo.url"
              v-clipboard:success="onPasswordUrlCopy"
            ></el-button>
          </el-tooltip>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Set password"
      :visible="setPasswordDialogVisible"
      @close="onSetPasswordDialogClose"
    >
      <span
        >Password setup token has been generated. Please send it to user to
        complete account setup process.</span
      >
      <div style="margin-top: 15px">
        <div style="display: inline-block; width: 80%">
          <el-input size="small" v-model="passwordSetupUrl"></el-input>
        </div>
        <div style="display: inline-block">
          <el-tooltip content="Copy to clipboard" placement="top-start">
            <el-button
              icon="el-icon-document-copy"
              v-clipboard:copy="passwordSetupUrl"
              v-clipboard:success="onPasswordUrlCopy"
            ></el-button>
          </el-tooltip>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UserInfo from "./UserInfo.vue";
import UserPermissions from "./UserPermissions.vue";
import EdocUserPermissions from "./EdocUserPermissions.vue";
import EdocUserRoles from "./EdocUserRoles.vue";
import UserRoles from "./UserRoles.vue";

import { PermissionSource } from "../../constants/PermissionSource";

import { setPasswordSetupUrl } from "../../store/modules/mutationTypes";

import Vue from "vue";

import { showLoading, hideLoading } from "../../store/mutation-types";
import { mapState, mapMutations } from "vuex";

import { userService } from "../../services/users.service";
import UserInfoModel from "../../models/UserInfoModel";
import UserPermissionModel from "../../models/UserPermissionModel";
import UserRoleModel from "../../models/UserRoleModel";

import UserPermissionSourceModel from "../../models/UserPermissionSourceModel";

export default Vue.extend({
  components: {
    UserInfo,
    UserPermissions,
    UserRoles,
    EdocUserPermissions,
    EdocUserRoles,
  },
  created() {
    userService
      .getUserInfo(this.userId)
      .then((response) => (this.userInfo = new UserInfoModel(response.data)));
    this.buildUserPermissions();
    this.loadEDocPermissions();
    this.loadEDocRoles();

    userService
      .getUserRoles(this.userId)
      .then(
        (response) =>
          (this.userRoles = response.data.map(
            (item) => new UserRoleModel(item)
          ))
      );
  },
  data() {
    return {
      columns: ["label", "granted"],
      options: {
        editableColumns: ["granted"],
      },
      userInfo: new UserInfoModel(),
      userPermissions: [],
      eDocPermissions: [],
      userRoles: [],
      eDocRoles: [],
      resetPasswordInfo: {
        dialogVisible: false,
        url: "",
      },
    };
  },
  computed: {
    ...mapState({
      passwordSetupUrl: (state) => state.users.passwordSetupUrl,
    }),
    userId() {
      return this.$route.params.id;
    },
    setPasswordDialogVisible() {
      return this.passwordSetupUrl != "";
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapMutations("users", [setPasswordSetupUrl]),
    saveUserInfo() {
      this.showLoading();

      userService.saveUserInfo(this.userInfo).then((response) => {
        var data = response.data;

        if (data.succeeded) {
          this.$message({
            message: "User Details updated.",
            type: "success",
          });

          userService
            .getUserInfo(this.userId)
            .then(
              (response) => (this.userInfo = new UserInfoModel(response.data))
            );
        } else {
          this.$message({
            message: data.errors[0],
            showClose: true,
            duration: 0,
            type: "error",
          });
        }

        this.hideLoading();
      });
    },
    saveUserPermissions() {
      this.showLoading();

      let permissions = this.userPermissions;

      var grantedPermissions = permissions.filter((p) => p.granted);
      var revokedPermissions = permissions.filter((p) => !p.granted);

      var crtPermissionsToGrant = grantedPermissions.filter(
        (p) =>
          p.sources.find((s) => s.name === PermissionSource.Crt) !== undefined
      );
      var crtPermissionsToRevoke = revokedPermissions.filter(
        (p) =>
          p.sources.find((s) => s.name === PermissionSource.Crt) !== undefined
      );

      var crtGrantPromise = userService.grantCrtPermissions(
        this.userId,
        crtPermissionsToGrant.map(
          (p) => p.sources.find((s) => s.name === PermissionSource.Crt).id
        )
      );
      var crtRevokePromise = userService.revokeCrtPermissions(
        this.userId,
        crtPermissionsToRevoke.map(
          (p) => p.sources.find((s) => s.name === PermissionSource.Crt).id
        )
      );

      var userPermissions = grantedPermissions.filter(
        (p) =>
          p.sources.find((s) => s.name === PermissionSource.User) !== undefined
      );

      var usePermissionsPromise = userService.grantUserPermissions(
        this.userId,
        userPermissions.map(
          (p) => p.sources.find((s) => s.name === PermissionSource.User).id
        )
      );

      Promise.all([
        crtGrantPromise,
        crtRevokePromise,
        usePermissionsPromise,
      ]).then(() => {
        this.$message({
          message: "Roles saved.",
          type: "success",
        });

        this.hideLoading();
      });
    },
    saveUserRoles() {
      this.showLoading();

      var grantedRoles = this.userRoles.filter((p) => p.granted);
      var revokedRoles = this.userRoles.filter((p) => !p.granted);

      var assignPromise = userService.assignRoles(
        this.userId,
        grantedRoles.map((p) => p.id)
      );
      var dissociatePromise = userService.dissociateRoles(
        this.userId,
        revokedRoles.map((p) => p.id)
      );
      Promise.all([assignPromise, dissociatePromise]).then(() => {
        this.$message({
          message: "Role saved.",
          type: "success",
        });

        this.hideLoading();
      });
    },
    resetPassword() {
      this.$confirm("Do you want to reset password for this user?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        userService.resetPassword(this.userId).then((response) => {
          var data = response.data;

          if (data.succeeded) {
            this.resetPasswordInfo.dialogVisible = true;
            this.resetPasswordInfo.url = data.resetPasswordUrl;
          }
        });
      });
    },
    onSetPasswordDialogClose() {
      this.setPasswordSetupUrl("");
    },
    onPasswordUrlCopy() {
      this.$message({
        message: "Copied",
        type: "success",
        duration: 1000,
      });
    },
    buildUserPermissions() {
      let crtPermissionsPromise = userService
        .getCrtUserPermissions(this.userId)
        .then((response) => {
          return Promise.resolve({
            name: "crtPermissions",
            result: response.data.map((item) => {
              var permission = new UserPermissionModel(
                item.name,
                item.code,
                item.granted
              );
              permission.sources.push(
                new UserPermissionSourceModel(item.id, PermissionSource.Crt)
              );
              return permission;
            }),
          });
        });

      let userManagementPermissionsPromise = userService
        .getUserManagementPermissions()
        .then((r) =>
          Promise.resolve({
            name: "allUserPermissions",
            result: r.data,
          })
        );
      let grantedUserManagementPermissionsPromise = userService
        .getAssignedUserManagementPermissions(this.userId)
        .then((r) =>
          Promise.resolve({
            name: "grantedUserPermissions",
            result: r.data,
          })
        );

      var userManagementPromise = Promise.all([
        userManagementPermissionsPromise,
        grantedUserManagementPermissionsPromise,
      ]).then((results) => {
        var lookup = results.reduce((prev, curr) => {
          prev[curr.name] = curr.result;
          return prev;
        }, {});

        let allPermissions = lookup["allUserPermissions"];
        let grantedPermissions = lookup["grantedUserPermissions"];

        return {
          name: "userPermissions",
          result: allPermissions.map((m) => {
            var isGranted =
              grantedPermissions.find((item) => item.id == m.id) !== undefined;

            var permission = new UserPermissionModel(null, m.name, isGranted);
            permission.sources.push(
              new UserPermissionSourceModel(m.id, PermissionSource.User)
            );

            return permission;
          }),
        };
      });

      Promise.all([crtPermissionsPromise, userManagementPromise]).then((r) => {
        var lookup = r.reduce((prev, curr) => {
          prev[curr.name] = curr.result;
          return prev;
        }, {});

        var crtPermissions = lookup["crtPermissions"];
        var userPermissions = lookup["userPermissions"];

        var allPermisions = Array.from(
          new Set(
            crtPermissions
              .map((p) => p.code)
              .concat(userPermissions.map((p) => p.code))
          )
        );

        this.userPermissions = allPermisions.map((p) => {
          var crtPermission = crtPermissions.find((item) => item.code == p);
          var userPermission = userPermissions.find((item) => item.code == p);

          var permission = new UserPermissionModel(
            crtPermission && crtPermission.name,
            p,
            (crtPermission && crtPermission.granted) ||
              (userPermission && userPermission.granted)
          );

          if (crtPermission !== undefined) {
            permission.sources.push(...crtPermission.sources);
          }

          if (userPermission !== undefined) {
            permission.sources.push(...userPermission.sources);
          }

          return permission;
        });
      });
    },
    loadEDocPermissions() {
      userService.getEDocPermissions(this.userId).then(
        (response) => {
          this.eDocPermissions = response.data;
        },
        (error) => {
          this.eDocPermissions = [];
        }
      );
    },
    loadEDocRoles() {
      userService.getEDocRoles(this.userId).then(
        (response) => {
          this.eDocRoles = response.data;
        },
        (error) => {
          this.eDocRoles = [];
        }
      );
    },
    saveEDocPermissions() {
      this.showLoading();

      let permissions = this.eDocPermissions;

      var grantedPermissions = permissions.filter((p) => p.granted);
      var revokedPermissions = permissions.filter((p) => !p.granted);

      var grantPermissionsPromise = userService.grantEDocPermissions(
        this.userId,
        grantedPermissions.map((p) => p.id)
      );
      var revokePermissionsPromise = userService.revokeEDocPermissions(
        this.userId,
        revokedPermissions.map((p) => p.id)
      );

      Promise.all([grantPermissionsPromise, revokePermissionsPromise]).then(
        () => {
          this.$message({
            message: "Permissions saved.",
            type: "success",
          });

          this.hideLoading();
        }
      );
    },
    saveEDocRoles() {
      this.showLoading();

      let roles = this.eDocRoles;

      var grantedRoles = roles.filter((p) => p.granted);
      var revokedRoles = roles.filter((p) => !p.granted);

      var grantRolesPromise = userService.grantEDocRoles(
        this.userId,
        grantedRoles.map((p) => p.id)
      );
      var revokeRolesPromise = userService.revokeEDocRoles(
        this.userId,
        revokedRoles.map((p) => p.id)
      );

      Promise.all([grantRolesPromise, revokeRolesPromise]).then(() => {
        this.$message({
          message: "Roles saved.",
          type: "success",
        });

        this.hideLoading();
      });
    },
  },
});
</script>

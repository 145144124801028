<template>
  <el-dialog
    title="Add link"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="dialogForm"
      :rules="rules"
      ref="planLinkForm"
      label-width="120px"
    >
      <el-form-item label="Plan Id" prop="requestId">
        <el-select
          v-model="dialogForm.requestId"
          filterable
          remote
          placeholder="Type to search"
          :remote-method="getRequestIds"
          :loading="loading"
        >
          <el-option
            v-for="requestId in currentRequestIds"
            :key="requestId"
            :label="requestId"
            :value="requestId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Link Type" prop="linkType">
        <el-radio-group v-model="dialogForm.linkType">
          <el-radio
            v-for="link in linkTypes"
            :label="link.type"
            :key="link.type"
            >{{ link.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Comment">
        <el-input v-model="dialogForm.comment"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Confirm</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { fulfilmentPlanListService } from "@/services/fulfilmentplanlist.service";
export default {
  props: {
    linkTypes: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogForm: {
        requestId: null,
        linkType: null,
        comment: "",
      },
      currentRequestIds: [],
      loading: false,
      rules: {
        requestId: [{ required: true, message: "Please select plan id" }],
        linkType: [{ required: true, message: "Please select link type" }],
      },
    };
  },
  watch: {
    visible: function (oldValue, newValue) {
      if (newValue === true) {
        this.dialogForm.requestId = null;
        this.dialogForm.linkType = null;
        this.dialogForm.comment = "";
      }
    },
  },
  methods: {
    getRequestIds(query) {
      fulfilmentPlanListService.filterRequests(query).then((response) => {
        const result = response.data;
        this.currentRequestIds = result.data;
      });
    },
    submitForm() {
      this.$refs.planLinkForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.dialogForm);
        }
        return false;
      });
    },
    closeForm() {
      this.$emit("close");
    },
  },
};
</script>





























































import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import DateTime from "../../mixins/DateTime";
import CommentModel from "@/models/CommentModel";
import CommentFormModel from "@/models/CommentFormModel";

export default Vue.extend({
  mixins: [DateTime],
  props: {
    comments: {
      required: true,
      type: Array as PropType<Array<CommentModel>>,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "100%",
    },
    buttonSize: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      form: new CommentFormModel(),
      rules: {
        text: [
          {
            required: true,
            message: "Comment can't be empty",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    dialogTitle() {
      return this.form.id === null ? "Add new comment" : "Edit comment";
    },
  },
  watch: {
    dialogVisible: function (newValue) {
      if (!newValue) {
        this.form = new CommentFormModel();
      }
    },
  },
  methods: {
    canEditOrDelete(row: CommentModel) {
      return row.userId === parseInt(this.oidcUser.sub);
    },
    openDialog() {
      this.$emit("update:dialogVisible", true);
    },
    closeForm() {
      this.$emit("update:dialogVisible", false);
    },
    openEditDialog(comment: CommentModel) {
      this.form.id = comment.id;
      this.form.text = comment.text;
      this.$emit("update:dialogVisible", true);
    },
    onSubmitDialog() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id !== null) {
            this.$emit("edit", this.form);
          } else {
            this.$emit("add", this.form);
          }
        } else {
          return false;
        }
      });
    },
    handleDelete(row) {
      this.$confirm("Do you want to remove this comment?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("delete", row);
      });
    },
  },
});

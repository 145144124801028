export default class {
  productVersions: any[];
  needsKeyCode: boolean;

  constructor(data: any = null) {
    if (data !== null) {
      this.productVersions = data.productVersions;
      this.needsKeyCode = data.needsKeyCode;
    }
  }
}

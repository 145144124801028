export default class MessageTypeHelper {
  static getDescription(type) {
    if (type == 0) {
      return "info";
    }
    if (type == 1) {
      return "success";
    }
    if (type == 2) {
      return "warning";
    }
    if (type == 3) {
      return "error";
    }
  }
}

<template>
  <div>
    <el-card style="width: 100%">
      <div slot="header">
        <h4 style="padding-left: 20px; display: inline-block">
          Combined Request Ids
        </h4>
        <el-button
          style="float: right"
          :disabled="!showAdd"
          v-on:click="isDialogVisible = true"
          >Add</el-button
        >
      </div>
      <div style="min-height: 99px">
        <el-tag
          v-for="manufacturingJob in manufacturingJobs"
          :key="manufacturingJob.id"
          disable-transitions
          closable
          :type="getRequestTagType(manufacturingJob)"
          v-on:close="onDeleteClick(manufacturingJob)"
        >
          <router-link
            :to="
              '/ManufacturingCycle/Details/' + manufacturingJob.fulfilmentPlanId
            "
          >
            {{
              manufacturingJob.requestId +
              " (" +
              manufacturingJob.workflow +
              (manufacturingJob.passNumber > 1
                ? ", " + manufacturingJob.passNumber
                : "") +
              ")"
            }}
          </router-link>
        </el-tag>
      </div>
    </el-card>
    <el-dialog
      :visible.sync="isDialogVisible"
      v-on:closed="onDialogClose"
      v-on:open="getCandidates"
      title="Add Request Id"
      width="fit-content"
    >
      <el-form :model="form" :rules="rules" ref="form" :inline="true">
        <div v-loading="isDataLoading">
          <el-form-item label="Request Id" prop="selectedFulfilmentPlanId">
            <el-select v-model="form.selectedFulfilmentPlanId" filterable :filter-method="requestIdsCandidatesFilter" @focus="requestIdsCandidatesReset">            
              <el-option
                v-for="item in requestIdsCandidates"
                :label="item.requestId"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Workflow" prop="selectedManufacturingJobId">
            <el-select v-model="form.selectedManufacturingJobId">
              <el-option
                v-for="item in availableManufacturingJobs"
                :label="item.workflow"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer">
        <el-button v-on:click="isDialogVisible = false">Cancel</el-button>
        <el-button type="primary" v-on:click="onAddClick">Add</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import CombinedOrderCandidateModel from "../../models/CombinedOrderCandidateModel";
import { OperationResultType } from "../../enums/enums";
import { combinedOrderService } from "../../services/combinedorder.service";

export default Vue.extend({
  props: {
    manufacturingJobs: {
      type: Array,
      default() {
        return [];
      },
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    availableManufacturingJobs: function () {
      let selectedFulfilmentPlan = this.availableCombinedOrderCandidates.find(
        (candidate) => {
          return candidate.id == this.form.selectedFulfilmentPlanId;
        }
      );

      return selectedFulfilmentPlan
        ? selectedFulfilmentPlan.manufacturingJobs
        : [];
    },
  },
  data() {
    return {
      isDialogVisible: false,
      isDataLoading: false,
      form: {
        selectedFulfilmentPlanId: null,
        selectedManufacturingJobId: null,
      },
      rules: {
        selectedFulfilmentPlanId: [
          { required: true, message: "Request Id is required" },
        ],
        selectedManufacturingJobId: [
          { required: true, message: "Workflow is required" },
        ],
      },
      availableCombinedOrderCandidates: [],
      requestIdsCandidates: [],
    };
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    getCandidates() {
      this.isDataLoading = true;
      var promise = combinedOrderService.getCandidates(
        this.$store.state.combinedOrder.combinedOrderId
      );
      promise
        .then((data) => {
          this.availableCombinedOrderCandidates = data.data.map((item) => {
            return new CombinedOrderCandidateModel(item);
          });
          this.requestIdsCandidates = this.availableCombinedOrderCandidates;
        })
        .finally(() => (this.isDataLoading = false));
    },
    onDialogClose() {
      this.$refs.form.resetFields();
    },
    onAddClick: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.showLoading();
          combinedOrderService
            .addCombinedPlan(
              this.$store.state.combinedOrder.combinedOrderId,
              this.form.selectedManufacturingJobId
            )
            .then((result) => {
              this.hideLoading();
              if (result.data.result == OperationResultType.Success) {
                this.isDialogVisible = false;
                this.$emit("change", this.form.selectedManufacturingJobId);
              } else {
                this.$message.error(result.data.message);
                this.isDialogVisible = false;
                this.$emit("change", null);
              }
            });
        } else {
          return false;
        }
      });
    },
    onDeleteClick: function (item) {
      this.$confirm(
        "This will delete Combined Request Id. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        combinedOrderService
          .deleteCombinedPlan(
            this.$store.state.combinedOrder.combinedOrderId,
            item.id
          )
          .then((result) => {
            var data = result.data;

            if (data.result === OperationResultType.Error) {
              this.$message.error(data.message);
              return;
            }

            this.$message({
              message: "Combined Request Id has been removed.",
              type: "success",
            });

            this.$emit("change", item.id);
          });
      });
    },
    requestIdsCandidatesFilter(query) {
      this.requestIdsCandidates = this.availableCombinedOrderCandidates.filter((item) => item.requestId.startsWith(query.trim()));
    },
    requestIdsCandidatesReset() {
      this.requestIdsCandidates = this.availableCombinedOrderCandidates;
    },
    getRequestTagType(manufacturingJob){
      if(!manufacturingJob.isReadyForManufacturing){
        return "danger";
      } 
      
    }
  },
});
</script>
<style lang="scss" scoped>
.el-tag {
  margin: 5px;
}
</style>

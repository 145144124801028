<template>
  <div>
    <h4>No privileges assigned. Please contact system administrator.</h4>
    <el-button type="primary" size="medium" @click="signOut"
      >Sign out</el-button
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("oidcStore", ["signOutOidc", "clearStaleState"]),
    signOut() {
      this.clearStaleState();
      this.signOutOidc();
    },
  },
};
</script>

<template>
  <div class="grid-content">
    <el-card style="width: 100%">
      <div slot="header">
        <h4 style="padding-left: 20px">Request Information</h4>
      </div>
      <el-form
        ref="form"
        label-width="140px"
        size="mini"
        style="width: 100%"
        v-loading="loadingRequestInformation || editionLock"
      >
        <el-form-item label="Category" class="form-item-padding">
          <editable-select
            v-model="model.category"
            :selectList="model.availableRequestCategories"
            :editable="false"
          ></editable-select>
        </el-form-item>
        <el-form-item
          label="Request ID"
          v-if="notPrototypeAndDesignOnly"
          class="form-item-padding"
        >
          <div
            v-if="
              configuration.isManufacturingOnly &&
              configuration.isAssociatedWithCustomerOrder
            "
            style="font-size: 12px; padding-left: 15px"
          >
            <router-link
              target="_blank"
              rel="noopener noreferrer"
              :to="underlyingRequestUrl"
              >{{ model.requestId }}</router-link
            >
          </div>
          <div v-else style="font-size: 12px; padding-left: 15px">
            <a target="_blank" rel="noopener noreferrer" :href="model.url">
              {{ model.requestId }}
            </a>
            <el-button
              v-if="canManageFulfilmentPlan"
              style="margin-left: 5px"
              @click="reimportRequest()"
              >Reimport</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          v-if="configuration.isManufacturingOnly"
          label="Order Number"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <editable-input
              v-model="model.orderNumber"
              @input="editOrderNumber(false)"
              :editable="
                !configuration.isAssociatedWithCustomerOrder ||
                configuration.isManufacturingOnly
              "
            ></editable-input>
          </div>
        </el-form-item>
        <el-form-item label="Product Type" class="form-item-padding">
          <div style="font-size: 12px; padding-left: 15px">
            {{ model.productType }}
          </div>
        </el-form-item>
        <el-form-item label="Product Number" class="form-item-padding">
          <div v-if="!isReorder">
            <editable-select
              v-model="model.productNumber"
              :selectList="model.availableProductsList"
              @input="editProductNumber(false)"
              :editable="canManageFulfilmentPlan"
            ></editable-select>
          </div>
          <div v-else>
            <editable-select
              v-model="model.productNumber"
              :selectList="model.availableProductsList"
              :editable="false"
            ></editable-select>
          </div>
        </el-form-item>
        <el-form-item
          v-if="configuration.isAssociatedWithCustomerOrder"
          label="Design Revision"
          class="form-item-padding"
        >
          <editable-number-input
            v-model="model.designRevision"
            :editable="false"
          ></editable-number-input>
        </el-form-item>
        <el-form-item
          v-if="everythingButPrototypeAndRepeatOrder"
          label="Distribution Center"
          class="form-item-padding"
        >
          <div>
            <editable-select
              v-model="model.distributionCenterId"
              :selectList="model.availableDistributionCenters"
              @input="editDistributionCenter(false)"
              :editable="canBeCreatedManually && canManageFulfilmentPlan"
            ></editable-select>
          </div>
        </el-form-item>
        <el-form-item
          v-if="model.showPONumberField || isReorder"
          label="PO Number"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <editable-input
              v-model="model.poNumber"
              @input="editPONumber"
              :editable="canManageFulfilmentPlan"
            ></editable-input>
          </div>
        </el-form-item>
        <el-form-item
          v-if="
            model.showPONumberField &&
            !configuration.isManufacturingOnly &&
            configuration.isAssociatedWithCustomerOrder
          "
          label="PO Number Date"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <editable-date-picker
              v-model="model.poNumberDate"
              @input="editPONumberDate"
              :editable="canManageFulfilmentPlan"
            ></editable-date-picker>
          </div>
        </el-form-item>
        <el-form-item
          v-if="model.isProductTmj"
          label="RA Approval"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <el-checkbox
              v-model="model.raApproval"
              @change="editRaApproval"
              :disabled="!canManageFulfilmentPlan"
            ></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item
          label="Loaner Set"
          class="form-item-padding"
          v-if="model.isProductTmj"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <el-checkbox
              v-model="model.loanerSet"
              @change="editLoanerSet"
              :disabled="!canManageFulfilmentPlan"
            ></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item
          v-if="isReorder || isERequestAndSMRP"
          label="Quantity"
          class="form-item-padding"
        >
          <editable-number-input
            v-model="model.quantity"
            @input="editQuantity"
            :editable="canManageFulfilmentPlan"
          ></editable-number-input>
        </el-form-item>
        <el-form-item label="Initiated" class="form-item-padding">
          <div style="font-size: 12px; padding-left: 15px">
            {{ model.initiated }}
            <i class="el-icon-time"></i>
          </div>
        </el-form-item>
        <el-form-item
          v-if="
            (!configuration.isManufacturingOnly &&
              configuration.isAssociatedWithCustomerOrder) ||
            model.plannedSurgeryDate
          "
          label="Planned surgery"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            {{ model.plannedSurgeryDate }}
            <i class="el-icon-time"></i>
          </div>
        </el-form-item>
        <el-form-item
          v-if="model.isProductTmj"
          label="All Ti"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <el-checkbox v-model="model.hasMetalAllergy" disabled></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item
          v-if="
            !configuration.isManufacturingOnly &&
            !configuration.designSessionOnly
          "
          label="Status"
          class="form-item-padding"
        >
          <editable-select
            v-model="model.status"
            :selectList="model.availableStatusList"
            @input="editRequestStatus(false)"
            :editable="canBeCreatedManually && canManageFulfilmentPlan"
          ></editable-select>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import {
  EditableSelect,
  EditableInput,
  EditableNumberInput,
  EditableDatePicker,
} from "../shared/Shared.vue";
import { OperationResultType, RequestCategory, ProductType } from "../../enums/enums";
import RequestInformationModel from "../../models/fulfilment-plan/RequestInformationModel";
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    EditableSelect,
    EditableInput,
    EditableNumberInput,
    EditableDatePicker,
  },
  data() {
    return {
      model: new RequestInformationModel(null),
      loadingRequestInformation: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initRequestInformation(this.$route.params.id);
    });
  },
  computed: {
    notPrototypeAndDesignOnly() {
      return (
        this.configuration.isAssociatedWithCustomerOrder ||
        (!this.configuration.isManufacturingOnly &&
          !this.configuration.designSessionOnly)
      );
    },
    everythingButPrototypeAndRepeatOrder() {
      return (
        !this.configuration.isManufacturingOnly && 
        !this.configuration.canBeCreatedManually
      );
    },
    isReorder() {
      return (
        this.configuration.isManufacturingOnly &&
        this.configuration.isAssociatedWithCustomerOrder
      );
    },
    isERequestAndSMRP() {
      return (
        this.model.productType === ProductType.SMRP &&
        this.model.category === RequestCategory.eRequest
      );
    },
    computedCategory() {
      return this.model.category;
    },
    ...mapState("fulfilmentPlanDetails", [
      "timestamp",
      "requestInformationRefresh",
      "canBeCreatedManually",
      "canManageFulfilmentPlan",
      "editionLock",
    ]),
    underlyingRequestUrl() {
      return "/FulfilmentPlan/Details/" + this.model.url;
    },
  },
  watch: {
    requestInformationRefresh: function () {
      if (this.requestInformationRefresh) {
        this.getRequestInformation(this.model.id);
        this.setRequestInformationRefresh(false);
      }
    },
    computedCategory: function () {
      this.$emit("input", this.computedCategory);
    },
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions("fulfilmentPlanDetails", [
      "storeTimestamp",
      "setEditionLock",
      "setManufacturingUrl",
      "setCanBeCreatedManually",
      "setCanManageFulfilmentPlan",
      "setCanManageManufacturingCycles",
      "setChangesRefresh",
      "setManufacturingInformationRefresh",
      "setRequestInformationRefresh",
      "setMilestoneRefresh",
      "setPlanDataRefresh",
      "setTimelineRefresh",
      "setPrototypeManufacturingUrl",
    ]),
    initRequestInformation(id) {
      this.loadingRequestInformation = true;
      fulfilmentPlanDetailsService
        .getRequestInformation(id)
        .then((response) => {
          const data = response.data;
          this.model = new RequestInformationModel(data);
          this.storeTimestamp(data.model.timestampBase64);
          this.loadingRequestInformation = false;
          if(data.model.manufacturingCycleId !== null){
            this.setManufacturingUrl(data.model.manufacturingCycleId);
          }
          if(data.model.prototypeManufacturingCycleId !== null){
            this.setPrototypeManufacturingUrl(data.model.prototypeManufacturingCycleId);
          }
          if (data.model.canBeCreatedManually !== null) {
            this.setCanBeCreatedManually(data.model.canBeCreatedManually);
          }
          if (data.model.canManageFulfilmentPlan !== null) {
            this.setCanManageFulfilmentPlan(data.model.canManageFulfilmentPlan);
            this.setCanManageManufacturingCycles(
              data.model.canManageManufacturingCycles
            );
          }
        });
    },
    getRequestInformation(id) {
      this.loadingRequestInformation = true;
      fulfilmentPlanDetailsService
        .getRequestInformation(id)
        .then((response) => {
          const data = response.data;
          this.model = new RequestInformationModel(data);
          if(data.model.manufacturingCycleId !== null){
            this.setManufacturingUrl(data.model.manufacturingCycleId);
          }
          if(data.model.prototypeManufacturingCycleId !== null){
            this.setPrototypeManufacturingUrl(data.model.prototypeManufacturingCycleId);
          }
          this.loadingRequestInformation = false;
        });
    },
    editProductNumber(ignoreWarnings) {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editProductNumber(
          this.model.id,
          this.model.productNumber,
          this.timestamp,
          ignoreWarnings
        )
        .then((response) => {
          const data = response.data;
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Product number changed succesfully.",
              "success"
            );
            this.setChangesRefresh(true);
            this.setManufacturingInformationRefresh(true);
            this.setRequestInformationRefresh(true);
            this.setMilestoneRefresh(true);
            this.setPlanDataRefresh(true);
          } else if (data.result === OperationResultType.Warning) {
            this.openConfirmPopup(data.message, (confirmAction) => {
              this.editProductNumber(true);
            });
          } else if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.setRequestInformationRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editDistributionCenter(ignoreWarnings) {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editDistributionCenter(
          this.model.id,
          this.model.distributionCenterId,
          this.timestamp,
          ignoreWarnings
        )
        .then((response) => {
          const data = response.data;
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Distribution center changed succesfully.",
              "success"
            );
            this.setRequestInformationRefresh(true);
            this.setPlanDataRefresh(true);
            this.setChangesRefresh(true);
          } else if (data.result === OperationResultType.Warning) {
            this.openConfirmPopup(data.Message, (confirmAction) => {
              this.editManufacturingLocalization(true);
            });
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setRequestInformationRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editRequestStatus(ignoreWarnings) {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editRequestStatus(
          this.model.id,
          this.model.status,
          this.timestamp,
          ignoreWarnings
        )
        .then((response) => {
          const data = response.data;
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Request status changed succesfully.",
              "success"
            );
            this.setChangesRefresh(true);
            this.setManufacturingInformationRefresh(true);
            this.setMilestoneRefresh(true);
          } else if (data.result === OperationResultType.Warning) {
            this.openConfirmPopup(data.message, (confirmAction) => {
              this.editRequestStatus(true);
            });
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setRequestInformationRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editQuantity() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editQuantity(this.model.id, this.model.quantity, this.timestamp)
        .then((response) => {
          const data = response.data;
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification("Quantity changed succesfully.", "success");
            this.setChangesRefresh(true);
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setRequestInformationRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editPONumber() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editPONumber(this.model.id, this.model.poNumber, this.timestamp)
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.setMilestoneRefresh(true);
            this.setChangesRefresh(true);
            this.setTimelineRefresh(true);
            this.setRequestInformationRefresh(true);
            this.showNotification("PO Number set succesfully.", "success");
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setRequestInformationRefresh(true);
            this.setMilestoneRefresh(true);
            this.setChangesRefresh(true);
            this.setTimelineRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editPONumberDate() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editPONumberDate(
          this.model.id,
          this.model.poNumberDate,
          this.timestamp
        )
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.setMilestoneRefresh(true);
            this.setChangesRefresh(true);
            this.setTimelineRefresh(true);
            this.showNotification("PO Number set succesfully.", "success");
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setRequestInformationRefresh(true);
            this.setMilestoneRefresh(true);
            this.setChangesRefresh(true);
            this.setTimelineRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editRaApproval() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editRaApproval(this.model.id, this.model.raApproval, this.timestamp)
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.setChangesRefresh(true);
            this.showNotification("RA Approval set succesfully.", "success");
          } else if (data.result === OperationResultType.Error) {
            this.setChangesRefresh(true);
            this.showNotification(data.message, "error");
          }
          this.setEditionLock(false);
        });
    },
    editLoanerSet() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editLoanerSet(this.model.id, this.model.loanerSet)
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.setChangesRefresh(true);
            this.showNotification("Loaner Set updated succesfully.", "success");
          } else if (data.result === OperationResultType.Error) {
            this.setChangesRefresh(true);
            this.showNotification(data.message, "error");
          }
          this.setEditionLock(false);
        });
    },
    editOrderNumber(ignoreWarnings) {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editOrderNumber(
          this.model.id,
          this.model.orderNumber,
          this.timestamp,
          ignoreWarnings
        )
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification("Order Number set succesfully.", "success");
          }
          if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
          }
          this.setRequestInformationRefresh(true);
          this.setChangesRefresh(true);
          this.setPlanDataRefresh(true);
          this.setEditionLock(false);
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    openConfirmPopup(message, confirmAction) {
      this.$confirm(message, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          confirmAction();
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Action aborted.",
          });
        });
    },
    reimportRequest() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .reimportRequest(this.model.id, true)
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification("Reimport queued.", "success");
          }
          if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
          }
          this.setEditionLock(false);
        });
    },
  },
};
</script>

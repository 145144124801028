import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";

class KeyCodeService {
  getKeyCodesList() {
    return DocumentationHttpClient.get("/KeyCode");
  }

  addKeyCode() {
    return DocumentationHttpClient.post("/KeyCode");
  }

  getAvailableDocuments(keyCodeId: number) {
    return DocumentationHttpClient.get(
      `/KeyCode/${keyCodeId}/availableDocuments`
    );
  }

  getAvailableProducts(keyCodeId: number) {
    return DocumentationHttpClient.get(
      `/KeyCode/${keyCodeId}/availableProducts`
    );
  }

  updateDocuments(keyCodeId: number, data) {
    return DocumentationHttpClient.put(`/KeyCode/${keyCodeId}/documents`, data);
  }

  updateProducts(keyCodeId: number, data) {
    return DocumentationHttpClient.put(`/KeyCode/${keyCodeId}/products`, data);
  }
}

export const keyCodeService = new KeyCodeService();

import { HTTP } from "./HttpCommon";
import { DocumentationHttpClient } from "../shared/edoc/DocumentationHttpClient";

class VersionService {
  getVersion() {
    return HTTP.get("Version/GetVersion");
  }

  getEDocVersion() {
    return DocumentationHttpClient.get("/Version");
  }
}

export const versionService = new VersionService();

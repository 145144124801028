var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}]},[(_vm.addTemplate)?_c('el-row',[_c('el-col',[(_vm.addTemplate)?_c('el-button',{staticClass:"crud-add-button",on:{"click":_vm.handleAdd}},[_vm._v("Add")]):_vm._e()],1)],1):_vm._e(),_c('v-client-table',{staticClass:"crud-table",attrs:{"data":_vm.data,"columns":_vm.allTableColumns,"options":_vm.allTableOptions},scopedSlots:_vm._u([{key:"operations",fn:function(scope){return (_vm.editTemplate || _vm.deletable)?_c('div',{},[_c('el-button',{attrs:{"disabled":!_vm.editTemplate,"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.row)}}},[_vm._v("Edit")]),_c('el-button',{attrs:{"disabled":!_vm.deletable || scope.row.canBeDeleted === false,"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleRemove(scope.row)}}},[_vm._v("Delete")])],1):_vm._e()}}],null,true)}),(_vm.addTemplate || _vm.editTemplate)?_c('el-dialog',_vm._b({attrs:{"title":_vm.dialogTitle,"visible":_vm.isDialogVisible},on:{"update:visible":function($event){_vm.isDialogVisible=$event}}},'el-dialog',_vm.formOptions,false),[(_vm.formData)?_c('el-form',_vm._b({ref:"form",attrs:{"model":_vm.formData,"rules":_vm.handleFormRulesMode()}},'el-form',_vm.formOptions,false),[_vm._l((_vm.formData),function(value,key){return [(
            _vm.handleFormTemplateMode(key).component
              ? _vm.handleAttribute(
                  _vm.handleFormTemplateMode(key).component.show,
                  true
                )
              : true
          )?[_c('el-form-item',{key:key,attrs:{"label":_vm.handleFormTemplateMode(key).title,"prop":key}},[(
                !_vm.handleFormTemplateMode(key).component ||
                !_vm.handleFormTemplateMode(key).component.name ||
                _vm.handleFormTemplateMode(key).component.name === 'el-input'
              )?_c('el-input',_vm._b({model:{value:(_vm.formData[key]),callback:function ($$v) {_vm.$set(_vm.formData, key, $$v)},expression:"formData[key]"}},'el-input',_vm.handleFormTemplateMode(key).component,false)):(
                _vm.handleFormTemplateMode(key).component.name === 'el-select'
              )?_c('el-select',_vm._b({model:{value:(_vm.formData[key]),callback:function ($$v) {_vm.$set(_vm.formData, key, $$v)},expression:"formData[key]"}},'el-select',_vm.handleFormTemplateMode(key).component,false),_vm._l((_vm.handleFormTemplateMode(key).component
                  .options),function(option){return _c('el-option',_vm._b({key:option.value},'el-option',option,false))}),1):(
                _vm.handleFormTemplateMode(key).component.name === 'el-checkbox'
              )?_c('el-checkbox',_vm._b({model:{value:(_vm.formData[key]),callback:function ($$v) {_vm.$set(_vm.formData, key, $$v)},expression:"formData[key]"}},'el-checkbox',_vm.handleFormTemplateMode(key).component,false)):(
                _vm.handleFormTemplateMode(key).component.name ===
                'el-time-select'
              )?_c('el-time-select',_vm._b({model:{value:(_vm.formData[key]),callback:function ($$v) {_vm.$set(_vm.formData, key, $$v)},expression:"formData[key]"}},'el-time-select',_vm.handleFormTemplateMode(key).component,false)):_vm._e()],1)]:_vm._e()]})],2):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleDialogCancel}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDialogSave}},[_vm._v("Confirm")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
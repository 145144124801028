<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">DOCUMENT EXPORT</h1>
      </div>
    </el-row>
    <el-row>
      <el-form
        :model="item"
        :inline="true"
        :rules="rules"
        ref="exportForm"
        label-position="top"
      >
        <el-form-item label="Export Mode">
          <el-radio
            v-model="chosenMode"
            :label="documentExportMode.Manual"
            class="vertical-option"
            >Manual</el-radio
          >
          <el-radio
            v-model="chosenMode"
            :label="documentExportMode.Bulk"
            class="vertical-option"
            >Load List</el-radio
          >
        </el-form-item>
        <template v-if="chosenMode === documentExportMode.Manual">
          <el-form-item label="Documents">
            <el-select
              v-model="documentNumbers"
              filterable
              remote
              collapse-tags
              multiple
              reserve-keyword
              placeholder="None selected"
              :remote-method="searchDocumentNumbers"
              class="document-select"
            >
              <el-option
                v-for="item in availableDocumentNumbers"
                :key="item.id"
                :label="item.number"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="chosenMode === documentExportMode.Bulk">
          <el-form-item label="Document numbers to load">
            <el-input
              type="textarea"
              :autosize="{ minRows: 10 }"
              v-model="documentNumbersToLoad"
            ></el-input>
            <el-button style="margin-top: 5px" @click="loadList"
              >Load list</el-button
            >
          </el-form-item>
        </template>
        <el-form-item
          label="Revisions and files"
          prop="chosenFiles"
          class="tree-form-item-large"
        >
          <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            ref="tree"
            :props="documentProps"
          ></el-tree>
        </el-form-item>
        <el-form-item>
          <template slot="label">&nbsp;</template>
          <el-button @click="onSubmit">Export</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { documentExportService } from "../../../services/edoc/documentExport.service";
import axios from "axios";
import { DocumentExportMode } from "../../../models/edoc/DocumentExportMode";

export default Vue.extend({
  data() {
    return {
      documentExportMode: DocumentExportMode,
      chosenMode: DocumentExportMode.Manual,
      loading: false,
      item: {
        chosenFiles: [],
      },
      rules: {
        chosenFiles: [
          {
            required: true,
            message: "Choose at least one file",
            trigger: "blur",
          },
        ],
      },
      documentNumbers: [],
      availableDocumentNumbers: [],
      data: [],
      documentNumbersToLoad: null,
      documentProps: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },
    };
  },
  computed: {},
  methods: {
    searchDocumentNumbers: function (query = "") {
      documentExportService
        .getFilteredDocumentNumbers(query)
        .then((response) => {
          this.availableDocumentNumbers = response.data;
        });
    },
    handleAdd(documentId) {
      this.loading = true;
      documentExportService
        .getDocumentExportItem(documentId)
        .then((response) => {
          var index = this.data.findIndex(
            (treeNode) => treeNode.id === `document-${documentId}`
          );

          if (index != -1) {
            return;
          }

          this.data.push(response.data);
          this.$nextTick(() => {
            if (!response.data.disabled) {
              this.$refs.tree.setChecked(response.data.id, true, true);
            }
          });

          this.loading = false;
        });
    },
    handleDelete(documentId) {
      var index = this.data.findIndex(
        (treeNode) => treeNode.id === `document-${documentId}`
      );

      if (index != -1) {
        this.data.splice(index, 1);
      }
    },
    loadList() {
      if (!this.documentNumbersToLoad) {
        return;
      }

      this.loading = true;

      documentExportService
        .getDocumentExportItems(this.documentNumbersToLoad)
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            var item = response.data[i];
            var index = this.data.findIndex(
              (treeNode) => treeNode.id === item.id
            );
            if (index != -1) {
              continue;
            }

            this.addDocumentNumber(item);
            this.data.push(item);
          }

          this.$nextTick(() => {
            for (var i = 0; i < response.data.length; i++) {
              var item = response.data[i];
              if (!item.disabled) {
                this.$refs.tree.setChecked(item.id, true, true);
              }
            }
          });

          this.loading = false;
        });
    },
    addDocumentNumber(item) {
      var documentId = Number(item.id.replace("document-", ""));
      this.documentNumbers.push(documentId);

      var elementNotExistsInAvailableDocumentNumbers =
        this.availableDocumentNumbers.findIndex((d) => d.id === documentId) ===
        -1;
      if (elementNotExistsInAvailableDocumentNumbers) {
        this.availableDocumentNumbers.push({
          id: documentId,
          number: item.label,
        });
      }
    },
    onSubmit() {
      this.item.chosenFiles = this.$refs.tree.getCheckedNodes(true, false);

      this.$refs.exportForm.validate((valid) => {
        if (valid) {
          this.exportDocuments();
        }
      });
    },
    exportDocuments() {
      this.loading = true;
      const fileIds = this.item.chosenFiles.map((fileNode) => fileNode.id);

      axios
        .all([
          documentExportService.exportDocuments(fileIds),
          documentExportService.exportDocumentFiles(fileIds),
        ])
        .then((responseArr) => {
          this.unpackAndDownloadFile(
            responseArr[0].data,
            "export-documents",
            "txt"
          );
          this.unpackAndDownloadFile(
            responseArr[1].data,
            "export-documents",
            "bat"
          );
          this.loading = false;
        });
    },
    unpackAndDownloadFile(fileContent, fileName, fileExtension) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
    },
  },
  watch: {
    chosenMode: function () {
      this.item.chosenFiles = [];
      this.documentNumbers = [];
      this.availableDocumentNumbers = [];
      this.data = [];
      this.documentNumbersToLoad = null;
    },
    documentNumbers: function (newVal, oldVal) {
      if (newVal.length || oldVal.length) {
        var isDelete = oldVal != null && oldVal.length > newVal.length;

        if (isDelete) {
          for (var i in oldVal) {
            if (!newVal.includes(oldVal[i])) {
              this.handleDelete(oldVal[i]);
              break;
            }
          }
        } else {
          var documentId = newVal[newVal.length - 1];
          var index = this.data.findIndex(
            (treeNode) => treeNode.id === `document-${documentId}`
          );
          if (index != -1) {
            return;
          }

          this.handleAdd(documentId);
        }
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.vertical-option {
  display: block;
  line-height: normal;
  margin-right: 50px;
}

.tree-form-item-large {
  min-width: 300px;
  max-width: 700px;
  margin-left: 20px;
}

.document-select {
  width: 350px;
}
</style>

<template>
  <div v-loading.fullscreen.lock="isLoading">
    <nav-bar :user-name="userName" v-if="canRenderNavbar"></nav-bar>
    <div class="container non-static content" id="appContainer">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import NavBar from "./components/NavBar";
import { mapGetters } from "vuex";

export default {
  props: ["userName"],
  components: {
    NavBar,
  },
  computed: {
    routeKey: function () {
      if (this.$route.path.startsWith("/CombinedOrders")) {
        return this.$route.path;
      } else {
        return this.$route.fullPath;
      }
    },
    ...mapGetters("oidcStore", ["oidcIsAuthenticated", "oidcAccessToken"]),
    ...mapGetters(["isLoading"]),
    canRenderNavbar: function () {
      return (
        !this.$route.meta.isOidcCallback &&
        !this.$route.meta.isNotFound &&
        this.$route.name !== null
      );
    },
    hasPermissions: function () {
      return this.$route.path !== "/PermissionsLack";
    },
  },
  watch: {
    oidcAccessToken: function (newValue) {
      if (newValue === null) {
        this.$alert("Authentication failed. Please reload page.", "", {
          confirmButtonText: "Reload",
          showClose: false,
          callback: () => {
            window.location.reload();
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
:root {
  --fc-button-text-color: #333;
  --fc-button-bg-color: #f5f5f5;
  --fc-button-border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
    rgba(0, 0, 0, 0.25);
  --fc-button-active-bg-color: #cccccc;
  --fc-button-active-border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
    rgba(0, 0, 0, 0.25);
  --fc-button-hover-border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
    rgba(0, 0, 0, 0.25);
  --fc-button-hover-bg-color: #cccccc;
}
</style>

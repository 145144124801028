import DesignerDetails from "../../models/design/DesignerDetails";
import DesignSession from "../../models/design/DesignSession";
import { DesignHttpClient } from "../../shared/DesignHttpClient";
import { HTTP } from "../HttpCommon";
import "moment-duration-format";

class DesignSessionService {
  createSession(designSessionModel: DesignSession) {
    const model = {
      requestId: designSessionModel.requestId,
      designerId: designSessionModel.designerId,
      startDate: designSessionModel.startDate.format(),
      preparationDuration:
        designSessionModel.preparationTime.format("D.HH:mm:ss", {
          trim: false,
        }),
      sessionDuration: designSessionModel.sessionTime.format("HH:mm:ss", {
        trim: false,
      }),
      postprocessingDuration:
        designSessionModel.postProcessingTime.format("D.HH:mm:ss", {
          trim: false,
        }),
    };

    return DesignHttpClient.post("sessionsAdministration", model);
  }

  updateSession(designSessionModel: DesignSession) {
    const model = {
      id: designSessionModel.id,
      requestId: designSessionModel.requestId,
      designerId: designSessionModel.designerId,
      startDate: designSessionModel.startDate.format(),
      preparationDuration:
        designSessionModel.preparationTime.format("D.HH:mm:ss", {
          trim: false,
        }),
      sessionDuration: designSessionModel.sessionTime.format("HH:mm:ss", {
        trim: false,
      }),
      postprocessingDuration:
        designSessionModel.postProcessingTime.format("D.HH:mm:ss", {
          trim: false,
        }),
    };

    return DesignHttpClient.put("sessionsAdministration", model);
  }

  deleteSession(id: number) {
    return DesignHttpClient.delete("sessionsAdministration/" + id);
  }

  filterRequestIds(containsString: string) {
    return HTTP.get(
      "FulfilmentPlan/FilterRequests?containsString=" + containsString
    );
  }

  getSession(id: number) {
    return DesignHttpClient.get("sessionsAdministration/" + id);
  }

  getSessionForRequestId(requestId: string) {
    return DesignHttpClient.get(
      "sessionsAdministration/requestId/" + requestId
    );
  }

  getMeetingInfo(requestId: string) {
    return DesignHttpClient.get("meetingStatus/" + requestId);
  }
}

export const designSessionService = new DesignSessionService();

const manufacturingUrl = "/ManufacturingCycle/Details/";
const prototypeManufacturingUrl = "/PrototypeManufacturingCycle/Details/";

const getters = {};

const state = {
  timestamp: "",
  changesRefresh: false,
  milestoneRefresh: false,
  planDataRefresh: false,
  manufacturingInformationRefresh: false,
  requestInformationRefresh: false,
  canBeCreatedManually: false,
  canManageFulfilmentPlan: false,
  canManageManufacturingCycles: false,
  showTimeline: false,
  timelineRefresh: false,
  editionLock: false,
  manufacturingUrl: "",
};

const actions = {
  storeTimestamp(context, value) {
    context.commit("storeTimestamp", value);
  },
  setEditionLock(context, value) {
    context.commit("setEditionLock", value);
  },
  setChangesRefresh(context, value) {
    context.commit("setChangesRefresh", value);
  },
  setMilestoneRefresh(context, value) {
    context.commit("setMilestoneRefresh", value);
  },
  setPlanDataRefresh(context, value) {
    context.commit("setPlanDataRefresh", value);
  },
  setManufacturingInformationRefresh(context, value) {
    context.commit("setManufacturingInformationRefresh", value);
  },
  setRequestInformationRefresh(context, value) {
    context.commit("setRequestInformationRefresh", value);
  },
  setManufacturingUrl(context, value) {
    context.commit("setManufacturingUrl", value);
  },
  setCanBeCreatedManually(context, value) {
    context.commit("setCanBeCreatedManually", value);
  },
  setCanManageFulfilmentPlan(context, value) {
    context.commit("setCanManageFulfilmentPlan", value);
  },
  setCanManageManufacturingCycles(context, value) {
    context.commit("setCanManageManufacturingCycles", value);
  },
  setTimelineRefresh(context, value) {
    context.commit("setTimelineRefresh", value);
  },
  setShowTimeline(context, value) {
    context.commit("setShowTimeline", value);
  },
  setPrototypeManufacturingUrl(context, value) {
    context.commit("setPrototypeManufacturingUrl", value);
  },
};

const mutations = {
  storeTimestamp(state, value) {
    state.timestamp = value;
  },
  setEditionLock(state, value) {
    state.editionLock = value;
  },
  setChangesRefresh(state, value) {
    state.changesRefresh = value;
  },
  setMilestoneRefresh(state, value) {
    state.milestoneRefresh = value;
  },
  setPlanDataRefresh(state, value) {
    state.planDataRefresh = value;
  },
  setRequestInformationRefresh(state, value) {
    state.requestInformationRefresh = value;
  },
  setManufacturingInformationRefresh(state, value) {
    state.manufacturingInformationRefresh = value;
  },
  setManufacturingUrl(state, value) {
    state.manufacturingUrl = manufacturingUrl + value;
  },
  setCanBeCreatedManually(state, value) {
    state.canBeCreatedManually = value;
  },
  setCanManageFulfilmentPlan(state, value) {
    state.canManageFulfilmentPlan = value;
  },
  setCanManageManufacturingCycles(state, value) {
    state.canManageManufacturingCycles = value;
  },
  setTimelineRefresh(state, value) {
    state.timelineRefresh = value;
  },
  setShowTimeline(state, value) {
    state.showTimeline = value;
  },
  setPrototypeManufacturingUrl(state, value) {
    state.manufacturingUrl = prototypeManufacturingUrl + value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

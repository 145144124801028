import ManufacturingCycleTabsModel from "./ManufacturingCycleTabsModel";
import { ManufacturingCycleTabType } from "../../enums/enums";
import ProductTypeFilterModel from "../ProductTypeFilterModel";
import SimpleSelectControlItemModel from "../SimpleSelectControlItemModel";

export default class FiltersModel {
  availableProductTypes: ProductTypeFilterModel[] = [];
  availableDesignStatuses: SimpleSelectControlItemModel[] = [];
  availableProcessingStatuses: SimpleSelectControlItemModel[] = [];
  availableManufacturingLocations: SimpleSelectControlItemModel[] = [];
  availableJobSteps: SimpleSelectControlItemModel[] = [];
  productTypeTabs: ManufacturingCycleTabsModel[] = [];
  requestTypeTabs: ManufacturingCycleTabsModel[] = [];
  constructor(data) {
    if (data !== null) {
      this.availableProductTypes = data.availableProductTypes.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.name,
            value: Number(x.id),
          })
      );
      this.availableDesignStatuses = data.availableDesignStatuses.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.text,
            value: Number(x.value),
          })
      );
      this.availableProcessingStatuses = data.availableProcessingStatuses.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.text,
            value: Number(x.value),
          })
      );
      this.availableManufacturingLocations =
        data.availableManufacturingLocations.map(
          (x) =>
            new SimpleSelectControlItemModel({
              label: x.text,
              value: Number(x.value),
            })
        );
      this.availableJobSteps = data.availableJobSteps.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.name,
            value: Number(x.id),
          })
      );

      data.availableProductTypes.forEach((item: any) => {
        this.productTypeTabs.push(
          new ManufacturingCycleTabsModel(
            item,
            ManufacturingCycleTabType.ProductType
          )
        );
      });
      data.availableRequestTypes.forEach((item: any) => {
        this.requestTypeTabs.push(
          new ManufacturingCycleTabsModel(
            item,
            ManufacturingCycleTabType.RequestType
          )
        );
      });
    }
  }
}

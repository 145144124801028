export default class TimelineItemModel {
  date: string;
  isWeekend: boolean;
  isHoliday: boolean;
  plannedMilestones: string[];
  actualMilestones: string[];

  constructor(data) {
    if (data !== null) {
      this.date = data.date.format("YYYY-MM-DD");
      this.isWeekend = data.isWeekend;
      this.isHoliday = data.isHoliday;
      this.plannedMilestones = data.plannedMilestones;
      this.actualMilestones = data.actualMilestones;
    }
  }
}

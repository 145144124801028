import { HTTP } from "./HttpCommon";
import qs from "qs";

class TrackingInformationService {
  getTrackingInformation(filter) {
    return HTTP.get("TrackingInformation/GetTrackingInformation", {
      params: filter,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getTrackingInformationStatuses() {
    return HTTP.get("TrackingInformation/GetStatuses");
  }
}

export const trackingInformationService = new TrackingInformationService();

<template>
  <el-dialog
    width="25%"
    title="Add document revision"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="item"
      :rules="rules"
      ref="revisionForm"
      label-width="150px"
    >
      <el-form-item label="Name" prop="name">
        <el-input v-model="item.name"></el-input>
      </el-form-item>
      <el-form-item label="Issue date" prop="issueDate">
        <el-date-picker
          v-model="item.issueDate"
          style="width: 100%"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="Select date"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="Incompatible"
        prop="incompatible"
        v-if="isDocumentVersioned && !isFirstVersionInDocument"
      >
        <el-checkbox v-model="item.incompatible"></el-checkbox>
      </el-form-item>
      <el-form-item
        label="Version Name"
        prop="versionName"
        v-if="
          (isDocumentVersioned && isFirstVersionInDocument) || item.incompatible
        "
      >
        <el-input v-model="item.versionName"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Add</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isFirstVersionInDocument: {
      type: Boolean,
      default: false,
    },
    isDocumentVersioned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: "Name is required" }],
        issueDate: [{ required: true, message: "Issue date is required" }],
        versionName: [{ required: true, message: "Version Name is required" }],
      },
      item: {
        name: null,
        issueDate: null,
        incompatible: false,
        versionName: null,
      },
    };
  },
  computed: {},
  methods: {
    submitForm() {
      this.$refs.revisionForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.item);
        }
        return false;
      });
    },
    closeForm() {
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.$refs.revisionForm.resetFields();
      this.item = {
        name: null,
        issueDate: null,
        incompatible: false,
        versionName: null,
      };
    },
  },
});
</script>

<template>
  <div>
    <div class="heading-bottom-border">
      <h4>Workflows</h4>
      <el-button class="crud-add-button" @click="onAddWorkflow" plain
        >Add Workflow</el-button
      >
    </div>
    <div v-for="(workflow, index) in workflowList" :key="index">
      <Workflow
        :workflow-model.sync="workflowList[index]"
        :identifier="index"
        @delete="onDeleteWorkflow"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import WorkflowModel from "../../models/WorkflowModel";
import Workflow from "./Workflow.vue";

export default Vue.extend({
  props: {
    workflowList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Workflow,
  },
  data() {
    return {
      dialogVisible: false,
      model: this.workflowList,
    };
  },
  computed: {
    workProcedureId() {
      return this.$route.params.id;
    },
  },
  methods: {
    onAddWorkflow() {
      this.dialogVisible = true;
      this.workflowList.push(new WorkflowModel());
    },
    onDeleteWorkflow(identifier) {
      this.workflowList.splice(identifier, 1);
    },
  },
});
</script>

<style lang="scss" scoped>
.heading-bottom-border {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dcdfe6;
}
</style>

<template>
  <el-dialog
    width="25%"
    title="Add product version"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="item"
      :rules="rules"
      ref="productVersionForm"
      label-width="150px"
    >
      <el-form-item label="Name" prop="name">
        <el-input v-model="item.name"></el-input>
      </el-form-item>
      <el-form-item label="ECN Number" prop="ecnNumber">
        <el-input v-model="item.ecnNumber"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Add</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: "Name is required" }],
      },
      item: {
        type: Object,
        default: {
          name: null,
          ecnNumber: null,
        },
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.productVersionForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.item);
        }
        return false;
      });
    },
    clearForm() {
      this.$refs.productVersionForm.resetFields();
      this.item = {
        name: null,
        ecnNumber: null,
      };
    },
    closeForm() {
      this.clearForm();
      this.$emit("update:visible", false);
    },
  },
});
</script>

<template>
  <div>
    <el-card style="width: 100%">
      <div slot="header">
        <h4
          style="padding-left: 20px; padding-right: 20px; display: inline-block"
        >
          {{ manufacturingJob.name }}
        </h4>
        <el-button
          type="primary"
          :disabled="!manufacturingJob.canStart"
          v-on:click="inProgressClick"
          >In Progress</el-button
        >
        <el-button
          type="success"
          :disabled="!manufacturingJob.canFinish"
          v-on:click="doneClick"
          >Done</el-button
        >
        <el-button
          type="info"
          :disabled="!manufacturingJob.canUndo"
          v-on:click="undoClick"
          >Undo</el-button
        >
      </div>
      <manufacturing-job-stepper
        :steps="steps"
        :current-step="this.manufacturingJob.currentSequenceNumber - 1"
      ></manufacturing-job-stepper>
    </el-card>
  </div>
</template>
<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import { combinedOrderService } from "../../services/combinedorder.service";
import ManufacturingJobStepper from "./ManufacturingJobStepper.vue";
import { JobStepStatusEnum } from "../../models/JobStepStatusEnum";
import { StepStatusEnum } from "../../enums/StepStatusEnum";

export default Vue.extend({
  components: {
    ManufacturingJobStepper,
  },
  props: {
    manufacturingJob: {
      type: Object,
      required: true,
    },
  },
  computed: {
    steps: function () {
      return this.manufacturingJob.jobSteps.map((step, index) => {
        if (index < this.manufacturingJob.currentSequenceNumber - 1) {
          step.status = StepStatusEnum.Success;
        }

        if (index === this.manufacturingJob.currentSequenceNumber - 1) {
          step.status =
            this.manufacturingJob.currentStepStatus ==
              JobStepStatusEnum.Pending && this.manufacturingJob.canStart
              ? StepStatusEnum.Process
              : this.manufacturingJob.currentStepStatus ==
                JobStepStatusEnum.InProgress
              ? StepStatusEnum.Finish
              : StepStatusEnum.Wait;
        }

        if (
          this.manufacturingJob.currentStepStatus == JobStepStatusEnum.Completed
        ) {
          step.status = StepStatusEnum.Success;
          this.manufacturingJob.currentSequenceNumber += 1;
        }

        return step;
      });
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    inProgressClick: function () {
      this.showLoading();
      combinedOrderService
        .startCurrentStep(
          this.$store.state.combinedOrder.combinedOrderId,
          this.manufacturingJob.workflowId
        )
        .then((result) => {
          this.$emit("change");
          this.hideLoading();
        });
    },
    doneClick: function () {
      this.showLoading();
      combinedOrderService
        .markCurrentStepAsCompleted(
          this.$store.state.combinedOrder.combinedOrderId,
          this.manufacturingJob.workflowId
        )
        .then((result) => {
          this.$emit("change");
          this.hideLoading();
        });
    },
    undoClick: function () {
      this.showLoading();
      combinedOrderService
        .undoStep(
          this.$store.state.combinedOrder.combinedOrderId,
          this.manufacturingJob.workflowId
        )
        .then((result) => {
          this.$emit("change");
          this.hideLoading();
        });
    },
  },
});
</script>

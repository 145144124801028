import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";
import qs from "qs";

class KeyCodeExportService {
  getKeyCodeExportItem(productId) {
    return DocumentationHttpClient.get(`/KeyCodeExport/${productId}`);
  }
  getKeyCodeExportItems(products) {
    return DocumentationHttpClient.get(`/KeyCodeExport/List`, {
      params: { products },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getFilteredProductGtins(query) {
    return DocumentationHttpClient.get(`/KeyCodeExport/ProductGtins`, {
      params: { query },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getFilteredDocumentNumbers(query) {
    return DocumentationHttpClient.get(`/KeyCodeExport/DocumentNumbers`, {
      params: { query },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  exportKeyCodes(chosenProducts) {
    const chosenIds = chosenProducts.map((productNode) => productNode.id);

    return DocumentationHttpClient.post("/KeyCodeExport", { chosenIds });
  }
  extractGtins(file) {
    const formData = new FormData();
    formData.append("file", file);

    return DocumentationHttpClient.post(
      "/KeyCodeExport/ExtractGtins",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}

export const keyCodeExportService = new KeyCodeExportService();

import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";
import { StoredQueryType } from "../../models/edoc/StoredQueryType";

class StoredQueryService {
  addStoredQuery(storedQueryType, query, name) {
    let methodName = "";
    if (storedQueryType === StoredQueryType.Documents) {
      methodName = "Document";
    } else if (storedQueryType === StoredQueryType.Products) {
      methodName = "Product";
    }

    return DocumentationHttpClient.post(`/StoredQuery/${methodName}`, {
      query,
      name,
    });
  }
  getStoredQueries(storedQueryType) {
    let methodName = "";
    if (storedQueryType === StoredQueryType.Documents) {
      methodName = "Document";
    } else if (storedQueryType === StoredQueryType.Products) {
      methodName = "Product";
    }

    return DocumentationHttpClient.get(`/StoredQuery/${methodName}`);
  }
}

export const storedQueryService = new StoredQueryService();

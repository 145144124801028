import TimelineItemModel from "./TimelineItemModel";
import moment from "moment";
import "moment-timezone";
export default class TimelineModel {
  id: number;
  timeline: TimelineItemModel[] = [];

  constructor(data) {
    if (data !== null) {
      this.id = data.id;

      const defaultLength = 12;

      if (
        data.milestones.some(
          (item) => item.actualDate !== null || item.plannedDate !== null
        )
      ) {
        const userTimezone = moment.tz.guess();

        const actualDates = data.milestones
          .filter((d) => d.actualDate !== null)
          .map((d) => moment(d.actualDate));
        const plannedDates = data.milestones
          .filter((d) => d.plannedDate !== null)
          .map((d) => moment.tz(d.plannedDate, d.timezone));

        const allDates = actualDates.concat(plannedDates);

        const minDate = moment.min(allDates);
        const maxDate = moment.max(allDates);

        let timelineLength =
          maxDate.tz(userTimezone).diff(minDate.tz(userTimezone), "days") + 2;
        timelineLength += timelineLength % 2;

        timelineLength = Math.max(defaultLength, timelineLength);

        for (let i = 0; i < timelineLength; i++) {
          const date = moment(maxDate).tz(userTimezone);

          date.add(-i + 1, "days");

          const newItem = new TimelineItemModel({
            date: date,
            isWeekend: date.isoWeekday() === 6 || date.isoWeekday() === 7,
            isHoliday: data.holidays.some((item) =>
              moment(item.date).isSame(date, "day")
            ),
            plannedMilestones: data.milestones
              .filter(
                (m) =>
                  m.plannedDate !== null &&
                  moment(
                    moment.tz(m.plannedDate, m.timezone).format("YYYY-MM-DD")
                  ).isSame(date, "day")
              )
              .map((m) => m.name),
            actualMilestones: data.milestones
              .filter(
                (m) =>
                  m.actualDate !== null &&
                  moment(m.actualDate).isSame(date, "day")
              )
              .map((m) => m.name),
          });

          this.timeline.push(newItem);
        }
      }
    }
  }
}

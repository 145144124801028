import ManufacturingCycleAdditionalFiltersItem from "./ManufacturingCycleAdditionalFiltersItem";
import _ from "lodash";
export default class ManufacturingCycleAdditionalFiltersModel {
  filters: ManufacturingCycleAdditionalFiltersItem[] = [];
  filtersPopoverVisible = false;
  storedQueryName: string;

  constructor(model: any) {
    if (model != null) {
      model.forEach((item: any) => {
        this.filters.push(new ManufacturingCycleAdditionalFiltersItem(item));
      });
      if (model.storedQueryName !== null) {
        this.storedQueryName = model.storedQueryName;
      }
    }
  }
}

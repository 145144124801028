import { HTTP } from "./HttpCommon";

class ProductService {
  loadProducts() {
    return HTTP.get("Product/GetRecords");
  }

  addProduct(record) {
    return HTTP.post("Product/Add", record);
  }

  editProduct(record) {
    return HTTP.put("Product/Edit/" + record.id, record);
  }
}

export const productService = new ProductService();

<template>
  <div>
    <h1 class="details-header">Designer Properties</h1>
    <el-row :gutter="20">
      <el-col :span="4">
        <label class="field-label">Name filter</label>
        <el-input id="searchingName" v-model="searchingName" placeholder="Search..."></el-input>
      </el-col>
      <el-col :span="3">
        <label class="field-label">Hide disabled user</label>
        <el-switch id="hideDisabledUsers" v-model="hideDisabledUsers" inactive-text="Off" active-text="On"></el-switch>
      </el-col>
      <el-col :span="4">
        <label class="field-label">Filter by product type</label>
        <el-select
          id="filterByProductType"
          v-model="chosenProductTypes"
          multiple
          clearable
          collapse-tags
          placeholder="None selected"
      >
        <el-option
          v-for="productType in productTypes"
            :key="productType.id"
            :label="productType.name"
            :value="productType.id"
        >
        </el-option>
      </el-select>
      </el-col>
    </el-row>
    <el-table :data="filteredData">
      <el-table-column label="Name" prop="name"></el-table-column>
      <el-table-column label="Design Products" prop="products"></el-table-column>
      <el-table-column label="Location" prop="location"></el-table-column>
      <el-table-column label="Bookable via API" width="160" align="center">
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.bookableViaApi"
            disabled
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="sessionsPerDay"
        label="Sessions per day"
        width="160"
        align="center"
      ></el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <el-button @click="openDesignerDetailsModel(scope.row)" size="mini"
            >Edit</el-button
          >
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <router-link :to="'/Designer/Schedule/' + scope.row.id"
            >Show schedule</router-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="Designer details"
      :visible.sync="editModalVisible"
      width="450px"
    >
      <el-form label-width="140px">
        <el-form-item label="Name">
          {{ getFullName(modal.id) }}
        </el-form-item>
        <el-form-item label="Location">
          <el-select v-model="modal.locationId" @change="locationUpdated">
            <el-option
              v-for="location in locations"
              :key="location.id"
              :label="location.name"
              :value="location.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Design products">
          <el-select style="width: 100%" multiple v-model="modal.productIds">
            <el-option
              v-for="productType in productTypes"
              :key="productType.id"
              :label="productType.name"
              :value="productType.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Bookable via API">
          <el-checkbox v-model="modal.bookableViaApi"></el-checkbox>
        </el-form-item>
        <el-form-item label="Sessions per day">
          <el-input-number
            v-model="modal.sessionsPerDay"
            :min="1"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button @click="editModalVisible = false">Cancel</el-button>
        <el-button type="primary" @click="editDesignerProperties">OK</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { designerService } from "../../services/design/designer.service";
import { userService } from "../../services/users.service";
import { manufacturingLocationService } from "../../services/manufacturinglocation.service";
import { configurationService } from "../../services/design/configuration.service";

export default Vue.extend({
  created() {
    Promise.all([
      userService.getUsers(),
      configurationService.getProducts(),
      manufacturingLocationService.loadManufacturingLocations()
    ]).then(([users, productTypes, locations]) => {
      this.users = users.data;
      this.productTypes = productTypes.data;
      this.locations = locations.data.records;
      this.loadData();
    });    
  },
  data() {
    return {
      designers: [],
      users: [],
      locations: [],
      productTypes: [],
      modal: {},
      editModalVisible: false,
      searchingName: '',
      hideDisabledUsers : true,
      filteredProductTypes: []
    };
  },
  computed: {
    filteredData() {
      return this.designers
        .filter(
          (data) => data.name.toLowerCase().includes(this.searchingName.toLowerCase())
        )
        .filter(
          (data) => {
            if (!this.hideDisabledUsers) {
              return data.isActive == this.hideDisabledUsers || !this.hideDisabledUsers;
            }
            return data.isActive == this.hideDisabledUsers;
          }
        )
        .filter(
          (data) => this.filteredProductTypes.length === 0 || data.productIds.some(id => this.filteredProductTypes.includes(id))
        );
    },
    chosenProductTypes: {
      get: function () {
        return this.filteredProductTypes;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filteredProductTypes = null;
        } else {
          this.filteredProductTypes = newValue;
        }
      },
    },
  },
  methods: {
    loadData() {
      designerService.getDesigners().then((response) => {
        response.data.map(item => {
          item.name = this.getFullName(item.id);
          item.location = this.getLocation(item.locationId);
          item.products = this.getProducts(item.productIds);
          item.isActive = this.getIsActive(item.id);
        });
        this.designers = response.data;
      });
    },
    getProducts(productIds) {
      const productTypes = productIds.map((id) => {
        const productType = this.productTypes.find((pt) => pt.id === id);

        return (productType && productType.name) || "";
      });

      return productTypes.join(" ");
    },
    getLocation(locationId) {
      const location = this.locations.find((u) => u.id === locationId);

      return (location && location.name) || "";
    },
    getFullName(id) {
      const user = this.users.find((u) => u.id === id);

      return (user && `${user.firstName} ${user.lastName}`) || "";
    },
    openDesignerDetailsModel: function (item) {
      this.modal = Object.assign({}, item);
      this.editModalVisible = true;
    },
    locationUpdated(newLocationId) {
      const newLocation = this.locations.find((l) => l.id === newLocationId);

      this.modal.locationTimezoneName = newLocation.timeZone;
    },
    editDesignerProperties: function () {
      designerService.editDesignerProperties(this.modal).then((response) => {
        this.loadData();
        this.editModalVisible = false;
      });
    },
    getIsActive(id) {
      const user = this.users.find((u) => u.id === id);

      return (user && user.isActive);
    }
  }
});
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 1%;
}
.el-switch {
  margin-top: 10px;
}
</style>

import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";

class ConfigurationService {
  loadDocumentTypes() {
    return DocumentationHttpClient.get("/Configuration/DocumentType");
  }
  addDocumentType(item) {
    return DocumentationHttpClient.post("/Configuration/DocumentType", item);
  }
  editDocumentType(item) {
    return DocumentationHttpClient.put("/Configuration/DocumentType", item);
  }
  deleteDocumentType(item) {
    return DocumentationHttpClient.delete(
      `/Configuration/DocumentType/${item.id}`
    );
  }
  loadCountries() {
    return DocumentationHttpClient.get("/Configuration/Country");
  }
  addCountry(item) {
    return DocumentationHttpClient.post("/Configuration/Country", item);
  }
  editCountry(item) {
    return DocumentationHttpClient.put("/Configuration/Country", item);
  }
  deleteCountry(item) {
    return DocumentationHttpClient.delete(`/Configuration/Country/${item.id}`);
  }
  loadDivisions() {
    return DocumentationHttpClient.get("/Configuration/Division");
  }
  addDivision(item) {
    return DocumentationHttpClient.post("/Configuration/Division", item);
  }
  editDivision(item) {
    return DocumentationHttpClient.put("/Configuration/Division", item);
  }
  deleteDivision(item) {
    return DocumentationHttpClient.delete(`/Configuration/Division/${item.id}`);
  }
  loadPortals() {
    return DocumentationHttpClient.get("/Configuration/Portal");
  }
  addPortal(item) {
    return DocumentationHttpClient.post("/Configuration/Portal", item);
  }
  editPortal(item) {
    return DocumentationHttpClient.put("/Configuration/Portal", item);
  }
  deletePortal(item) {
    return DocumentationHttpClient.delete(`/Configuration/Portal/${item.id}`);
  }
  loadLanguages() {
    return DocumentationHttpClient.get("/Configuration/Language");
  }
  addLanguage(item) {
    return DocumentationHttpClient.post("/Configuration/Language", item);
  }
  editLanguage(item) {
    return DocumentationHttpClient.put("/Configuration/Language", item);
  }
  deleteLanguage(item) {
    return DocumentationHttpClient.delete(`/Configuration/Language/${item.id}`);
  }
  loadProductFamilies() {
    return DocumentationHttpClient.get("/Configuration/ProductFamily");
  }
  addProductFamily(item) {
    return DocumentationHttpClient.post("/Configuration/ProductFamily", item);
  }
  editProductFamily(item) {
    return DocumentationHttpClient.put("/Configuration/ProductFamily", item);
  }
  deleteProductFamily(item) {
    return DocumentationHttpClient.delete(
      `/Configuration/ProductFamily/${item.id}`
    );
  }
  loadRegions() {
    return DocumentationHttpClient.get("/Configuration/Region");
  }
  addRegion(item) {
    return DocumentationHttpClient.post("/Configuration/Region", item);
  }
  editRegion(item) {
    return DocumentationHttpClient.put("/Configuration/Region", item);
  }
  deleteRegion(item) {
    return DocumentationHttpClient.delete(`/Configuration/Region/${item.id}`);
  }
}

export const configurationService = new ConfigurationService();

<template>
  <div>
    <fulfilment-plan-filters></fulfilment-plan-filters>
    <fulfilment-plan-list-table></fulfilment-plan-list-table>
  </div>
</template>

<script>
import FulfilmentPlanFilters from "./Filters.vue";
import FulfilmentPlanListTable from "./FulfilmentPlanListTable.vue";
import _ from "lodash";

export default {
  components: {
    FulfilmentPlanFilters,
    FulfilmentPlanListTable,
  },
};
</script>

import { HTTP } from "./HttpCommon";
import { DocumentationHttpClient } from "../shared/edoc/DocumentationHttpClient";

class FilterService {
  getCrtStoredQueriesForManagement() {
    return HTTP.get("QueryStorage/GetStoredQueriesForManagement");
  }

  getEDocStoredQueries() {
    return DocumentationHttpClient.get("/StoredQuery");
  }

  editCrtStoredQuery(storedQuery) {
    return HTTP.post("QueryStorage/UpdateQuerySettings/", {
      id: storedQuery.id,
      newName: storedQuery.name,
      sharingLevel: storedQuery.sharingLevel,
    });
  }

  editEDocStoredQuery(storedQuery) {
    return DocumentationHttpClient.put("/StoredQuery", {
      id: storedQuery.id,
      name: storedQuery.name,
    });
  }

  deleteCrtStoredQuery(storedQueryId) {
    return HTTP.post("QueryStorage/DeleteQuery/" + storedQueryId);
  }

  deleteEDocStoredQuery(storedQueryId) {
    return DocumentationHttpClient.delete(`/StoredQuery?id=${storedQueryId}`);
  }
  getFilteredUsers(containsString: string) {
    return HTTP.get(
      `QueryStorage/GetUsers?containsString=${containsString}`
    );
  }
}


export const filterService = new FilterService();

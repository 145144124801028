<template>
  <el-dialog
    width="25%"
    title="Add file"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form :model="item" :rules="rules" ref="fileForm" label-width="150px">
      <el-form-item label="Location" prop="location">
        <el-input v-model="item.location"></el-input>
      </el-form-item>
      <el-form-item label="Countries" prop="countries">
        <el-cascader
          v-model="item.countries"
          :options="availableRegions"
          :props="cascaderProps"
          :show-all-levels="false"
          placeholder="Choose countries"
          collapse-tags
          filterable
          style="width: 100%"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="Languages" prop="languages">
        <el-select
          v-model="item.languages"
          multiple
          clearable
          collapse-tags
          filterable
          placeholder="None selected"
          style="width: 100%"
        >
          <el-option
            v-for="language in availableLanguages"
            :key="language.value"
            :label="language.label"
            :value="language.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Add</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";

import { getRegions } from "../../../store/modules/mutationTypes";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        location: [{ required: true, message: "Location is required" }],
        countries: [
          {
            required: true,
            message: "At least one country is required",
            trigger: "blur",
          },
        ],
        languages: [
          {
            required: true,
            message: "At least one language is required",
            trigger: "blur",
          },
        ],
      },
      item: {
        location: null,
        countries: null,
        languages: null,
      },
      cascaderProps: {
        value: "id",
        label: "name",
        multiple: true,
      },
    };
  },
  created() {
    if (this.availableRegions == null || this.availableRegions.length === 0) {
      this.getRegions();
    }
  },
  computed: {
    availableLanguages() {
      return this.$store.state.document.availableLanguages;
    },
    availableRegions() {
      return this.$store.state.document.availableRegions;
    },
  },
  methods: {
    ...mapActions("document", [getRegions]),
    submitForm() {
      this.$refs.fileForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            location: this.item.location,
            countries: this.item.countries.map((c) => c[1]),
            languages: this.item.languages,
          });
        }
        return false;
      });
    },
    closeForm() {
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.$refs.fileForm.resetFields();
      this.item = {
        location: null,
        countries: null,
        languages: null,
      };
    },
  },
});
</script>
<style lang="scss">
.el-cascader-menu__wrap {
  height: 235px;
}
</style>

<template>
  <div>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :form-options="formOptions"
      :table-options="tableOptions"
      :add-template="template"
      :add-rules="validationRules"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";

import _ from "lodash";

import { productTypeMappingService } from "../../services/producttypemapping.service";
import CrudScreen from "../shared/crud/CrudScreen.vue";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      productTypes: [],
      requestTypes: [],
      manufacturingCycleDefinitions: [],
      manufacturingLocations: [],
      columns: [
        "requestProductName",
        "productKey",
        "productType",
        "priority",
        "designSessionRequired",
        "requestType",
      ],
      formOptions: {
        width: "800px",
        labelWidth: "300px",
      },
      tableOptions: {
        headings: {
          requestProductName: "Product Name",
          productKey: "Product Key",
          productType: "Product Type",
          priority: "Priority",
          designSessionRequired: "Session required",
          requestType: "Request Type",
        },
      },
      validationRules: {
        requestProductName: [
          {
            required: true,
            message: "Product Name is required",
            trigger: "blur",
          },
        ],
        productKey: [
          {
            required: true,
            message: "Product Key is required",
            trigger: "blur",
          },
        ],
        productTypeId: [
          {
            required: true,
            message: "Product Type is required",
            trigger: "blur",
          },
        ],
        requestTypeId: [
          {
            required: true,
            message: "Request Type is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    template() {
      return {
        id: {
          component: {
            show: false,
          },
        },
        requestProductName: {
          title: "Product Name",
        },
        productKey: {
          title: "Product Key",
        },
        productTypeId: {
          title: "Product Type",
          component: {
            name: "el-select",
            filterable: true,
            options: this.productTypes.map((productType) => ({
              value: productType.id,
              label: productType.name,
            })),
          },
        },
        requestTypeId: {
          title: "Request Type",
          component: {
            name: "el-select",
            filterable: true,
            options: this.requestTypes.map((requestType) => ({
              value: requestType.id,
              label: requestType.name,
            })),
          },
        },
        isPriority: {
          title: "Priority",
          component: {
            name: "el-checkbox",
          },
        },
        isDesignSessionRequired: {
          title: "Session required",
          component: {
            name: "el-checkbox",
          },
        },
      };
    },
  },
  methods: {
    getRecords() {
      productTypeMappingService.loadProductTypeMappings().then((data) => {
        this.productTypes = data.data.productTypes;
        this.requestTypes = data.data.requestTypes;
        this.records = data.data.records.map((item) => ({
          ...item,
          productType: this.productTypes.find(
            (productType) => productType.id == item.productTypeId
          ).name,
          requestType:
            item.requestTypeId !== null
              ? this.requestTypes.find(
                  (requestType) => requestType.id == item.requestTypeId
                ).name
              : "",
          priority: item.isPriority.toString(),
          designSessionRequired: item.isDesignSessionRequired.toString(),
        }));
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      productTypeMappingService.addProductTypeMapping(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      productTypeMappingService.editProductTypeMapping(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row style="margin-bottom: 0px">
      <div class="fleft">
        <h1 class="details-header no-margin">KEYCODES</h1>
      </div>
      <el-button
        class="fright"
        style="margin-bottom: 5px"
        :disabled="!eDocPermissions.CanManageKeyCodes"
        @click="addNewKeyCode"
        >Create new keycode</el-button
      >
    </el-row>
    <el-row>
      <v-client-table
        style="margin-top: 15px"
        ref="table"
        :columns="columns"
        :options="options"
        :data="keyCodes"
        :v-if="keyCodes"
      >
        <div slot="documents" class="links" slot-scope="props">
          <div
            v-for="{ number, versionName, documentId } in props.row.documents"
            :key="documentId"
          >
            <router-link
              v-if="eDocPermissions.CanReadDocuments"
              :to="'/Documentation/Document/' + documentId"
              :key="number + versionName"
              style="display: block"
              >{{ formatDocumentData(number, versionName) }}</router-link
            >
            <p v-else :key="number + versionName" style="display: block">
              {{ formatDocumentData(number, versionName) }}
            </p>
          </div>
        </div>
        <div slot="products" class="links" slot-scope="props">
          <div
            v-for="{ gtin, versionName, productId } in props.row.products"
            :key="productId"
          >
            <router-link
              v-if="eDocPermissions.CanReadProducts"
              :to="'/Documentation/Product/' + productId"
              :key="gtin + versionName"
              style="display: block"
              >{{ formatProductData(gtin, versionName) }}</router-link
            >
            <p v-else :key="gtin + versionName" style="display: block">
              {{ formatProductData(gtin, versionName) }}
            </p>
          </div>
        </div>
        <div slot="assignedAt" slot-scope="props">
          <div
            v-for="{ gtin, versionName, keyCodeAssignedAt } in props.row
              .products"
            :key="gtin + versionName"
          >
            {{ toLocalDate(keyCodeAssignedAt) }}
          </div>
        </div>
        <div slot="edit" slot-scope="props">
          <el-button
            @click="editKeyCodeDocument(props.row)"
            size="mini"
            :disabled="!eDocPermissions.CanManageKeyCodes"
            >Edit Documents</el-button
          >
          <el-button
            @click="editKeyCodeProduct(props.row)"
            size="mini"
            :disabled="!eDocPermissions.CanManageKeyCodes"
            >Edit Products</el-button
          >
        </div>
      </v-client-table>
    </el-row>
    <edit-key-code-document-dialog
      :visible.sync="editDocumentDialogVisible"
      :item="editedKeyCode"
      @submit="updateDocuments"
    ></edit-key-code-document-dialog>
    <edit-key-code-product-dialog
      :visible.sync="editProductDialogVisible"
      :item="editedKeyCode"
      @submit="updateProducts"
    ></edit-key-code-product-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import { mapState } from "vuex";
import { keyCodeService } from "../../../services/edoc/keycode.service";

import { Resources } from "../../../shared/edoc/Resources";
import { ResourcesHelper } from "../../../shared/edoc/ResourcesHelper";

import EditKeyCodeDocumentDialog from "./EditKeyCodeDocumentDialog.vue";
import EditKeyCodeProductDialog from "./EditKeyCodeProductDialog.vue";

export default Vue.extend({
  created() {
    this.loadData();
  },
  components: {
    EditKeyCodeDocumentDialog,
    EditKeyCodeProductDialog,
  },
  data() {
    return {
      loading: true,
      editDocumentDialogVisible: false,
      editProductDialogVisible: false,
      keyCodes: [],
      columns: ["code", "documents", "products", "assignedAt", "edit"],
      options: {
        headings: {
          code: "Code",
          documents: "Document Sets",
          products: "Assigned Products",
          assignedAt: "Assigned at",
          edit: "",
        },
        filterable: true,
        sortable: ["code", "documents", "products", "assignedAt"],
      },
      editedKeyCode: null,
    };
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
  },
  methods: {
    loadData() {
      this.loading = true;

      keyCodeService.getKeyCodesList().then((response) => {
        this.keyCodes = response.data;
        this.loading = false;
      });
    },
    addNewKeyCode: function () {
      keyCodeService.addKeyCode().then(
        () => {
          this.$message({
            type: "success",
            message: Resources.AddKeyCodeSuccess,
          });
          this.loadData();
        },
        (error) => {
          this.onError(
            error,
            function () {
              // do nothing.
            },
            Resources.AddKeyCodeError
          );
        }
      );
    },
    editKeyCodeDocument: function (row) {
      this.editedKeyCode = row;
      this.editDocumentDialogVisible = true;
    },
    editKeyCodeProduct: function (row) {
      this.editedKeyCode = row;
      this.editProductDialogVisible = true;
    },
    updateDocuments(data) {
      keyCodeService.updateDocuments(this.editedKeyCode.id, data).then(
        () => {
          this.$message({
            type: "success",
            message: Resources.AssignKeyCodeSuccess,
          });
          this.loadData();
          this.editDocumentDialogVisible = false;
        },
        (error) =>
          this.onError(
            error,
            function () {
              // do nothing.
            },
            Resources.AssignKeyCodeError
          )
      );
    },
    updateProducts(data) {
      keyCodeService.updateProducts(this.editedKeyCode.id, data).then(
        () => {
          this.$message({
            type: "success",
            message: Resources.AssignKeyCodeSuccess,
          });
          this.loadData();
          this.editProductDialogVisible = false;
        },
        (error) =>
          this.onError(
            error,
            function () {
              // do nothing.
            },
            Resources.AssignKeyCodeError
          )
      );
    },
    onError(error, callbackFunction, defaultError) {
      const alertTitle =
        error.response.status === 409 ? "Validation Error" : "Error";
      const alertContent =
        error.response.status === 409
          ? ResourcesHelper.getValidationMessage(error.response.data.errors)
          : defaultError;

      this.$alert(alertContent, alertTitle, {
        confirmButtonText: "OK",
        type: "error",
        callback: () => {
          callbackFunction();
        },
      });
    },
    formatProductData(gtin, versionName) {
      let productData = gtin;

      if (versionName) {
        productData += ` (${versionName})`;
      }

      return productData;
    },
    formatDocumentData(number, versionName) {
      let documentData = number;

      if (versionName) {
        documentData += ` (${versionName})`;
      }

      return documentData;
    },
    toLocalDate(datetimeOffset) {
      var utcDate = moment(datetimeOffset).toDate();
      var local = moment(utcDate).local().format("DD/MM/YYYY HH:mm:ss");

      return local;
    },
  },
});
</script>
<style lang="scss" scoped>
.links {
  padding-top: 5px;
  line-height: 18px;
}
</style>

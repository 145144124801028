import { DesignHttpClient } from "../../shared/DesignHttpClient";

class ScheduleService {
  getDesignerSchedule(designerId: number, start: string, end: string) {
    return DesignHttpClient.get(
      `schedules/${designerId}?start=${start}&end=${end}`
    );
  }

  getDesignersSchedule(start: string, end: string, productClasses: number[]) {
    const productClassesString =
      productClasses && productClasses.length > 0
        ? "&productTypes=" + productClasses.join("&productTypes=")
        : "";

    return DesignHttpClient.get(
      `schedules?start=${start}&end=${end}${productClassesString}`
    );
  }

  getAvailabilities(start: string, end: string, productClasses: number[]) {
    const productClassesString =
      productClasses && productClasses.length > 0
        ? "&productTypes=" + productClasses.join("&productTypes=")
        : "";

    return DesignHttpClient.get(
      `schedules/availability?start=${start}&end=${end}${productClassesString}`
    );
  }

  getDesignSessions(start: string, end: string, productClasses: number[] = []) {
    const productClassesString =
      productClasses && productClasses.length > 0
        ? "&productTypes=" + productClasses.join("&productTypes=")
        : "";

    return DesignHttpClient.get(
      `schedules/designsession?start=${start}&end=${end}${productClassesString}`
    );
  }

  getPreReservedDesignSessions(start: string, end: string, productClasses: number[] = []) {
    const productClassesString =
      productClasses && productClasses.length > 0
        ? "&productTypes=" + productClasses.join("&productTypes=")
        : "";

    return DesignHttpClient.get(
      `schedules/prereservation?start=${start}&end=${end}${productClassesString}`
    );
  }
}

export const scheduleService = new ScheduleService();

<template>
  <div>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :table-options="tableOptions"
      :form-options="formOptions"
      :add-template="template"
      :add-rules="validationRules"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";

import _ from "lodash";

import { productService } from "../../services/product.service";

import CrudScreen from "../shared/crud/CrudScreen.vue";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      productTypes: [],
      manufacturingCycleDefinitions: [],
      manufacturingLocations: [],
      columns: [
        "number",
        "name",
        "symbol",
        "productType",
        "numberOfImplants",
        "manufacturingCycleDefinition",
        "manufacturingLocation",
        "priority",
        "manufacturingAtRisk",
        "active",
      ],
      formOptions: {
        labelWidth: "30%",
      },
      tableOptions: {
        headings: {
          manufacturingCycleDefinition: "manufacturing Cycle Definition",
          manufacturingLocation: "manufacturing Location",
          productType: "product Type",
          manufacturingAtRisk: "Manufacturing at Risk",
          numberOfImplants: "number Of Implants",
        },
      },
      validationRules: {
        number: [
          { required: true, message: "Number is required", trigger: "blur" },
        ],
        name: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
        symbol: [
          { required: true, message: "Symbol is required", trigger: "blur" },
        ],
        productTypeId: [
          {
            required: true,
            message: "Product Type is required",
            trigger: "blur",
          },
        ],
        manufacturingCycleDefinitionId: [
          {
            required: true,
            message: "Manufacturing Cycle Definition is required",
            trigger: "blur",
          },
        ],
        manufacturingLocationId: [
          {
            required: true,
            message: "Manufacturing Location is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    template() {
      return {
        id: {
          component: {
            show: false,
          },
        },
        number: {
          title: "Number",
        },
        name: {
          title: "Name",
        },
        symbol: {
          title: "Symbol",
        },
        numberOfImplants: {
          title: "Number of implants",
          value: 1,
          component: {
            name: "el-input",
            type: "number",
            min: 1,
          },
        },
        productTypeId: {
          title: "Product Type",
          component: {
            name: "el-select",
            filterable: true,
            options: this.productTypes.map((productType) => ({
              value: productType.id,
              label: productType.name,
            })),
          },
        },
        manufacturingCycleDefinitionId: {
          title: "Manufacturing Cycle Definition",
          component: {
            name: "el-select",
            filterable: true,
            options: this.manufacturingCycleDefinitions.map(
              (manufacturingCycleDefinition) => ({
                value: manufacturingCycleDefinition.id,
                label: manufacturingCycleDefinition.name,
              })
            ),
          },
        },
        manufacturingLocationId: {
          title: "Manufacturing Location",
          component: {
            name: "el-select",
            filterable: true,
            options: this.manufacturingLocations.map(
              (manufacturingLocation) => ({
                value: manufacturingLocation.id,
                label: manufacturingLocation.name,
                disabled: !manufacturingLocation.isActive,
              })
            ),
          },
        },
        isPriority: {
          title: "Priority",
          component: {
            name: "el-checkbox",
          },
        },
        isActive: {
          title: "Active",
          value: true,
          component: {
            name: "el-checkbox",
          },
        },
      };
    },
  },
  methods: {
    getRecords() {
      productService.loadProducts().then((data) => {
        this.manufacturingCycleDefinitions =
          data.data.manufacturingCycleDefinitions;
        this.manufacturingLocations = data.data.manufacturingLocations;
        this.productTypes = data.data.productTypes;

        this.records = data.data.records.map((item) => ({
          ...item,
          productType: this.productTypes.find(
            (productType) => productType.id === item.productTypeId
          ).name,
          manufacturingCycleDefinition: item.manufacturingCycleDefinitionId
            ? this.manufacturingCycleDefinitions.find(
                (manufacturingCycleDefinition) =>
                  manufacturingCycleDefinition.id ===
                  item.manufacturingCycleDefinitionId
              ).name
            : "",
          manufacturingLocation: this.manufacturingLocations.find(
            (manufacturingLocation) =>
              manufacturingLocation.id == item.manufacturingLocationId
          ).name,
          active: item.isActive.toString(),
          priority: item.isPriority.toString(),
          manufacturingAtRisk: item.manufacturingAtRisk.toString(),
        }));
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      productService.addProduct(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      productService.editProduct(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

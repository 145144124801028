<template>
  <el-container v-loading.fullscreen.lock="loading">
    <el-page-header @back="goBack" />
    <el-header>
      <h1 class="details-header">Manufacturing Cycle Definition Details</h1>
    </el-header>
    <el-main>
      <el-card class="box-card">
        <div class="label-16">Name</div>
        <el-input v-model="model.name"></el-input>
        <work-procedures :work-procedure-list="model.workProcedures" />
      </el-card>
      <el-row class="mar-t-20">
        <el-button class="crud-add-button" @click="onSave(model)" plain
          >Save</el-button
        >
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import Vue from "vue";

import { manufacturingCycleDefinitionService } from "../../services/manufacturingCycleDefinition.service";
import ManufacturingCycleDefinitionModel from "../../models/ManufacturingCycleDefinitionModel";
import WorkProcedures from "./WorkProcedures.vue";
import { OperationResultType } from "../../enums/enums";

export default Vue.extend({
  components: {
    WorkProcedures,
  },
  created() {
    if (this.manufacturingCycleDefinitionId === 0) {
      this.model.id = 0;
      this.loading = false;
    } else {
      this.loadData();
    }
  },
  data() {
    return {
      loading: true,
      dialogVisible: false,
      model: new ManufacturingCycleDefinitionModel(),
    };
  },
  computed: {
    manufacturingCycleDefinitionId() {
      return Number(this.$route.params.id);
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      this.getManufacturingCycleDefinition().then(() => {
        this.loading = false;
      });
    },
    getManufacturingCycleDefinition() {
      var promise =
        manufacturingCycleDefinitionService.getManufacturingCycleDefinition(
          this.manufacturingCycleDefinitionId
        );
      promise.then((response) => {
        this.model = new ManufacturingCycleDefinitionModel(response.data);
      });

      return promise;
    },
    onSave(item) {
      this.loading = true;
      manufacturingCycleDefinitionService
        .save(item)
        .then((updateResponse) => {
          if (updateResponse.data.result === OperationResultType.Success) {
            if (
              updateResponse.data.id !== this.manufacturingCycleDefinitionId
            ) {
              this.$router.push({
                path:
                  "/ManufacturingCycleDefinition/Details/" +
                  updateResponse.data.id,
              });
              this.showNotification(
                "Manufacturing Cycle Definition created succesfully.",
                "success"
              );
            } else {
              this.showNotification(
                "Manufacturing Cycle Definition updated succesfully.",
                "success"
              );
            }
          } else {
            this.showNotification(updateResponse.data.message, "error");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    goBack() {
      this.$router.push("/ManufacturingCycleDefinition/List");
    },
  },
  watch: {
    "$route.params.id": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.box-card::v-deep > .label-16 {
  font-size: 16px;
  display: inline-block;
}
</style>

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-container>
      <el-header>
        <h1 class="details-header">NOTIFICATIONS SETTINGS</h1>
      </el-header>
      <el-main>
        <el-row :gutter="15">
          <el-col :span="8">
            <el-card style="width: 100%; margin-bottom: 20px">
              <div slot="header">
                <h4 class="pad-l-20">Main</h4>
              </div>
              <el-row>
                <el-form
                  ref="form"
                  label-width="140px"
                  size="mini"
                  style="width: 100%"
                >
                  <el-form-item
                    label="Notification Expiry"
                    class="form-item-padding"
                  >
                    <editable-number-input
                      v-model="notificationsSettings.notificationLifespan"
                      @input="editNotificationLifespan"
                      :editable="true"
                    ></editable-number-input>
                  </el-form-item>
                </el-form>
              </el-row>
            </el-card>
            <el-card style="width: 100%">
              <div slot="header">
                <h4 class="pad-l-20">Alerts</h4>
              </div>
              <el-collapse v-model="activeNames">
                <milestone-alerts
                  :milestoneAlertsSettings="milestoneAlertsSettings"
                  :refreshPage.sync="refreshPage"
                  :notificationsSettingsId="notificationsSettings.id"
                  :milestoneAlertsNotificationsDialog="
                    milestoneAlertsNotificationsDialog
                  "
                ></milestone-alerts>
                <job-step-alerts
                  :jobStepAlertsSettings="jobStepAlertsSettings"
                  :refreshPage.sync="refreshPage"
                  :notificationsSettingsId="notificationsSettings.id"
                  :jobStepAlertsNotificationsDialog="
                    jobStepAlertsNotificationsDialog
                  "
                ></job-step-alerts>
              </el-collapse>
            </el-card>
          </el-col>
          <el-col :span="16">
            <el-card style="width: 100%">
              <div slot="header">
                <h4 class="pad-l-20">Changes</h4>
              </div>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-collapse v-model="activeNames">
                    <fulfilment-plan-changes
                      :changesNotificationSettings="changesNotificationSettings"
                      :refreshPage.sync="refreshPage"
                      :notificationsSettingsId="notificationsSettings.id"
                      :changeNotificationSettingDialog="
                        changeNotificationSettingDialog
                      "
                    ></fulfilment-plan-changes>
                    <el-collapse-item name="milestoneChanges">
                      <template slot="title">
                        <div class="el-collapse_header-container">
                          <h4 class="el-collapse-header-bold">
                            Milestone Changes
                          </h4>
                        </div>
                      </template>
                      <planned-milestone-changes
                        :changesNotificationSettings="
                          changesNotificationSettings
                        "
                        :refreshPage.sync="refreshPage"
                        :notificationsSettingsId="notificationsSettings.id"
                        :changeNotificationSettingDialog="
                          changeNotificationSettingDialog
                        "
                      ></planned-milestone-changes>
                      <el-divider></el-divider>
                      <actual-milestone-changes
                        :changesNotificationSettings="
                          changesNotificationSettings
                        "
                        :refreshPage.sync="refreshPage"
                        :notificationsSettingsId="notificationsSettings.id"
                        :changeNotificationSettingDialog="
                          changeNotificationSettingDialog
                        "
                      ></actual-milestone-changes>
                    </el-collapse-item>
                  </el-collapse>
                </el-col>
                <el-col :span="12">
                  <el-collapse v-model="activeNames">
                    <manufacturing-cycle-changes
                      :changesNotificationSettings="changesNotificationSettings"
                      :refreshPage.sync="refreshPage"
                      :notificationsSettingsId="notificationsSettings.id"
                      :changeNotificationSettingDialog="
                        changeNotificationSettingDialog
                      "
                    ></manufacturing-cycle-changes>
                    <job-step-changes
                      :changesNotificationSettings="changesNotificationSettings"
                      :refreshPage.sync="refreshPage"
                      :notificationsSettingsId="notificationsSettings.id"
                      :changeNotificationSettingDialog="
                        changeNotificationSettingDialog
                      "
                    ></job-step-changes>
                  </el-collapse>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Vue from "vue";
import { EditableNumberInput } from "../shared/Shared.vue";
import { OperationResultType } from "../../enums/enums";
import { notificationSettingsService } from "../../services/notificationsettings.service";
import MilestoneAlerts from "./notification-settings/MilestoneAlerts.vue";
import JobStepAlerts from "./notification-settings/JobStepAlerts.vue";
import FulfilmentPlanChanges from "./notification-settings/FulfilmentPlanChanges.vue";
import ManufacturingCycleChanges from "./notification-settings/ManufacturingCycleChanges.vue";
import JobStepChanges from "./notification-settings/JobStepChanges.vue";
import PlannedMilestoneChanges from "./notification-settings/PlannedMilestoneChanges.vue";
import ActualMilestoneChanges from "./notification-settings/ActualMilestoneChanges.vue";

export default Vue.extend({
  components: {
    EditableNumberInput,
    MilestoneAlerts,
    JobStepAlerts,
    FulfilmentPlanChanges,
    ManufacturingCycleChanges,
    JobStepChanges,
    PlannedMilestoneChanges,
    ActualMilestoneChanges,
  },
  mounted() {
    this.getNotificationSettings();
  },
  data() {
    return {
      loading: false,
      refreshPage: false,
      userId: null,
      notificationsSettings: {},
      activeNames: [
        "milestoneAlertsSettings",
        "jobStepAlertsSettings",
        "fulfilmentPlanChanges",
        "milestoneChanges",
        "manufacturingCycleChanges",
        "jobStepChanges",
      ],
      jobStepAlertsSettings: [],
      milestoneAlertsSettings: [],
      changesNotificationSettings: {},
      milestoneAlertsNotificationsDialog: {},
      jobStepAlertsNotificationsDialog: {},
      changeNotificationSettingDialog: {},
    };
  },
  watch: {
    refreshPage: function (val) {
      if (val) {
        this.getNotificationSettings();
        this.refreshPage = false;
      }
    },
  },
  methods: {
    getNotificationSettings() {
      this.loading = true;
      notificationSettingsService
        .getNotificationsSettings()
        .then((response) => {
          this.userId = response.data.userId;
          this.notificationsSettings = response.data.notificationsSettings;
          this.milestoneAlertsSettings = response.data.milestoneAlertsSettings;
          this.jobStepAlertsSettings = response.data.jobStepAlertsSettings;
          this.changesNotificationSettings =
            response.data.changesNotificationSettings;
          this.milestoneAlertsNotificationsDialog =
            response.data.milestoneAlertsNotificationsDialog;
          this.jobStepAlertsNotificationsDialog =
            response.data.jobStepAlertsNotificationsDialog;
          this.changeNotificationSettingDialog =
            response.data.changeNotificationSettingDialog;
          this.changeNotificationSettingDialog.changesNotificationSettings =
            response.data.changesNotificationSettings;
          this.loading = false;
        });
    },
    editNotificationLifespan() {
      notificationSettingsService
        .editNotificationLifespan(
          this.notificationsSettings.id,
          this.userId,
          this.notificationsSettings.notificationLifespan
        )
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Notification Expiry changed succesfully.",
              "success"
            );
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.getNotificationSettings();
          }
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

<style lang="scss">
.el-collapse_header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.el-collapse_header-container > .el-collapse-header-bold {
  font-weight: bold;
}

.el-collapse_header-container > .mar-r-5 {
  margin-right: 5px;
}

.el-container-item {
  display: flex;
  justify-content: space-between;
}

.el-container-item > .mar-b-15-l-5 {
  margin: 0 0 15px 5px;
}
</style>

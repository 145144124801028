<template>
  <el-dialog
    width="25%"
    title="Add product"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="item"
      :rules="rules"
      :validateOnRuleChange="false"
      ref="productForm"
      label-width="150px"
    >
      <el-form-item label="Name" prop="description">
        <el-input v-model="item.description"></el-input>
      </el-form-item>
      <el-form-item label="Catalog Number" prop="catalogNumber">
        <el-input v-model="item.catalogNumber"></el-input>
      </el-form-item>
      <el-form-item label="GTIN" prop="gtin">
        <el-input maxlength="14" v-model="item.gtin"></el-input>
      </el-form-item>
      <el-form-item label="Division" prop="divisionId">
        <el-select filterable v-model="item.divisionId">
          <el-option
            v-for="division in availableDivisions"
            :key="division.value"
            :label="division.label"
            :value="division.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Add</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: {
        description: null,
        catalogNumber: null,
        gtin: null,
        divisionId: null,
      },
      rules: {
        description: [{ required: true, message: "Name is required" }],
        divisionId: [{ required: true, message: "Division is required" }],
        catalogNumber: [
          { required: true, message: "Catalog Number is required" },
        ],
        gtin: [
          { required: true, message: "GTIN is required" },
          { len: 14, message: "GTIN should contain 14 characters" },
          {
            pattern: /^[0-9]*$/,
            message: "GTIN should contain numberic characters only",
          },
        ],
      },
    };
  },
  computed: {
    availableDivisions() {
      return this.$store.state.document.availableDivisions;
    },
  },
  methods: {
    clearFieldsValidation(...fieldNames) {
      if (this.$refs.productForm != null) {
        this.$refs.productForm.clearValidate(fieldNames);
      }
    },
    submitForm() {
      this.$refs.productForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.item);
        }
        return false;
      });
    },
    closeForm() {
      this.$refs.productForm.resetFields();
      this.$emit("update:visible", false);
      this.item = {
        description: null,
        catalogNumber: null,
        gtin: null,
        divisionId: null,
      };
    },
  },
});
</script>

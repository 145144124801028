
export default {};
export const CombinedOrderKeyType = {
  BuildId: 1,
  TrackingNumber: 2,
  SterileLotNumber: 3,
};

export function getCombinedOrderLabel(keyType: number): string {
  switch (keyType) {
    case CombinedOrderKeyType.TrackingNumber:
      return "Tracking Number";
    case CombinedOrderKeyType.BuildId:
      return "Build Id";
    case CombinedOrderKeyType.SterileLotNumber:
      return "Sterile Lot Number";
    default:
      return "Identifier";
  }
}

import { HTTP } from "./HttpCommon";
import CommentModel from "@/models/CommentModel";
import CommentFormModel from "@/models/CommentFormModel";
import ChangeDataResponseModel from "@/models/ChangeDataResponseModel";

class CombinedOrderService {
  loadCombinedOrderDefinitions() {
    return HTTP.get("CombinedOrder/GetCombinedOrderDefinitions");
  }
  addCombinedOrder(
    identifier: string,
    orderNumber: string,
    combinedOrderDefinitionId: number
  ) {
    return HTTP.post("CombinedOrder/CreateCombinedOrder", {
      identifier,
      combinedOrderDefinitionId,
      orderNumber: orderNumber,
    });
  }

  getWorkOrder(combinedOrderId: number) {
    return HTTP.get("CombinedOrder/GetWorkOrder", {
      params: {
        id: combinedOrderId,
      },
    });
  }

  getCombinedManufacturingJobs(combinedOrderId: number) {
    return HTTP.get("CombinedOrder/GetCombinedManufacturingJobs", {
      params: {
        id: combinedOrderId,
      },
    });
  }

  getFilteredIdentifiers(definitionId: number, query: string) {
    return HTTP.get("CombinedOrder/GetFilteredIdentifiers", {
      params: {
        definitionId,
        query,
      },
    });
  }

  getCandidates(combinedOrderId: number) {
    return HTTP.get("CombinedOrder/GetCandidates", {
      params: {
        id: combinedOrderId,
      },
    });
  }

  addCombinedPlan(combinedOrderId: number, manufacturingJobId: number) {
    return HTTP.post("CombinedOrder/AddCombinedPlan", {
      combinedOrderId,
      manufacturingJobId,
    });
  }

  deleteCombinedPlan(combinedOrderId: number, manufacturingJobId: number) {
    return HTTP.post("CombinedOrder/RemoveCombinedPlan", {
      combinedOrderId,
      manufacturingJobId,
    });
  }

  startCurrentStep(combinedOrderId: number) {
    return HTTP.put(`CombinedOrder/${combinedOrderId}/StartCurrentStep`);
  }

  markCurrentStepAsCompleted(combinedOrderId: number) {
    return HTTP.put(
      `CombinedOrder/${combinedOrderId}/MarkCurrentStepAsCompleted`
    );
  }

  undoStep(combinedOrderId: number) {
    return HTTP.put(`CombinedOrder/${combinedOrderId}/UndoStep`);
  }

  getCombinedOrderDefinitions() {
    return HTTP.get("CombinedOrder/GetCombinedOrderDefinitions");
  }

  getCombinedOrderStatuses() {
    return HTTP.get("CombinedOrder/GetCombinedOrderStatuses");
  }

  editOrderNumber(
    combinedOrderId: number,
    orderNumber: string,
    success,
    error
  ) {
    return HTTP.post("CombinedOrder/editOrderNumber", {
      combinedOrderId,
      orderNumber,
    })
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        error(e);
      });
  }

  addComment(combinedOrderId: number, text: string) {
    return HTTP.post<ChangeDataResponseModel>(
      `CombinedOrder/${combinedOrderId}/Comments`,
      {
        text,
      }
    );
  }
  editComment(comment: CommentModel) {
    return HTTP.put<ChangeDataResponseModel>(
      `CombinedOrder/Comments/${comment.id}`,
      comment
    );
  }
  deleteComment(comment: CommentModel) {
    return HTTP.delete<ChangeDataResponseModel>(
      `CombinedOrder/Comments/${comment.id}`
    );
  }
  editIdentifier(combinedOrderId: number, identifier: string, success, error) {
    return HTTP.post("CombinedOrder/editIdentifier", {
      combinedOrderId,
      identifier,
    })
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        error(e);
      });
  }
}

export const combinedOrderService = new CombinedOrderService();

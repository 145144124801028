import { combinedOrderService } from "../../services/combinedorder.service";

const storeCombinedOrderId = "storeCombinedOrderId";

const getCombinedOrderStatuses = "getCombinedOrderStatuses";
const getCombinedOrderDefinitions = "getCombinedOrderDefinitions";

const getters = {};

const state = {
  combinedOrderId: null,
  combinedOrderStatuses: [],
  combinedOrderDefinitions: [],
};

const actions = {
  [getCombinedOrderStatuses]({ state, commit }) {
    if (state.combinedOrderStatuses.length === 0) {
      combinedOrderService.getCombinedOrderStatuses().then((response) => {
        commit(getCombinedOrderStatuses, response.data);
      });
    }
  },
  [getCombinedOrderDefinitions]({ commit }) {
    if (state.combinedOrderDefinitions.length === 0) {
      combinedOrderService.getCombinedOrderDefinitions().then((response) => {
        commit(getCombinedOrderDefinitions, response.data);
      });
    }
  },
};

const mutations = {
  [storeCombinedOrderId](state, value) {
    state.combinedOrderId = value;
  },
  [getCombinedOrderStatuses](state, data) {
    state.combinedOrderStatuses = data;
  },
  [getCombinedOrderDefinitions](state, data) {
    state.combinedOrderDefinitions = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

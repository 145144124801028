<template>
  <div>
    <el-table :data="milestoneLeadTimes" style="width: 100%">
      <el-table-column
        sortable
        align="center"
        show-overflow-tooltip
        sort-by="name"
        prop="name"
        label="Lead Times Group Name"
      ></el-table-column>
      <el-table-column
        sortable
        align="center"
        show-overflow-tooltip
        sort-by="name"
        prop="productType"
        label="Default product type"
      ></el-table-column>
      <el-table-column width="250">
        <template slot-scope="scope">
          <router-link :to="'/MilestoneLeadTime/' + scope.row.id">
            <el-button size="mini">List</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { milestoneLeadTimeService } from "../../services/milestoneleadtime.service";
import { MilestoneLeadTimeGroup } from "../../models/MilestoneLeadTimeGroup";
export default {
  created: function () {
    let promise1 = milestoneLeadTimeService.loadMilestoneLeadTimeGroups();

    promise1.then((response) => {
      this.milestoneLeadTimes = response.data;
      this.loading = false;
    });
  },
  data() {
    return {
      loading: true,
      milestoneLeadTimes: [],
    };
  },
};
</script>

import { DesignHttpClient } from "../../shared/DesignHttpClient";

class ConfigurationService {
  getProducts() {
    return DesignHttpClient.get("configuration/products");
  }

  getTimezones() {
    return DesignHttpClient.get("configuration/timezones");
  }

  getAllTimezones() {
    return DesignHttpClient.get("configuration/timezones/all");
  }
}

export const configurationService = new ConfigurationService();

<template>
  <el-container>
    <el-header>
      <h1 class="details-header">IMPORT REQUEST DETAILS</h1>
    </el-header>
    <el-main>
      <div
        class="alert alert-info alert-block"
        v-for="item in messages"
        :type="getType(item.type)"
        :key="item.message"
      >
        <button class="close" data-dismiss="alert" type="button">×</button>
        <p>{{ item.message }}</p>
      </div>
      <el-alert
        v-for="(item, index) in multipieceCandidates"
        :type="getType(2)"
        :key="index"
        >Possible Multi-Piece Case: {{ item.hospital }}, {{ item.surgeon }}
        <span v-if="item.surgeryDate"
          >, {{ item.surgeryDate | moment }}</span
        ></el-alert
      >
      <v-server-table
        style="margin-top: 15px"
        ref="table"
        :columns="columns"
        :options="options"
      >
        <div slot-scope="props" slot="requestId">
          <router-link
            v-if="props.row.fulfilmentPlanId"
            :to="'/FulfilmentPlan/Details/' + props.row.fulfilmentPlanId"
            >{{ props.row.requestId }}</router-link
          >
          <p v-else>{{ props.row.requestId }}</p>
        </div>
        <div slot-scope="props" slot="updatedFields">
          <div
            v-for="item in props.row.updatedFields"
            :key="item.changeDescription"
          >
            {{ item.changeDescription }}
          </div>
        </div>
      </v-server-table>
    </el-main>
  </el-container>
</template>
<script>
import Vue from "vue";
import { requestImportService } from "../../services/requestimport.service";
import DateTime from "../../mixins/DateTime";
import MessageTypeHelper from "../../utils/MessageTypeHelper";

export default Vue.extend({
  mounted() {
    this.importId = this.$route.params.id;
    this.getMessages();
  },
  mixins: [DateTime],
  data() {
    return {
      records: [],
      importId: null,
      messages: [],
      multipieceCandidates: [],
      columns: [
        "requestId",
        "statusName",
        "skipReason",
        "updatedFields",
        "additionalInfo",
      ],
      options: {
        headings: {
          requestId: "Plan Id",
          statusName: "Status",
          skipReason: "Skip Reason",
          updatedFields: "Updated Fields (Old Value ➜ New Value)",
          additionalInfo: "Additional Info",
        },
        requestFunction: (data) => {
          data.id = this.$route.params.id;
          return requestImportService.getImportDetails(data).catch((error) => {
            console.log(error.response);
          });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.importResults,
            count: response.data.count,
          };
        },
        filterable: false,
        sortable: [],
      },
    };
  },
  methods: {
    getMessages() {
      requestImportService.getImportMessages(this.importId).then((data) => {
        this.messages = data.data.messages;
        this.multipieceCandidates = data.data.multipieceCandidates;
      });
    },
    getType(type) {
      return MessageTypeHelper.getDescription(type);
    },
  },
});
</script>

export default class UserListItemModel {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  symbol: string;
  isActive: boolean;
  timestamp: string;

  constructor(data: any = null) {
    if (data !== null) {
      this.id = data.id;
      this.userName = data.userName;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.email = data.email;
      this.symbol = data.symbol;
      this.isActive = data.isActive;
      this.timestamp = data.timestamp;
    }
  }
}

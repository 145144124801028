import { HTTP } from "./HttpCommon";
import ManufacturingCycleBulkOperationsAffectedCasesModel from "@/models/ManufacturingCycleBulkOperationsAffectedCasesModel";
import ManufacturingCycleBulkOperationsWorkflowProgressModel from "@/models/ManufacturingCycleBulkOperationsWorkflowProgressModel";

class ManufacturingCycleBulkOperationsService {
  getManufacturingCyclesWithCompatibleWorkflows(
    model: ManufacturingCycleBulkOperationsAffectedCasesModel
  ) {
    return HTTP.post(
      "ManufacturingCycle/GetManufacturingCyclesWithCompatibleWorkflows",
      model
    );
  }

  getWorkflowData(
    model: ManufacturingCycleBulkOperationsWorkflowProgressModel
  ) {
    return HTTP.post(
      "ManufacturingCycle/GetManufacturingCycleWorkflows",
      model
    );
  }
}

export const manufacturingCycleBulkOperationsService =
  new ManufacturingCycleBulkOperationsService();

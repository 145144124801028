export default class SimpleSelectControlItemModel {
  value: number;
  label: string;

  constructor(data: any = null) {
    this.value = null;
    this.label = "";

    if (data) {
      this.value = data.value;
      this.label = data.label;
    }
  }
}

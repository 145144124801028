<template>
  <div>
    <el-container>
      <el-header>
        <combined-order-list-filters
          v-on:show-dialog="showDialog"
          :identifier-label-name="identifierName"
        />
      </el-header>
      <el-main>
        <combined-order-table
          v-if="combinedOrderDefinitions.length > 0"
          :identifier-column-name="identifierName"
          :combined-order-definition="combinedOrderDefinition"
        ></combined-order-table>
      </el-main>
    </el-container>
    <add-combined-order-dialog
      :visible.sync="addNewDialogVisible"
      v-on:submit="addNewCombinedOrder"
      :identifier-name="identifierName"
      :combined-order-definition="combinedOrderDefinition"
    ></add-combined-order-dialog>
  </div>
</template>
<script>
import Vue from "vue";

import { showLoading, hideLoading } from "../../store/mutation-types";
import { mapMutations } from "vuex";
import AddCombinedOrderDialog from "./AddCombinedOrderDialog.vue";
import CombinedOrderListFilters from "./CombinedOrderListFilters.vue";
import CombinedOrderTable from "./CombinedOrderTable.vue";
import { combinedOrderService } from "../../services/combinedorder.service";
import { Event } from "vue-tables-2";
import qs from "qs";
import { OperationResultType } from "../../enums/enums";
import {
  CombinedOrderKeyType,
  getCombinedOrderLabel,
} from "./CombinedOrderKeyType";

import _ from "lodash";
import CombinedOrderDefinitionModel from "../../models/CombinedOrderDefinitionModel";

export default Vue.extend({
  components: {
    AddCombinedOrderDialog,
    CombinedOrderListFilters,
    CombinedOrderTable,
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      combinedOrderKeyType: CombinedOrderKeyType,
      addNewDialogVisible: false,
    };
  },
  computed: {
    currentFilterQuery: function () {
      var result = qs.stringify(this.$route.query);
      return result ? "?" + result : "";
    },
    chosenDefinitionId() {
      if (this.$route.query.chosenDefinition) {
        return this.$route.query.chosenDefinition;
      }
      if (
        !!this.combinedOrderDefinitions &&
        this.combinedOrderDefinitions.length > 0
      ) {
        return this.combinedOrderDefinitions[0].id;
      }

      return null;
    },
    combinedOrderDefinitions() {
      return this.$store.state.combinedOrder.combinedOrderDefinitions;
    },
    combinedOrderDefinition() {
      let data = {};
      if (this.chosenDefinitionId) {
        data = this.combinedOrderDefinitions.find(
          (cod) => cod.id === Number(this.chosenDefinitionId)
        );
      } else if (this.combinedOrderDefinitions.length > 0) {
        data = this.combinedOrderDefinitions[0];
      }

      return new CombinedOrderDefinitionModel(data);
    },
    identifierName() {
      const keyType = this.combinedOrderDefinition.keyType;
      return getCombinedOrderLabel(keyType);
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    loadData() {
      Event.$on("vue-tables.loading", () => {
        this.showLoading();
      });

      Event.$on("vue-tables.loaded", () => {
        this.hideLoading();
      });
    },
    addNewCombinedOrder: function (item) {
      this.addNewDialogVisible = false;
      combinedOrderService
        .addCombinedOrder(
          item.identifier,
          item.orderNumber,
          item.combinedOrderDefinitionId
        )
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.$router.push({
              path: `CombinedOrders/${response.data.message}`,
            });
            this.$message({
              type: "success",
              message: `Combined order created succesfully.`,
            });
          } else {
            this.$alert(response.data.message, "Validation Error", {
              confirmButtonText: "OK",
              type: "error",
              dangerouslyUseHTMLString: true,
              callback: (action) => {
                this.showDialog();
              },
            });
          }
        })
        .catch((error) => {
          this.$alert(error.response.data, "Validation Error", {
            confirmButtonText: "OK",
            type: "error",
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.showDialog();
            },
          });
        });
    },
    showDialog: function () {
      this.addNewDialogVisible = true;
    },
  },
});
</script>

import { HTTP } from "./HttpCommon";
import { DocumentationHttpClient as documentationHttpClient } from "../shared/edoc/DocumentationHttpClient";

class NavBarService {
  getReports(success) {
    HTTP.get(`Report/GetReportList`).then((response) => {
      success(response.data);
    });
  }

  getFulfilmentPlanFilters(success) {
    HTTP.get(`FulfilmentPlan/GetFiltersList`).then((response) => {
      success(response.data);
    });
  }

  getManufacturingFilters(success) {
    HTTP.get(`ManufacturingCycle/GetFiltersList`).then((response) => {
      success(response.data);
    });
  }

  getChangesFilters(success, error) {
    HTTP.get(`Changes/GetFiltersList`)
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        error(e);
      });
  }

  getDocumentsFilters(success) {
    documentationHttpClient.get("/StoredQuery/Document").then((response) => {
      success(response.data);
    });
  }

  getProductsFilters(success) {
    documentationHttpClient.get("/StoredQuery/Product").then((response) => {
      success(response.data);
    });
  }

  getNumberOfNotifications(success) {
    HTTP.get(`Notification/GetNumberOfNotifications`).then((response) => {
      success(response.data);
    });
  }

  getGrantedEDocPermissions(success, error) {
    documentationHttpClient
      .get("/Permission/Granted")
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        error(e);
      });
  }

  getNotifications(success) {
    HTTP.get(`Notification/GetNotifications`).then((response) => {
      success(response.data);
    });
  }

  acknowledgeNotifications(success) {
    HTTP.get(`Notification/AcknowledgeNotifications`).then((response) => {
      success(response.data);
    });
  }

  acknowledgeNotification(id, success) {
    HTTP.get(`Notification/AcknowledgeNotification/` + id).then((response) => {
      success(response.data);
    });
  }
}

export const navBarService = new NavBarService();

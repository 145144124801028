import { ProductTypeChildrenFilterModel } from "./ProductTypeChildrenFilterModel";

export default class ProductTypeFilterModel {
  value: number;
  label: string;
  children = new Array<ProductTypeChildrenFilterModel>();

  constructor(model: any) {
    if (model) {
      this.value = model.id;
      this.label = model.name;
      if (model.products) {
        model.products.forEach((item: any) => {
          this.children.push(new ProductTypeChildrenFilterModel(item));
        });
      } else {
        this.children = null;
      }
    }
  }
}

<template>
  <div class="fright">
    <el-form-item class="fright">
      <template slot="label">&nbsp;</template>
      <el-popover
        placement="bottom"
        title="Save filters"
        width="200"
        v-model="saveFiltersPopoverVisible"
        trigger="click"
      >
        <el-input
          v-model="filterNameToSave"
          placeholder="Filter name"
        ></el-input>
        <el-button
          style="margin-top: 10px"
          @click="saveFilters"
          :disabled="isFilterNameToSaveInvalid()"
          type="primary"
          >Save</el-button
        >
        <el-button slot="reference" style="margin-bottom: 5px"
          >Save filters</el-button
        >
      </el-popover>
    </el-form-item>
    <el-form-item class="fright">
      <template slot="label">&nbsp;</template>
      <el-button style="margin-bottom: 5px" @click="openApplyFiltersDialog"
        >Apply filters</el-button
      >
    </el-form-item>
    <el-dialog
      title="Apply filters"
      :visible.sync="applyFiltersDialogVisible"
      :before-close="closeApplyFiltersDialog"
      width="30%"
    >
      <span>
        <el-select
          v-model="filterSelectedToApply"
          placeholder="Select filter..."
          style="width: 100%"
        >
          <el-option
            v-for="item in availableFilters"
            :key="item.id"
            :label="item.name"
            :value="item.query"
          >
            <span style="float: left">{{ item.name }}</span>
            <span
              v-if="item.isPrivate"
              class="icon-sharing-level glyphicon glyphicon-user"
            ></span>
            <span
              v-if="!item.isPrivate"
              class="icon-sharing-level glyphicon glyphicon-globe"
            ></span>
          </el-option>
        </el-select>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeApplyFiltersDialog"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          :disabled="filterSelectedToApply === null"
          @click="applyFilters"
          >Apply</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import qs from "qs";
import _ from "lodash";
import { storedQueryService } from "../../../services/edoc/storedquery.service";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  created() {
    this.loadFilters();
  },
  props: {
    storedQueryType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      saveFiltersPopoverVisible: false,
      filterNameToSave: null,
      applyFiltersDialogVisible: false,
      filterSelectedToApply: null,
      availableFilters: [],
    };
  },
  computed: {},
  methods: {
    loadFilters() {
      storedQueryService
        .getStoredQueries(this.storedQueryType)
        .then((response) => {
          this.availableFilters = response.data;
        });
    },
    openApplyFiltersDialog() {
      this.applyFiltersDialogVisible = true;
    },
    closeApplyFiltersDialog() {
      this.applyFiltersDialogVisible = false;
    },
    saveFilters() {
      storedQueryService
        .addStoredQuery(
          this.storedQueryType,
          qs.stringify(this.$route.query),
          this.filterNameToSave
        )
        .then(
          () => this.showNotification(Resources.SaveFilterSuccess, "success"),
          () => this.showNotification(Resources.SaveFilterError, "error")
        );

      this.saveFiltersPopoverVisible = false;
      this.filterNameToSave = null;
    },
    applyFilters() {
      const prefix = "handleFilter=1&";
      const query = qs.parse(prefix + this.filterSelectedToApply);
      this.$router.replace({ query });

      this.closeApplyFiltersDialog();
    },
    isFilterNameToSaveInvalid() {
      return (
        this.filterNameToSave === null ||
        this.filterNameToSave.match(/^ *$/) !== null
      );
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
  watch: {},
});
</script>
<style lang="scss">
.icon-sharing-level {
  margin-left: 5px;
}
</style>

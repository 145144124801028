import "bootstrap/dist/css/bootstrap.min.css";
import "regenerator-runtime/runtime";
import "./plugins/element.ts";

import "./assets/styles/_common.scss";
import "./assets/styles/crt/_fonts.scss";
import "./assets/styles/crt/_fulfilment-plan.scss";
import "./assets/styles/crt/_manufacturing-cycle.scss";
import "./assets/styles/vue/_vue-element-custom.scss";
import "./assets/styles/vue/_vue-tables-2-custom.scss";

import Vue from "vue";
import Vuex from "vuex";

import { ClientTable, ServerTable } from "vue-tables-2";
Vue.use(ClientTable);
Vue.use(ServerTable);

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faIndustry,
  faColumns,
  faChartPie,
  faPencilRuler,
  faCogs,
  faUserCog,
  faBell,
  faCheck,
  faCheckDouble,
  faSearch,
  faUser,
  faGlobeAmericas,
  faBox,
  faFileAlt,
  faKey,
  faSave,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faIndustry,
  faColumns,
  faChartPie,
  faPencilRuler,
  faCogs,
  faUserCog,
  faBell,
  faCheck,
  faCheckDouble,
  faSearch,
  faUser,
  faGlobeAmericas,
  faBox,
  faFileAlt,
  faKey,
  faSave,
  faInfoCircle
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueClipboard from "vue-clipboard2";
import moment from "moment";
import "moment-timezone";

import Notifications from "vue-notification";
Vue.use(Notifications, {
  name: "vueNotify",
});

import store from "./store";
import router from "./router";

Vue.use(Vuex);

Vue.use(VueClipboard);

import VueAppInsights from "./plugins/application-insights";

Vue.use(VueAppInsights, {
  router,
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  }

  return "";
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY");
  }

  return "";
});

Vue.filter("formatTime", function (value) {
  if (value) {
    return moment(value).format("HH:mm");
  }

  return "";
});

Vue.filter("formatDateTimeOffset", function (value) {
  if (value) {
    const utcDate = moment(value).toDate();
    const local = moment(utcDate).local().format("DD/MM/YYYY HH:mm:ss");

    return local;
  }

  return "";
});

// import { Log } from "oidc-client"; //Identity Server diagnosis
// Log.logger = console;
// Log.level = Log.DEBUG; // eslint-disable-line

import App from "./App.vue";

new Vue({
  el: "#app",
  store,
  router,
  render: (h) => h(App),
});

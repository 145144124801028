import _ from "lodash";
import moment from "moment";

export default {
  filters: {
    momenttz: function (date, timezone) {
      if (date && timezone) {
        return moment(date).tz(timezone).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
    moment: function (date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    datetime: function (date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY HH:mm");
      } else {
        return "-";
      }
    },
  },
};

<template>
  <div>
    <el-dialog
      :visible="visible"
      :before-close="cancel"
      :title="title"
      width="650px"
    >
      <el-form>
        <el-form-item label="Absence range">
          <el-row>
            <el-checkbox
              v-model="isAllDay"
              @change="allDayChanged"
              style="margin-right: 10px"
              >All day</el-checkbox
            >
            <el-date-picker
              v-if="isAllDay"
              v-model="dateRangeSelection"
              type="daterange"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>
            <el-date-picker
              v-else
              v-model="dateRangeSelection"
              type="datetimerange"
              start-placeholder="Start date"
              end-placeholder="End date"
              format="yyyy-MM-dd HH:mm"
            ></el-date-picker>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          type="danger"
          v-if="isEditMode"
          v-on:click="openDeleteConfirmDialog"
          >Delete</el-button
        >
        <el-button @click="cancel">Cancel</el-button>
        <el-button type="primary" @click="saveAbsence">OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import "moment-timezone";

import { designerAvailabilityService } from "../../services/design/designeravailability.service";

import Absence from "../../models/design/Absence";

export default Vue.extend({
  props: {
    visible: {
      required: true,
      default: false,
    },
    timezone: {
      type: String,
      required: true,
    },
    designerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: new Absence(),
      isAllDay: false,
    };
  },
  computed: {
    isEditMode() {
      return this.model.id !== null;
    },
    dateRangeSelection: {
      get: function () {
        return this.model.startDate && this.model.endDate
          ? [
              moment
                .tz(
                  this.model.startDate
                    .clone()
                    .tz(this.timezone)
                    .format("YYYY-MM-DD HH:mm"),
                  this.timezone
                )
                .format("YYYY-MM-DD HH:mm"),
              moment
                .tz(
                  this.model.endDate
                    .clone()
                    .tz(this.timezone)
                    .format("YYYY-MM-DD HH:mm"),
                  this.timezone
                )
                .format("YYYY-MM-DD HH:mm"),
            ]
          : Array(2).fill("");
      },
      set: function (newValue) {
        if (newValue !== null) {
          let startDate = moment
            .tz(
              moment(newValue[0]).local().format("YYYY-MM-DD HH:mm"),
              this.timezone
            )
            .local();

          let endDate = null;
          if (this.isAllDay) {
            endDate = moment
              .tz(
                moment(newValue[1]).local().format("YYYY-MM-DD HH:mm"),
                this.timezone
              )
              .endOf("day")
              .local();
          } else {
            endDate = moment
              .tz(
                moment(newValue[1]).local().format("YYYY-MM-DD HH:mm"),
                this.timezone
              )
              .local();
          }

          this.model.startDate = startDate;
          this.model.endDate = endDate;
        } else {
          this.model.startDate = "";
          this.model.endDate = "";
        }
      },
    },
    title() {
      return this.isEditMode ? "EDIT ABSENCE" : "ADD ABSENCE";
    },
  },
  methods: {
    allDayChanged(newValue) {
      if (newValue) {
        let startDate = this.model.startDate
          .clone()
          .tz(this.timezone)
          .startOf("day")
          .local();
        let endDate = this.model.endDate
          .clone()
          .tz(this.timezone)
          .endOf("day")
          .local();

        this.model.startDate = startDate;
        this.model.endDate = endDate;
      }
    },
    loadAbsence(id) {
      designerAvailabilityService.getAvailability(id).then((data) => {
        this.model = new Absence(data.data);
        const startDate = moment.tz(this.model.startDate, this.timezone);
        const startOfDay = startDate.clone().startOf("day");

        const endDate = moment.tz(this.model.endDate, this.timezone);
        const endOfDay = endDate.clone().endOf("day");
        this.isAllDay =
          startDate.isSame(startOfDay, "second") &&
          endDate.isSame(endOfDay, "second");

        this.$emit("update:visible", true);
      });
    },
    saveAbsence() {
      if (this.model.id !== null) {
        this.updateAbsence();
      } else {
        this.addAbsence();
      }
    },
    addAbsence() {
      designerAvailabilityService
        .addAbsence(this.model, this.designerId)
        .then((data) => {
          if (data.data.result === 0) {
            this.$emit("added");
            this.$emit("update:visible", false);
            return;
          }

          this.$message({
            message: data.data.messages.join(" "),
            type: "error",
            showClose: true,
          });
        });
    },
    updateAbsence() {
      designerAvailabilityService.updateAbsence(this.model).then((data) => {
        if (data.data.result === 0) {
          this.$emit("updated");
          this.$emit("update:visible", false);
          this.clearModel();
          return;
        }

        this.$message({
          message: data.data.messages.join(" "),
          type: "error",
          showClose: true,
        });
      });
    },
    deleteAbsence() {
      designerAvailabilityService.deleteAbsence(this.model.id).then((data) => {
        if (data.data.result === 0) {
          this.$emit("deleted");
          this.$emit("update:visible", false);
          this.clearModel();

          return;
        }

        this.$message({
          message: data.data.messages,
          type: "error",
          showClose: true,
        });
      });
    },
    clearModel() {
      this.model = new Absence(null, this.timezone);
    },
    cancel() {
      this.$emit("update:visible", false);
      this.clearModel();
    },
    openDeleteConfirmDialog() {
      this.$confirm("Do you want to delete this absence?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.deleteAbsence();
          this.$message({
            type: "success",
            message: "Delete completed",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
  },
});
</script>

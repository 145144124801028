export class SelectControlStringItemModel {
  value: string;
  label: string;

  constructor(model: any) {
    if (model) {
      this.value = model.value;
      this.label = model.label;
    }
  }
}

export default class CombinedOrderDefinitionModel {
  id: number;
  name = "";
  keyType: number;

  constructor(data: any = null) {
    if (data !== null) {
      this.id = data.id;
      this.name = data.name;
      this.keyType = data.keyType;
    }
  }
}

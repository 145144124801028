export default {
  namespaced: true,
  state: {
    timestamp: "",
    refreshData: false,
    planDataRefresh: false,
  },
  mutations: {
    storeTimestamp(state, value) {
      state.timestamp = value;
    },
    setRefreshData(state, value = true) {
      state.refreshData = value;
    },
    setPlanDataRefresh(state, value) {
      state.planDataRefresh = value;
    },
  },
  actions: {
    setRefreshData(context, value = true) {
      context.commit("setRefreshData", value);
    },
    storeTimestamp(context, value) {
      context.commit("storeTimestamp", value);
    },
    setPlanDataRefresh(context, value) {
      context.commit("setPlanDataRefresh", value);
    },
  },
};

<template>
  <el-card
    v-loading.fullscreen.lock="loading"
    v-if="filters && (filters.length > 0 || canManageSharingLevel)"
  >
    <div slot="header">
      <h4>CRT Filters</h4>
    </div>

    <crt-filters-list-user-filter
      v-if="canManageSharingLevel"
      ref="filters"
      @userChange="userFilterChanged"
    ></crt-filters-list-user-filter>

    <v-client-table
      style="margin-top: 15px"
      ref="table"
      :columns="columns"
      :options="options"
      :data="filters"
    >
      <div slot="name" slot-scope="props">
        <a :href="getHrefForType(props.row.containingEntity) + props.row.id">
          {{ props.row.name }}
        </a>
      </div>
      <div slot="accessibility" slot-scope="props">
        <span v-if="props.row.sharingLevel === 0">Private</span>
        <span v-if="props.row.sharingLevel === 1">Public</span>
      </div>
      <div slot="type" slot-scope="props">
        {{ getType(props.row.containingEntity) }}
      </div>
      <div slot="actions" slot-scope="props">
        <div v-if="props.row.canBeManaged">
          <el-button
            type="primary"
            size="medium"
            class="el-icon-edit"
            @click="openEditDialog(props.row)"
          ></el-button>
          <el-popconfirm
            title="Do you want to remove this filter?"
            @confirm="deleteStoredQuery(props.row)"
          >
            <el-button
              slot="reference"
              type="primary"
              size="medium"
              class="el-icon-delete"
            ></el-button>
          </el-popconfirm>
        </div>
      </div>
    </v-client-table>
    <edit-filter-dialog
      :show-sharing-level="canManageSharingLevel"
      :visible.sync="editFilterDialogVisible"
      :item="editedFilter"
      @submit="editStoredQuery"
    ></edit-filter-dialog>
  </el-card>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import EditFilterDialog from "./EditFilterDialog.vue";
import { filterService } from "../../services/filter.service";
import { Event } from "vue-tables-2";

import CrtFiltersListUserFilter from "./CrtFiltersListUserFilter.vue";

export default Vue.extend({
  components: {
    EditFilterDialog,
    CrtFiltersListUserFilter,
  },
  mounted() {
    this.getFilters();
  },
  computed: {
    ...mapState("permissions", ["userPermissions"]),
    canManageSharingLevel() {
      return this.userPermissions.CanManageGlobalFilters;
    },
  },
  data() {
    return {
      editFilterDialogVisible: false,
      editedFilter: null,
      loading: true,
      filters: [],
      columns: ["name", "accessibility", "type", "actions"],
      options: {
        headings: {
          name: "Name",
          accessibility: "Accessibility",
          actions: "Actions",
        },
        filterable: true,
        sortable: ["name"],
        customFilters: [{
          name: "userId",
          callback: function (row, userId) {
            return row.ownerUserId === userId;
          }
        }],
      },
    };
  },
  methods: {
    getType(value) {
      switch (value) {
        case 0:
          return "Fulfilment Plan";
        case 1:
          return "Manufacturing Cycle";
        case 2:
          return "Changes";
        default:
          return "";
      }
    },
    getFilters() {
      filterService.getCrtStoredQueriesForManagement().then((response) => {
        this.filters = response.data;
        this.loading = false;
      });
    },
    openEditDialog(storedQuery) {
      this.editFilterDialogVisible = true;
      this.editedFilter = storedQuery;
    },
    editStoredQuery(storedQuery) {
      filterService.editCrtStoredQuery(storedQuery).then((response) => {
        if (response.data.result == 0) {
          this.getFilters();
          this.editFilterDialogVisible = false;
        } else {
          this.showNotification(response.data.message, "error");
        }
      });
    },
    deleteStoredQuery(storedQuery) {
      filterService.deleteCrtStoredQuery(storedQuery.id).then((response) => {
        if (response.data.result == 0) {
          this.getFilters();
        } else {
          this.showNotification(response.data.message, "error");
        }
      });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    getHrefForType(value) {
      switch (value) {
        case 0:
          return "/CRT/FulfilmentPlan/LoadFilter/";
        case 1:
          return "/CRT/ManufacturingCycle/LoadFilter/";
        case 2:
          return "/CRT/Changes?storedQueryId=";
        default:
          return "";
      }
    },
    userFilterChanged(chosenUser) {
      if (this.$refs.table) {
        Event.$emit('vue-tables.filter::userId', chosenUser);
      }
    },
  },
});
</script>

<template>
  <el-container v-loading.fullscreen.lock="loading">
    <el-page-header @back="goBack" />
    <el-header>
      <h1 class="details-header">Create Workflow</h1>
    </el-header>
    <el-main>
      <el-card class="box-card">
        <el-divider content-position="left">Name</el-divider>
        <el-input v-model="workflowModel.symbol"></el-input>
        <el-divider content-position="left">Steps</el-divider>
        <el-row>
          <draggable
            :list="workflowModel.workflowSteps"
            ghost-class="ghost"
            @start="dragging = true"
            @end="dragging = false"
          >
            <div
              v-for="item in workflowModel.workflowSteps"
              :key="item.id"
              class="panel panel-success card-element"
            >
              <div class="panel-heading workflow-step-sorting">
                <i
                  class="panel-title glyphicon glyphicon-resize-horizontal"
                ></i>
              </div>
              <div class="panel-body job-step-definition-body">
                <div class="job-step-definition-name">
                  <a class="details">{{ item.name }}</a>
                </div>
                <el-popover placement="right" width="300" trigger="click">
                  <el-select
                    style="width: 100%"
                    v-model.lazy="item.successStepId"
                    @change="handleSuccessStepChange(item)"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="option in workflowModel.workflowSteps.filter(
                        (x) => x.stepId !== item.stepId
                      )"
                      :key="option.stepId"
                      :label="option.name"
                      :value="option.stepId"
                    ></el-option>
                  </el-select>
                  <div
                    class="job-step-definition-successor success-successor"
                    slot="reference"
                  >
                    {{ item.successName }}
                  </div>
                </el-popover>
                <el-popover placement="right" width="300" trigger="click">
                  <el-select
                    style="width: 100%"
                    v-model="item.failureStepId"
                    @change="handleFailureStepChange(item)"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="option in workflowModel.workflowSteps.filter(
                        (x) => x.stepId !== item.stepId
                      )"
                      :key="option.stepId"
                      :label="option.name"
                      :value="option.stepId"
                    ></el-option>
                  </el-select>
                  <div
                    class="job-step-definition-successor fail-successor"
                    slot="reference"
                  >
                    {{ item.failureName }}
                  </div>
                </el-popover>
                <div class="job-step-definition-successor-pin">
                  <el-button
                    size="mini"
                    icon="el-icon-delete"
                    type="text"
                    @click="handleRemoveStep(item)"
                  ></el-button>
                </div>
              </div>
            </div>
          </draggable>
        </el-row>
        <el-row>
          <el-button class="crud-add-button" @click="onAddStep" plain
            >Add Steps</el-button
          >
        </el-row>
        <el-dialog
          :visible.sync="dialogVisible"
          title="Job Steps"
          :before-close="handleClose"
        >
          <el-row>
            <el-table
              ref="multipleTable"
              :data="availableJobSteps"
              style="width: 100%"
              height="400"
              stripe
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column property="name" label="Name"></el-table-column>
            </el-table>
          </el-row>
          <el-row>
            <el-button class="crud-add-button" @click="onApplyJobSteps" plain
              >Apply</el-button
            >
          </el-row>
        </el-dialog>
      </el-card>
      <el-row class="mar-t-20">
        <el-button
          class="crud-add-button"
          @click="onCreate(workflowModel)"
          plain
          >Create</el-button
        >
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
import Vue from "vue";
import draggable from "vuedraggable";

import { workflowService } from "../../services/workflow.service";
import WorkflowModel from "../../models/WorkflowModel";
import WorkflowStepModel from "../../models/WorkflowStepModel";

export default Vue.extend({
  components: {
    draggable,
  },
  mounted() {
    this.getAvailableJobSteps();
  },
  data() {
    return {
      loading: true,
      dialogVisible: false,
      workflowModel: new WorkflowModel(),
      popupSelectedJobSteps: [],
      availableJobSteps: [],
    };
  },
  methods: {
    getAvailableJobSteps() {
      workflowService.getAvailableJobSteps().then((response) => {
        this.availableJobSteps = response.data.data.map((item) => ({
          ...item,
        }));
        this.loading = false;
      });
    },
    onAddStep() {
      this.dialogVisible = true;
      let jobStepsCheckedIds = [];

      this.convertListOfWorkflowStepsToPopupJobSteps().forEach(
        (selectedJobStep) => jobStepsCheckedIds.push(selectedJobStep.id)
      );
      Vue.nextTick().then(() => {
        this.availableJobSteps.forEach((availableJobStep) => {
          if (jobStepsCheckedIds.indexOf(availableJobStep.id) !== -1) {
            this.$refs.multipleTable.toggleRowSelection(availableJobStep, true);
          }
        });
      });
    },
    onApplyJobSteps() {
      this.dialogVisible = false;
      this.popupSelectedJobSteps.forEach((selectedStep) => {
        const isExist = this.workflowModel.workflowSteps.some(
          (ws) => ws.stepId === selectedStep.id
        );
        if (!isExist) {
          this.workflowModel.workflowSteps.push(
            new WorkflowStepModel({
              stepId: selectedStep.id,
              name: selectedStep.name,
              successName: "Next success",
              failureName: "Next fail",
            })
          );
        }
      });
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this? ").then(() => {
        done();
      });
    },
    handleSelectionChange(val) {
      this.popupSelectedJobSteps = val;
    },
    handleSuccessStepChange(item) {
      if (item.successStepId) {
        item.successName = this.workflowModel.workflowSteps.filter(
          (x) => x.stepId === item.successStepId
        )[0].name;
      } else {
        item.successName = "Next success";
      }
    },
    handleFailureStepChange(item) {
      if (item.failureStepId) {
        item.failureName = this.workflowModel.workflowSteps.filter(
          (x) => x.stepId === item.failureStepId
        )[0].name;
      } else {
        item.failureName = "Next fail";
      }
    },
    handleRemoveStep(item) {
      let successSteps = this.workflowModel.workflowSteps.filter(
        (x) => x.successStepId === item.stepId
      );
      let failureSteps = this.workflowModel.workflowSteps.filter(
        (x) => x.failureStepId === item.stepId
      );
      successSteps.forEach(this.clearSuccessStep);
      failureSteps.forEach(this.clearFailureStep);

      const index = this.workflowModel.workflowSteps.indexOf(item);
      if (index > -1) {
        this.workflowModel.workflowSteps.splice(index, 1);
        this.handleSelectionChange(this.workflowModel.workflowSteps);
      }
    },
    convertListOfWorkflowStepsToPopupJobSteps() {
      return this.workflowModel.workflowSteps.map((workflowStep) => {
        return {
          id: workflowStep.stepId,
          name: workflowStep.name,
        };
      });
    },
    clearSuccessStep(item) {
      item.successName = "Next success";
      item.successStepId = null;
    },
    clearFailureStep(item) {
      item.failureName = "Next fail";
      item.failureStepId = null;
    },
    onCreate(item) {
      this.loading = true;
      workflowService.addWorkflow(item).then((response) => {
        if (response.status === 200) {
          this.loading = false;
          this.showNotification("Workflow created succesfully.", "success");
          const savedWorkflowId = response.data.result.id;
          this.$router.push({ path: "/Workflow/Details/" + savedWorkflowId });
        } else {
          this.loading = false;
          this.showNotification(response.data.result.message, "error");
        }
      });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    goBack() {
      this.$router.push("/Workflow/List");
    },
  },
});
</script>
<style lang="scss">
.card-element {
  text-align: center;
  vertical-align: middle;
  font-size: 9px;
  font-weight: 600;
  width: 120px;
  margin-right: 5px;
  display: inline-block;
}
</style>

import { JobStepStatusEnum } from "./JobStepStatusEnum";

export default class ManufacturingJobModel {
  name: string;
  currentSequenceNumber: number;
  canStart: boolean;
  canFinish: boolean;
  canUndo: boolean;
  currentStepStatus: JobStepStatusEnum;
  jobSteps: Array<CombinedOrderJobStepModel> = [];

  constructor(data: any = null) {
    if (data !== null) {
      this.name = data.name;
      this.currentSequenceNumber = data.currentSequenceNumber;
      this.currentStepStatus = data.currentStepStatus;
      this.canStart = data.canStart;
      this.canFinish = data.canFinish;
      this.canUndo = data.canUndo;
      this.jobSteps = data.jobSteps.map((item) => {
        return new CombinedOrderJobStepModel(item);
      });
    }
  }
}

class CombinedOrderJobStepModel {
  id: number;
  name: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
  }
}

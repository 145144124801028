export class SurgeonPreferenceModel {
  id: number = null;
  surgeonId: number = null;
  surgeonName = "";
  plusPreferences = "";
  peekPreferences = "";
  medporPreferences = "";
  pmmaPreferences = "";
  designSessionPreferences = "";
  representativeId: number = null;
  representativeName = "";
  additionalInformation = "";
  umcPreferences = "";

  constructor(data: any = null) {
    if (data != null) {
      this.id = data.id;
      this.surgeonId = data.surgeonId;
      this.surgeonName = data.surgeonName;
      this.plusPreferences = data.plusPreferences;
      this.peekPreferences = data.peekPreferences;
      this.medporPreferences = data.medporPreferences;
      this.pmmaPreferences = data.pmmaPreferences;
      this.designSessionPreferences = data.designSessionPreferences;
      this.representativeId = data.representativeId;
      this.representativeName = data.representativeName;
      this.additionalInformation = data.additionalInformation;
      this.umcPreferences = data.umcPreferences;
    }
  }
}

<template>
  <div>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :form-options="formOptions"
      :add-template="template"
      :add-rules="validationRules"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
      @row-remove="onDelete"
      deletable
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";

import _ from "lodash";

import { manageCalendarService } from "../../services/managecalendar.service";
import CrudScreen from "../shared/crud/CrudScreen.vue";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      columns: ["name"],
      formOptions: {
        width: "800px",
        labelWidth: "300px",
      },
      validationRules: {
        name: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    template() {
      return {
        id: {
          component: {
            show: false,
          },
        },
        name: {
          title: "Name",
        },
      };
    },
  },
  methods: {
    getRecords() {
      manageCalendarService.loadCalendars().then((data) => {
        this.records = data.data.records.map((item) => ({
          ...item,
        }));
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      manageCalendarService.addCalendar(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      manageCalendarService.editCalendar(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onDelete(item, callback) {
      this.loading = true;
      manageCalendarService.deleteCalendar(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

<template>
  <div id="report-container" v-loading.fullscreen.lock="loading">
    <h2>{{ reportModel.name }}</h2>

    <iframe
      v-if="reportModel.link"
      width="100%"
      height="800"
      :src="reportModel.link"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </div>
</template>
<script>
import Vue from "vue";

import { reportService } from "../../services/report.service";

export default Vue.extend({
  created() {
    this.getReport();
  },
  data() {
    return {
      loading: true,
      reportId: null,
      reportModel: {
        name: "Loading...",
      },
    };
  },
  methods: {
    getReport() {
      reportService.getReport(this.$route.params.id).then((data) => {
        this.reportModel = data.data;
        this.loading = false;
      });
    },
  },
  watch: {
    "$route.params.id": function () {
      this.getReport();
    },
  },
});
</script>

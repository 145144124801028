export default class UserRoleModel {
  id: number;
  name: string;
  granted: boolean;

  constructor(data: any = null) {
    if (data !== null) {
      this.id = data.id;
      this.name = data.name;
      this.granted = data.granted;
    }
  }
}

export class ProductTypeChildrenFilterModel {
  value: number;
  label: string;

  constructor(model: any) {
    if (model) {
      this.value = model.id;
      this.label = model.productCode;
    }
  }
}

<template>
    <el-dialog width="90%" :title="'Manufacturing Cycle Workflow Wizard'" :visible.sync="visible" :append-to-body="true"
        :before-close="closeForm">
        <el-form :model="item">
            <el-form-item class="button-group">
                <el-button type="primary" @click="submitForm" icon="el-icon-check">Save workflow</el-button>
                <el-button @click="closeForm">Cancel</el-button>
            </el-form-item>
            <el-form-item>
                <el-row v-for="workProcedure in item.prototypeManufacturingJobs" :key="workProcedure.id">
                    <manufacturing-cycle-wizard-work-procedure :model="workProcedure" @remove="removeWorkflow">
                    </manufacturing-cycle-wizard-work-procedure>
                </el-row>         
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import Vue from "vue";
import ManufacturingCycleWizardWorkProcedure from "./ManufacturingCycleWizardWorkProcedure.vue";

export default Vue.extend({
    components: {
        ManufacturingCycleWizardWorkProcedure,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            item: Object,
            model: Object
        };
    },
    methods: {
        closeForm() {
            this.$emit("update:visible", false);
        },
        submitForm() {
            this.$emit("submit", this.item);
        },
        removeWorkflow(workflow) {
            this.item.prototypeManufacturingJobs.splice(this.item.prototypeManufacturingJobs.indexOf(workflow), 1);
        }
    }
});
</script>

<style>
.button-group {
    display: flex;
    justify-content: flex-end;
}
</style>
<template>
  <div>
    <el-dialog
      v-if="model !== null"
      :visible="isVisible && !loading"
      :title="(isPreReserved ? 'Pre-Reserved ' : '') + 'Design Session'"
      @update:visible="updateVisible"
      width="30%"
    >
      <el-form label-width="auto" v-if="users.length > 0">
        <el-alert v-if="isPreReserved"
          title="Important! This is Pre-Reserved Session!"
          type="warning"
          :closable="false"
          show-icon>
          Unless an in specification CT scan is uploaded until 24 hours before the scheduled time the reservation will be lost.
        </el-alert>
        <el-form-item label="Organizer">
          {{
            model.organizerId === null
              ? "API"
              : model.organizer.firstName + " " + model.organizer.lastName
          }}
        </el-form-item>
        <el-form-item label="Topic">
          Design Session for
          <router-link
            :to="'/FulfilmentPlan/Details/' + model.fulfilmentPlanId"
            >{{ model.requestId }}</router-link
          >
          {{ productClassName ? " / " + productClassName : "" }}
        </el-form-item>
        <el-form-item label="Begin">
          {{ model.startDate | formatDateTime }}
        </el-form-item>
        <el-form-item label="End">
          {{ model.endDate | formatDateTime }}
        </el-form-item>
        <el-form-item label="Participants">
          {{ designerName
          }}{{ model.surgeonName ? " / " + model.surgeonName : "" }}
        </el-form-item>
        <el-form-item v-if="meetingInfo !== null" label="Meeting Status">
          {{ meetingMessage }}
          {{
            meetingInfo && meetingInfo.code !== 2
              ? ""
              : "(Cannot delete Design Session)"
          }}
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="updateVisible(false)">OK</el-button>
        <el-button
          v-if="editable"
          :disabled="meetingInfo && meetingInfo.code !== 3"
          @click="$emit('edit', model.id)"
          >Edit</el-button
        >
        <el-button
          type="danger"
          :disabled="meetingInfo && meetingInfo.code === 2"
          @click="openDeleteConfirmDialog"
          >Delete</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { designSessionService } from "../../services/design/designsession.service";
import { productTypeMappingService } from "../../services/producttypemapping.service";

import DesignSessionInfo from "../../models/design/DesignSessionInfo";
import { userService } from "../../services/users.service";
import { fulfilmentPlanService } from "../../services/fulfilmentplan.service";
import { DesignerCalendarItemType } from "../../enums/design/DesignerCalendarItemType";
import moment from "moment";

export default Vue.extend({
  filters: {
    formatDateTime: function (value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
  created() {
    userService.getUsers().then((response) => (this.users = response.data));
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DesignerCalendarItemType,
      users: [],
      productClassName: "",
      meetingInfo: null,
      model: null,
    };
  },
  computed: {
    meetingMessage() {
      if (this.meetingInfo === null) {
        return "";
      }

      switch (this.meetingInfo.code) {
        case 1:
          return "Error";
        case 2:
          return "In progress";
        case 3:
          return "Created";
        case 4:
          return "Failed";
      }

      return "";
    },
    isVisible() {
      return this.visible;
    },
    designerName() {
      const user =
        this.users && this.users.find((u) => u.id == this.model.designerId);

      return (user && `${user.firstName} ${user.lastName}`) || "";
    },
    isPreReserved() {
      return this.model.sessionType == DesignerCalendarItemType.PreReservation;
    }
  },
  methods: {
    open(id) {
      if (id === null) {
        return;
      }

      this.$emit("update:loading", true);

      designSessionService.getSession(id).then((response) => {
        this.model = new DesignSessionInfo(response.data);

        fulfilmentPlanService
          .getPlanId(this.model.requestId)
          .then((response) => (this.model.fulfilmentPlanId = response.data));

        if (response.data.organizer !== null) {
          this.model.organizer = this.users.find(
            (x) => x.id === response.data.organizerId
          );
        }

        Promise.all([
          designSessionService.getMeetingInfo(this.model.requestId),
          productTypeMappingService.getProductTypeMappingByRequestId(
            this.model.requestId
          ),
        ]).then((values) => {
          const meetingInfo = values[0].data;
          const productTypeMapping = values[1].data;

          this.meetingInfo = meetingInfo;
          this.productClassName = productTypeMapping.requestProductName;

          this.$emit("update:loading", false);
          this.$emit("update:visible", true);
        });
      });
    },
    updateVisible(isVisible) {
      this.$emit("update:visible", isVisible);
    },
    openDeleteConfirmDialog() {
      this.$confirm("Do you want to delete this Design Session?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.deleteDesignSession();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    deleteDesignSession() {
      designSessionService
        .deleteSession(this.model.id)
        .then((data) => {
          this.$message({
            type: "success",
            message: "Delete completed",
          });
          this.$emit("deleted");
          this.updateVisible(false);
          return;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            message: "Something went wrong. Try again later",
          });
          return;
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px;
}
</style>

<template>
  <div>
    <el-dialog
      title="Create External Plan"
      :visible.sync="visible"
      :before-close="cancelPlanCreate"
      append-to-body
      v-loading.fullscreen.lock="loading"
    >
      <el-form
        label-width="180px"
        :model="model"
        ref="planDialogForm"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Plan Id" prop="requestId">
              <el-input
                style="width: 100%"
                v-model="model.requestId"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Distribution Center"
              prop="distributionCenterId"
            >
              <el-select
                style="width: 100%"
                v-model="model.distributionCenterId"
              >
                <el-option
                  v-for="distributionCenter in distributionCenters"
                  :label="distributionCenter.requestDistributionCentre"
                  :key="distributionCenter.id"
                  :value="distributionCenter.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Product type" prop="productTypeId">
              <el-select
                style="width: 100%"
                v-model="model.productTypeId"
                @change="productTypeChange"
              >
                <el-option
                  v-for="productType in productTypes"
                  :label="productType.name"
                  :key="productType.id"
                  :value="productType.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Product" prop="productId">
              <el-select style="width: 100%" v-model="model.productId">
                <el-option
                  v-for="product in availableProducts"
                  :label="product.number"
                  :key="product.id"
                  :value="product.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Surgery date" prop="surgeryDate">
              <el-date-picker
                style="width: 100%"
                v-model="model.surgeryDate"
                type="date"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Patient name" prop="patientName">
              <el-input
                style="width: 100%"
                v-model="model.patientName"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="Additional emails" prop="emailAddresses">
              <el-select
                style="width: 100%"
                v-model="model.emailAddresses"
                allow-create
                filterable
                multiple
                remote
                default-first-option
              ></el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelPlanCreate">Cancel</el-button>
        <el-button @click="createExternalPlan">OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";

import { locationMappingService } from "../../services/locationmapping.service";
import { productTypeService } from "../../services/producttype.service";
import { productService } from "../../services/product.service";
import { fulfilmentPlanService } from "../../services/fulfilmentplan.service";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.loadInitialData();
  },
  data() {
    return {
      loading: false,
      productTypes: [],
      products: [],
      distributionCenters: [],
      availableDesigners: [],
      model: {
        requestId: null,
        distributionCenterId: null,
        productTypeId: null,
        productId: null,
        emailAddresses: [],
        patientName: "",
        surgeryDate: null,
      },
      rules: {
        requestId: [{ required: true, message: "Request Id is required" }],
        productTypeId: [
          { required: true, message: "Product Type is required" },
        ],
        productId: [{ required: true, message: "Product is required" }],
        distributionCenterId: [
          { required: true, message: "Distribution Center is required" },
        ],
        emailAddresses: [
          {
            type: "array",
            defaultField: { type: "email", message: "Invalid email provided" },
          },
        ],
        patientName: [{ required: true, message: "Patient name is required" }],
      },
    };
  },
  methods: {
    async loadInitialData() {
      const productTypesRequest = productTypeService.loadProductTypes();
      const productsRequest = productService.loadProducts();
      const distributionCenterRequest =
        locationMappingService.loadLocationMappings();

      const productTypesResponse = await productTypesRequest;
      const productsResponse = await productsRequest;
      const distributionCenteResponse = await distributionCenterRequest;

      this.productTypes = productTypesResponse.data.records;
      this.products = productsResponse.data.records;
      this.distributionCenters = distributionCenteResponse.data.records;
    },
    productTypeChange() {
      this.model.productId = null;
    },
    cancelPlanCreate() {
      this.model = {
        requestId: null,
        distributionCenterId: null,
        productTypeId: null,
        productId: null,
        emailAddresses: [],
        patientName: "",
        surgeryDate: null,
      };
      this.$emit("update:visible", false);
    },
    async createExternalPlan() {
      this.$refs.planDialogForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const response = await fulfilmentPlanService.createExternalPlan(
            this.model
          );
          this.loading = false;

          const data = response.data;
          if (data.success) {
            this.$message({
              message: "External Plan Created.",
              type: "success",
            });

            this.$emit("created", this.model.requestId);
            this.$emit("update:visible", false);
          } else {
            this.$message({
              message: data.message.join(" "),
              type: "error",
            });
          }
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    availableProducts() {
      if (this.model.productTypeId) {
        return this.products.filter(
          (p) => p.productTypeId === this.model.productTypeId
        );
      }
      return [];
    },
  },
});
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 0px;
}
</style>

<template>
  <div>
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="8">
        <label class="field-label">Plan Id</label>
        <el-select
          placeholder="None selected"
          suffix-icon="el-icon-tickets"
          v-model="chosenRequestId"
          filterable
          remote
          reserve-keyword
          :remote-method="getAvailableRequestIds"
          :loading="false"
          style="width: 100%"
          :clearable="true"
        >
          <el-option
            v-for="item in availableRequestIds"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <label class="field-label">Product Types</label>
        <el-cascader
          v-model="chosenProducts"
          :options="filters.productTypes"
          :props="{ multiple: true }"
          placeholder="None selected"
          collapse-tags
          filterable
          clearable
          style="width: 100%"
        ></el-cascader>
      </el-col>
      <el-col :span="8">
        <change-type-filter
          :change-types="filters.changeTypes"
          :chosen-filters="chosenFilters.changeTypeFilter"
          @change="emitFilterChange"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="8">
        <changes-date-filter
          :chosen-filters.sync="chosenFilters.dateFilter"
          @changed="dateFilterChanged"
        />
      </el-col>
      <el-col :span="8">
        <label class="field-label">Distribution Center</label>
        <el-select
          v-model="chosenDistributionCenters"
          multiple
          filterable
          style="width: 100%"
          placeholder="None selected"
        >
          <el-option
            v-for="item in filters.distributionCenters"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <label class="field-label">User</label>
        <el-select
          placeholder="None selected"
          suffix-icon="el-icon-tickets"
          v-model="chosenUser"
          :filterable="true"
          remote
          reserve-keyword
          :remote-method="getAvailableUsers"
          :loading="false"
          :clearable="true"
          style="width: 100%"
        >
          <el-option
            v-for="item in availableUsers"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
      <el-button type="primary" size="mini" @click="clearFilters"
        >Clear filters</el-button
      >
      <el-button type="primary" size="mini" @click="openFiltersModal"
        >Apply filters</el-button
      >
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-upload"
        @click="filterDialogVisible = true"
        >Save filter</el-button
      >
    </el-row>

    <el-dialog
      title="Apply filters"
      :visible.sync="filtersModalVisible"
      :before-close="handleCloseFiltersModal"
      width="30%"
    >
      <span>
        <el-select
          v-model="selectedFilter"
          placeholder="Select filter..."
          style="width: 100%"
        >
          <el-option
            v-for="item in savedFilters"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
            <span
              v-if="item.sharingLevel === 0"
              style="float: right; color: #8492a6; font-size: 13px"
              class="glyphicon glyphicon-user"
            ></span>
            <span
              v-if="item.sharingLevel === 1"
              style="float: right; color: #8492a6; font-size: 13px"
              class="glyphicon glyphicon-globe"
            ></span>
          </el-option>
        </el-select>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" type="primary" @click="handleCloseFiltersModal"
          >Cancel</el-button
        >
        <el-button
          size="medium"
          type="primary"
          :disabled="selectedFilter === null"
          @click="applyFilters"
          >Apply</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Save filter"
      :visible.sync="filterDialogVisible"
      width="30%"
    >
      <label class="field-label">Filter name</label>
      <el-input placeholder v-model="filterName"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button v-on:click="closeFilterDialog" size="medium"
          >Cancel</el-button
        >
        <el-button type="primary" @click="saveFilter" size="medium"
          >Save</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { mapState, mapMutations } from "vuex";
import { OperationResultType } from "../../enums/enums";

import { changesService } from "../../services/changes.service";

import ChangesDateFilter from "./ChangesDateFilter.vue";
import ChangeTypeFilter from "./ChangeTypeFilter.vue";

import { ChangesChosenFiltersModel } from "../../models/ChangesChosenFiltersModel";
import { ChangesFiltersModel } from "../../models/ChangesFiltersModel";

export default Vue.extend({
  components: {
    ChangesDateFilter,
    ChangeTypeFilter,
  },
  created() {
    this.loadFilters();
  },
  data() {
    return {
      OperationResultType,
      filters: new ChangesFiltersModel(null),
      availableRequestIds: [],
      availableUsers: [],
      filtersModalVisible: false,
      filterDialogVisible: false,
      savedFilters: [],
      selectedFilter: null,
      filterName: "",
    };
  },
  computed: {
    ...mapState("changes", ["chosenFilters"]),
    chosenRequestId: {
      get() {
        return this.chosenFilters.requestId;
      },
      set(newValue) {
        this.chooseRequestId(newValue);
        this.emitFilterChange();
      },
    },
    chosenCascaderProducts() {
      return this.chosenFilters.chosenProducts.map((item) => [
        item.split("-")[0],
        item,
      ]);
    },
    chosenProducts: {
      get() {
        return this.chosenCascaderProducts;
      },
      set(newValue) {
        if (!newValue) {
          this.chooseProducts(null);
        } else {
          this.chooseProducts(
            newValue.map((item) => {
              return item[1];
            })
          );
        }
        this.emitFilterChange();
      },
    },
    chosenDistributionCenters: {
      get() {
        return this.chosenFilters.chosenDistributionCenters;
      },
      set(newValue) {
        this.chooseDistributionCenters(newValue);
        this.emitFilterChange();
      },
    },
    chosenUser: {
      get() {
        return this.chosenFilters.userId;
      },
      set(newValue) {
        this.chooseUserId(newValue);
        this.emitFilterChange();
      },
    },
  },
  watch: {
    "$route.query.storedQueryId": {
      immediate: true,
      handler(storedQueryId) {
        this.loadFilter(storedQueryId);
      },
    },
  },
  methods: {
    ...mapMutations("changes", [
      "clearChosenFilters",
      "setChosenFilters",
      "chooseRequestId",
      "chooseProducts",
      "chooseChangeType",
      "chooseDistributionCenters",
      "chooseUserId",
    ]),
    loadFilters() {
      changesService.loadFilters().then((response) => {
        this.filters = new ChangesFiltersModel(response.data);
      });
    },
    clearFilters() {
      this.clearChosenFilters();
      this.$emit("change");
    },
    saveFilter() {
      changesService
        .saveChangesFilter({
          ...this.chosenFilters,
          name: this.filterName,
        })
        .then((response) => {
          this.filterDialogVisible = false;
          if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
          } else {
            this.showNotification("Filters saved succesfully.", "success");
          }
        });
    },
    loadFilter(storedQueryId) {
      if (storedQueryId) {
        changesService.loadState(storedQueryId).then((response) => {
          this.setChosenFilters(new ChangesChosenFiltersModel(response.data));
          this.$emit("change");
        });
      } else {
        this.clearFilters();
      }
    },
    closeFilterDialog: function () {
      this.filterDialogVisible = false;
      this.filterName = null;
    },
    applyFilters() {
      this.loadFilter(this.selectedFilter);
      this.filtersModalVisible = false;
    },
    openFiltersModal() {
      this.selectedFilter = null;
      this.filtersModalVisible = true;
      changesService.getFiltersList().then((response) => {
        this.savedFilters = response.data.content;
      });
    },
    handleCloseFiltersModal() {
      this.filtersModalVisible = false;
    },
    getAvailableRequestIds(enteredValue) {
      changesService.getFilteredRequestIds(enteredValue).then((response) => {
        this.availableRequestIds = response.data;
      });
    },
    getAvailableUsers(enteredValue) {
      changesService.getFilteredUsers(enteredValue).then((response) => {
        this.availableUsers = response.data;
      });
    },
    dateFilterChanged(newValue) {
      this.chosenFilters.dateFilter = newValue;
      this.emitFilterChange();
    },
    emitFilterChange: _.debounce(function () {
      this.$emit("change");
    }, 500),
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

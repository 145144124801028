<template>
  <div class="grid-content">
    <div v-if="showTimeline">
      <el-card style="width: 100%">
        <div slot="header">
          <h4 style="padding-left: 20px; display: inline-block">Timeline</h4>
        </div>
        <div
          class="milestones-timeline-container"
          v-loading="loadingTimeline"
          style="margin-left: 15px"
        >
          <table class="table milestones-timeline-table">
            <thead>
              <tr>
                <td
                  v-for="item in model.timeline"
                  :key="item.date"
                  style="min-width: 80px"
                >
                  <span>{{ item.date }}</span>
                </td>
                <td class="milestone-kind"></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="item in model.timeline"
                  :key="item.date"
                  v-bind:class="{
                    'timeline-weekend': item.isWeekend || item.isHoliday,
                  }"
                >
                  <div v-for="text in item.plannedMilestones" :key="text">
                    {{ text }}
                  </div>
                </td>
                <td class="milestone-kind">
                  <label>Due</label>
                </td>
              </tr>
              <tr>
                <td
                  v-for="item in model.timeline"
                  :key="item.text"
                  v-bind:class="{
                    'timeline-weekend': item.isWeekend || item.isHoliday,
                  }"
                >
                  <div v-for="text in item.actualMilestones" :key="text">
                    {{ text }}
                  </div>
                </td>
                <td class="milestone-kind">
                  <label>Actual</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";
import TimelineModel from "@/models/fulfilment-plan/TimelineModel";

export default {
  data() {
    return {
      model: new TimelineModel(null),
      loadingTimeline: false,
    };
  },
  computed: {
    ...mapState("fulfilmentPlanDetails", [
      "planDataRefresh",
      "showTimeline",
      "timelineRefresh",
    ]),
  },
  watch: {
    timelineRefresh: function () {
      if (this.timelineRefresh) {
        this.getTimeline(this.$route.params.id);
        this.setTimelineRefresh(false);
      }
    },
  },
  methods: {
    ...mapActions("fulfilmentPlanDetails", ["setTimelineRefresh"]),
    getTimeline(id) {
      this.loadingTimeline = true;
      fulfilmentPlanDetailsService.getTimeline(id).then((response) => {
        const data = response.data;
        this.model = new TimelineModel(data.model);
        this.loadingTimeline = false;
      });
    },
  },
};
</script>

import { DesignHttpClient } from "../../shared/DesignHttpClient";
import moment from "moment";

class GeneralSessionPropertiesService {
  getGeneralSessionProperties() {
    return DesignHttpClient.get("GeneralSessionProperties");
  }

  getDefaultGeneralSessionProperties(planId: string) {
    return DesignHttpClient.get(`GeneralSessionProperties/${planId}`);
  }

  addGeneralSessionProperties(
    productTypeId: number,
    priority: boolean,
    preparationDuration: moment.Duration,
    sessionDuration: moment.Duration,
    postProcessingDuration: moment.Duration,
    sessionLimit: number
  ) {
    return DesignHttpClient.post("GeneralSessionProperties", {
      productTypeId: productTypeId,
      priority: priority,
      designSessionDuration: sessionDuration.format("HH:mm:ss", {
        trim: false,
      }),
      postProcessingDuration: postProcessingDuration.format("D.HH:mm:ss", {
        trim: false,
      }),
      preparationDuration: preparationDuration.format("D.HH:mm:ss", {
        trim: false,
      }),
      sessionLimit: sessionLimit,
    });
  }

  updateGeneralSessionProperties(
    id: number,
    preparationDuration: any,
    sessionDuration: any,
    postProcessingDuration: any,
    sessionLimit: number
  ) {
    return DesignHttpClient.put(`GeneralSessionProperties/${id}`, {
      designSessionDuration: sessionDuration.format("HH:mm:ss", {
        trim: false,
      }),
      postProcessingDuration: postProcessingDuration.format("D.HH:mm:ss", {
        trim: false,
      }),
      preparationDuration: preparationDuration.format("D.HH:mm:ss", {
        trim: false,
      }),
      sessionLimit: sessionLimit,
    });
  }

  deleteGeneralSessionProperties(id: number) {
    return DesignHttpClient.delete(`GeneralSessionProperties/${id}`);
  }
}

export const generalSessionPropertiesService =
  new GeneralSessionPropertiesService();

import ProductTypeFilterModel from "../ProductTypeFilterModel";
import SimpleSelectControlItemModel from "../SimpleSelectControlItemModel";
import _ from "lodash";
import moment from "moment";

export default class FiltersModel {
  availableProductTypes: ProductTypeFilterModel[] = [];
  availableRequestTypes: SimpleSelectControlItemModel[] = [];
  availableManufacturingLocations: SimpleSelectControlItemModel[] = [];
  availableDesignStatuses: SimpleSelectControlItemModel[] = [];
  availableProcessingStatuses: SimpleSelectControlItemModel[] = [];
  availableDates: SimpleSelectControlItemModel[] = [];
  availableDateTypes: SimpleSelectControlItemModel[] = [];
  availableDistributionCenters: SimpleSelectControlItemModel[] = [];

  constructor(data) {
    if (data !== null) {
      this.availableProductTypes = data.availableProducts.map(
        (x) => new ProductTypeFilterModel(x)
      );
      this.availableDesignStatuses = data.availableDesignStatuses.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.text,
            value: Number(x.value),
          })
      );
      this.availableRequestTypes = data.availableRequestTypes.map(
        (x) => new SimpleSelectControlItemModel(x)
      );
      this.availableManufacturingLocations =
        data.availableManufacturingLocations.map(
          (x) => new SimpleSelectControlItemModel(x)
        );
      this.availableProcessingStatuses = data.availableProcessingStatuses.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.text,
            value: Number(x.value),
          })
      );

      const filterDates = data.availableDates.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.text,
            value: Number(x.value),
          })
      );
      const milestoneDates = data.availableMilestones.map(
        (x) => new SimpleSelectControlItemModel({ label: x.name, value: x.id })
      );
      this.availableDates = filterDates.concat(milestoneDates);
      this.availableDateTypes = data.availableDateTypes.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.text,
            value: Number(x.value),
          })
      );
      this.availableDistributionCenters = data.availableDistributionCenters.map(
        (x) =>
          new SimpleSelectControlItemModel({
            label: x.text,
            value: Number(x.value),
          })
      );
    }
  }
}

<template>
  <div>
    <el-collapse-item name="milestoneAlertsSettings">
      <template slot="title">
        <div class="el-collapse_header-container">
          <h4 class="el-collapse-header-bold">Milestone Alerts</h4>
          <el-button
            size="mini"
            class="mar-r-5"
            @click.stop="onAddMilestoneAlert"
            plain
            >Add</el-button
          >
        </div>
      </template>
      <div
        class="el-container-item"
        v-for="item in milestoneAlertsSettings"
        :key="item.id"
      >
        <span>{{ item.alertDescription }}</span>
        <div class="nowrap mar-b-15-l-5">
          <el-button
            size="mini"
            icon="el-icon-edit"
            @click="onEditMilestoneAlert(item)"
          />
          <el-button
            size="mini"
            icon="el-icon-delete"
            type="danger"
            @click="onDeleteMilestoneAlert(item)"
          />
        </div>
      </div>
    </el-collapse-item>
    <milestone-alert-popup
      :visible.sync="showMilestoneAlertPopup"
      ref="milestoneAlertPopup"
      :editMode="isEdit"
      @submit="addEditMilestoneAlertNotificationSetting"
      :definitions="milestoneAlertsNotificationsDialog"
    ></milestone-alert-popup>
  </div>
</template>
<script>
import Vue from "vue";

import MilestoneAlertPopup from "./MilestoneAlertPopup.vue";
import { notificationSettingsService } from "../../../services/notificationsettings.service";
import { OperationResultType } from "../../../enums/enums";

export default Vue.extend({
  components: {
    MilestoneAlertPopup,
  },
  props: {
    milestoneAlertsSettings: {
      type: Array,
      default() {
        return [];
      },
    },
    milestoneAlertsNotificationsDialog: {
      type: Object,
      default() {
        return [];
      },
    },
    notificationsSettingsId: {
      type: Number,
      default: 0,
    },
    refreshPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMilestoneAlertPopup: false,
      isEdit: false,
    };
  },
  methods: {
    onAddMilestoneAlert() {
      this.isEdit = false;
      this.showMilestoneAlertPopup = true;
    },
    onEditMilestoneAlert(item) {
      this.isEdit = true;
      this.$refs.milestoneAlertPopup.item = {
        id: item.id,
        selectedMilestoneId: item.milestoneId,
        threshold: item.threshold < 0 ? -item.threshold : item.threshold,
        selectedDirection: item.threshold < 0 ? "0" : "1",
        selectedProductTypeId: item.productTypeId,
        selectedManufacturingLocationId: item.manufacturingLocationId,
      };
      this.showMilestoneAlertPopup = true;
    },
    onDeleteMilestoneAlert(item) {
      this.$confirm(
        "Do you want to remove " + item.alertDescription + "?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.deleteMilestoneAlertNotificationSetting(item.id);
      });
    },
    addEditMilestoneAlertNotificationSetting: function (item) {
      if (this.isEdit) {
        this.editMilestoneAlertNotificationSetting(item);
      } else {
        this.addMilestoneAlertNotificationSetting(item);
      }
    },
    addMilestoneAlertNotificationSetting: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService
        .addMilestoneAlertNotificationSetting(item)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Milestone Alert added succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    editMilestoneAlertNotificationSetting: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService
        .editMilestoneAlertNotificationSetting(item)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Milestone Alert changed succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    deleteMilestoneAlertNotificationSetting: function (id) {
      notificationSettingsService
        .deleteMilestoneAlertNotificationSetting(id)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Milestone Alert deleted succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

import { AvailabilityType } from "../../enums/design/AvailabilityType";
import { DesignHttpClient } from "../../shared/DesignHttpClient";
import Absence from "../../models/design/Absence";
import SingleAvailability from "../../models/design/SingleAvailability";
import CalendarAvailability from "../../models/design/CalendarAvailability";
import DesignerDetails from "../../models/design/DesignerDetails";
import moment from "moment";
import "moment-timezone";

class DesignerAvailabilityService {
  findAvailableDesigners(
    requestId: string,
    startDate: any,
    preparationDuration: any,
    sessionDuration: any,
    postprocessingDuration: any
  ) {
    return DesignHttpClient.get(
      `availabilities?requestId=${requestId}&startDate=${startDate
        .utc()
        .format()}&preparationDuration=${preparationDuration.format(
          "D.HH:mm:ss", {
          trim: false,
        }
        )}&sessionDuration=${sessionDuration.format(
          "HH:mm:ss", {
          trim: false,
        }
        )}&postprocessingDuration=${postprocessingDuration.format("D.HH:mm:ss", {
          trim: false,
        })}`
    );
  }

  getAvailability(id: number) {
    return DesignHttpClient.get("availabilities/" + id);
  }

  getSavedSchedules(designerId: number) {
    return DesignHttpClient.get(`designers/${designerId}/savedSchedules`);
  }

  deleteSavedSchedule(designerId: number, savedScheduleId: number) {
    return DesignHttpClient.post(
      `designers/${designerId}/savedSchedules/${savedScheduleId}`
    );
  }

  addAbsence(model: Absence, designerId: number) {
    const postModel = {
      startDate: model.startDate.format(),
      endDate: model.endDate.format(),
    };

    return DesignHttpClient.post(
      `designers/${designerId}/availabilities/absence`,
      postModel
    );
  }

  addSingleAvailability(
    designerDetails: DesignerDetails,
    model: SingleAvailability
  ) {
    const startDateZoned = moment.tz(
      model.startDate.local().format(),
      "YYYY-MM-DD",
      designerDetails.timezone
    );
    const endDateZoned = startDateZoned.clone();

    const start = moment(model.startTime, "HH:mm");
    const end = moment(model.endTime, "HH:mm");

    startDateZoned.set({
      hour: start.get("hour"),
      minute: start.get("minute"),
    });

    endDateZoned.set({
      hour: end.get("hour"),
      minute: end.get("minute"),
    });

    const postModel = {
      designerId: designerDetails.id,
      startDate: startDateZoned.format(),
      endDate: endDateZoned.format(),
    };

    return DesignHttpClient.post(
      `designers/${designerDetails.id}/availabilities/single`,
      postModel
    );
  }

  addCalendarAvailability(
    designerDetails: DesignerDetails,
    model: CalendarAvailability
  ) {
    const startDateZoned = moment
      .tz(
        model.startDate.local().format(),
        "YYYY-MM-DD",
        designerDetails.timezone
      )
      .format();
    const endDateZoned = moment
      .tz(
        model.endDate.local().format(),
        "YYYY-MM-DD",
        designerDetails.timezone
      )
      .format();

    const postModel = {
      startDate: startDateZoned,
      endDate: endDateZoned,
      days: model.days,
      saveSchedule: model.saveSchedule,
      scheduleName: model.scheduleName,
    };

    return DesignHttpClient.post(
      `designers/${designerDetails.id}/availabilities/calendar`,
      postModel
    );
  }

  updateAbsence(model: Absence) {
    return DesignHttpClient.put(`availabilities/absence/${model.id}`, {
      startDate: model.startDate.format(),
      endDate: model.endDate.format(),
    });
  }

  updateSingleAvailability(
    designerDetails: DesignerDetails,
    model: SingleAvailability
  ) {
    const startDateZoned = moment.tz(
      model.startDate.local().format(),
      "YYYY-MM-DD",
      designerDetails.timezone
    );
    const endDateZoned = startDateZoned.clone();

    const start = moment(model.startTime, "HH:mm");
    const end = moment(model.endTime, "HH:mm");

    startDateZoned.set({
      hour: start.get("hour"),
      minute: start.get("minute"),
    });

    endDateZoned.set({
      hour: end.get("hour"),
      minute: end.get("minute"),
    });

    return DesignHttpClient.put(`availabilities/single/${model.id}`, {
      startDate: startDateZoned,
      endDate: endDateZoned,
    });
  }

  updateCalendarAvailability(
    designerDetails: DesignerDetails,
    model: CalendarAvailability
  ) {
    const startDateZoned = moment
      .tz(
        model.startDate.local().format(),
        "YYYY-MM-DD",
        designerDetails.timezone
      )
      .format();
    const endDateZoned = moment
      .tz(
        model.endDate.local().format(),
        "YYYY-MM-DD",
        designerDetails.timezone
      )
      .format();

    return DesignHttpClient.put(`availabilities/calendar/${model.id}`, {
      ...model,
      startDate: startDateZoned,
      endDate: endDateZoned,
    });
  }

  deleteAvailability(id: number) {
    return DesignHttpClient.delete(`availabilities/${id}`);
  }

  deleteAbsence(id: number) {
    return this.deleteAvailability(id);
  }
}

export const designerAvailabilityService = new DesignerAvailabilityService();

<template>
  <div>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :add-template="template"
      :add-rules="validationRules"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
      @row-remove="onRemove"
      deletable
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";

import { reportService } from "../../services/report.service";

import CrudScreen from "../shared/crud/CrudScreen.vue";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      columns: ["name", "link"],
      template: {
        id: {
          component: {
            show: false,
          },
        },
        name: {
          title: "Name",
          value: "",
          component: {
            name: "el-input",
          },
        },
        link: {
          title: "Link",
          value: "",
          component: {
            name: "el-input",
          },
        },
      },
      validationRules: {
        name: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
        link: [
          { required: true, message: "Link is required", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getRecords() {
      reportService.loadReports().then((data) => {
        this.records = data.data;
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      reportService.addReport(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      reportService.editReport(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onRemove(item, callback) {
      this.loading = true;
      reportService.removeReport(item.id).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

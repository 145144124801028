import Availability from "./Availability";
import { AvailabilityType } from "../../enums/design/AvailabilityType";
import moment from "moment";

export default class Absence extends Availability {
  id: number = null;
  startDate: moment.Moment = moment();
  endDate: moment.Moment = moment();

  constructor(model: any = null) {
    super(AvailabilityType.Absence);

    if (model != null) {
      this.id = model.id;

      this.startDate = moment(model.startDate);
      this.endDate = moment(model.endDate);
    }
  }
}

import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";
import qs from "qs";

class ProductExportService {
  getProductExportItem(productId) {
    return DocumentationHttpClient.get(`/ProductExport/${productId}`);
  }

  getProductExportItems(products) {
    return DocumentationHttpClient.get("/ProductExport/List", {
      params: { products },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }

  getFilteredProductGtins(query) {
    return DocumentationHttpClient.get(`/ProductExport/ProductGtins`, {
      params: { query },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getFilteredDocumentNumbers(query) {
    return DocumentationHttpClient.get(`/ProductExport/DocumentNumbers`, {
      params: { query },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  exportProducts(formModel) {
    const chosenIds = formModel.products.map((fileNode) => fileNode.id);
    const exportModel = { chosenIds, ...formModel };

    return DocumentationHttpClient.post("/ProductExport", exportModel);
  }
  exportProductsAsZip(formModel) {
    const chosenIds = formModel.products.map((fileNode) => fileNode.id);
    const exportModel = { chosenIds, ...formModel };

    return DocumentationHttpClient.post("/ProductExport/ZipExport", exportModel, { responseType: 'blob' });
  }
  getAssociatedProducts(documentIds) {
    return DocumentationHttpClient.get("/ProductExport/AssociatedProducts", {
      params: { documentIds },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  extractGtins(file) {
    const formData = new FormData();
    formData.append("file", file);

    return DocumentationHttpClient.post(
      "/ProductExport/ExtractGtins",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}

export const productExportService = new ProductExportService();

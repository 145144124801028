<template>
  <el-dialog
    width="25%"
    title="Add combined order"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="handleClose"
  >
    <el-form :model="item" :rules="rules" ref="commentForm" label-width="150px">
      <el-form-item label="Definition" prop="combinedOrderDefinitionId">
        <el-input v-model="combinedOrderDefinition.name" disabled></el-input>
      </el-form-item>
      <el-form-item :label="identifierName" prop="identifier">
        <el-input v-model="item.identifier"></el-input>
      </el-form-item>
      <el-form-item :label="orderNumberLabel" prop="orderNumber">
        <el-input v-model="item.orderNumber"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Add</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import { CombinedOrderKeyType } from "./CombinedOrderKeyType";
import CombinedOrderDefinitionModel from "../../models/CombinedOrderDefinitionModel";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    identifierName: {
      type: String,
      default: "Identifier",
    },
    combinedOrderDefinition: {
      type: Object,
      default: () => new CombinedOrderDefinitionModel(),
    },
  },
  data() {
    return {
      combinedOrderKeyType: CombinedOrderKeyType,
      rules: {
        identifier: [{ required: true, message: "Identifier is required" }],
      },
      item: {
        type: Object,
        default: {
          identifier: null,
          orderNumber: null,
          combinedOrderDefinitionId: this.combinedOrderDefinition.id,
        },
      },
    };
  },
  mounted() {
    this.item.combinedOrderDefinitionId = this.combinedOrderDefinition.id;
  },
  watch: {
    visible: function (oldValue, newValue) {
      if (newValue === true) {
        this.item.comment = "";
      }
      this.item.combinedOrderDefinitionId = this.combinedOrderDefinition.id;
    },
  },
  methods: {
    submitForm() {
      this.$refs.commentForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.item);
        }
        return false;
      });
    },
    closeForm() {
      this.$refs.commentForm.resetFields();
      this.$emit("update:visible", false);
      this.item = {
        identifier: null,
        orderNumber: null,
      };
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
  },
  computed: {
    orderNumberLabel() {
      if (
        this.combinedOrderDefinition.keyType ===
        this.combinedOrderKeyType.SterileLotNumber
      ) {
        return "Tracking Number";
      }
      return "Order Number";
    },
  },
});
</script>

import { storeProductImportId } from "./mutationTypes";

const getters = {};

const state = {
  productImportId: null,
};

const actions = {};

const mutations = {
  [storeProductImportId](state, value) {
    state.productImportId = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { DesignerCalendarItemType } from "@/enums/design/DesignerCalendarItemType";
import moment from "moment";
export default class DesignSessionInfo {
  id: number = null;
  requestId: string = null;
  designerId: number = null;
  fulfilmentPlanId: number = null;
  organizerId: number = null;
  surgeonName: string = null;
  startDate: string = moment(new Date()).format();
  endDate: string;
  canBeDeleted = true;
  sessionType: DesignerCalendarItemType;

  constructor(model: any = null) {
    if (model != null) {
      this.id = model.id;
      this.organizerId = model.organizerId;
      this.surgeonName = model.surgeonName;
      this.requestId = model.requestId;
      this.designerId = model.designerId;
      this.startDate = moment(model.startDate).format();
      this.endDate = moment(model.endDate).format();
      this.canBeDeleted = model.canBeDeleted;
      this.sessionType = model.sessionType;
    }
  }
}

<template>
  <div>
    <v-client-table
      v-model="currentRoles"
      :columns="columns"
      :options="options"
    >
      <el-checkbox
        slot="granted"
        slot-scope="{ row, update }"
        v-model="row.granted"
        @change="update"
      ></el-checkbox>
    </v-client-table>
    <el-button type="primary" @click="$emit('save')">Save</el-button>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: ["name", "granted"],
      options: {
        editableColumns: ["granted"],
        sortable: ["name"],
        orderBy: {
          column: "name",
          ascending: true,
        },
      },
    };
  },
  computed: {
    currentRoles: {
      get: function () {
        return this.roles;
      },
      set: function (newValue) {
        this.$emit("update:roles", newValue);
      },
    },
  },
});
</script>

import { HTTP } from "./HttpCommon";
import { SurgeonPreferenceListFilter } from "../models/SurgeonPreferenceListFilter";
import { SurgeonPreferenceModel } from "../models/SurgeonPreferenceModel";

export class SurgeonPreferencesService {
  basePath = "SurgeonPreferences/";

  addSurgeonPreferences(model: SurgeonPreferenceModel) {
    return HTTP.post(`${this.basePath}Add`, model);
  }

  editSurgeonPreferences(model: SurgeonPreferenceModel) {
    return HTTP.post(`${this.basePath}Update`, model);
  }

  deleteSurgeonPreferences(id: number) {
    return HTTP.post(`${this.basePath}Delete/${id}`);
  }

  getSurgeonPreferences(filter: SurgeonPreferenceListFilter) {
    return HTTP.get(
      `${this.basePath}SurgeonsList?surgeonId=${filter.surgeonId}&representativeId=${filter.representativeId}`
    );
  }

  getData() {
    return HTTP.get(`${this.basePath}GetData`);
  }

  export(filter: SurgeonPreferenceListFilter) {
    const queryString = Object.keys(filter)
      .map((key) => key + "=" + filter[key])
      .join("&");
    return HTTP.get("SurgeonPreferences/Export?" + queryString, {
      responseType: "blob",
    });
  }
}

export const surgeonPreferencesService = new SurgeonPreferencesService();

<template>
  <div>
    <el-dialog
      :visible="visible"
      :title="title"
      @open="dialogOpened"
      width="40%"
      v-loading.fullscreen.lock="loading"
      :before-close="cancel"
    >
      <el-form
        label-width="180px"
        :model="model"
        ref="designSessionModalForm"
        :rules="validationRules"
      >
        <el-form-item label="Plan Id" prop="requestId">
          <el-col :span="11">
            <el-select
              v-model="model.requestId"
              style="width: 100%"
              filterable
              remote
              reserve-keyword
              placeholder="Search for Plan Id"
              :remote-method="filterRequestIds"
              :disabled="model.id != null"
              :automatic-dropdown="true"
              @change="setRequestId"
              :loading="model.loadingRequestIds"
            >
              <el-option
                v-for="item in availableRequestIds"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-button @click="planDialogVisible = true" class="fright"
              >Create External Plan</el-button
            >
          </el-col>
        </el-form-item>
        <el-form-item label="Start date" prop="startDate">
          <el-col :span="11">
            <el-date-picker
              v-model="date"
              style="width: 100%"
              type="datetime"
              format="dd MMMM yyyy HH:mm"
              placeholder="Select date and time"
              @change="findDesigners"
            ></el-date-picker>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-select v-model="timezone" filterable style="width: 100%">
              <el-option
                v-for="timezone in timezones"
                :key="timezone.ianaName"
                :label="timezone.name"
                :value="timezone.ianaName"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="Preparation time">
          <el-col :span="11">
            <el-select
              v-model="preparationDays"
              :disabled="model.requestId == null"
              placeholder="Select"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in availableDays"
                :key="index"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-select
              v-model="preparationHours"
              :disabled="model.requestId == null"
              placeholder="Select"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in availableHours"
                :key="index"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="Design Session time">
          <el-col :span="11">
            <el-select
              v-model="sessionHours"
              :disabled="model.requestId == null"
              placeholder="Select"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in availableHours"
                :key="index"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-select
              v-model="sessionMinutes"
              :disabled="model.requestId == null"
              placeholder="Select"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in availableMinutes"
                :key="index"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="Post-processing time">
          <el-col :span="11">
            <el-select
              v-model="postProcessingDays"
              :disabled="model.requestId == null"
              placeholder="Select"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in availableDays"
                :key="index"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-select
              v-model="postProcessingHours"
              :disabled="model.requestId == null"
              placeholder="Select"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in availableHours"
                :key="index"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item
          v-if="showDesignerSelection"
          label="Designer"
          prop="designerId"
        >
          <el-select
            v-model="model.designerId"
            filterable
            style="width: 100%"
            reserve-keyword
            no-data-text="No designers available"
            :disabled="model.requestId == null"
            :automatic-dropdown="true"
          >
            <el-option
              v-for="item in availableDesigners"
              :key="item.id"
              :label="item.firstName + ' ' + item.lastName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">Cancel</el-button>
        <el-button @click="saveDesignSession">OK</el-button>
      </div>
      <external-plan-create-dialog
        :visible.sync="planDialogVisible"
        @created="setRequestId"
      ></external-plan-create-dialog>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import "moment-timezone";
import { OperationResultType } from "../../enums/enums";

import { designSessionService } from "../../services/design/designsession.service";
import { designerAvailabilityService } from "../../services/design/designeravailability.service";
import { generalSessionPropertiesService } from "../../services/design/generalsessionproperties.service";
import { configurationService } from "../../services/design/configuration.service";
import { userService } from "../../services/users.service";

import availableTimes from "../../mixins/design/availableTimes";

import ExternalPlanCreateDialog from "./ExternalPlanCreateDialog";

export default Vue.extend({
  mixins: [availableTimes],
  components: {
    ExternalPlanCreateDialog,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    designer: null,
    showDesignerSelection: {
      type: Boolean,
      default: true,
    },
    timezone: {
      type: String,
      default: moment.tz.guess(),
    },
  },
  watch: {
    visible: function (newValue, oldValue) {
      if (newValue === false) {
        this.$refs.designSessionModalForm.resetFields();
        this.loading = false;
      }
    },
  },
  data() {
    return {
      OperationResultType,
      activeTabName: "standard",
      users: [],
      timezones: [],
      availableRequestIds: [],
      availableDesigners: [],
      planDialogVisible: false,
      loading: false,
    };
  },
  created() {
    this.loadInitialData();
    this.dialogOpened();
  },
  methods: {
    async loadInitialData() {
      const timezonesRequest = configurationService.getAllTimezones();
      const timezonesResponse = await timezonesRequest;
      this.timezones = timezonesResponse.data;
    },
    dialogOpened() {
      if (this.model.requestId !== null) {
        this.findDesigners();
      }
    },
    findDesigners() {
      if (!this.showDesignerSelection) {
        return;
      }

      if (this.model.requestId === null) {
        return;
      }

      if (this.model.startDate === null) {
        return;
      }

      this.loading = true;
      let userPromise = null;

      if (this.users.length === 0) {
        userPromise = userService.getUsers();
      } else {
        userPromise = Promise.resolve({
          data: this.users,
        });
      }

      const designersPromise =
        designerAvailabilityService.findAvailableDesigners(
          this.model.requestId,
          this.model.startDate,
          this.model.preparationTime,
          this.model.sessionTime,
          this.model.postProcessingTime
        );

      Promise.all([userPromise, designersPromise]).then((response) => {
        const users = response[0].data;
        this.users = users;
        const availableDesignerIds = response[1].data.items.map((d) => d.id);

        if(response[1].data.chosenDesigner != null) {
          this.model.designerId = response[1].data.chosenDesigner.id;
        }
        
        const designers = availableDesignerIds.map((d) => {
          const user = users.find((u) => u.id === d);
          return user;
        });

        const containsCurrentDesigner =
          designers.find((d) => d.id === this.model.designerId) !== undefined;

        if (!containsCurrentDesigner) {
          this.model.designerId = null;
        }

        this.availableDesigners = designers;

        this.loading = false;
      });
    },
    saveDesignSession() {
      this.$refs.designSessionModalForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.model.id !== null) {
            this.updateSession();
          } else {
            this.createSession();
          }
        } else {
          return false;
        }
      });
    },
    createSession() {
      designSessionService
        .createSession(this.model)
        .then((data) => {
          if (data.data.result === OperationResultType.Success) {
            this.$emit("added");
            this.$emit("update:visible", false);
            return;
          }

          this.$message({
            message: data.data.messages.join(" "),
            type: "error",
            showClose: true,
          });

          this.loading = false;
        })
        .catch((error) => {
          this.$message({
            message: error.response.data.messages.join(" "),
            type: "error",
            showClose: true,
          });

          this.loading = false;
        });
    },
    updateSession() {
      designSessionService
        .updateSession(this.model)
        .then((data) => {
          if (data.data.result === OperationResultType.Success) {
            this.$emit("updated");
            this.$emit("update:visible", false);
            return;
          }

          this.$message({
            message: data.data.messages.join(" "),
            type: "error",
            showClose: true,
          });

          this.loading = false;
        })
        .catch((error) => {
          this.$message({
            message: error.response.data.messages.join(" "),
            type: "error",
            showClose: true,
          });

          this.loading = false;
        });
    },
    cancel() {
      this.$emit("update:visible", false);
    },
    filterRequestIds(containsString) {
      this.model.loadingRequestIds = true;
      this.model.filteredRequestIds = [];
      designSessionService.filterRequestIds(containsString).then((response) => {
        this.availableRequestIds = response.data.data.map((item) => ({
          value: item,
          label: item,
        }));

        this.model.loadingRequestIds = false;
      });
    },
    setRequestId(data) {
      this.model.requestId = data;
      this.updateSessionTimes();
    },
    updateSessionTimes() {
      if (this.model.id === null) {
        var requestId = this.model.requestId;
        generalSessionPropertiesService
          .getDefaultGeneralSessionProperties(requestId)
          .then((response) => {
            const item = response.data;

            this.model.preparationTime = moment.duration(item.preparationTime);
            this.model.sessionTime = moment.duration(item.designSessionTime);
            this.model.postProcessingTime = moment.duration(
              item.postProcessingTime
            );

            this.findDesigners();
          });
      }
    },
  },
  computed: {
    validationRules() {
      return {
        designerId: [{ required: true, message: "Designer is required." }],
        startDate: [{ required: true, message: "Start date is required." }],
        requestId: [{ required: true, message: "Request Id is required" }],
      };
    },
    title() {
      if (this.model.id !== null) {
        return "Edit session";
      } else {
        return "Add session";
      }
    },
    date: {
      get: function () {
        if (this.model.startDate) {
          return moment
            .tz(
              this.model.startDate
                .clone()
                .tz(this.timezone)
                .format("YYYY-MM-DD HH:mm"),
              moment.tz.guess()
            )
            .format();
        }

        return this.model.startDate;
      },
      set: function (newValue) {
        if (newValue !== null) {
          this.model.startDate = moment
            .tz(
              moment(newValue).clone().local().format("YYYY-MM-DD HH:mm"),
              this.timezone
            )
            .local();
        } else {
          this.model.startDate = "";
          this.availableDesigners = [];
          this.model.designerId = null;
        }
      },
    },
    preparationDays: {
      get: function () {
        return Math.floor(this.model.preparationTime.asHours() / 8);
      },
      set: function (newValue) {
        const hours = this.model.preparationTime.asHours() % 8;

        this.model.preparationTime = moment.duration(
          newValue * 8 + hours,
          "hours"
        );
        this.findDesigners();
      },
    },
    preparationHours: {
      get: function () {
        return this.model.preparationTime.asHours() % 8;
      },
      set: function (newValue) {
        const days = Math.floor(this.model.preparationTime.asHours() / 8);

        this.model.preparationTime = moment.duration(
          days * 8 + newValue,
          "hours"
        );
        this.findDesigners();
      },
    },
    sessionHours: {
      get: function () {
        return this.model.sessionTime.hours();
      },
      set: function (newValue) {
        this.model.sessionTime = moment.duration({
          hours: newValue,
          minutes: this.model.sessionTime.minutes(),
        });
        this.findDesigners();
      },
    },
    sessionMinutes: {
      get: function () {
        return this.model.sessionTime.minutes();
      },
      set: function (newValue) {
        this.model.sessionTime = moment.duration({
          hours: this.model.sessionTime.hours(),
          minutes: newValue,
        });
        this.findDesigners();
      },
    },
    postProcessingDays: {
      get: function () {
        return Math.floor(this.model.postProcessingTime.asHours() / 8);
      },
      set: function (newValue) {
        const hours = this.model.postProcessingTime.asHours() % 8;

        this.model.postProcessingTime = moment.duration(
          newValue * 8 + hours,
          "hours"
        );
        this.findDesigners();
      },
    },
    postProcessingHours: {
      get: function () {
        return this.model.postProcessingTime.asHours() % 8;
      },
      set: function (newValue) {
        const days = Math.floor(this.model.postProcessingTime.asHours() / 8);

        this.model.postProcessingTime = moment.duration(
          days * 8 + newValue,
          "hours"
        );
        this.findDesigners();
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 0px;
}
</style>

import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";
import qs from "qs";

class DocumentExportService {
  getDocumentExportItem(documentId) {
    return DocumentationHttpClient.get(`/DocumentExport/${documentId}`);
  }
  getDocumentExportItems(documentNumbers) {
    return DocumentationHttpClient.get("/DocumentExport/List", {
      params: { documentNumbers },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  exportDocuments(chosenFileIds) {
    return DocumentationHttpClient.post("/DocumentExport/Documents", {
      chosenFileIds,
    });
  }
  exportDocumentFiles(chosenFileIds) {
    return DocumentationHttpClient.post("/DocumentExport/Files", {
      chosenFileIds,
    });
  }
  getFilteredDocumentNumbers(query) {
    return DocumentationHttpClient.get("/DocumentExport/DocumentNumbers", {
      params: { query },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
}

export const documentExportService = new DocumentExportService();

<template>
  <div v-loading="editionLock">
    <comment-list
      :comments="comments"
      :dialog-visible.sync="dialogVisible"
      :disabled="!canManageComments"
      height="500"
      button-size="mini"
      @add="handleAdd"
      @edit="handleEdit"
      @delete="handleDelete"
    ></comment-list>
  </div>
</template>
<script>
import CommentList from "../shared/CommentList.vue";
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    CommentList,
  },
  data() {
    return {
      comments: [],
      dialogVisible: false,
      loadingComments: false,
    };
  },
  computed: {
    ...mapState("fulfilmentPlanDetails", [
      "editionLock",
      "canManageFulfilmentPlan",
      "canManageManufacturingCycles",
    ]),
    canManageComments() {
      return this.canManageFulfilmentPlan || this.canManageManufacturingCycles;
    },
  },
  mounted() {
    this.getComments(this.$route.params.id);
  },
  methods: {
    ...mapActions("fulfilmentPlanDetails", ["setEditionLock"]),
    getComments(id) {
      this.loadingComments = true;
      fulfilmentPlanDetailsService.getComments(id).then((response) => {
        const data = response.data;
        this.comments = data.comments;
        this.loadingComments = false;
      });
    },
    handleAdd(item) {
      const id = this.$route.params.id;
      this.dialogVisible = false;
      this.setEditionLock(true);
      fulfilmentPlanDetailsService.addComment(
        id,
        item.text,
        () => {
          this.getComments(id);
          this.$message({
            message: "Comment added.",
            type: "success",
          });
        },
        () => {
          this.$message.error("Error during adding comment. Please try again.");
        }
      );
      this.setEditionLock(false);
    },
    handleEdit(item) {
      const id = this.$route.params.id;
      this.dialogVisible = false;
      fulfilmentPlanDetailsService.editComment(
        id,
        item.id,
        item.text,
        () => {
          this.getComments(id);
          this.$message({
            message: "Comment edited.",
            type: "success",
          });
        },
        () => {
          this.$message.error(
            "Error during editing comment. Please try again."
          );
        }
      );
    },
    handleDelete(row) {
      var url = document.URL;
      this.setEditionLock(true);
      var id = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
      fulfilmentPlanDetailsService.deleteComment(
        row.id,
        () => {
          this.getComments(id);
          this.$message({
            message: "Comment deleted.",
            type: "success",
          });
          this.setEditionLock(false);
        },
        () => {
          this.$message.error(
            "Error during deleting comment. Please try again."
          );
          this.setEditionLock(false);
        }
      );
    },
  },
};
</script>

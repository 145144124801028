<template>
  <div>
    <el-steps align-center :active="currentStep">
      <el-step
        v-for="step in steps"
        :key="step.id"
        :title="step.name"
        :status="step.status"
        :description="step.description"
      ></el-step>
    </el-steps>
  </div>
</template>
<script>
import Vue from "vue";
import { combinedOrderService } from "../../services/combinedorder.service";
import ManufacturingJobStepper from "./ManufacturingJobStepper.vue";

export default Vue.extend({
  props: {
    steps: {
      type: Array,
      default: function () {
        return [];
      },
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
});
</script>
<style lang="scss">
.el-step__title.is-finish {
  font-weight: 700;
}
</style>

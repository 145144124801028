<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">LIST OF IMPORTS</h1>
      </div>
    </el-row>
    <el-row>
      <v-server-table
        style="margin-top: 15px"
        ref="table"
        :columns="columns"
        :options="options"
      >
        <div slot="documentNumber" slot-scope="props">
          <router-link
            v-if="props.row.documentId"
            :to="'/Documentation/Document/' + props.row.documentId"
            >{{ props.row.documentNumber }}</router-link
          >
          <p v-else>{{ props.row.documentNumber }}</p>
        </div>
        <div slot="view" slot-scope="props">
          <router-link :to="'/Documentation/DocumentImport/' + props.row.id"
            >View</router-link
          >
        </div>
        <div slot="details" slot-scope="props">
          <span
            v-for="details in props.row.details"
            :key="details"
            style="display: block"
            >{{ details }}</span
          >
        </div>
        <div slot="importDate" slot-scope="props">
          <span>{{ props.row.importDate | formatDateTimeOffset }}</span>
        </div>
        <div slot="userId" slot-scope="props">
          <span>{{ getUserInfo(props.row.userId) }}</span>
        </div>
      </v-server-table>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Event } from "vue-tables-2";
import { mapActions, mapState } from "vuex";
import { documentImportService } from "../../../services/edoc/documentImport.service";
import { getUsers } from "../../../store/modules/mutationTypes";

export default Vue.extend({
  created() {
    this.getUsers();
  },
  mounted() {
    Event.$on("vue-tables.loading", () => {
      this.loading = true;
    });

    Event.$on("vue-tables.loaded", () => {
      this.loading = false;
    });
  },
  data() {
    return {
      loading: false,
      columns: [
        "documentNumber",
        "revisionName",
        "importDate",
        "userId",
        "status",
        "details",
        "view",
      ],
      options: {
        headings: {
          documentNumber: "Document",
          revisionName: "Revision",
          importDate: "Import date",
          userId: "User",
          status: "Status",
          details: "Details",
        },
        filterable: false,
        sortable: [],
        requestFunction: (data) => {
          return documentImportService
            .getDocumentImportsList(data)
            .catch(function (e) {
              this.dispatch("error", e);
            });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
      },
    };
  },
  computed: {
    ...mapState("users", ["users"]),
  },
  methods: {
    ...mapActions("users", [getUsers]),
    getUserInfo(userId) {
      const user = this.users.find((u) => u.id === userId);
      if (user == null) {
        return "";
      }

      return `${user.firstName} ${user.lastName} (${user.symbol})`;
    },
  },
});
</script>

import _ from "lodash";

export default {
  computed: {
    availableDays() {
      const range = _.range(6);

      return range.map((number) => {
        return {
          key: number,
          value: number + " working day" + (number !== 1 ? "s" : ""),
        };
      });
    },
    availableHours() {
      const range = _.range(8);

      return range.map((number) => {
        return {
          key: number,
          value: number + " working hour" + (number !== 1 ? "s" : ""),
        };
      });
    },
    availableMinutes() {
      const range = _.range(0, 60, 5);

      return range.map((number) => {
        return {
          key: number,
          value: number + " working minute" + (number !== 1 ? "s" : ""),
        };
      });
    },
  },
};

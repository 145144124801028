<template>
  <div v-loading.fullscreen.lock="loading">
    <h1 class="details-header">MY SCHEDULE</h1>
    <el-row class="row" style="margin-top: 20px">
      <el-col :span="6">
        <div class="row">
          <div class="col-md-3 text-right">
            <label class="details">Designer</label>
          </div>
          <div class="col-md-6">
            <label class="details">
              {{ designerDetails.firstName }} {{ designerDetails.lastName }}
            </label>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="content-center">
          <el-button
            type="primary"
            :disabled="designerDetails.timezone === ''"
            v-on:click="openCreateSessionModal"
            >Create session</el-button
          >
          <el-button
            type="primary"
            :disabled="designerDetails.timezone === ''"
            style="margin-left: 15px"
            v-on:click="openAvailabilityModal"
            >Add availability</el-button
          >
          <el-button
            type="primary"
            :disabled="designerDetails.timezone === ''"
            style="margin-left: 15px"
            v-on:click="openAbsenceModal"
            >Add absence</el-button
          >
        </div>
      </el-col>
      <el-col :span="6" style="margin-top: 30px">
        <div class="pull-right">
          <label class="details">
            Timezone:
            {{ designerDetails.locationTimezoneName || "Not assigned" }}
          </label>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="designerDetails && designerDetails.locationId !== 0">
      <el-col :span="12">
        <el-radio-group
          v-model="timeFormat24h"
          size="small"
          @change="changeTimeFormat"
        >
          <el-radio-button :label="true">24H</el-radio-button>
          <el-radio-button :label="false">12H</el-radio-button>
        </el-radio-group>
      </el-col>
      <div class="fright">
        <el-checkbox v-model="showWeekends" @change="changeWeekendsMode"
          >Show weekends</el-checkbox
        >
      </div>
    </el-row>
    <FullCalendar
      v-if="designerDetails.locationTimezoneName !== ''"
      ref="designerScheduleCalendar"
      :options="calendarOptions"
    ></FullCalendar>
    <div style="color: red; text-align: center" v-else>
      Designer timezone not assigned. Please assign timezone in designer
      properties.
    </div>
    <availability-modal
      ref="availabilityModal"
      :visible.sync="availabilityModalVisible"
      :designer="designerDetails"
      @added="refreshCalendar"
      @updated="refreshCalendar"
      @deleted="refreshCalendar"
    ></availability-modal>
    <absence-modal
      ref="absenceModal"
      :visible.sync="absenceModalVisible"
      :designer-id="designerDetails.id"
      :timezone="designerDetails.timezone"
      @added="refreshCalendar"
      @updated="refreshCalendar"
      @deleted="refreshCalendar"
    ></absence-modal>
    <design-session-details
      ref="designSessionDetailsModal"
      :designer="designerDetails"
      @edit="openEditSessionModal"
      :visible.sync="sessionDetailsVisible"
      :loading.sync="loading"
      @deleted="refreshCalendar"
    ></design-session-details>
    <design-session-modal
      :model.sync="designSessionModel"
      :visible.sync="sessionModalVisible"
      :designer="designerDetails"
      :show-designer-selection="false"
      :timezone="designerDetails.timezone"
      @added="refreshCalendar"
      @updated="sessionUpdated"
    ></design-session-modal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import "moment-timezone";

import FullCalendar from "@fullcalendar/vue";
import { DateTime } from "luxon";

import calendarOptions from "../../mixins/design/calendarOptions";

import DesignerDetails from "../../models/design/DesignerDetails";
import Absence from "../../models/design/Absence";
import DesignSession from "../../models/design/DesignSession";

import { designerService } from "../../services/design/designer.service";
import { designSessionService } from "../../services/design/designsession.service";
import { scheduleService } from "../../services/design/schedule.service";
import { userService } from "../../services//users.service";

import AvailabilityModal from "./AvailabilityModal";
import DesignSessionModal from "./DesignSessionModal";
import DesignSessionDetails from "./DesignSessionDetails";
import AbsenceModal from "./AbsenceModal";

import { AvailabilityType } from "../../enums/design/AvailabilityType";
import { DesignerCalendarItemType } from "../../enums/design/DesignerCalendarItemType";

export default Vue.extend({
  mixins: [calendarOptions],
  created() {
    this.absenceModel = new Absence();
    this.absenceModel.type = AvailabilityType.Absence;

    if (this.$route.params.id) {
      this.designerDetails.id = this.$route.params.id;
    } else {
      this.designerDetails.id = this.oidcUser.sub;
    }

    userService.getUserInfo(this.designerDetails.id).then((response) => {
      const user = response.data;

      this.designerDetails.firstName = user.firstName;
      this.designerDetails.lastName = user.lastName;
    });

    designerService
      .getDesignerDetails(this.designerDetails.id)
      .then((response) => {
        const data = response.data;

        this.designerDetails.locationId = data.locationId;
        this.designerDetails.sessionsPerDay = data.sessionsPerDay;
        this.calendarOptions.timeZone = data.timezone;
        this.designerDetails.timezone = data.timezone;

        this.designerDetails.locationTimezoneName = data.locationTimezoneName;

        moment.tz.setDefault(this.designerDetails.timezone);

        let requestId = this.$route.query.requestId;

        if (requestId) {
          designSessionService
            .getSessionForRequestId(requestId)
            .then((response) => {
              this.$refs.designSessionDetailsModal.open(response.data.id);
              this.$refs.designerScheduleCalendar
                .getApi()
                .gotoDate(response.data.startDate);
              this.sessionDetailsVisible = true;
            });
        }
      });
  },
  components: {
    FullCalendar,
    AvailabilityModal,
    DesignSessionDetails,
    DesignSessionModal,
    AbsenceModal,
  },
  data() {
    return {
      DesignerCalendarItemType,
      loading: false,
      designerDetails: new DesignerDetails(),
      designSessionModel: new DesignSession(),
      sessionModalVisible: false,
      sessionDetailsVisible: false,
      absenceModalVisible: false,
      timeFormat24h: true,
      showWeekends: false,
      availabilityModalVisible: false,
      calendarOptions: {
        eventClick: (eventClickInfo) => {
          const event = eventClickInfo.event.extendedProps;
          if (
            event.type !== DesignerCalendarItemType.Session &&
            event.type !== DesignerCalendarItemType.Absence &&
            event.type !== DesignerCalendarItemType.PreReservation
          ) {
            this.$refs.availabilityModal.loadAvailability(event.id);
          } else if (event.type === DesignerCalendarItemType.Absence) {
            this.$refs.absenceModal.loadAbsence(event.id);
          } else {
            this.$refs.designSessionDetailsModal.open(event.id);
            this.sessionDetailsVisible = true;
          }
        },

        events: (fetchInfo, successCallback, failureCallback) => {
          const start = DateTime.fromISO(fetchInfo.startStr).toUTC().toISO();
          const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();

          scheduleService
            .getDesignerSchedule(this.designerDetails.id, start, end)
            .then((response) => {
              let events = [];

              const absences = response.data.absences.map((a) => ({
                start: a.startDate,
                end: a.endDate,
                extendedProps: {
                  id: a.id,
                  type: DesignerCalendarItemType.Absence,
                },
                title: "Absence",
                color: "#990000",
              }));

              events.push(...absences);

              const availabilities = response.data.availabilities.map((a) => ({
                start: a.startDate,
                end: a.endDate,
                extendedProps: {
                  id: a.id,
                  type: a.sessionsLimitReached
                    ? DesignerCalendarItemType.AvailabilityReserved
                    : DesignerCalendarItemType.Availability,
                },
                title: a.sessionsLimitReached
                  ? "(Max.number of sessions reached)"
                  : "Availability",
                color: a.sessionsLimitReached ? "#df9425" : "#008000",
              }));

              events.push(...availabilities);

              const sessions = response.data.sessions.map((a) => ({
                start: a.startDate,
                end: a.endDate,
                extendedProps: {
                  id: a.id,
                  type: DesignerCalendarItemType.Session,
                },
                title: a.itemType,
                color: a.itemType === "Session" ? "#990000" : "#df9425",
              }));

              events.push(...sessions);

              const preReservations = response.data.preReservations.map((a) => ({
                start: a.startDate,
                end: a.endDate,
                extendedProps: {
                  id: a.id,
                  type: DesignerCalendarItemType.PreReservation,
                },
                title: a.itemType === "PreReservation" ? "Pre-Reserved Session" : a.itemType,
                color: a.itemType === "PreReservation" ? "#2D66EB" : "#df9425",
              }));

              events.push(...preReservations);

              successCallback(events);
            });
        },
      },
    };
  },
  methods: {
    openCreateSessionModal() {
      this.sessionModalVisible = true;
      this.designSessionModel = new DesignSession();
      this.designSessionModel.designerId = this.designerDetails.id;
    },
    openEditSessionModal(id) {
      designSessionService.getSession(id).then((response) => {
        this.designSessionModel = new DesignSession(response.data);
        this.sessionDetailsVisible = false;
        this.sessionModalVisible = true;
      });
    },
    openAvailabilityModal() {
      this.availabilityModalVisible = true;
    },
    openAbsenceModal() {
      this.absenceModalVisible = true;
      this.absenceModel = new Absence(null, this.designerDetails.timezone);
      this.absenceModel.type = AvailabilityType.Absence;
    },
    changeTimeFormat() {
      this.calendarOptions.eventTimeFormat = {
        ...this.calendarOptions.eventTimeFormat,
        hour12: !this.timeFormat24h,
      };

      this.calendarOptions.slotLabelFormat = {
        ...this.calendarOptions.slotLabelFormat,
        hour12: !this.timeFormat24h,
      };
    },
    changeWeekendsMode() {
      this.calendarOptions.weekends = this.showWeekends;
    },
    sessionUpdated() {
      this.sessionDetailsVisible = false;
      this.refreshCalendar();
    },
    refreshCalendar() {
      this.$refs.designerScheduleCalendar.getApi().refetchEvents();
    },
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
  },
});
</script>

import LinkedPlanModel from "./LinkedPlanModel";

export default class LinkedPlanListModel {
  linkedPlans: LinkedPlanModel[] = [];

  constructor(data) {
    if (data !== null && data.model.length > 0) {
      for (const item of data.model) {
        const newLink = new LinkedPlanModel(item);
        this.linkedPlans.push(newLink);
      }
    }
  }
}

export default class DocumentEIFUDataModel {
  eIFUName: string;
  portalId: number;

  constructor(data: any = null) {
    if (data !== null) {
      this.eIFUName = data.eifuName;
      this.portalId = data.portalId;
    }
  }
}

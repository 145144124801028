<template>
  <div>
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">COUNTRIES</h1>
      </div>
    </el-row>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :form-options="{ width: '20%' }"
      :add-template="template"
      :add-rules="validationRules"
      :table-options="tableOptions"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
      @row-remove="onDelete"
      deletable
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";
import { configurationService } from "../../../services/edoc/configuration.service";
import CrudScreen from "../../shared/crud/CrudScreen.vue";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      regions: [],
      columns: ["name", "eifuValue", "region"],
      tableOptions: {
        headings: {
          eifuValue: "eIFU Value",
        },
      },
      validationRules: {
        name: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
        eifuValue: [
          {
            required: true,
            message: "eIFU Value is required",
            trigger: "blur",
          },
        ],
        regionId: [
          { required: true, message: "Region is required", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    template() {
      return {
        id: {
          component: {
            show: false,
          },
        },
        name: {
          title: "Name",
          value: "",
          component: {
            name: "el-input",
          },
        },
        eifuValue: {
          title: "eIFU Value",
          value: "",
          component: {
            name: "el-input",
          },
        },
        regionId: {
          title: "Region",
          component: {
            name: "el-select",
            filterable: true,
            options: this.regions.map((region) => ({
              value: region.id,
              label: region.name,
            })),
          },
        },
      };
    },
  },
  methods: {
    getRecords() {
      Promise.all([
        configurationService.loadRegions(),
        configurationService.loadCountries(),
      ]).then((values) => {
        this.regions = values[0].data;
        this.records = values[1].data.map((country) => ({
          ...country,
          region: this.regions.find((region) => region.id == country.regionId)
            .name,
        }));
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      configurationService.addCountry(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      configurationService.editCountry(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onDelete(item, callback) {
      this.loading = true;
      configurationService.deleteCountry(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

import { HTTP } from "./HttpCommon";

class NotificationSettingsService {
  getNotificationsSettings() {
    return HTTP.get("NotificationSettings/GetNotificationsSettings");
  }

  editNotificationLifespan(id: number, userId: number, newValue: number) {
    return HTTP.put("NotificationSettings/EditNotificationLifespan", {
      id: id,
      userId: userId,
      newValue: newValue,
    });
  }
  addMilestoneAlertNotificationSetting(model) {
    return HTTP.post(
      "NotificationSettings/AddMilestoneAlertNotificationSetting",
      { ...model }
    );
  }
  editMilestoneAlertNotificationSetting(model) {
    return HTTP.put(
      "NotificationSettings/EditMilestoneAlertNotificationSetting",
      { ...model }
    );
  }
  deleteMilestoneAlertNotificationSetting(id: number) {
    return HTTP.delete(
      "NotificationSettings/DeleteMilestoneAlertNotificationSetting/" + id
    );
  }
  addJobStepAlertNotificationSetting(model) {
    return HTTP.post(
      "NotificationSettings/AddJobStepAlertNotificationSetting",
      { ...model }
    );
  }
  editJobStepAlertNotificationSetting(model) {
    return HTTP.put(
      "NotificationSettings/EditJobStepAlertNotificationSetting",
      { ...model }
    );
  }
  deleteJobStepAlertNotificationSetting(id: number) {
    return HTTP.delete(
      "NotificationSettings/DeleteJobStepAlertNotificationSetting/" + id
    );
  }
  getAvailableMaterials(id: number) {
    return HTTP.get("NotificationSettings/GetAvailableMaterials/" + id);
  }
  getAvailableJobSteps(id: number) {
    return HTTP.get("NotificationSettings/GetAvailableJobSteps/" + id);
  }
  addFulfilmentPlanChange(model) {
    return HTTP.post("NotificationSettings/AddFulfilmentPlanChange", {
      ...model,
    });
  }
  editFulfilmentPlanChange(model) {
    return HTTP.put("NotificationSettings/EditFulfilmentPlanChange", {
      ...model,
    });
  }
  deleteFulfilmentPlanChange(id: number) {
    return HTTP.delete("NotificationSettings/DeleteFulfilmentPlanChange/" + id);
  }
  addManufacturingCycleChange(model) {
    return HTTP.post("NotificationSettings/AddManufacturingCycleChange", {
      ...model,
    });
  }
  editManufacturingCycleChange(model) {
    return HTTP.put("NotificationSettings/EditManufacturingCycleChange", {
      ...model,
    });
  }
  deleteManufacturingCycleChange(id: number) {
    return HTTP.delete(
      "NotificationSettings/DeleteManufacturingCycleChange/" + id
    );
  }
  addJobStepChange(model) {
    return HTTP.post("NotificationSettings/AddJobStepChange", {
      ...model,
    });
  }
  editJobStepChange(model) {
    return HTTP.put("NotificationSettings/EditJobStepChange", {
      ...model,
    });
  }
  deleteJobStepChange(id: number) {
    return HTTP.delete("NotificationSettings/DeleteJobStepChange/" + id);
  }
  addPlannedMilestoneChange(model) {
    return HTTP.post("NotificationSettings/AddPlannedMilestoneChange", {
      ...model,
    });
  }
  editPlannedMilestoneChange(model) {
    return HTTP.put("NotificationSettings/EditPlannedMilestoneChange", {
      ...model,
    });
  }
  deletePlannedMilestoneChange(id: number) {
    return HTTP.delete(
      "NotificationSettings/DeletePlannedMilestoneChange/" + id
    );
  }
  addActualMilestoneChange(model) {
    return HTTP.post("NotificationSettings/AddActualMilestoneChange", {
      ...model,
    });
  }
  editActualMilestoneChange(model) {
    return HTTP.put("NotificationSettings/EditActualMilestoneChange", {
      ...model,
    });
  }
  deleteActualMilestoneChange(id: number) {
    return HTTP.delete(
      "NotificationSettings/DeleteActualMilestoneChange/" + id
    );
  }
}

export const notificationSettingsService = new NotificationSettingsService();

import Vue from "vue";
import VueRouter from "vue-router";
import qs from "qs";

import UsersList from "../components/user//UsersList.vue";
import UserDetails from "../components/user/UserDetails.vue";
import ChangePassword from "../components/user/ChangePassword.vue";
import NotificationsSettings from "../components/user/NotificationsSettings.vue";

import Details from "../components/fulfilment-plan-details/Details.vue";
import FulfilmentPlanList from "../components/fulfilment-plan-list/FulfilmentPlanList.vue";
import LoadFulfilmentPlanFilter from "../components/fulfilment-plan-list/LoadFilter.vue";
import List from "../components/manufacturing-cycle-list/List.vue";
import LoadManufacturingCycleFilter from "../components/manufacturing-cycle-list/LoadFilter.vue";
import ManufacturingCycleDetails from "../components/manufacturing-cycle-details/ManufacturingCycleDetails.vue";
import PrototypeManufacturingCycleDetails from "../components/prototype-manufacturing/PrototypeManufacturingCycleDetails.vue"

import LocationMappingList from "../components/location-mapping/LocationMappingList.vue";
import JobStepList from "../components/job-step/JobStepList.vue";
import ReportList from "../components/report/ReportList.vue";
import EmbeddedReport from "../components/report/EmbeddedReport.vue";
import ManageCalendarList from "../components/manage-calendar/ManageCalendarList.vue";
import LocalCalendar from "../components/local-calendar/LocalCalendar.vue";
import ManufacturingLocationList from "../components/manufacturing-location/ManufacturingLocationList.vue";
import ProductTypeMappingList from "../components/product-type-mapping/ProductTypeMappingList.vue";
import ProductTypeList from "../components/product-type/ProductTypeList.vue";
import ProductList from "../components/product/ProductList.vue";
import FilterManagement from "../components/filter/FilterManagement.vue";
import RequestImportLog from "../components/import-request/RequestImportLog.vue";
import ImportList from "../components/import-request/ImportList.vue";
import ImportDetails from "../components/import-request/ImportDetails.vue";
import ImportFile from "../components/import-request/ImportFile.vue";
import CombinedOrderList from "../components/combined-order/CombinedOrderList.vue";
import CombinedOrderDetails from "../components/combined-order/CombinedOrderDetails.vue";
import LeadTimeModificationList from "../components/lead-time-modifications/LeadTimeModificationList.vue";
import MilestoneLeadTimeGroupList from "../components/milestone-lead-time/MilestoneLeadTimeGroupList.vue";
import MilestoneLeadTimeGroupDetails from "../components/milestone-lead-time/MilestoneLeadTimeGroupDetails.vue";
import WorkflowStepEffortList from "../components/workflow-step-efforts/WorkflowStepEffortList.vue";
import TrackingInformationList from "../components/tracking-information/TrackingInformationList.vue";
import WorkflowList from "../components/workflow/WorkflowList.vue";
import WorkflowCreate from "../components/workflow/WorkflowCreate.vue";
import WorkflowDetails from "../components/workflow/WorkflowDetails.vue";
import SurgeonPreferences from "../components/surgeon-preferences/SurgeonPreferences.vue";
import ManufacturingCycleDefinitionList from "../components/manufacturing-cycle-definition/ManufacturingCycleDefinitionList.vue";
import ManufacturingCycleDefinitionDetails from "../components/manufacturing-cycle-definition/ManufacturingCycleDefinitionDetails.vue";
import ChangesList from "../components/changes/ChangesList.vue";

import DesignerSchedule from "../components/design/DesignerSchedule.vue";
import DesignerList from "../components/design/DesignerList.vue";
import DesignerAllSchedules from "../components/design/DesignerAllSchedules.vue";
import GeneralSessionPropertiesList from "../components/design/GeneralSessionPropertiesList.vue";
import SessionPlanner from "../components/design/SessionPlanner.vue";

import DocumentList from "../components/edoc/documents/DocumentList.vue";
import DocumentDetails from "../components/edoc/documents/DocumentDetails.vue";
import ProductListEDoc from "../components/edoc/products/ProductList.vue";
import ProductDetails from "../components/edoc/products/ProductDetails.vue";
import ProductImport from "../components/edoc/product-import/ProductImport.vue";
import ProductImportsList from "../components/edoc/product-import/ProductImportsList.vue";
import ProductImportDetails from "../components/edoc/product-import/ProductImportDetails.vue";
import DocumentExport from "../components/edoc/document-export/DocumentExport.vue";
import ProductExport from "../components/edoc/product-export/ProductExport.vue";
import DocumentTypesList from "../components/edoc/configuration/DocumentTypesList.vue";
import CountriesList from "../components/edoc/configuration/CountriesList.vue";
import DivisionsList from "../components/edoc/configuration/DivisionsList.vue";
import PortalsList from "../components/edoc/configuration/PortalsList.vue";
import LanguagesList from "../components/edoc/configuration/LanguagesList.vue";
import ProductFamiliesList from "../components/edoc/configuration/ProductFamiliesList.vue";
import KeyCodesList from "../components/edoc/keycodes/KeyCodesList.vue";
import KeyCodeExport from "../components/edoc/keycode-export/KeyCodeExport.vue";
import DocumentImport from "../components/edoc/document-import/DocumentImport.vue";
import DocumentImportsList from "../components/edoc/document-import/DocumentImportsList.vue";
import DocumentImportDetails from "../components/edoc/document-import/DocumentImportDetails.vue";

import PermissionsLack from "../components/shared/PermissionsLack.vue";
import InsufficientPermissions from "../components/shared/InsufficientPermissions.vue";
import OidcCallback from "../components/shared/OidcCallback.vue";
import OidcCallbackError from "../components/shared/OidcCallbackError.vue";

import BulkOperationsManufacturingCycleProcessingScreen from "../components/bulk-operations/manufacturing-cycle/ProcessingScreen.vue";

import RepeatOrdersList from "../components/repeat-orders/RepeatOrdersList.vue";

import PageNotFound from "@/views/PageNotFound.vue";
import Home from "@/views/Home.vue";

import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

import store from "../store/index";

import _ from "lodash";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: "/CRT",
  routes: [
    {
      path: "/FulfilmentPlan/Details/:id",
      component: Details,
      meta: {
        title: "Plan Details",
        authorize: ["ReadOnlyFulfilmentPlanAccess", "CanManageFulfilmentPlan"],
      },
    },
    {
      path: "/FulfilmentPlan/List",
      component: FulfilmentPlanList,
      meta: {
        title: "Plan Coordination",
        authorize: ["ReadOnlyFulfilmentPlanAccess", "CanManageFulfilmentPlan"],
      },
    },
    {
      path: "/FulfilmentPlan/LoadFilter/:id",
      component: LoadFulfilmentPlanFilter,
      meta: {
        title: "Plan Coordination",
        authorize: ["ReadOnlyFulfilmentPlanAccess", "CanManageFulfilmentPlan"],
      },
    },
    {
      path: "/ManufacturingCycle/List",
      component: List,
      meta: {
        title: "Manufacturing Cycles",
        authorize: [
          "CanManageManufacturingCycles",
          "ReadOnlyManufacturingCycleAccess",
        ],
      },
    },
    {
      path: "/ManufacturingCycle/LoadFilter/:id",
      component: LoadManufacturingCycleFilter,
      meta: {
        title: "Manufacturing Cycles",
        authorize: [
          "CanManageManufacturingCycles",
          "ReadOnlyManufacturingCycleAccess",
        ],
      },
    },
    {
      path: "/ManufacturingCycle/Details/:id",
      component: ManufacturingCycleDetails,
      meta: {
        title: "Manufacturing Details",
        authorize: [
          "CanManageManufacturingCycles",
          "ReadOnlyManufacturingCycleAccess",
        ],
      },
    },
    {
      path: "/PrototypeManufacturingCycle/Details/:id",
      component: PrototypeManufacturingCycleDetails,
      meta: {
        title: "Prototype Manufacturing Details",
        authorize: [
          "CanManageManufacturingCycles",
          "ReadOnlyManufacturingCycleAccess",
        ],
      },
    },
    {
      path: "/Users/:id",
      component: UserDetails,
      meta: { title: "Users", authorize: ["CanManageUsers"] },
    },
    {
      path: "/Users",
      component: UsersList,
      meta: { title: "Users", authorize: ["CanManageUsers"] },
    },
    {
      path: "/ChangePassword",
      component: ChangePassword,
      meta: { title: "Change Password" },
    },
    {
      path: "/Notification/NotificationsSettings",
      component: NotificationsSettings,
      meta: { title: "Notifications Settings" },
    },
    {
      path: "/DistributionCenters",
      component: LocationMappingList,
      meta: { title: "Distribution Centers", authorize: ["CanManageMappings"] },
    },
    {
      path: "/JobStep",
      component: JobStepList,
      meta: { title: "Job Steps", authorize: ["CanManageWorkflows"] },
    },
    {
      path: "/Reports",
      component: ReportList,
      meta: {
        title: "Report Definitions",
        authorize: ["CanManageReportDefinitions"],
      },
    },
    {
      path: "/Reports/EmbeddedReport/:id",
      component: EmbeddedReport,
      meta: { title: "Embedded Report" },
    },
    {
      path: "/LocalCalendar",
      component: LocalCalendar,
      meta: { title: "Local Calendar", authorize: ["CanManageLocalCalendar"] },
    },
    {
      path: "/ManageCalendars",
      component: ManageCalendarList,
      meta: { title: "Manage Calendars", authorize: ["CanManageLocalCalendar"] },
    },
    {
      path: "/ManufacturingLocations",
      component: ManufacturingLocationList,
      meta: {
        title: "Manufacturing Locations",
        authorize: ["CanFulfilmentPlanAdministrate"],
      },
    },
    {
      path: "/ProductTypeMapping",
      component: ProductTypeMappingList,
      meta: { title: "Product Type Mapping", authorize: ["CanManageMappings"] },
    },
    {
      path: "/Product",
      component: ProductList,
      meta: { title: "Products", authorize: ["CanManageProductTypes"] },
    },
    {
      path: "/ProductType",
      component: ProductTypeList,
      meta: { title: "Product Types", authorize: ["CanManageProductTypes"] },
    },
    {
      path: "/WorkflowStepEfforts",
      component: WorkflowStepEffortList,
      meta: {
        title: "Workflows Step Efforts",
        authorize: ["CanManageWorkflowStepEfforts"],
      },
    },
    {
      path: "/Workflow/List",
      component: WorkflowList,
      meta: { title: "Workflows", authorize: ["CanManageWorkflows"] },
    },
    {
      path: "/Workflow/Create",
      component: WorkflowCreate,
      meta: { title: "Create Workflow", authorize: ["CanManageWorkflows"] },
    },
    {
      path: "/Workflow/Details/:id",
      component: WorkflowDetails,
      meta: { title: "Workflow Details", authorize: ["CanManageWorkflows"] },
    },
    {
      path: "/ManufacturingCycleDefinition/List",
      component: ManufacturingCycleDefinitionList,
      meta: {
        title: "Manufacturing Cycle Definitions",
        authorize: ["CanManageWorkflows"],
      },
    },
    {
      path: "/ManufacturingCycleDefinition/Details/:id",
      component: ManufacturingCycleDefinitionDetails,
      meta: {
        title: "Manufacturing Cycle Definition Details",
        authorize: ["CanManageWorkflows"],
      },
    },
    {
      path: "/CombinedOrders",
      component: CombinedOrderList,
      meta: { title: "Combined Orders" },
    },
    {
      path: "/CombinedOrders/:id",
      component: CombinedOrderDetails,
      meta: { title: "Combined Orders" },
    },
    {
      path: "/LeadTimeModifications",
      component: LeadTimeModificationList,
      meta: {
        title: "Lead Time Modifications",
        authorize: ["CanManageMilestoneLeadTimes"],
      },
    },
    {
      path: "/MilestoneLeadTime",
      component: MilestoneLeadTimeGroupList,
      meta: {
        title: "Milestone Lead Times",
        authorize: ["CanManageMilestoneLeadTimes"],
      },
    },
    {
      path: "/MilestoneLeadTime/:id",
      component: MilestoneLeadTimeGroupDetails,
      meta: {
        title: "Milestone Lead Times",
        authorize: ["CanManageMilestoneLeadTimes"],
      },
    },
    {
      path: "/SurgeonPreferences",
      component: SurgeonPreferences,
      meta: {
        title: "Surgeon Preferences",
        authorize: ["CanManageSurgeonPreferences"],
      },
    },
    {
      path: "/Changes",
      component: ChangesList,
      meta: {
        title: "Changes",
        authorize: ["CanManageFulfilmentPlan", "ReadOnlyFulfilmentPlanAccess"],
      },
    },

    {
      path: "/FilterManagement",
      component: FilterManagement,
      meta: { title: "Filter Management" },
    },

    {
      path: "/ImportRequest/Log",
      component: RequestImportLog,
      meta: { title: "Imports", authorize: ["CanManageImportSettings"] },
    },
    {
      path: "/ImportRequest/List",
      component: ImportList,
      meta: {
        title: "List of imports",
        authorize: ["CanManageImportSettings"],
      },
    },
    {
      path: "/ImportRequest/Details/:id",
      name: "importRequestDetails",
      component: ImportDetails,
      meta: {
        title: "Import Request Details",
        authorize: ["CanManageImportSettings"],
      },
    },
    {
      path: "/ImportRequest/ImportFile",
      component: ImportFile,
      meta: { title: "Import Request", authorize: ["CanManageImportSettings"] },
    },
    {
      path: "/TrackingInformation",
      component: TrackingInformationList,
      meta: {
        title: "Tracking Information",
        authorize: ["CanManageImportSettings"],
      },
    },

    {
      path: "/Designer/Schedule",
      component: DesignerSchedule,
      meta: { title: "Designer Schedule", authorize: ["IsDesigner"] },
    },
    {
      path: "/Designer/Schedule/:id",
      component: DesignerSchedule,
      meta: {
        title: "Designer Schedule",
        authorize: ["CanManageDesignSessions"],
      },
    },
    {
      path: "/Designer/List",
      component: DesignerList,
      meta: { title: "Designer List", authorize: ["CanManageDesignSessions"] },
    },
    {
      path: "/Designer/AllSchedules",
      component: DesignerAllSchedules,
      meta: {
        title: "All Schedules",
        authorize: ["CanViewDesignSessions", "CanManageDesignSessions"],
      },
    },
    {
      path: "/GeneralSessionProperties/List",
      component: GeneralSessionPropertiesList,
      meta: {
        title: "General Session Properties",
        authorize: ["CanManageDesignSessionSettings"],
      },
    },
    {
      path: "/Designer/SessionPlanner",
      component: SessionPlanner,
      meta: {
        title: "Session planner",
        authorize: ["CanViewDesignSessions", "CanManageDesignSessions"],
      },
    },

    {
      path: "/Documentation/Document",
      component: DocumentList,
      meta: { title: "eDOC - Documents", authorize: ["CanReadDocuments"] },
    },
    {
      path: "/Documentation/Document/Export",
      component: DocumentExport,
      meta: {
        title: "eDOC - Document Export",
        authorize: ["CanExportDocuments"],
      },
    },
    {
      path: "/Documentation/Document/:id",
      component: DocumentDetails,
      meta: {
        title: "eDOC - Document Details",
        authorize: ["CanReadDocuments"],
      },
    },
    {
      path: "/Documentation/DocumentImport",
      component: DocumentImport,
      meta: {
        title: "eDOC - Document Import",
        authorize: ["CanImportDocuments"],
      },
    },
    {
      path: "/Documentation/DocumentImport/List",
      component: DocumentImportsList,
      meta: {
        title: "eDOC - Document Imports",
        authorize: ["CanImportDocuments"],
      },
    },
    {
      path: "/Documentation/DocumentImport/:id",
      component: DocumentImportDetails,
      meta: {
        title: "eDOC - Document Import Details",
        authorize: ["CanImportDocuments"],
      },
    },

    {
      path: "/Documentation/KeyCode/Export",
      component: KeyCodeExport,
      meta: {
        title: "eDOC - Keycode Export",
        authorize: ["CanExportProducts"],
      },
    },
    {
      path: "/Documentation/Product/Export",
      component: ProductExport,
      meta: {
        title: "eDOC - Product Export",
        authorize: ["CanExportProducts"],
      },
    },

    {
      path: "/Documentation/Product/",
      component: ProductListEDoc,
      meta: { title: "eDOC - Products", authorize: ["CanReadProducts"] },
    },
    {
      path: "/Documentation/Product/:id",
      component: ProductDetails,
      meta: { title: "eDOC - Product Details", authorize: ["CanReadProducts"] },
    },
    {
      path: "/Documentation/KeyCode",
      component: KeyCodesList,
      meta: { title: "eDOC - Keycodes", authorize: ["CanReadProducts"] },
    },

    {
      path: "/Documentation/ProductImport",
      component: ProductImport,
      meta: {
        title: "eDOC - Product Import",
        authorize: ["CanImportProducts"],
      },
    },
    {
      path: "/Documentation/ProductImport/List",
      component: ProductImportsList,
      meta: {
        title: "eDOC - Product Imports",
        authorize: ["CanImportProducts"],
      },
    },
    {
      path: "/Documentation/ProductImport/:id",
      component: ProductImportDetails,
      meta: {
        title: "eDOC - Product Import Details",
        authorize: ["CanImportProducts"],
      },
    },

    {
      path: "/Documentation/DocumentType",
      component: DocumentTypesList,
      meta: {
        title: "eDOC - Document Types",
        authorize: ["CanManageEDocSystemConfiguration"],
      },
    },
    {
      path: "/Documentation/Country",
      component: CountriesList,
      meta: {
        title: "eDOC - Countries",
        authorize: ["CanManageEDocSystemConfiguration"],
      },
    },
    {
      path: "/Documentation/Division",
      component: DivisionsList,
      meta: {
        title: "eDOC - Divisions",
        authorize: ["CanManageEDocSystemConfiguration"],
      },
    },
    {
      path: "/Documentation/Portal",
      component: PortalsList,
      meta: {
        title: "eDOC - Portals",
        authorize: ["CanManageEDocSystemConfiguration"],
      },
    },
    {
      path: "/Documentation/Language",
      component: LanguagesList,
      meta: {
        title: "eDOC - Languages",
        authorize: ["CanManageEDocSystemConfiguration"],
      },
    },
    {
      path: "/Documentation/ProductFamily",
      component: ProductFamiliesList,
      meta: {
        title: "eDOC - Product Families",
        authorize: ["CanManageEDocSystemConfiguration"],
      },
    },
    //{ path: '/Documentation/Region', component: RegionsList, meta: { title: 'eDOC - Regions' } }, //Hidden until regions rework is finished

    {
      path: "/InsufficientPermissions",
      component: InsufficientPermissions,
      meta: { title: "Insufficient permissions" },
    },
    {
      path: "/PermissionsLack",
      component: PermissionsLack,
      meta: { title: "Permissions Lack" },
    },
    {
      path: "/oidc-callback",
      component: OidcCallback,
      meta: { isOidcCallback: true, isPublic: true },
    },
    {
      path: "/oidc-callback-error",
      component: OidcCallbackError,
      meta: { isPublic: true },
    },
    {
      path: "/ManufacturingCycle/BulkOperations",
      component: BulkOperationsManufacturingCycleProcessingScreen,
      meta: {
        title: "Manufacturing Cycle Bulk Operations",
        authorize: [
          "CanManageManufacturingCycles",
          "ReadOnlyManufacturingCycleAccess",
        ],
      },
    },
    {
      path: "/RepeatOrders/:id",
      component: RepeatOrdersList,
      meta: {
        title: "Repeat Orders",
        authorize: [
          "ReadOnlyFulfilmentPlanAccess",
          "CanManageFulfilmentPlan"
        ],
      },
    },
    {
      path: "/",
      component: Home,
    },
    {
      path: "*",
      component: PageNotFound,
      meta: { isNotFound: true },
    },
  ],
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);

    return result ? "?" + result : "";
  },
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

router.beforeEach(async (to, from, next) => {
  const { title, authorize } = to.meta;

  if (title) {
    document.title = title;
  }

  if (to.meta.isNotFound) {
    return next();
  }

  if (authorize) {
    if (!store.getters["oidcStore/oidcIsAuthenticated"]) {
      return next({
        path: "/PermissionsLack",
      });
    }

    if (authorize.length) {
      const allPermissions = await getAllPermissions();
      const isPermitted = authorize.some(
        (requiredPermission) => allPermissions[requiredPermission] === true
      );

      if (isPermitted) {
        return next();
      } else {
        return next({
          path: "/InsufficientPermissions",
        });
      }
    }
  } else {
    next();
  }
});

async function getAllPermissions(): Promise<Record<string, boolean>> {
  const getEDocPermissions = store.dispatch(
    "permissions/getAssignedEDocPermissions"
  );
  const getPermissions = store.dispatch("permissions/getAssignedPermissions");
  const getDesignSessionPermissions = store.dispatch(
    "permissions/getAssignedDesignSessionPermissions"
  );
  const permissions = await Promise.all([
    getPermissions,
    getEDocPermissions,
    getDesignSessionPermissions,
  ]);

  return _.merge({}, permissions[0], permissions[1], permissions[2]);
}

export default router;

import SimpleSelectControlItemModel from "../SimpleSelectControlItemModel";
import { TreatmentSide } from "@/components/fulfilment-plan-details/TreatmentSide";
import moment from "moment";

export default class ManufacturingInformationModel {
  id: number;
  dataAvailability: boolean;
  status: string;
  progress: number;
  location: string;
  category: number;
  sides: number;
  availableManufacturingLocations: SimpleSelectControlItemModel[] = [];
  requiredManufacturingDataRevision: number;
  canSetOnHoldResume: false;
  canRescheduleManufacturingCycle: boolean;
  statusDescription: string;
  treatmentSide?: TreatmentSide;
  isProductTmj: boolean;
  customBlank: boolean;
  manufacturingStartDate: string;
  isInCBSScope: boolean;
  manufacturingPosition: number;
  manufacturingCapacity: number;
  productTypeCode: string;

  constructor(data) {
    if (data !== null) {
      this.id = data.model.id;
      this.category = data.model.category;
      this.requiredManufacturingDataRevision =
        data.model.requiredManufacturingDataRevision;
      this.dataAvailability = data.model.manufacturingDataAvailable;
      this.sides = data.model.sides;
      this.status = data.model.statusDescription;
      this.progress = data.model.manufacturingProgress;
      this.location = data.model.manufacturingLocalizationId;
      this.canSetOnHoldResume = data.model.canSetOnHoldResume;
      this.canRescheduleManufacturingCycle =
        data.model.canRescheduleManufacturingCycle;
      this.statusDescription = data.model.statusDescription;
      for (const location of data.model.availableManufacturingLocations) {
        const newLocation = new SimpleSelectControlItemModel(location);
        this.availableManufacturingLocations.push(newLocation);
      }
      this.treatmentSide =
        data.model.treatmentSide === null
          ? null
          : TreatmentSide[data.model.treatmentSide as string];
      this.isProductTmj = data.model.isProductTmj;
      this.customBlank = data.model.customBlank;
      this.manufacturingStartDate = data.model.manufacturingStartDate ? moment(data.model.manufacturingStartDate).format("YYYY-MM-DD") : null;
      this.isInCBSScope = data.model.isInCBSScope;
      this.manufacturingPosition = data.model.manufacturingPosition;
      this.manufacturingCapacity = data.model.manufacturingCapacity;
      this.productTypeCode = data.model.productTypeCode;
    }
  }
}

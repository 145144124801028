<template>
    <div>
      <div v-if="model !== null" :key="$route.params.id" prefix="prototypeManufacturingCycleDetails">
        <el-row style="padding-top: 20px;">
          <div class="fleft">
            <h1 class="details-header no-margin">MANUFACTURING DETAILS</h1>
              <div>
                <router-link :to="planDetailsUrl" class="el-button el-button--text" type="text"
                style="padding: 6px 0px 0px 0px">Go to plan details</router-link>
              </div>
            </div>
        </el-row>
        <el-row>
          <el-col :span="7">
            <request-information :model="model"></request-information>
          </el-col>
        </el-row>
        <el-row v-if="model.requestType === RequestCategory.Prototype">
          <el-card class="grey-info">
            <el-row :gutter="20" style="margin: 0;">
              <el-col :span="12" class="left-col">
                <font-awesome-icon
                  icon="info-circle"
                  style="color:#808080;font-size: 24px;"
                ></font-awesome-icon>
              </el-col>
              <el-col :span="12" class="right-col">
                <p><b>Information</b><br>This is a prototype case. It's workflow steps may be modified.</p>
              </el-col>
            </el-row>
          </el-card>
          <el-button v-if="model.requestType === RequestCategory.Prototype" @click="addWorkflow" type="primary" style="margin-top: 20px;">
            Add new workflow
          </el-button>
        </el-row>
        <el-row v-for="procedure in model.prototypeWorkProcedureModels" :key="procedure.id">
          <el-button v-if="model.requestType === RequestCategory.Prototype" :disabled="isDisabled(procedure)" class="workflow-modify-button" @click="onManufacturingCycleChange(procedure)">
            Modify workflow
          </el-button>
          <el-button v-if="model.requestType === RequestCategory.Prototype" :disabled="isDisabled(procedure)" type="danger"  @click="openDeleteWorkProcedureDialog(procedure)">
            Delete workflow
          </el-button>
          <el-card>
            <div slot="header">
              <h4>{{ procedure.name }}</h4>
            </div>
            <el-row style="overflow-y: auto">
              <el-col :span="5">
                <work-order-information :model="procedure"></work-order-information>
              </el-col>
              <el-col :span="19">
                <prototype-work-procedure :model="procedure"></prototype-work-procedure>
              </el-col>
            </el-row>
          </el-card>
        </el-row>
      </div>
      <manufacturing-cycle-wizard
      :visible.sync="showManufacturingCycleWizard"
      ref="manufacturingCycleWizard"
      @submit="saveManufacturingCycle"
      >
      </manufacturing-cycle-wizard>

    </div>
</template>

<script>
import { showLoading, hideLoading } from "../../store/mutation-types";
import Vue from "vue";
import _ from "lodash";
import { RequestCategory, OperationResultType } from "../../enums/enums";
import { mapActions, mapMutations, mapState } from "vuex";
import RequestInformation from "../manufacturing-cycle-details/RequestInformation.vue";
import { prototypeManufacturingCycleService } from "../../services/prototypemanufacturingcycle.service";
import WorkOrderInformation from "../manufacturing-cycle-details/WorkOrderInformation.vue";
import PrototypeWorkProcedure from "./PrototypeWorkProcedure.vue";
import ManufacturingCycleWizard from "../manufacturing-cycle-wizard/ManufacturingCycleWizard.vue"

export default Vue.extend({
    components: {
        RequestInformation,
        WorkOrderInformation,
        PrototypeWorkProcedure,
        ManufacturingCycleWizard
    },
    mounted() {
        this.$nextTick(() => {
            this.getData();
        })
    },
    computed: {
        ...mapState("prototypeManufacturingCycleDetails", ["refreshPrototypeData"]),
        manufacturingCycleId() {
            return this.$route.params.id;
        },
        planDetailsUrl() {
            return `/FulfilmentPlan/Details/` + this.$route.params.id;
        },
    },
    watch: {
      refreshPrototypeData(newValue){
        if(newValue){
          this.getData();
        }
      }
    },
    data() {
        return {
            model: null,
            RequestCategory: RequestCategory,
            showManufacturingCycleWizard: false
        };
    },
    methods: {
        ...mapMutations([showLoading, hideLoading]),
        ...mapActions("prototypeManufacturingCycleDetails", ["setRefreshData", "storeTimestamp"]),
        getData(){
          this.showLoading();
            prototypeManufacturingCycleService
                .getDetails(this.manufacturingCycleId)
                .then((respone) => {
                    this.model = respone.data;
                    this.storeTimestamp(this.model.timestamp);
                    this.setRefreshData(false);
                    this.hideLoading();
                })
        },
        onManufacturingCycleChange(record){
          this.showManufacturingCycleWizard = true;
          this.$refs.manufacturingCycleWizard.item = _.cloneDeep(record);
        },
        exchangeWorkOrder(item){
          this.model.prototypeWorkProcedureModels[0] = item;
        },
        saveManufacturingCycle: function (item) {
          this.showLoading();
          prototypeManufacturingCycleService
          .updateWorkProcedure(item)
          .then((resposne) => {
            if (resposne.data.result === OperationResultType.Success) {
              this.showManufacturingCycleWizard = false;
              this.setRefreshData();
              this.showNotification("Workflow updated succesfully.", "success");
            } else {
              this.showNotification(resposne.data.message, "error");
            }
          })
          .finally(() => {
            this.hideLoading();
          });
        },
        showNotification(message, type) {
          this.$message({
            message: message,
            type: type,
            duration: 4000,
          });
        },
        openDeleteWorkProcedureDialog(item) {
          this.$confirm(
            "Are you sure you want to delete this workflow?",
            "Warning",
          {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          }
          ).then(() => {
            this.deleteManufacturingCycle(item);
          })
          .catch((e) => {
            this.$message({
              type: "info",
              message: "Deleting workflow canceled.",
            });
          });
        },
        deleteManufacturingCycle: function (item) {
          this.showLoading();
          prototypeManufacturingCycleService
          .deleteWorkProcedure(item.id)
          .then((response) => {
            this.showManufacturingCycleWizard = false;
            this.setRefreshData();
            this.hideLoading();
          })
        },
        addWorkflow() {
          this.showLoading();
          prototypeManufacturingCycleService
          .createWorkflow(this.manufacturingCycleId)
          .then((response) => {
            this.setRefreshData();
            this.hideLoading();
          })
          
        },
        isDisabled(item) {
          var result = false;
          item.prototypeManufacturingJobs.forEach((row) => {
            if(row.manufacturingStatus != 0){
              result = true;
            }
          });
          
          return result;
        }
    }
});
</script>
<style lang="scss" scoped>
::v-deep h4 {
  margin: 0px;
}

.grey-info {
  background-color: #ECECEC;
}

.left-col{
  text-align: end;
  padding-top: 5px;
}

.right-col{
  text-align: start;
}

.workflow-modify-button {
  margin-bottom: inherit;
  background-color: #ECECEC;
}
</style>

import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";
import qs from "qs";

class DocumentService {
  getDocuments(documentsFilter) {
    documentsFilter.ascending = documentsFilter.ascending == 1 ? true : false;

    return DocumentationHttpClient.get("/Document", {
      params: documentsFilter,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getDocument(documentId) {
    return DocumentationHttpClient.get(`/Document/${documentId}`);
  }
  getDivisions() {
    return DocumentationHttpClient.get("/Dictionary/Divisions");
  }
  getDocumentTypes() {
    return DocumentationHttpClient.get("/Dictionary/DocumentTypes");
  }
  getPortals() {
    return DocumentationHttpClient.get("/Dictionary/Portals");
  }
  getRegions() {
    return DocumentationHttpClient.get("/Dictionary/Regions");
  }
  getLanguages() {
    return DocumentationHttpClient.get("/Dictionary/Languages");
  }
  addDocument(document) {
    return DocumentationHttpClient.post("/Document", document);
  }
  deleteDocument(documentId) {
    return DocumentationHttpClient.delete(`/Document/${documentId}`);
  }
  editDocumentName(documentId, name) {
    return DocumentationHttpClient.put(
      `/Document/${documentId}/Name?name=${name}`
    );
  }
  editDocumentDivision(documentId, divisionId) {
    return DocumentationHttpClient.put(
      `/Document/${documentId}/Division?divisionId=${divisionId}`
    );
  }
  editDocumentType(documentId, typeId) {
    return DocumentationHttpClient.put(
      `/Document/${documentId}/Type?typeId=${typeId}`
    );
  }
  editEIFUName(documentId, name) {
    return DocumentationHttpClient.put(
      `/Document/${documentId}/EIFUName?name=${name}`
    );
  }
  editEIFUPortal(documentId, portalId) {
    return DocumentationHttpClient.put(
      `/Document/${documentId}/Portal?portalId=${portalId}`
    );
  }
  editIsVersioned(documentId, isVersioned) {
    return DocumentationHttpClient.put(
      `/Document/${documentId}/IsVersioned?isVersioned=${isVersioned}`
    );
  }
  addRevision(documentId, revision) {
    return DocumentationHttpClient.post("/Document/Revision", {
      ...revision,
      documentId,
    });
  }
  editRevision(documentId, revision) {
    return DocumentationHttpClient.put("/Document/Revision", {
      ...revision,
      documentId,
    });
  }
  deleteRevision(revisionId) {
    return DocumentationHttpClient.delete(`/Document/Revision/${revisionId}`);
  }
  addFile(documentId, revisionId, file) {
    return DocumentationHttpClient.post("/Document/File", {
      ...file,
      documentId,
      revisionId,
    });
  }
  editFile(file) {
    return DocumentationHttpClient.put("/Document/File", file);
  }
  deleteFile(fileId) {
    return DocumentationHttpClient.delete(`/Document/File/${fileId}`);
  }
}

export const documentService = new DocumentService();

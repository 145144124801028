import DocumentRevisionModel from "./DocumentRevisionModel";

export default class DocumentRevisionsListModel {
  revisions: DocumentRevisionModel[];
  isDocumentVersioned: boolean;

  constructor(data: any = null) {
    if (data !== null) {
      this.isDocumentVersioned = data.isVersioned;
      this.revisions = [];

      for (const revision of data.revisions) {
        const revisionModel = new DocumentRevisionModel(revision);
        this.revisions.push(revisionModel);
      }
    }
  }
}

import SimpleSelectControlItemModel from "./SimpleSelectControlItemModel";
import { SurgeonPreferenceModel } from "./SurgeonPreferenceModel";

export class SurgeonPreferencesListModel {
  items: Array<SurgeonPreferenceModel>;
  representatives: Array<SimpleSelectControlItemModel>;
  surgeons: Array<SimpleSelectControlItemModel>;
  availableSurgeons: Array<SimpleSelectControlItemModel>;
  representativesWithPreferences: Array<SimpleSelectControlItemModel>;

  constructor(data: any = null) {
    this.items = new Array<SurgeonPreferenceModel>();
    this.representatives = new Array<SimpleSelectControlItemModel>();
    this.surgeons = Array<SimpleSelectControlItemModel>();
    this.availableSurgeons = Array<SimpleSelectControlItemModel>();
    this.representativesWithPreferences =
      new Array<SimpleSelectControlItemModel>();

    if (data) {
      data.representatives.forEach((item: any) => {
        this.representatives.push(
          new SimpleSelectControlItemModel({ value: item.id, label: item.name })
        );
      });

      data.representativesWithPreferences.forEach((item: any) => {
        this.representativesWithPreferences.push(
          new SimpleSelectControlItemModel({ value: item.id, label: item.name })
        );
      });

      data.surgeons.forEach((item: any) => {
        this.surgeons.push(
          new SimpleSelectControlItemModel({ value: item.id, label: item.name })
        );
      });

      data.availableSurgeons.forEach((item: any) => {
        this.availableSurgeons.push(
          new SimpleSelectControlItemModel({ value: item.id, label: item.name })
        );
      });

      data.items.forEach((item: any) => {
        this.items.push(new SurgeonPreferenceModel(item));
      });
    }
  }
}

<template>
  <el-table
    :data="model.changes"
    stripe
    v-loading="loadingChanges"
    height="500"
  >
    <el-table-column
      prop="field"
      label="FIELD"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="oldValue"
      label="OLD VALUE"
      :show-overflow-tooltip="true"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="newValue"
      label="NEW VALUE"
      :show-overflow-tooltip="true"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="date"
      label="DATE"
      width="150"
      align="center"
    ></el-table-column>
    <el-table-column
      prop="user"
      label="USER"
      width="80"
      align="center"
    ></el-table-column>
  </el-table>
</template>
<script>
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";
import ChangesListModel from "@/models/fulfilment-plan/ChangesListModel";

export default {
  data() {
    return {
      model: new ChangesListModel(null),
      loadingChanges: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getChanges(this.$route.params.id);
    });
  },
  computed: {
    ...mapState("fulfilmentPlanDetails", ["changesRefresh"]),
  },
  watch: {
    changesRefresh: function () {
      if (this.changesRefresh) {
        this.getChanges(this.model.id);
        this.setChangesRefresh(false);
      }
    },
  },
  props: ["value"],
  methods: {
    ...mapActions("fulfilmentPlanDetails", ["setChangesRefresh"]),
    getChanges(id) {
      this.loadingChanges = true;
      fulfilmentPlanDetailsService.getChanges(id, (data) => {
        this.model = new ChangesListModel(data);
        this.loadingChanges = false;
      });
    },
  },
};
</script>

<template>
  <el-form label-position="top" inline>
    <el-form-item label="Division">
      <el-select
        v-model="chosenDivisions"
        multiple
        clearable
        collapse-tags
        placeholder="None selected"
      >
        <el-option
          v-for="item in availableDivisions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Type">
      <el-select
        v-model="chosenTypes"
        multiple
        clearable
        collapse-tags
        placeholder="None selected"
        style="width: 300px"
      >
        <el-option
          v-for="item in availableTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Portal">
      <el-select
        v-model="chosenPortals"
        multiple
        clearable
        collapse-tags
        placeholder="None selected"
        style="width: 300px"
      >
        <el-option
          v-for="item in availablePortals"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item class="fright">
      <template slot="label">&nbsp;</template>
      <el-button
        style="margin-bottom: 5px"
        @click="$emit('show-dialog')"
        :disabled="!eDocPermissions.CanManageDocuments"
        >Add New Document</el-button
      >
    </el-form-item>
    <stored-query-filters
      :stored-query-type="storedQueryType.Documents"
    ></stored-query-filters>
  </el-form>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Event } from "vue-tables-2";
import StoredQueryFilters from "../shared/StoredQueryFilters.vue";
import { StoredQueryType } from "../../../models/edoc/StoredQueryType";
import _ from "lodash";

export default Vue.extend({
  components: {
    StoredQueryFilters,
  },
  data() {
    return {
      storedQueryType: StoredQueryType,
      filters: {
        types:
          (this.$route.query.chosenTypes &&
            this.$route.query.chosenTypes.map(Number)) ||
          [],
        divisions:
          (this.$route.query.chosenDivisions &&
            this.$route.query.chosenDivisions.map(Number)) ||
          [],
        portals:
          (this.$route.query.chosenPortals &&
            this.$route.query.chosenPortals.map(Number)) ||
          [],
      },
    };
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
    availableDivisions() {
      return this.$store.state.document.availableDivisions;
    },
    availableTypes() {
      return this.$store.state.document.availableTypes;
    },
    availablePortals() {
      return this.$store.state.document.availablePortals;
    },
    chosenDivisions: {
      get: function () {
        return this.filters.divisions;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filters.divisions = null;
        } else {
          this.filters.divisions = newValue;
        }
        this.emitFilterChange("chosenDivisions", this.filters.divisions);
      },
    },
    chosenTypes: {
      get: function () {
        return this.filters.types;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filters.types = null;
        } else {
          this.filters.types = newValue;
        }
        this.emitFilterChange("chosenTypes", this.filters.types);
      },
    },
    chosenPortals: {
      get: function () {
        return this.filters.portals;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filters.portals = null;
        } else {
          this.filters.portals = newValue;
        }
        this.emitFilterChange("chosenPortals", this.filters.portals);
      },
    },
  },
  methods: {
    emitFilterChange: _.debounce(function (filterName, newValue) {
      Event.$emit("vue-tables.filter::" + filterName, newValue);
    }, 500),
    reloadData() {
      this.$emit("reload-data");
    },
  },
  watch: {
    $route(to, from) {
      if (to.query.handleFilter) {
        this.filters = {
          types:
            (this.$route.query.chosenTypes &&
              this.$route.query.chosenTypes.map(Number)) ||
            [],
          divisions:
            (this.$route.query.chosenDivisions &&
              this.$route.query.chosenDivisions.map(Number)) ||
            [],
          portals:
            (this.$route.query.chosenPortals &&
              this.$route.query.chosenPortals.map(Number)) ||
            [],
        };

        this.reloadData();
      }
    },
  },
});
</script>

<template>
  <div>
    <el-container>
      <el-header>
        <h1 class="details-header">LOCAL CALENDAR</h1>
      </el-header>
      <el-main>
        <el-select
          style="width: 25%; padding-bottom: 10px"
          v-model="selectedCalendarId"
          @change="refetchEvents"
        >
          <el-option
            v-for="calendar in calendars"
            :key="calendar.value"
            :label="calendar.text"
            :value="calendar.value"
          ></el-option>
        </el-select>
        <el-select 
          v-if="calendarIsCapacity" 
          v-model="selectedLocationId" 
          @change="refetchEvents"
          style="width: 15%; padding-bottom: 10px; margin-left: 1%"
        >
          <el-option
            v-for="location in locations"
            :key="location.id"
            :label="location.name"
            :value="location.id"
          ></el-option>
        </el-select>
        <el-row v-if="calendarIsCapacity" style="width: 20%; padding-bottom: 10px; margin-left: 39%; display: inline-block; position: relative;">
          <label class="custom-label">Default value:</label>
          <editable-input 
            v-model="capacityDefaultValue" 
            @input="editDefaultValue" 
          ></editable-input>
        </el-row>
        <FullCalendar
          ref="localCalendar"
          :options="calendarOptions"
        ></FullCalendar>
      </el-main>
    </el-container>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="20%">
      <el-form label-width="60px" @submit.native.prevent>
        <el-form-item label="Date">{{ holidayDetails.date }}</el-form-item>
        <el-form-item label="Name">
          <el-input
            placeholder="Holiday name"
            v-model="holidayDetails.name"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button v-if="holidayDetails.id" type="danger" @click="deleteHoliday"
          >Delete</el-button
        >
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addOrEditHoliday">OK</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="capacityTitle" :visible.sync="capacityDialogVisible" width="20%" >
      <el-form label-width="65px" @submit.native.prevent>
        <el-form-item label="Date">{{ capacityDetails.date }}</el-form-item>
        <el-form-item label="Date To">
          <el-date-picker 
            type="date" 
            v-model="capacityDetails.dateTo" 
            style="width: 100%" 
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="Empty">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Value">
          <el-input
            placeholder="Capacity value"
            v-model="capacityDetails.value"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button v-if="capacityDetails.id" type="danger" @click="deleteCapacity"
          >Delete</el-button
        >
        <el-button @click="capacityDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addOrEditCapacity">OK</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import { OperationResultType } from "../../enums/enums";
import { localCalendarService } from "../../services/localcalendar.service";
import { EditableInput } from "../shared/Shared.vue";

export default Vue.extend({
  mounted() {
    this.getRecords();
    this.getLocations();
  },
  components: {
    FullCalendar,
    EditableInput
  },
  data() {
    return {
      OperationResultType,
      loading: true,
      selectedCalendarId: null,
      selectedLocationId: null,
      capacityDefaultValue: null,
      calendars: [],
      locations: [],
      holidayDetails: {
        id: null,
        name: null,
        date: null,
      },
      capacityDetails: {
        id: null,
        value: null,
        date: null,
        dateTo: null,
      },
      dialogVisible: false,
      capacityDialogVisible: false,
      calendarOptions: {
        height: "auto",
        plugins: [dayGridPlugin, interaction],
        initialView: "dayGridMonth",
        weekends: true,
        editable: true,
        selectable: true,
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "",
        },
        locale: "en-GB",
        events: (info, successCallback) => {
          if (this.selectedCalendarId == null) {
            successCallback([]);
            return;
          }

          const start = DateTime.fromISO(info.startStr).toUTC().toISO();
          const end = DateTime.fromISO(info.endStr).toUTC().toISO();

          localCalendarService
            .loadCalendar(this.selectedCalendarId, start, end, this.selectedLocationId)
            .then((response) => {
              successCallback(response.data);
            });
        },
        dateClick: (info) => {
          const events = this.$refs.localCalendar.getApi().getEvents();
          const eventsThatDate = this.isEventOnGoingSpecificDate(events, info.date);

          if(this.calendarIsCapacity) {
            if(!eventsThatDate){
              this.capacityDialogVisible = true;
              this.capacityDetails = {
                id: null,
                value: null,
                date: info.dateStr,
                dateTo: null
              }  
            }           
          } else {
            this.dialogVisible = true;
            this.holidayDetails = {
              id: null,
              name: null,
              date: info.dateStr,
            };
          }
        },
        eventClick: (info) => {
          if(info.event.extendedProps.isCapacity) {
            if(info.event.extendedProps.isDefault) {
              this.capacityDetails = {
                id: null,
                value: this.capacityDefaultValue,
                date: info.event.startStr,
                dateTo: null
              };
              this.capacityDialogVisible = true;
            } 
            else 
            {
              localCalendarService
                .getCapacityItem(info.event.startStr, this.selectedCalendarId, this.selectedLocationId)
                .then((response) => {
                  const capacity = response.data;
                  this.capacityDetails = {
                    id: capacity.id,
                    value: capacity.value,
                    date: DateTime.fromISO(capacity.date).startOf("day").toISODate(),
                    dateTo: DateTime.fromISO(capacity.endDate).startOf("day").toISODate(),
                  };
                  this.capacityDialogVisible = true;
                });
            }
          }
          else {
            localCalendarService
            .getItem(info.event.startStr, this.selectedCalendarId)
            .then((response) => {
              const holiday = response.data;
              this.holidayDetails = {
                id: holiday.id,
                name: holiday.name,
                date: DateTime.fromISO(holiday.date).startOf("day").toISODate(),
              };

              this.dialogVisible = true;
            });
          }
        },
        eventDidMount: (info) => {
          if(info.event.extendedProps.isCapacity){
            info.el.style.backgroundColor = '#000000';
            info.el.style.fontWeight = 'bold';
          }
          if(info.event.extendedProps.isDefault){
            info.el.style.backgroundColor = '#3788d8';
            info.el.style.fontWeight = 'bold';
          }
        }
      },
    };
  },
  methods: {
    getRecords() {
      this.loading = true;
      localCalendarService.getCalendars().then((response) => {
        this.calendars = response.data;
        this.selectedCalendarId = this.calendars[0].value;
        this.$refs.localCalendar.getApi().refetchEvents();
        this.loading = false;
      });
    },
    getLocations() {
      this.loading = true;
      localCalendarService.getLocations().then((response) => {
        this.locations = response.data;
        this.selectedLocationId = this.locations[0].id;
        this.loading = false;
      });
    },
    getCapacityDefaultValue() {
      this.loading = true;
      localCalendarService.getCapacityDefaultValue(this.selectedCalendarId, this.selectedLocationId)
        .then((response) => {
          this.capacityDefaultValue = response.data.value;
          this.loading = false;
      });
    },
    refetchEvents() {
      this.$refs.localCalendar.getApi().refetchEvents();
      if(this.calendarIsCapacity) {
        this.getCapacityDefaultValue();
      }
    },
    addOrEditCapacity() {
      this.loading = true;

      if(this.capacityDetails.id !== null) {
        localCalendarService
          .editCapacity(
            this.capacityDetails.id,
            this.capacityDetails.value,
            this.capacityDetails.date,
            this.capacityDetails.dateTo,
            this.selectedLocationId,
            this.selectedCalendarId
          )
          .then((response) => {
            const data = response.data;

            if(data.result === OperationResultType.Success) {
              this.$message({
                message: "Capacity updated.",
                type: "success",
              });

              this.refetchEvents();
              this.capacityDialogVisible = false;
            } else {
              this.$message({
                message: data.message,
                type: "error",
              });
            }
            this.loading = false;
          });
      } else {
        localCalendarService
          .addCapacity(
            this.selectedCalendarId,
            this.capacityDetails.date,
            this.capacityDetails.value,
            this.capacityDetails.dateTo,
            this.selectedLocationId
          )
          .then((response) => {
            const data = response.data;

            if(data.result === OperationResultType.Success) {
              this.$message({
                message: "Capacity added.",
                type: "success"
              });

              this.refetchEvents();
              this.capacityDialogVisible = false;
            } else {
              this.$message({
                message: data.message,
                type: "error",
              });
            }
            this.loading = false;
          });
      }
    },
    addOrEditHoliday() {
      this.loading = true;

      if (this.holidayDetails.id !== null) {
        localCalendarService
          .editItem(this.holidayDetails.id, this.holidayDetails.name)
          .then((response) => {
            const data = response.data;

            if (data.result === OperationResultType.Success) {
              this.$message({
                message: "Holiday updated.",
                type: "success",
              });

              this.refetchEvents();
              this.dialogVisible = false;
            } else {
              this.$message({
                message: data.message,
                type: "error",
              });
            }

            this.loading = false;
          });
      } else {
        localCalendarService
          .addItem(
            this.selectedCalendarId,
            this.holidayDetails.date,
            this.holidayDetails.name
          )
          .then((response) => {
            const data = response.data;

            if (data.result === OperationResultType.Success) {
              this.$message({
                message: "Holiday updated.",
                type: "success",
              });

              this.refetchEvents();
              this.dialogVisible = false;
            } else {
              this.$message({
                message: data.message,
                type: "error",
              });
            }
            this.loading = false;
          });
      }
    },
    deleteHoliday() {
      this.dialogVisible = false;
      this.loading = true;

      localCalendarService
        .deleteItem(this.holidayDetails.id)
        .then((response) => {
          this.$message({
            message: "Holiday deleted.",
            type: "success",
          });

          this.refetchEvents();
          this.loading = false;
        });
    },
    deleteCapacity() {
      this.capacityDialogVisible = false;
      this.loading = true;

      localCalendarService
        .deleteCapacity(this.capacityDetails.id)
        .then((response) => {
          this.$message({
            message: "Capacity deleted.",
            type: "success",
          });

          this.refetchEvents();
          this.loading = false;
        })
    },
    editDefaultValue() {
      this.loading = true;
      localCalendarService
        .editCapacityDefaultValue(this.selectedCalendarId, this.selectedLocationId, parseInt(this.capacityDefaultValue))
        .then((response) => {
          this.$message({
            message: "Capacity default value changed.",
            type: "success",
          });

          this.refetchEvents();
          this.loading = false;
        })
    },
    isEventOnGoingSpecificDate(events, selectDate) {
      for(const event of events) {
        if(event.end === null && selectDate >= event.start) {
          return true;
        }

        if(event.end !== null && selectDate >= event.start && selectDate <= event.end) {
          return true;
        }
      }
      return false;
    }
  },
  computed: {
    dialogTitle() {
      return this.holidayDetails.id === null ? "Add Holiday" : "Edit Holiday";
    },
    capacityTitle() {
      return this.capacityDetails.id === null ? "Add Capacity" : "Edit Capacity";
    },
    calendarIsCapacity() {
      const selectedCalendar = this.calendars.find(c => c.value === this.selectedCalendarId);
      return selectedCalendar && selectedCalendar.text.includes("Capacity");
    }
  }
});
</script>

<style scoped>
.custom-label {
  float: left;
  text-align: right;
  vertical-align: middle;
  line-height: 40px;
  padding: 0px 12px 0px 0px;
}
</style>

<template>
  <div>
    <div>
      <el-dialog
        :visible="visible"
        @update:visible="$emit('update:visible', $event)"
        title="Availability Details"
      >
        <div class="availability-details">
          <el-row>
            <el-col :span="8">
              <i class="el-icon-date"></i>
              {{ date | formatDate }}
            </el-col>
            <el-col :span="5">Available Times</el-col>
            <el-col :span="9">Available Materials</el-col>
          </el-row>
          <el-row v-for="(item, index) in availabilities" :key="index">
            <el-col :span="8">
              <i class="el-icon-success" style="color: forestgreen"></i>
              {{ item.designerName }} ({{ item.locationName }})
            </el-col>
            <el-col :span="5">
              {{ item.start | formatTime }} -
              {{ item.end | formatTime }}
            </el-col>
            <el-col :span="9">{{ item.products }}</el-col>
          </el-row>
        </div>
        <div slot="footer">
          <el-button type="primary" @click="$emit('update:visible', false)"
            >OK</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    date: null,
    availabilities: {
      type: Array,
      default() {
        return [];
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.availability-details .el-row {
  margin-bottom: 10px;
}
</style>

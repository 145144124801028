import UserPermissionSourceModel from "./UserPermissionSourceModel";

export default class UserPermissionModel {
  name: string;
  code: string;
  granted: boolean;
  sources: Array<UserPermissionSourceModel> = [];

  constructor(name: string, code: string, granted: boolean) {
    this.name = name;
    this.code = code;
    this.granted = granted;
  }
}

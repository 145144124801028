import operationType from "../enums/OperationType";

import _ from "lodash";

export default {
  methods: {
    handleAdd() {
      this.formMode = operationType.add;
      this.isDialogVisible = true;

      this.formData = this.editTemplate ? _.cloneDeep(this.addTemplate) : {};
      this.addTemplateStorage = this.editTemplate
        ? _.cloneDeep(this.addTemplate)
        : {};

      _.forEach(this.formData, (value, key) => {
        this.formData[key] = this.addTemplateStorage[key].value;
      });
    },
  },
};

import { HTTP } from "./HttpCommon";

class JobStepService {
  loadJobSteps() {
    return HTTP.get("JobStep/GetRecords");
  }

  addJobStep(item) {
    return HTTP.post("JobStep/Add", item);
  }

  editJobStep(record) {
    return HTTP.put("JobStep/Edit/" + record.id, record);
  }
}

export const jobStepService = new JobStepService();

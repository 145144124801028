<template>
  <div>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :form-options="formOptions"
      :table-options="tableOptions"
      :add-template="template"
      :add-rules="validationRules"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
      @row-remove="onDelete"
      deletable
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";

import _ from "lodash";

import { locationMappingService } from "../../services/locationmapping.service";
import CrudScreen from "../shared/crud/CrudScreen.vue";
import { configurationService } from "../../services/design/configuration.service";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      timeZones: [],
      calendars: [],
      locations: [],
      manufacturingLocations: [],
      columns: [
        "requestDistributionCentre",
        "locationName",
        "timeZoneName",
        "calendarName",
      ],
      formOptions: {
        width: "800px",
        labelWidth: "300px",
      },
      tableOptions: {
        headings: {
          calendarName: "Calendar Name",
          timeZoneName: "Time Zone",
          requestDistributionCentre: "Distribution Center",
          locationName: "Destination Location",
        },
      },
      validationRules: {
        requestDistributionCentre: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
        calendarId: [
          { required: true, message: "Calendar is required", trigger: "blur" },
        ],
        timeZoneId: [
          { required: true, message: "Time Zone is required", trigger: "blur" },
        ],
        destinationLocation: [
          {
            required: true,
            message: "Destination Location is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    template() {
      return {
        id: {
          component: {
            show: false,
          },
        },
        requestDistributionCentre: {
          title: "Distribution Center",
        },
        destinationLocation: {
          title: "Destination Location",
          component: {
            name: "el-select",
            filterable: true,
            options: this.locations.map((l) => ({
              value: l.value,
              label: l.label,
            })),
          },
        },
        timeZoneId: {
          title: "Time Zone",
          component: {
            name: "el-select",
            filterable: true,
            options: this.timeZones.map((tz) => ({
              value: tz.ianaName,
              label: tz.name,
            })),
          },
        },
        calendarId: {
          title: "Calendar",
          component: {
            name: "el-select",
            filterable: true,
            options: this.calendars.map((c) => ({
              value: c.id,
              label: c.name,
            })),
          },
        },
      };
    },
  },
  methods: {
    getTimeZoneName(item, timeZones){
      var timeZone = timeZones.find(
             (tz) => tz.id == item.timeZoneId
           );
      if(timeZone == undefined) {
        return '';
      }
      return timeZone.name;
    },
    getRecords() {
      configurationService
        .getAllTimezones()
        .then((response) => (this.timeZones = response.data));

      locationMappingService.loadLocationMappings().then((data) => {
        this.calendars = data.data.calendars;
        this.locations = data.data.locations;
        this.records = data.data.records.map((item) => ({
          ...item,
          timeZoneName: this.getTimeZoneName(item, data.data.timeZones),
          calendarName: this.calendars.find((c) => c.id == item.calendarId)
            .name,
          locationName: this.locations.find(
            (l) => l.value == item.destinationLocation
          ).label,
        }));
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      locationMappingService.addLocationMapping(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      locationMappingService.editLocationMapping(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onDelete(item, callback) {
      this.loading = true;
      locationMappingService.deleteLocationMapping(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

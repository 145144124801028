<template>
  <div v-on-clickaway="closeEditMode">
    <div v-if="editMode" style="display: flex">
      <el-input
        ref="editableInput"
        placeholder=""
        style="margin-right: 8px"
        v-model="text"
      ></el-input>
      <el-button
        class="share-button"
        icon="el-icon-check"
        type="primary"
        v-on:click="applyChanges"
      ></el-button>
      <el-button
        class="share-button"
        icon="el-icon-close"
        type="primary"
        v-on:click="closeEditMode"
      ></el-button>
    </div>
    <div v-else>
      <div style="font-size: 12px">
        <div v-if="editable">
          <el-button
            v-if="text !== null && text.length > 0"
            type="text"
            v-on:click="editMode = true"
            >{{ text }}</el-button
          >
          <el-button
            v-else
            type="text"
            v-on:click="editMode = true"
            style="color: #dd1144"
            >{{ emptyFieldPlaceHolderText }}</el-button
          >
        </div>
        <div v-else>
          <div v-if="text !== null && text.length > 0">{{ text }}</div>
          <div v-else>{{ emptyFieldPlaceHolderText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  data() {
    return {
      editMode: false,
      text: this.value,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    emptyFieldPlaceHolderText: {
      type: String,
      default: "Empty",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: function () {
      this.text = this.value;
    },
  },
  methods: {
    applyChanges() {
      if (!this.allowEmpty && this.text === "") {
        this.text = this.value;
        this.editMode = false;
        this.$message({
          message: "Empty value not allowed",
          type: "error",
          duration: 4000,
        });
      } else {
        this.$emit("input", this.$refs.editableInput.value);
        this.editMode = false;
      }
    },
    closeEditMode: function () {
      this.text = this.value;
      this.editMode = false;
    },
  },
};
</script>

export default class MilestoneLeadTimeGroupItem {
  id: number = null;
  requestTypeId: number = null;
  productTypeId: number = null;
  distributionCenterId: number = null;
  manufacturingLocationId: number = null;
  schedulingStrategyId: number = null;
  serviceLevelDays = 1;
  ladTimeGroupId: number = null;
  manufacturingAtRiskDays = 1;
  isDefault = false;
}

import Vue from "vue";
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "../element-variables.scss";

Vue.use(Element, { locale });

const customMessage = function (options) {
  if (typeof options !== "string" && options.showClose === undefined) {
    return Element.Message({
      ...options,
      showClose: true,
    });
  }

  return Element.Message(options);
};

const ElementPlugin = {
  install: function (Vue, options) {
    Vue.prototype.$message = customMessage;
    Vue.prototype.$message.success = (data) => {
      if (typeof data === "string") {
        customMessage({ message: data, type: "success" });
      } else {
        customMessage({ ...data, type: "success" });
      }
    };
    Vue.prototype.$message.warning = (data) => {
      if (typeof data === "string") {
        customMessage({ message: data, type: "warning" });
      } else {
        customMessage({ ...data, type: "warning" });
      }
    };
    Vue.prototype.$message.info = (data) => {
      if (typeof data === "string") {
        customMessage({ message: data, type: "info" });
      } else {
        customMessage({ ...data, type: "info" });
      }
    };
    Vue.prototype.$message.error = (data) => {
      if (typeof data === "string") {
        customMessage({ message: data, type: "error" });
      } else {
        customMessage({ ...data, type: "error" });
      }
    };
  },
};

Vue.use(ElementPlugin);

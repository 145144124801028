<template>
  <div>
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">PRODUCT DETAILS</h1>
      </div>
      <div class="fright" v-if="allProducts">
        <router-link
          :to="previousProductUrl"
          :event="previousProduct === null ? '' : 'click'"
        >
          <el-button
            :disabled="previousProduct === null"
            icon="el-icon-arrow-up"
            round
            size="small"
            :title="previousProductText"
          ></el-button>
        </router-link>
        <span>{{ currentItemIndex + 1 }} of {{ allProducts.length }}</span>
        <router-link
          :to="nextProductUrl"
          :event="nextProduct === null ? '' : 'click'"
        >
          <el-button
            :disabled="nextProduct === null"
            icon="el-icon-arrow-down"
            round
            size="small"
            :title="nextProductText"
          ></el-button>
        </router-link>
      </div>
      <div class="fright">
        <router-link
          :to="'/Documentation/Product' + queryString"
          class="el-button el-button--text"
          style="padding: 10px 0px 0px 0px"
          >Go to products list</router-link
        >
      </div>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <product-information
          :product-information="productInformation"
          @reload-data="loadData"
        ></product-information>
      </el-col>
      <el-col :span="12">
        <product-eifu-data :e-ifu-data="eIFUData"></product-eifu-data>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <associated-document-list
          :associated-documents="associatedDocuments"
          :product-versions="productVersions"
          @reload-data="loadData"
        ></associated-document-list>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <product-versions-list
          :product-versions="productVersions"
          @reload-data="loadData"
        ></product-versions-list>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <changes-list :changes="changes"></changes-list>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import qs from "qs";

import { productService } from "../../../services/edoc/product.service";
import ProductInformation from "./ProductInformation.vue";
import ProductEifuData from "./ProductEifuData.vue";
import AssociatedDocumentList from "./AssociatedDocumentList.vue";
import ProductVersionsList from "./ProductVersionsList.vue";
import ChangesList from "../shared/ChangesList.vue";

import ProductInformationModel from "../../../models/edoc/ProductInformationModel";
import ProductEIFUDataModel from "../../../models/edoc/ProductEIFUDataModel";
import AssociatedDocumentListModel from "../../../models/edoc/AssociatedDocumentListModel";
import ProductVersionsListModel from "../../../models/edoc/ProductVersionsListModel";

import { storeProductId } from "../../../store/modules/mutationTypes";
import { showLoading, hideLoading } from "../../../store/mutation-types";

export default Vue.extend({
  components: {
    ProductInformation,
    ProductEifuData,
    AssociatedDocumentList,
    ProductVersionsList,
    ChangesList,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      productInformation: new ProductInformationModel(),
      eIFUData: new ProductEIFUDataModel(),
      associatedDocuments: new AssociatedDocumentListModel(),
      productVersions: new ProductVersionsListModel(),
      changes: [],
      allProducts: null,
      loadedQuery: null,
    };
  },
  computed: {
    queryString: function () {
      var result = qs.stringify(this.$route.query);

      return result ? "?" + result : "";
    },
    previousProduct() {
      let currentItem = this.allProducts.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return (
        this.allProducts[this.allProducts.indexOf(currentItem) - 1] || null
      );
    },
    previousProductUrl() {
      return this.previousProduct !== null
        ? "/Documentation/Product/" + this.previousProduct.id + this.queryString
        : "";
    },
    previousProductText() {
      return this.previousProduct !== null
        ? "Previous Product: " + this.previousProduct.gtin
        : "Not available";
    },
    nextProduct() {
      let currentItem = this.allProducts.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return (
        this.allProducts[this.allProducts.indexOf(currentItem) + 1] || null
      );
    },
    nextProductUrl() {
      return this.nextProduct !== null
        ? "/Documentation/Product/" + this.nextProduct.id + this.queryString
        : "";
    },
    nextProductText() {
      return this.nextProduct !== null
        ? "Next Product: " + this.nextProduct.gtin
        : "Not available";
    },
    currentItemIndex() {
      let currentItem = this.allProducts.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return this.allProducts.indexOf(currentItem);
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapMutations("product", [storeProductId]),
    loadData() {
      this.showLoading();
      this.storeProductId(this.$route.params.id);

      this.getProduct().then(() => {
        this.hideLoading();
      });

      if (this.loadedQuery !== this.queryString) {
        this.getAllProducts();
      }
    },
    getProduct() {
      var productPromise = productService.getProduct(
        this.$store.state.product.productId
      );
      productPromise.then((response) => {
        this.productInformation = new ProductInformationModel(response.data);
        this.eIFUData = new ProductEIFUDataModel(response.data);
        this.associatedDocuments = new AssociatedDocumentListModel(
          response.data
        );
        this.productVersions = new ProductVersionsListModel(response.data);
        this.changes = response.data.changes;
      });

      return productPromise;
    },
    getAllProducts() {
      this.loadedQuery = this.queryString;

      productService
        .getProducts(qs.parse(this.$route.query))
        .then((response) => {
          this.allProducts = response.data.data;
        });
    },
  },
  watch: {
    "$route.params.id": function (id) {
      this.loadData();
    },
  },
});
</script>

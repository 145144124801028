var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('notifications',{staticStyle:{"width":"400px","top":"110px","right":"40px"},attrs:{"group":"custom-template","position":"top right"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('div',{staticClass:"el-notification",staticStyle:{"width":"400px"}},[_c('div',{staticClass:"el-notification__closeBtn el-icon-close",staticStyle:{"position":"absolute","right":"20px","top":"initial"},on:{"click":function($event){return _vm.closeNotification(props)}}}),_c('i',{staticClass:"el-el-notification__icon",class:{
            'el-icon-info':
              props.item.data.messageType == _vm.NotificationMessageType.Info,
            'el-icon-warning':
              props.item.data.messageType == _vm.NotificationMessageType.Warning,
            'el-icon-error':
              props.item.data.messageType == _vm.NotificationMessageType.Error,
          }}),_c('div',{staticClass:"el-notification__group is-with-icon"},[_c('h2',{staticClass:"el-notification__title"},[_vm._v(_vm._s(props.item.title))]),_c('div',{staticClass:"el-notification__content"},[_c('div',{domProps:{"innerHTML":_vm._s(props.item.text)}}),_c('p',{staticStyle:{"font-size":"10px"}},[_c('el-checkbox',{model:{value:(props.item.isAcknowledged),callback:function ($$v) {_vm.$set(props.item, "isAcknowledged", $$v)},expression:"props.item.isAcknowledged"}}),_vm._v(" I acknowledge ")],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Availability from "./Availability";
import { AvailabilityType } from "../../enums/design/AvailabilityType";
import moment from "moment";

export default class CalendarAvailability extends Availability {
  startDate: moment.Moment = moment().startOf("day");
  endDate: moment.Moment = moment().startOf("day");
  days: AvailabilityCalendarDay[] = [];
  saveSchedule = false;
  scheduleName: string = null;

  constructor(model: any, timezone: string) {
    super(AvailabilityType.Recurring);

    this.days = moment.weekdays().map((weekDay) => {
      const calendarDay = new AvailabilityCalendarDay();
      calendarDay.weekDay = weekDay;
      return calendarDay;
    });

    if (model != null) {
      this.id = model.id;

      this.startDate = moment(model.startDate);
      this.endDate = moment(model.endDate);

      if (model.days != null) {
        model.days.forEach((modelDay) => {
          const day = this.days.find(
            (item) => item.weekDay === modelDay.weekDay
          );

          day.startTime = modelDay.startTime;
          day.endTime = modelDay.endTime;
        });
      }
    }
  }
}

class AvailabilityCalendarDay {
  startTime: string = null;
  endTime: string = null;
  weekDay: string;

  constructor(model: any = null) {
    if (model != null) {
      this.startTime = model.startTime;
      this.endTime = model.endTime;
      this.weekDay = model.weekDay;
    }
  }
}

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">PRODUCT EXPORT</h1>
      </div>
    </el-row>
    <el-row>
      <el-form
        :model="item"
        :inline="true"
        :rules="rules"
        ref="exportForm"
        label-position="top"
        :key="'form' + item.mode"
      >
        <el-form-item label="Export Mode">
          <el-radio
            v-model="currentMode"
            :label="productExportMode.Manual"
            class="vertical-option"
            >Manual</el-radio
          >
          <el-radio
            v-model="currentMode"
            :label="productExportMode.Bulk"
            class="vertical-option"
            >Load List</el-radio
          >
          <el-radio
            v-model="currentMode"
            :label="productExportMode.DocumentBased"
            class="vertical-option"
            >By Document</el-radio
          >
        </el-form-item>
        <el-form-item label="Export Profile">
          <el-radio
            v-model="item.profile"
            :label="productExportProfile.Manual"
            class="vertical-option"
            style="display: none"
            >Manual</el-radio
          >
          <el-radio
            v-model="item.profile"
            :label="productExportProfile.CMF"
            class="vertical-option"
            >CMF</el-radio
          >
          <el-radio
            v-model="item.profile"
            :label="productExportProfile.AGT"
            class="vertical-option"
            >AGT</el-radio
          >
        </el-form-item>
        <template v-if="currentMode === productExportMode.Manual">
          <el-form-item label="Products">
            <el-select
              v-model="productGtins"
              filterable
              remote
              collapse-tags
              multiple
              reserve-keyword
              placeholder="None selected"
              :remote-method="searchProductGtins"
              class="product-select"
            >
              <el-option
                v-for="item in availableProductGtins"
                :key="item.value"
                :label="item.label"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="currentMode === productExportMode.Bulk">
          <el-form-item label="Product GTIN's or Catalog Numbers" prop="bulk">
            <el-input
              type="textarea"
              :autosize="{ minRows: 10 }"
              v-model="bulkData"
            ></el-input>
            <div style="margin-top: 5px">
              <el-button @click="$refs.fileInput.click()"
                >Load Product GTIN's from import file</el-button
              >
              <input type="file" @change="onFileChange" ref="fileInput" />
            </div>
            <el-button style="margin-top: 5px" @click="loadList"
              >Load list</el-button
            >
          </el-form-item>
        </template>
        <template v-if="currentMode === productExportMode.DocumentBased">
          <el-form-item label="Documents" prop="chosenDocumentIds">
            <el-select
              v-model="chosenDocumentIds"
              filterable
              remote
              multiple
              reserve-keyword
              placeholder="None selected"
              :remote-method="searchDocumentNumbers"
              class="document-select"
            >
              <el-option
                v-for="item in availableDocumentNumbers"
                :key="item.id"
                :label="item.number"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item label="Products" prop="products" class="tree-form-item">
          <el-tree
            :data="treeData"
            show-checkbox
            node-key="id"
            ref="tree"
            :props="productProps"
          ></el-tree>
        </el-form-item>
        <el-form-item>
          <template slot="label">&nbsp;</template>
          <el-button @click="onSubmit">Export</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { productExportService } from "../../../services/edoc/productExport.service";
import { ProductExportProfile } from "../../../models/edoc/ProductExportProfile";
import { ProductExportMode } from "../../../models/edoc/ProductExportMode";

export default Vue.extend({
  data() {
    return {
      productExportMode: ProductExportMode,
      productExportProfile: ProductExportProfile,
      currentMode: ProductExportMode.Manual,
      bulkData: null,
      chosenDocumentIds: [],
      loading: false,
      item: {
        products: [],
        profile: ProductExportProfile.CMF,
      },
      rules: {
        products: [
          {
            required: true,
            message: "Choose at least one product",
            trigger: "blur",
          },
        ],
      },
      productGtins: [],
      availableProductGtins: [],
      availableDocumentNumbers: [],
      treeData: [],
      productProps: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },
    };
  },
  computed: {
    isProfileNotManual() {
      return this.item.profile !== ProductExportProfile.Manual;
    },
  },
  methods: {
    searchProductGtins: function (query = "") {
      productExportService.getFilteredProductGtins(query).then((response) => {
        this.availableProductGtins = response.data.map((product) => {
          return { label: product.gtin, value: product.id };
        });
      });
    },
    searchDocumentNumbers: function (query = "") {
      productExportService
        .getFilteredDocumentNumbers(query)
        .then((response) => {
          this.availableDocumentNumbers = response.data;
        });
    },
    handleAdd(product) {
      this.loading = true;
      productExportService
        .getProductExportItem(product.value)
        .then((response) => {
          this.handleTreeAdd([response.data]);

          this.loading = false;
        });
    },
    handleTreeAdd(items) {
      let newItems = items.filter((item) => {
        var index = this.treeData.findIndex(
          (treeNode) => treeNode.label === item.label
        );

        if (index != -1) {
          return false;
        }

        return true;
      });

      this.treeData.push(...newItems);
      this.$nextTick(() => {
        newItems.forEach((item) => {
          const checked = !(item.children == null && item.disabled);
          this.$refs.tree.setChecked(item.id, checked, true);
        });
      });
    },
    handleTreeDelete(product) {
      var index = this.treeData.findIndex(
        (treeNode) => treeNode.id === product.id
      );

      if (index != -1) {
        this.treeData.splice(index, 1);
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];

      if (file != null) {
        this.loading = true;
        productExportService.extractGtins(file).then((response) => {
          this.bulkData = response.data.join("\n");
          this.loading = false;
        });
      }
    },
    loadList() {
      if (!this.bulkData) {
        return;
      }

      this.loading = true;
      productExportService
        .getProductExportItems(this.bulkData)
        .then((response) => {
          this.treeData = [];
          this.item.products = [];
          this.handleTreeAdd(response.data);
          this.loading = false;
        });
    },
    onSubmit() {
      this.item.products = this.$refs.tree.getCheckedNodes(true, false);

      this.$refs.exportForm.validate((valid) => {
        if (valid) {
          this.exportProducts();
          this.exportProductsAsZip();
        }
      });
    },
    exportProducts() {
      this.loading = true;

      productExportService.exportProducts(this.item).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `products-bulk-import.txt`);
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },
    exportProductsAsZip() {
      this.loading = true;

      productExportService.exportProductsAsZip(this.item).then((response) => {
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute('download', `products-bulk-import.zip`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        this.loading = false;
      });
    }
  },
  watch: {
    productGtins: function (newVal, oldVal) {
      if (newVal.length === oldVal.length) {
        return;
      }

      var isDelete = oldVal != null && oldVal.length > newVal.length;

      if (isDelete) {
        for (var i in oldVal) {
          if (!newVal.includes(oldVal[i])) {
            this.handleTreeDelete(oldVal[i]);
            break;
          }
        }
      } else {
        this.handleAdd(newVal[newVal.length - 1]);
      }
    },
    chosenDocumentIds: function (newVal, oldVal) {
      if (newVal.length || oldVal.length) {
        var isDelete = oldVal != null && oldVal.length > newVal.length;
        var isAdd = oldVal != null && oldVal.length < newVal.length;

        this.loading = true;
        productExportService.getAssociatedProducts(newVal).then((response) => {
          if (isAdd) {
            this.handleTreeAdd(response.data);
          }

          if (isDelete) {
            const newProductIds = response.data.map((item) => item.id);
            const oldProductIds = this.treeData.map((item) => item.id);
            const removedIds = oldProductIds.filter(
              (item) => !newProductIds.includes(item)
            );

            for (const id of removedIds) {
              const item = this.treeData.find((item) => item.id === id);
              this.handleTreeDelete(item);
            }
          }

          this.loading = false;
        });
      }
    },
    currentMode: function () {
      this.productGtins = [];
      this.availableProductGtins = [];
      this.chosenDocumentIds = [];
      this.availableDocumentNumbers = [];
      this.bulkData = null;
      this.treeData = [];
      this.item.products = [];
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
  },
});
</script>
<style lang="scss" scoped>
input[type="file"] {
  display: none;
}

.tree-form-item {
  width: 300px;
  margin-left: 20px;
}

.vertical-option {
  display: block;
  line-height: normal;
  margin-right: 50px;
}

.product-select {
  width: 350px;
}
</style>

import { ManufacturingCycleTabType } from "../../enums/enums";

export default class ManufacturingCycleTabsModel {
  name: string;
  id: number;
  notificationsNumber: number = null;
  type: ManufacturingCycleTabType;

  constructor(model: any, type: ManufacturingCycleTabType) {
    if (model) {
      this.name = model.name;
      this.id = model.id;
      this.type = type;
    }
  }
}

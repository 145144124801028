<template>
  <div>
    <el-container>
      <el-header>
        <h1 class="details-header">LIST OF IMPORTS</h1>
      </el-header>
      <el-main>
        <v-server-table
          style="margin-top: 15px"
          ref="table"
          :columns="columns"
          :options="options"
        >
          <div slot-scope="props" slot="importDate">
            {{ props.row.importDate | formatDateTimeOffset }}
          </div>
          <div slot-scope="props" slot="shouldUpdateExistingPlans">
            {{ props.row.shouldUpdateExistingPlans ? "Yes" : "No" }}
          </div>
          <div slot-scope="props" slot="isStaged">
            {{ props.row.isStaged ? "Yes" : "No" }}
          </div>
          <div slot-scope="props" slot="isImported">
            {{ props.row.isImported ? "Yes" : "No" }}
          </div>
          <router-link
            :to="'/ImportRequest/Details/' + props.row.id"
            slot-scope="props"
            slot="actions"
            >View</router-link
          >
        </v-server-table>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Vue from "vue";
import { HTTP } from "@/services/HttpCommon";

export default Vue.extend({
  data() {
    return {
      loading: true,
      records: [],
      columns: [
        "importDate",
        "shouldUpdateExistingPlans",
        "isStaged",
        "isImported",
        "userSymbol",
        "actions",
      ],
      options: {
        headings: {
          importDate: "Import Date",
          shouldUpdateExistingPlans: "Update Plans",
          isStaged: "Is Staged",
          isImported: "Is Imported",
          userSymbol: "User Symbol",
          actions: "",
        },
        requestFunction: (data) => {
          return HTTP.post("ImportRequest/GetImportedRequests", data).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
        responseAdapter: function (response) {
          return {
            data: response.data.items,
            count: response.data.count,
          };
        },
        filterable: false,
        sortable: [],
      },
    };
  },
});
</script>

export default class CommentModel {
  id: number = null;
  modifiedDate: string = null;
  userSymbol: string = null;
  userId: number = null;
  text = "";

  constructor(data: any) {
    if (data) {
      this.id = data.id;
      this.modifiedDate = data.modifiedDate;
      this.userSymbol = data.userSymbol;
      this.userId = data.userId;
      this.text = data.text;
    }
  }
}

<template>
  <div v-loading.fullscreen.lock="loading">
    <h1 class="details-header">Session planner</h1>
    <el-row>
      <el-form label-position="top" inline>
        <el-form-item label="Plan Id">
          <el-select
            v-model="chosenPlanId"
            style="width: 100%"
            filterable
            remote
            reserve-keyword
            placeholder="Search for Plan Id"
            :remote-method="searchPlans"
            automatic-dropdown
            @change="handlePlanIdSelect"
          >
            <el-option
              v-for="item in availableRequestIds"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Product type">
          <el-select
            v-model="chosenProductType"
            clearable
            @clear="chosenProductType = null"
            @change="productTypeChanged"
          >
            <el-option
              v-for="productType in productTypes"
              :key="productType.id"
              :label="productType.name"
              :value="productType.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Priority">
          <el-checkbox
            @change="updateDefaultTimes"
            :disabled="chosenPlanId !== null"
            v-model="priority"
          ></el-checkbox>
        </el-form-item>
        <el-form-item label="Preparation time">
          <el-select v-model="preparationDays">
            <el-option
              v-for="day in availableDays"
              :key="day.key"
              :label="day.value"
              :value="day.key"
            ></el-option>
          </el-select>
          <el-select v-model="preparationHours">
            <el-option
              v-for="hour in availableHours"
              :key="hour.key"
              :label="hour.value"
              :value="hour.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Design Session time">
          <el-select v-model="sessionHours">
            <el-option
              v-for="hour in availableHours"
              :key="hour.key"
              :label="hour.value"
              :value="hour.key"
            ></el-option>
          </el-select>
          <el-select v-model="sessionMinutes">
            <el-option
              v-for="minute in availableMinutes"
              :key="minute.key"
              :label="minute.value"
              :value="minute.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Post processing time">
          <el-select v-model="postProcessingDays">
            <el-option
              v-for="day in availableDays"
              :key="day.key"
              :label="day.value"
              :value="day.key"
            ></el-option>
          </el-select>
          <el-select v-model="postProcessingHours">
            <el-option
              v-for="hour in availableHours"
              :key="hour.key"
              :label="hour.value"
              :value="hour.key"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-form inline>
        <el-form-item>
          <el-button
            type="primary"
            :disabled="chosenPlanId === null && chosenProductType === null"
            :loading="areFiltersLoading"
            @click="applyFilters"
            >Apply filters</el-button
          ></el-form-item>
        <el-form-item label="View timezone">
          <el-select v-model="chosenTimezone" @change="updateTimezone">
            <el-option
              v-for="timezone in timezones"
              :key="timezone.ianaName"
              :label="timezone.name"
              :value="timezone.ianaName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row v-if="!filtersApplied" style="color: red; text-align: center"
      >Select Plan Id or Product Type and click 'Apply filters' button</el-row
    >
    <el-row v-if="filtersApplied">
      <el-col :span="12">
        <el-radio-group
          v-model="timeFormat24h"
          size="small"
          @change="changeTimeFormat"
        >
          <el-radio-button :label="true">24H</el-radio-button>
          <el-radio-button :label="false">12H</el-radio-button>
        </el-radio-group>
      </el-col>
      <div class="fright">
        <el-checkbox v-model="showWeekends" @change="changeWeekendsMode"
          >Show weekends</el-checkbox
        >
      </div>
    </el-row>
    <FullCalendar
      v-if="filtersApplied"
      ref="sessionPlannerCalendar"
      :options="calendarOptions"
    ></FullCalendar>
    <design-session-modal
      ref="designSessionModal"
      :model.sync="designSessionModel"
      :visible.sync="sessionModalVisible"
      @added="applyFilters"
      @updated="applyFilters"
    ></design-session-modal>
    <design-session-details
      ref="designSessionDetailsModal"
      :visible.sync="sessionDetailsModalVisible"
      :loading.sync="loading"
      :editable="false"
      @added="applyFilters"
      @updated="applyFilters"
      @deleted="applyFilters"
    ></design-session-details>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import "moment-timezone";
import "moment-duration-format";
import availableTimes from "../../mixins/design/availableTimes";

import FullCalendar from "@fullcalendar/vue";
import { DateTime, Duration } from "luxon";

import calendarOptions from "../../mixins/design/calendarOptions";

import DesignSessionModal from "./DesignSessionModal";
import DesignSessionDetails from "./DesignSessionDetails";

import DesignerDetails from "../../models/design/DesignerDetails";
import DesignSession from "../../models/design/DesignSession";
import DesignSessionInfo from "../../models/design/DesignSessionInfo";

import { designerService } from "../../services/design/designer.service";
import { sessionTimesService } from "../../services/design/sessiontimes.service";
import { generalSessionPropertiesService } from "../../services/design/generalsessionproperties.service";
import { fulfilmentPlanListService } from "../../services/fulfilmentplanlist.service";
import { scheduleService } from "../../services/design/schedule.service";
import { configurationService } from "../../services/design/configuration.service";
import { DesignerCalendarItemType } from "../../enums/design/DesignerCalendarItemType";

export default Vue.extend({
  mixins: [calendarOptions, availableTimes],
  components: {
    FullCalendar,
    DesignSessionModal,
    DesignSessionDetails,
  },
  created() {
    configurationService
      .getProducts()
      .then((response) => (this.productTypes = response.data));
    generalSessionPropertiesService
      .getGeneralSessionProperties()
      .then((response) => (this.generalSessionProperties = response.data));
    configurationService.getTimezones().then((response) => {
      response.data.push({ name: "Local", ianaName: moment.tz.guess() });
      this.timezones = response.data;
    });
  },
  data() {
    return {
      DesignerCalendarItemType,
      availableRequestIds: [],
      loading: false,
      areFiltersLoading: false,
      sessionModalVisible: false,
      sessionDetailsModalVisible: false,
      designSessionModel: new DesignSession(),
      filtersApplied: false,
      productTypes: [],
      users: [],
      generalSessionProperties: [],
      chosenPlanId: null,
      chosenProductType: null,
      priority: false,
      preparationTime: Duration.fromMillis(0),
      designSessionTime: Duration.fromObject({ hours: 2 }),
      postProcessingTime: Duration.fromMillis(0),
      timeFormat24h: true,
      showWeekends: false,
      timezones: [],
      chosenTimezone: moment.tz.guess(),
      calendarOptions: {
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "",
        },
        validRange: {
          start: DateTime.utc().toSQLDate(),
        },
        timeZone: moment.tz.guess(),
        eventClick: (eventClickInfo) => {
          if (!this.userPermissions.CanManageDesignSessions) {
            return;
          }

          const start = DateTime.fromISO(eventClickInfo.event.startStr)
            .toUTC()
            .toISO();
          const event = eventClickInfo.event.extendedProps;

          if (event.type === DesignerCalendarItemType.Availability) {
            this.openCreateSessionModal(start);
          } else {
            this.$refs.designSessionDetailsModal.open(event.id);
          }
        },
        eventSources: [
          {
            events: (fetchInfo, successCallback, failureCallback) => {
              const start = DateTime.fromISO(fetchInfo.startStr)
                .toUTC()
                .toISO();
              const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();

              if (this.chosenPlanId !== null) {
                sessionTimesService
                  .getAvailableTimesForPlan(
                    start,
                    end,
                    this.chosenPlanId,
                    this.preparationTime,
                    this.designSessionTime,
                    this.postProcessingTime
                  )
                  .then((response) => {
                    this.areFiltersLoading = false;
                    const slots = response.data.availableSlots.map((s) => ({
                      title: "Available session time",
                      start: s.dateTimeFrom,
                      end: s.dateTimeTo,
                      extendedProps: {
                        type: DesignerCalendarItemType.Availability,
                      },
                    }));

                    successCallback(slots);
                  })
                  .catch((e) => {
                    this.handleErrorResponse(e);
                    successCallback([]);
                  });
              } else if (this.chosenProductType !== null) {
                sessionTimesService
                  .getAvailableTimes(
                    start,
                    end,
                    this.chosenProductType,
                    this.priority,
                    this.preparationTime,
                    this.designSessionTime,
                    this.postProcessingTime
                  )
                  .then((response) => {
                    this.areFiltersLoading = false;
                    const slots = response.data.availableSlots.map((s) => ({
                      title: "Available session time",
                      start: s.dateTimeFrom,
                      end: s.dateTimeTo,
                      extendedProps: {
                        type: DesignerCalendarItemType.Availability,
                      },
                    }));

                    successCallback(slots);
                  })
                  .catch((e) => {
                    this.handleErrorResponse(e);
                    successCallback([]);
                  });
              } else {
                successCallback([]);
              }
            },
            color: "#008000",
          },
          {
            events: (fetchInfo, successCallback, failureCallback) => {
              const start = DateTime.fromISO(fetchInfo.startStr)
                .toUTC()
                .toISO();
              const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();

              scheduleService.getDesignSessions(start, end).then((response) => {
                const slots = response.data.map((s) => ({
                  title: "Session",
                  start: s.startDate,
                  end: s.endDate,
                  extendedProps: {
                    id: s.id,
                    type: s.designSessionItemType,
                  },
                }));

                successCallback(slots);
              });
            },
            color: "#990000",
          },
          {
            events: (fetchInfo, successCallback, failureCallback) => {
              const start = DateTime.fromISO(fetchInfo.startStr)
                .toUTC()
                .toISO();
              const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();

              scheduleService.getPreReservedDesignSessions(start, end).then((response) => {
                const slots = response.data.map((s) => ({
                  title: "Pre-reserved Session",
                  start: s.startDate,
                  end: s.endDate,
                  extendedProps: {
                    id: s.id,
                    type: s.designSessionItemType,
                  },
                }));

                successCallback(slots);
              });
            },
            color: "#2D66EB",
          },
        ],
      },
    };
  },
  methods: {
    handleErrorResponse(error) {
      const data = error.response.data;
      const errorCode = data.errorCode;
      if (errorCode === "NOT_CONFIGURED") {
        this.$message({
          message: "Session Properties not configured.",
          type: "error",
        });
      }
    },
    openCreateSessionModal(startDate) {
      this.designSessionModel = new DesignSession();
      this.designSessionModel.preparationTime = moment.duration(
        this.preparationTime.toISO()
      );
      this.designSessionModel.sessionTime = moment.duration(
        this.designSessionTime.toISO()
      );
      this.designSessionModel.postProcessingTime = moment.duration(
        this.postProcessingTime.toISO()
      );
      this.designSessionModel.requestId = this.chosenPlanId;
      this.designSessionModel.startDate = moment.tz(
        startDate,
        moment.tz.guess()
      );

      this.sessionModalVisible = true;

      this.$nextTick(() => {
        this.$refs.designSessionModal.findDesigners();
      });
    },
    applyFilters() {
      const isCalendarVisible = this.filtersApplied;
      this.filtersApplied = true;
      this.areFiltersLoading = true;
      if (isCalendarVisible) {
        this.$refs.sessionPlannerCalendar.getApi().refetchEvents();
      }
    },
    productTypeChanged() {
      this.chosenPlanId = null;
      this.updateDefaultTimes();
    },
    updateDefaultTimes() {
      if (this.chosenPlanId !== null) {
        generalSessionPropertiesService
          .getDefaultGeneralSessionProperties(this.chosenPlanId)
          .then((response) => {
            const defaultProperties = response.data;

            this.preparationTime = Duration.fromISO(
              moment.duration(defaultProperties.preparationTime).toISOString()
            );
            this.postProcessingTime = Duration.fromISO(
              moment
                .duration(defaultProperties.postProcessingTime)
                .toISOString()
            );
            this.designSessionTime = Duration.fromISO(
              moment.duration(defaultProperties.designSessionTime).toISOString()
            );
            this.chosenProductType = defaultProperties.productTypeId;
            this.priority = defaultProperties.priority;
          });
        return;
      }

      if (this.chosenPlanId === null && this.chosenProductType !== null) {
        const defaultProperties = this.generalSessionProperties.find(
          (p) =>
            p.productTypeId === this.chosenProductType &&
            p.priority === this.priority
        );

        if (defaultProperties !== undefined) {
          this.preparationTime = Duration.fromISO(
            moment.duration(defaultProperties.preparationTime).toISOString()
          );
          this.postProcessingTime = Duration.fromISO(
            moment.duration(defaultProperties.postProcessingTime).toISOString()
          );
          this.designSessionTime = Duration.fromISO(
            moment.duration(defaultProperties.designSessionTime).toISOString()
          );

          return;
        }
      }

      this.preparationTime = Duration.fromMillis(0);
      this.postProcessingTime = Duration.fromMillis(0);
      this.designSessionTime = Duration.fromObject({ hours: 2 });
    },
    searchPlans(queryString) {
      fulfilmentPlanListService.filterRequests(queryString).then((response) => {
        this.availableRequestIds = response.data.data.map((item) => ({
          value: item,
          label: item,
        }));
      });
    },
    handlePlanIdSelect(item) {
      this.chosenPlanId = item;
      this.updateDefaultTimes();
    },
    changeTimeFormat() {
      this.calendarOptions.eventTimeFormat = {
        ...this.calendarOptions.eventTimeFormat,
        hour12: !this.timeFormat24h,
      };

      this.calendarOptions.slotLabelFormat = {
        ...this.calendarOptions.slotLabelFormat,
        hour12: !this.timeFormat24h,
      };
    },
    changeWeekendsMode() {
      this.calendarOptions.weekends = this.showWeekends;
    },
    updateTimezone(timezone) {
      moment.tz.setDefault(timezone);
      this.calendarOptions.timeZone = timezone;
    },
  },
  computed: {
    ...mapState("permissions", ["userPermissions"]),
    preparationDays: {
      get: function () {
        return Math.floor(this.preparationTime.as("hours") / 8);
      },
      set: function (newValue) {
        const hours = this.preparationTime.as("hours") % 8;
        this.preparationTime = Duration.fromObject({
          hours: newValue * 8 + hours,
        });
      },
    },
    preparationHours: {
      get: function () {
        return this.preparationTime.as("hours") % 8;
      },
      set: function (newValue) {
        const days = Math.floor(this.preparationTime.as("hours") / 8);
        this.preparationTime = Duration.fromObject({
          hours: days * 8 + newValue,
        });
      },
    },
    sessionHours: {
      get: function () {
        return this.designSessionTime.hours;
      },
      set: function (newValue) {
        this.designSessionTime = Duration.fromObject({
          hours: newValue,
          minutes: this.designSessionTime.minutes,
        });
      },
    },
    sessionMinutes: {
      get: function () {
        return this.designSessionTime.minutes;
      },
      set: function (newValue) {
        this.designSessionTime = Duration.fromObject({
          hours: this.designSessionTime.hours,
          minutes: newValue,
        });
      },
    },
    postProcessingDays: {
      get: function () {
        return Math.floor(this.postProcessingTime.as("hours") / 8);
      },
      set: function (newValue) {
        const hours = this.postProcessingTime.as("hours") % 8;

        this.postProcessingTime = Duration.fromObject({
          hours: newValue * 8 + hours,
        });
      },
    },
    postProcessingHours: {
      get: function () {
        return this.postProcessingTime.as("hours") % 8;
      },
      set: function (newValue) {
        const days = Math.floor(this.postProcessingTime.as("hours") / 8);
        this.postProcessingTime = Duration.fromObject({
          hours: days * 8 + newValue,
        });
      },
    },
  },
});
</script>

import { HTTP } from "./HttpCommon";

class LocationMappingService {
  loadLocationMappings() {
    return HTTP.get("DistributionCenters/GetRecords");
  }

  addLocationMapping(record) {
    return HTTP.post("DistributionCenters/Add", record);
  }

  editLocationMapping(record) {
    return HTTP.put("DistributionCenters/Edit/" + record.id, record);
  }

  deleteLocationMapping(record) {
    return HTTP.delete("DistributionCenters/Remove/" + record.id);
  }
}

export const locationMappingService = new LocationMappingService();

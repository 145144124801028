<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">IMPORT PRODUCTS</h1>
      </div>
    </el-row>
    <el-row>
      <el-form
        :model="item"
        :rules="rules"
        ref="importForm"
        label-width="140px"
      >
        <el-form-item label="File to import" prop="file">
          <div>
            <el-button
              style="margin-bottom: 5px"
              @click="$refs.fileInput.click()"
              >Choose file</el-button
            >
            <span>{{ chosenFileName }}</span>
            <input type="file" @change="onFileChange" ref="fileInput" />
          </div>
        </el-form-item>
        <el-form-item label="Division" prop="divisionId">
          <el-select filterable v-model="item.divisionId">
            <el-option
              v-for="division in availableDivisions"
              :key="division.value"
              :label="division.label"
              :value="division.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="updateExistingProducts">
          <el-checkbox
            v-model="item.updateExistingProducts"
            label="Update existing products"
          ></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button style="margin-bottom: 5px" @click="onSubmit"
            >Submit</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { getDivisions } from "../../../store/modules/mutationTypes";
import { productImportService } from "../../../services/edoc/productImport.service";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  created() {
    this.getDivisions();
  },
  data() {
    return {
      item: {
        updateExistingProducts: false,
        file: null,
        divisionId: null,
      },
      rules: {
        file: [{ required: true, message: "File is required" }],
        divisionId: [{ required: true, message: "Division is required" }],
      },
      chosenFileName: "",
      loading: false,
    };
  },
  computed: {
    availableDivisions() {
      return this.$store.state.document.availableDivisions;
    },
  },
  methods: {
    ...mapActions("document", [getDivisions]),
    onSubmit() {
      this.$refs.importForm.validate((valid) => {
        if (valid) {
          this.importProducts();
        }
      });
    },
    onFileChange(event) {
      this.item.file = event.target.files[0];

      if (this.item.file != null) {
        this.chosenFileName = this.item.file.name;
      } else {
        this.chosenFileName = "";
        this.$refs.fileInput.value = "";
      }
    },
    importProducts() {
      this.loading = true;
      productImportService
        .importProducts(
          this.item.file,
          this.item.updateExistingProducts,
          this.item.divisionId
        )
        .then(
          (response) => {
            this.$router.push({
              path: `/Documentation/ProductImport/${response.data}`,
            });
          },
          (reason) => {
            this.$alert(reason.response.data, "Validation Error", {
              confirmButtonText: "OK",
              type: "error",
            });
            this.loading = false;
            this.item.file = null;
            this.item.updateExistingProducts = false;
            this.item.divisionId = null;
            this.chosenFileName = "";
            this.$refs.fileInput.value = "";
          }
        );
    },
  },
});
</script>
<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
</style>

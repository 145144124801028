<template>
  <div>
    <el-dialog
      title="about"
      :visible.sync="visible"
      width="30%"
      :before-close="handleClose"
    >
      <el-row v-if="showCrt">
        <strong>{{ showAppName ? "CRT " : "" }}Version: </strong>
        {{ crtVersion }}
      </el-row>
      <el-row v-if="showEDoc">
        <strong>{{ showAppName ? "eDOC " : "" }}Version: </strong>
        {{ eDocVersion }}
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { versionService } from "../../services/version.service";

export default Vue.extend({
  props: {
    visible: {
      default: false,
      required: true,
    },
    showCrt: {
      default: false,
      required: true,
    },
    showEDoc: {
      default: false,
      required: true,
    },
  },
  computed: {
    showAppName() {
      return this.showCrt && this.showEDoc;
    },
  },
  data() {
    return {
      crtVersion: "Loading...",
      eDocVersion: "Loading...",
    };
  },
  watch: {
    showCrt() {
      if (this.showCrt) {
        versionService.getVersion().then((response) => {
          this.crtVersion = response.data;
        });
      }
    },
    showEDoc() {
      if (this.showEDoc) {
        versionService.getEDocVersion().then((response) => {
          this.eDocVersion = response.data;
        });
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
  },
});
</script>

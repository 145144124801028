<template>
  <el-dialog
    width="25%"
    :title="
      editMode ? 'Edit Fulfilment Plan Change' : 'Add Fulfilment Plan Change'
    "
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form :model="item" :rules="rules" ref="commentForm" label-width="150px">
      <el-form-item label="Product Type" prop="selectedProductTypeId">
        <el-select
          filterable
          style="width: 100%"
          v-model="item.selectedProductTypeId"
        >
          <el-option
            v-for="productType in definitions.availableProductTypes"
            :key="productType.id"
            :label="productType.name"
            :value="productType.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Manuf. Location"
        prop="selectedManufacturingLocationId"
      >
        <el-select
          filterable
          style="width: 100%"
          v-model="item.selectedManufacturingLocationId"
        >
          <el-option
            v-for="manufacturingLocation in definitions.availableManufacturingLocations"
            :key="manufacturingLocation.id"
            :label="manufacturingLocation.name"
            :value="manufacturingLocation.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Change Type" prop="selectedChangeType">
        <el-select
          filterable
          style="width: 100%"
          v-if="definitions.changesNotificationSettings"
          v-model="item.selectedChangeType"
        >
          <el-option
            v-for="changeType in definitions.changesNotificationSettings
              .availableFulfilmentPlanChanges"
            :key="changeType.value"
            :label="changeType.text"
            :value="changeType.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">{{
        editMode ? "Edit" : "Add"
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import _ from "lodash";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    definitions: {
      type: Object,
      default() {
        return {};
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        selectedProductTypeId: [
          { required: true, message: "Product Type is required" },
        ],
        selectedManufacturingLocationId: [
          { required: true, message: "Manufacturing Location is required" },
        ],
        selectedChangeType: [
          { required: true, message: "Change Type is required" },
        ],
      },
      item: {
        id: 0,
        selectedProductTypeId: null,
        selectedManufacturingLocationId: null,
        selectedChangeType: null,
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.commentForm.validate((valid) => {
        if (valid) {
          var model = _.clone(this.item);
          this.$emit("submit", model);
          this.closeForm();
        }
        return false;
      });
    },
    closeForm() {
      this.$refs.commentForm.resetFields();
      this.$emit("update:visible", false);
      this.clearField();
    },
    clearField() {
      this.item = {
        id: 0,
        selectedProductTypeId: null,
        selectedManufacturingLocationId: null,
        selectedChangeType: null,
      };
    },
  },
});
</script>

<template>
  <el-dialog
    width="25%"
    title="Add document"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="item"
      :rules="rules"
      ref="documentForm"
      label-width="150px"
    >
      <el-form-item label="Name" prop="name">
        <el-input v-model="item.name"></el-input>
      </el-form-item>
      <el-form-item label="Number" prop="number">
        <el-input v-model="item.number"></el-input>
      </el-form-item>
      <el-form-item label="Division" prop="divisionId">
        <el-select filterable v-model="item.divisionId">
          <el-option
            v-for="division in availableDivisions"
            :key="division.value"
            :label="division.label"
            :value="division.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Type" prop="typeId">
        <el-select filterable v-model="item.typeId">
          <el-option
            v-for="type in availableTypes"
            :key="type.value"
            :label="type.label"
            :value="type.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Add</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var validateNumberCharacters = (rule, value, callback) => {
      const disallowedCharacters = ["_"];

      const containsDisallowedCharacter = disallowedCharacters.some(
        (character) => value.includes(character)
      );

      if (containsDisallowedCharacter) {
        callback(
          new Error(
            `Number cannot contain '${disallowedCharacters.join(
              ", "
            )}' character`
          )
        );
      } else {
        callback();
      }
    };
    return {
      rules: {
        name: [{ required: true, message: "Name is required" }],
        number: [
          { required: true, message: "Number is required" },
          { validator: validateNumberCharacters },
        ],
        divisionId: [{ required: true, message: "Division is required" }],
        typeId: [{ required: true, message: "Type is required" }],
      },
      item: {
        name: null,
        number: null,
        divisionId: null,
        typeId: null,
      },
    };
  },
  computed: {
    availableDivisions() {
      return this.$store.state.document.availableDivisions;
    },
    availableTypes() {
      return this.$store.state.document.availableTypes;
    },
  },
  methods: {
    submitForm() {
      this.$refs.documentForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.item);
        }
        return false;
      });
    },
    closeForm() {
      this.$refs.documentForm.resetFields();
      this.$emit("update:visible", false);
      this.item = {
        name: null,
        number: null,
        divisionId: null,
        typeId: null,
      };
    },
  },
});
</script>

export default class CombinedOrderCandidateModel {
  requestId: string;
  id: number;
  manufacturingJobs: Array<CombinedOrderCandidateManufacturingJobModel>;

  constructor(data: any = null) {
    if (data !== null) {
      this.requestId = data.requestId;
      this.id = data.id;
      this.manufacturingJobs = data.manufacturingJobs.map((item) => {
        return new CombinedOrderCandidateManufacturingJobModel(item);
      });
    }
  }
}

export class CombinedOrderCandidateManufacturingJobModel {
  id: number;
  workflow: string;

  constructor(item) {
    this.id = item.id;
    this.workflow = item.workflow;
  }
}

import axios from "axios";
export default {};

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
};

const axiosConfig = axios.create({
  baseURL: "/api/documentation/",
});

axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export const DocumentationHttpClient = axiosConfig;

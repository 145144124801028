import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";
import qs from "qs";

class ProductService {
  getProducts(productsFilter) {
    productsFilter.ascending = productsFilter.ascending == 1 ? true : false;

    return DocumentationHttpClient.get("/Product", {
      params: productsFilter,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getProduct(productId) {
    return DocumentationHttpClient.get(`/Product/${productId}`);
  }
  addProduct(product) {
    return DocumentationHttpClient.post("/Product", product);
  }
  deleteProduct(productId) {
    return DocumentationHttpClient.delete(`/Product/${productId}`);
  }
  getProductFamilies() {
    return DocumentationHttpClient.get("/Dictionary/ProductFamilies");
  }
  getFilteredDocumentNumbers(query) {
    return DocumentationHttpClient.get("/Product/DocumentNumbers", {
      params: { query },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getFilteredDocumentNumbersForProduct(query, productId) {
    return DocumentationHttpClient.get(
      `/Product/${productId}/DocumentNumbers`,
      {
        params: { query },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      }
    );
  }
  getDocumentNumbersByIds(ids) {
    return DocumentationHttpClient.get("/Document/DocumentNumbers", {
      params: { ids },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  editProductDescription(productId, description) {
    return DocumentationHttpClient.put(
      `/Product/${productId}/Description?description=${description}`
    );
  }
  editProductFamily(productId, productFamilyId) {
    return DocumentationHttpClient.put(
      `/Product/${productId}/ProductFamily?productFamilyId=${productFamilyId}`
    );
  }
  editDivision(productId, divisionId) {
    return DocumentationHttpClient.put(
      `/Product/${productId}/Division?divisionId=${divisionId}`
    );
  }
  editNeedsKeyCode(productId, needsKeyCode) {
    return DocumentationHttpClient.put(
      `/Product/${productId}/NeedsKeyCode?needsKeyCode=${needsKeyCode}`
    );
  }
  editProductEIFUDescription(productId, description) {
    return DocumentationHttpClient.put(
      `/Product/${productId}/EIFUDescription?description=${description}`
    );
  }
  addAssociation(productId, documentIds) {
    return DocumentationHttpClient.post("/Product/Association", {
      productId,
      documentIds,
    });
  }
  editAssociation(productId, documentId, id) {
    return DocumentationHttpClient.put("/Product/Association", {
      productId,
      documentId,
      id,
    });
  }
  addProductVersion(productVersion, productId) {
    return DocumentationHttpClient.post("/Product/Version", {
      ...productVersion,
      productId,
    });
  }
  editProductVersion(productVersion, productId) {
    return DocumentationHttpClient.put("/Product/Version", {
      ...productVersion,
      productId,
    });
  }
  getDocumentVersions(documentId) {
    return DocumentationHttpClient.get(`/Document/${documentId}/Version`);
  }
  addVersionsAssociation(associationId, productVersionId, documentVersionId) {
    return DocumentationHttpClient.post("/Product/VersionsAssociation", {
      associationId,
      productVersionId,
      documentVersionId,
    });
  }
  editVersionsAssociation(
    id,
    associationId,
    productVersionId,
    documentVersionId
  ) {
    return DocumentationHttpClient.put("/Product/VersionsAssociation", {
      id,
      associationId,
      productVersionId,
      documentVersionId,
    });
  }
  assignKeyCode(productId, id) {
    return DocumentationHttpClient.post(`Product/${productId}/KeyCode`, {
      productVersionId: id,
      productId,
    });
  }
  deleteAssociation(associationId) {
    return DocumentationHttpClient.delete(
      `/Product/Association/${associationId}`
    );
  }
  deleteVersionsAssociation(versionsAssociationId) {
    return DocumentationHttpClient.delete(
      `/Product/VersionsAssociation/${versionsAssociationId}`
    );
  }
  deleteProductVersion(versionId) {
    return DocumentationHttpClient.delete(`/Product/Version/${versionId}`);
  }
}

export const productService = new ProductService();

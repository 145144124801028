<template>
  <div>
    <div v-if="model">
      <el-row>
        <div style="display: flex">
          <div style="flex: 1">
            <el-date-picker
              v-model="dateRangeSelection"
              type="daterange"
              start-placeholder="Start date"
              end-placeholder="End date"
            ></el-date-picker>
          </div>
          <div>
            <el-select
              style="width: 100%"
              :value="savedSchedule"
              @change="applySavedSchedule"
              placeholder="Use saved schedule"
            >
              <el-option
                v-for="savedSchedule in savedSchedules"
                :key="savedSchedule.id"
                :value="savedSchedule.id"
                :label="savedSchedule.name"
              ></el-option>
            </el-select>
          </div>
          <div style="width: 45px" v-if="savedSchedule !== null">
            <el-button
              class="fright"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteSavedScheduleClick"
            ></el-button>
          </div>
        </div>
      </el-row>
      <el-row>
        <el-checkbox v-model="showWeekends">Show weekends</el-checkbox>
      </el-row>
      <el-row>
        <el-row class="availability-calendar">
          <el-col
            v-for="item in visibleWeekDays"
            :key="item.weekDay"
            v-bind:style="{ width: 100 / visibleWeekDays.length + '%' }"
          >
            <el-row>{{ item.weekDay }}</el-row>
            <el-row>
              <div class="counter-text fright"></div>
            </el-row>
            <el-row>
              <el-time-select
                placeholder="Start time"
                v-model="item.startTime"
                :picker-options="{
                  format: 'HH:mm',
                  step: '00:15',
                  start: '00:00',
                  end: '23:45',
                }"
              ></el-time-select>
              <el-time-select
                placeholder="End time"
                v-model="item.endTime"
                :picker-options="{
                  format: 'HH:mm',
                  step: '00:15',
                  start: '00:00',
                  end: '23:45',
                  minTime: item.startTime,
                }"
              ></el-time-select>
            </el-row>
          </el-col>
        </el-row>
      </el-row>
      <el-row>
        <div style="display: inline">
          <el-checkbox v-model="model.saveSchedule"
            >Save schedule as</el-checkbox
          >
        </div>
        <el-input
          style="width: 20%"
          :disabled="!model.saveSchedule"
          v-model="model.scheduleName"
        ></el-input>
      </el-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import SingleAvailability from "../../models/design/SingleAvailability";

import { designerAvailabilityService } from "../../services/design/designeravailability.service";

export default Vue.extend({
  mounted: function () {
    this.loadSavedSchedules();
  },
  props: {
    model: null,
    savedSchedule: null,
    designer: null,
  },
  data() {
    return {
      savedSchedules: [],
      showWeekends: false,
    };
  },
  computed: {
    visibleWeekDays() {
      return this.model.days.filter((item) => {
        var weekDayExists = this.weekDays.find((day) => {
          return day === item.weekDay;
        });

        if (weekDayExists) {
          return true;
        }

        return false;
      });
    },
    weekDays() {
      let weekDays = moment.weekdays();

      if (this.showWeekends) {
        return weekDays;
      }

      return weekDays.filter(function (day) {
        return day !== "Sunday" && day !== "Saturday";
      });
    },
    dateRangeSelection: {
      get: function () {
        return this.model !== null
          ? [this.model.startDate, this.model.endDate]
          : Array(2).fill("");
      },
      set: function (newValue) {
        if (newValue !== null) {
          let startDate = moment(newValue[0]);
          let endDate = moment(newValue[1]);

          this.model.startDate = startDate;
          this.model.endDate = endDate;
        } else {
          this.model.startDate = "";
          this.model.endDate = "";
        }
      },
    },
  },
  methods: {
    loadSavedSchedules() {
      designerAvailabilityService
        .getSavedSchedules(this.designer.id)
        .then((data) => {
          this.savedSchedules = data.data;
        });
    },
    applySavedSchedule(newValue) {
      this.$emit("update:savedSchedule", newValue);
      let choosenSchedule = this.savedSchedules.find(
        (item) => item.id === newValue
      );

      this.model.days.forEach((day) => {
        let savedDay = choosenSchedule.items.find(
          (item) => item.weekDay === day.weekDay
        );

        if (savedDay) {
          day.startTime = savedDay.startTime;
          day.endTime = savedDay.endTime;
        } else {
          day.startTime = "";
          day.endTime = "";
        }
      });
    },
    deleteSavedSchedule() {
      designerAvailabilityService
        .deleteSavedSchedule(this.designer.id, this.savedSchedule)
        .then((data) => {
          this.$message({
            type: "success",
            message: "Delete completed",
          });
          this.loadSavedSchedules();
        });
    },
    deleteSavedScheduleClick() {
      let currentSavedSchedule = this.savedSchedules.find(
        (item) => item.id === this.savedSchedule
      );

      this.$confirm(
        "Do you want to delete '" + currentSavedSchedule.name + "' schedule?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.deleteSavedSchedule();
          this.$emit("update:savedSchedule", null);
        })
        .catch((error) => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
  },
});
</script>

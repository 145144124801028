<template>
  <div>
    <notifications
      group="custom-template"
      position="top right"
      style="width: 400px; top: 110px; right: 40px"
    >
      <template slot="body" slot-scope="props">
        <div class="el-notification" style="width: 400px">
          <div
            class="el-notification__closeBtn el-icon-close"
            @click="closeNotification(props)"
            style="position: absolute; right: 20px; top: initial"
          ></div>
          <i
            class="el-el-notification__icon"
            v-bind:class="{
              'el-icon-info':
                props.item.data.messageType == NotificationMessageType.Info,
              'el-icon-warning':
                props.item.data.messageType == NotificationMessageType.Warning,
              'el-icon-error':
                props.item.data.messageType == NotificationMessageType.Error,
            }"
          ></i>
          <div class="el-notification__group is-with-icon">
            <h2 class="el-notification__title">{{ props.item.title }}</h2>
            <div class="el-notification__content">
              <div v-html="props.item.text"></div>
              <p style="font-size: 10px">
                <el-checkbox v-model="props.item.isAcknowledged"></el-checkbox>
                I acknowledge
              </p>
            </div>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>
<script>
import Vue from "vue";
import { manufacturingCycleService } from "../../services/manufacturingcycle.service";
import { NotificationMessageType } from "../../enums/enums";

export default Vue.extend({
  data() {
    return {
      NotificationMessageType: NotificationMessageType,
    };
  },
  created() {
    this.getNotifications();
  },
  methods: {
    closeNotification(notification) {
      if (notification.item.isAcknowledged === true) {
        manufacturingCycleService.acknowledgeGlobalNotification(
          notification.item.data.notificationId
        );
      }
      notification.close();
    },
    getNotifications() {
      this.$vueNotify({
        group: "custom-template",
        clean: true,
      });
      manufacturingCycleService
        .getGlobalNotificationsForRequestId(this.$route.params.id)
        .then((response) => {
          const data = response.data;
          for (var i = 0; i < data.length; i++) {
            this.$vueNotify({
              group: "custom-template",
              title: data[i].title,
              text: data[i].message,
              closeOnClick: false,
              duration: -1,
              data: {
                notificationId: data[i].id,
                messageType: data[i].messageType,
              },
            });
          }
        });
    },
  },
});
</script>

export const getUsers = "getUsers";
export const setUsers = "setUsers";
export const setPasswordSetupUrl = "setPasswordSetupUrl";

export const storeDocumentId = "storeDocumentId";
export const getDivisions = "getDivisions";
export const getDocumentTypes = "getDocumentTypes";
export const getPortals = "getPortals";
export const getRegions = "getRegions";
export const getLanguages = "getLanguages";
export const storeProductId = "storeProductId";
export const getProductFamilies = "getProductFamilies";
export const storeProductImportId = "storeProductImportId";

export const getAssignedPermissions = "getAssignedPermissions";
export const getAssignedDesignSessionPermissions =
  "getAssignedDesignSessionPermissions";

export const getAssignedEDocPermissions = "getAssignedEDocPermissions";

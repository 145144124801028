export enum FulfilmentPlanStatus {
  ManufacturingInputPending = 0,
  ReadyForManufacturingApprovalPending,
  ReadyForManufacturing,
  ManufacturingApprovalPending,
  Manufacturing,
  ReadyForShipment,
  InTransit,
  Arrived,
  OnHold,
  Terminated,
  Done,
}

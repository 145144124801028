import WorkflowModel from "./WorkflowModel";

export default class WorkProcedureModel {
  id: number;
  materialNumber: string;
  workflows: WorkflowModel[];

  constructor(data: any = null) {
    this.id = null;
    this.materialNumber = null;
    this.workflows = [];

    if (data != null) {
      this.id = data.id;
      this.materialNumber = data.materialNumber;

      for (const workflow of data.workflows) {
        const workflowModel = new WorkflowModel(workflow);
        this.workflows.push(workflowModel);
      }
    }
  }
}

import { HTTP } from "./HttpCommon";

class ManageCalendarService {
  loadCalendars() {
    return HTTP.get("ManageCalendars/GetRecords");
  }

  addCalendar(record) {
    return HTTP.post("ManageCalendars/Add", record);
  }

  editCalendar(record) {
    return HTTP.put("ManageCalendars/Edit/" + record.id, record);
  }

  deleteCalendar(record) {
    return HTTP.delete("ManageCalendars/Remove/" + record.id);
  }
}

export const manageCalendarService = new ManageCalendarService();

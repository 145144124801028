import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";
import qs from "qs";

class ProductImportService {
  importProducts(file, updateExistingProducts, divisionId) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("updateExistingProducts", updateExistingProducts);
    formData.append("divisionId", divisionId);

    return DocumentationHttpClient.post("/ProductImport", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  getProductImportsList(filter) {
    return DocumentationHttpClient.get("/ProductImport", {
      params: filter,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getProductImportDetailsList(filter, importId) {
    return DocumentationHttpClient.get(`/ProductImport/${importId}`, {
      params: filter,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
}

export const productImportService = new ProductImportService();

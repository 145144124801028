<template>
  <div>
    <el-collapse-item name="jobStepChanges">
      <template slot="title">
        <div class="el-collapse_header-container">
          <h4 class="el-collapse-header-bold">Job Step Changes</h4>
          <el-button
            size="mini"
            class="mar-r-5"
            @click.stop="onAddJobStepChange"
            plain
            >Add</el-button
          >
        </div>
      </template>
      <div
        class="el-container-item"
        v-for="item in changesNotificationSettings.jobStepChanges"
        :key="item.id"
      >
        <span>{{ item.displayText }}</span>
        <div class="nowrap mar-b-15-l-5">
          <el-button
            size="mini"
            icon="el-icon-edit"
            @click="onEditJobStepChange(item)"
          ></el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="onDeleteJobStepChange(item)"
          ></el-button>
        </div>
      </div>
    </el-collapse-item>
    <job-step-change-popup
      :visible.sync="showJobStepChangePopup"
      ref="jobStepChangePopup"
      :editMode="isEdit"
      @submit="addEditJobStepChange"
      :definitions="changeNotificationSettingDialog"
    ></job-step-change-popup>
  </div>
</template>
<script>
import Vue from "vue";

import JobStepChangePopup from "./JobStepChangePopup.vue";
import { notificationSettingsService } from "../../../services/notificationsettings.service";
import { OperationResultType } from "../../../enums/enums";

export default Vue.extend({
  components: {
    JobStepChangePopup,
  },
  props: {
    changesNotificationSettings: {
      type: Object,
      default() {
        return [];
      },
    },
    changeNotificationSettingDialog: {
      type: Object,
      default() {
        return [];
      },
    },
    notificationsSettingsId: {
      type: Number,
      default: 0,
    },
    refreshPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showJobStepChangePopup: false,
      isEdit: false,
    };
  },
  methods: {
    onAddJobStepChange() {
      this.isEdit = false;
      this.showJobStepChangePopup = true;
    },
    onEditJobStepChange(item) {
      this.isEdit = true;
      this.$refs.jobStepChangePopup.item = {
        id: item.id,
        selectedProductTypeId: item.selectedProductTypeId,
        selectedManufacturingLocationId: item.selectedManufacturingLocationId,
        selectedMaterialId: item.selectedMaterialId,
        selectedJobStepId: item.selectedJobStepId,
      };
      this.showJobStepChangePopup = true;
      this.$refs.jobStepChangePopup.getAvailableMaterials();
      this.$refs.jobStepChangePopup.getAvailableJobSteps();
    },
    onDeleteJobStepChange(item) {
      this.$confirm(
        "Do you want to remove " + item.displayText + "?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.deleteJobStepChange(item.id);
      });
    },
    addEditJobStepChange: function (item) {
      if (this.isEdit) {
        this.editJobStepChange(item);
      } else {
        this.addJobStepChange(item);
      }
    },
    addJobStepChange: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService.addJobStepChange(item).then((response) => {
        if (response.data.result === OperationResultType.Success) {
          this.showNotification(
            "Job Step Change added succesfully.",
            "success"
          );
          this.$emit("update:refreshPage", true);
        } else if (response.data.result === OperationResultType.Error) {
          this.showNotification(response.data.message, "error");
          this.$emit("update:refreshPage", true);
        }
      });
    },
    editJobStepChange: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService.editJobStepChange(item).then((response) => {
        if (response.data.result === OperationResultType.Success) {
          this.showNotification(
            "Job Step Change edited succesfully.",
            "success"
          );
          this.$emit("update:refreshPage", true);
        } else if (response.data.result === OperationResultType.Error) {
          this.showNotification(response.data.message, "error");
          this.$emit("update:refreshPage", true);
        }
      });
    },
    deleteJobStepChange: function (id) {
      notificationSettingsService.deleteJobStepChange(id).then((response) => {
        if (response.data.result === OperationResultType.Success) {
          this.showNotification(
            "Job Step Change deleted succesfully.",
            "success"
          );
          this.$emit("update:refreshPage", true);
        } else if (response.data.result === OperationResultType.Error) {
          this.showNotification(response.data.message, "error");
          this.$emit("update:refreshPage", true);
        }
      });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

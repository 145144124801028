<template>
  <div>
    <el-card>
      <div slot="header">
        <h4>Request Information</h4>
      </div>
      <el-form label-width="140px" size="mini" style="width: 100%">
        <el-form-item
          v-if="model.requestType !== RequestCategory.Prototype"
          label="Request ID"
        >
          {{ model.requestIdWithManufacturingRevision }}
        </el-form-item>
        <el-form-item label="Out-of-Manuf.">
          {{ model.dueDate | moment }}
          <template v-if="model.schedulingStrategyShortName">
            ({{ model.schedulingStrategyShortName }})
          </template>
        </el-form-item>
        <el-form-item label="Product Number">
          {{ model.productNumber }}
        </el-form-item>
        <el-form-item
          v-if="model.requestType !== RequestCategory.ReOrder"
          label="Data Availability"
        >
          <el-checkbox
            :value="model.manufacturingDataAvailable"
            @change="editManufacturingDataRevision"
            :disabled="
              model.manufacturingDataAvailable || !canManageManufacturingCycles
            "
          ></el-checkbox>
          <span
            v-if="model.requestType == RequestCategory.eRequest"
            style="margin-left: 10px"
          >
            Rev. {{ model.requiredManufacturingDataRevision }}
          </span>
        </el-form-item>
        <el-form-item label="Approval State">
          {{ model.approvalState }}
        </el-form-item>
        <el-form-item v-if="model.requestType == 2" label="Quantity">
          {{ model.quantity }}
        </el-form-item>
        <el-form-item label="Order Number">
          <editable-input
            v-if="model.requestType === RequestCategory.eRequest"
            v-model="model.orderNumber"
            :editable="canManageManufacturingCycles"
            @input="editOrderNumber"
          ></editable-input>
          <div v-else>{{ model.orderNumber }}</div>
        </el-form-item>
        <el-button
          type="primary"
          v-if="model.showSkipButton"
          :disabled="!canManageManufacturingCycles"
          @click="openSkipConfirmModal"
          >Finalize</el-button
        >
      </el-form>
    </el-card>
  </div>
</template>
<script>
import Vue from "vue";

import { EditableInput } from "../shared/Shared.vue";
import { RequestCategory } from "../../enums/enums";
import DateTime from "../../mixins/DateTime";
import { mapState, mapActions, mapMutations } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import { OperationResultType } from "../../enums/enums";
import { manufacturingCycleService } from "../../services/manufacturingcycle.service";
import { prototypeManufacturingCycleService } from "../../services/prototypemanufacturingcycle.service";

import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";

export default Vue.extend({
  mixins: [DateTime],
  components: {
    EditableInput,
  },
  props: {
    model: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      RequestCategory: RequestCategory,
    };
  },
  computed: {
    ...mapState("manufacturingCycleDetails", ["timestamp"]),
    canManageManufacturingCycles() {
      return this.model.canManageManufacturingCycles;
    },
    manufacturingCycleId() {
      return this.$route.params.id;
    },
    isProductTMJ() {
      return this.model.productType == 'TMJ';
    }
  },
  methods: {
    ...mapActions("manufacturingCycleDetails", { setRefreshData: 'setRefreshData' }),
    ...mapActions("prototypeManufacturingCycleDetails", { setRefreshPrototypeData: 'setRefreshData' }),
    ...mapMutations([showLoading, hideLoading]),
    editManufacturingDataRevision(newValue) {
      this.$confirm(
        "Do you really want to check Data Availability? This operation cannot be reverted.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.showLoading();
        fulfilmentPlanDetailsService
          .editManufacturingDataRevision(
            this.model.id,
            newValue,
            this.timestamp
          )
          .then((response) => {
            const data = response.data;
            this.hideLoading();
            if (data.result === OperationResultType.Success) {
              this.setRefreshData();
              this.setRefreshPrototypeData();
            } else if (data.result === OperationResultType.Error) {
              this.$message.error(data.message);
            }
          });
      });
    },
    editOrderNumber() {
      manufacturingCycleService
        .editOrderNumber(
          this.manufacturingCycleId,
          this.model.orderNumber,
          this.timestamp
        )
        .then((response) => {
          if (response.data.result == OperationResultType.Error) {
            this.$message.error(response.data.message);
          } else {
            this.setRefreshData();

            this.$message({
              type: "success",
              message: "Order number updated.",
            });
          }
        });
    },
    openSkipConfirmModal() {
      this.$confirm("Are you sure you want to skip all steps?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        if (this.model.isCustomPrototype && !this.isProductTMJ) {
          this.skipAllPrototypeSteps();
        } else {
          this.skipAllSteps();
        }
      });
    },
    skipAllSteps() {
      this.showLoading();
      manufacturingCycleService
        .skipAllSteps(this.manufacturingCycleId, this.timestamp)
        .then((response) => {
          this.hideLoading();
          this.setRefreshData();
          this.$message({
            type: "success",
            message: "Manufacturing finalized.",
          });
        });
    },
    skipAllPrototypeSteps() {
      this.showLoading();
      prototypeManufacturingCycleService
        .skipAllSteps(this.manufacturingCycleId, this.timestamp)
        .then((response) => {
          this.hideLoading();
          this.setRefreshData();
          this.setRefreshPrototypeData();
          this.$message({
            type: "success",
            message: "Manufacturing finalized.",
          });
        });
    }
  },
});
</script>

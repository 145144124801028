<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row class="auto-height">
      <div class="fleft">
        <h1 class="details-header no-margin">IMPORT PRODUCT DETAILS</h1>
      </div>
    </el-row>
    <el-row>
      <div>
        <div class="alert alert-info alert-block">
          <button class="close" data-dismiss="alert" type="button">×</button>
          <p>{{ formattedSummary }}</p>
        </div>
      </div>
    </el-row>
    <el-row>
      <v-server-table
        style="margin-top: 15px"
        ref="table"
        :columns="columns"
        :options="options"
      >
        <div slot="productNumber" slot-scope="props">
          <router-link
            v-if="props.row.productId"
            :to="'/Documentation/Product/' + props.row.productId"
            >{{ props.row.productNumber }}</router-link
          >
          <p v-else>{{ props.row.productNumber }}</p>
        </div>
        <div slot="status" slot-scope="props">
          <span>{{ props.row.status }}</span>
        </div>
        <div slot="updatedFields" slot-scope="props">
          <div v-if="shouldDisplayUpdatedFields(props.row.status)">
            <span
              v-for="updatedField in props.row.updatedFields"
              :key="updatedField.column"
              style="display: block"
              >{{ formatUpdateDetails(updatedField) }}</span
            >
          </div>
          <span v-else>n/a</span>
        </div>
        <div slot="details" slot-scope="props">
          <span
            v-for="details in props.row.details"
            :key="details"
            style="display: block"
            >{{ details }}</span
          >
        </div>
      </v-server-table>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { Event } from "vue-tables-2";
import qs from "qs";
import { productImportService } from "../../../services/edoc/productImport.service";
import { storeProductImportId } from "../../../store/modules/mutationTypes";

export default Vue.extend({
  components: {},
  mounted() {
    Event.$on("vue-tables.loading", () => {
      this.loading = true;
    });

    Event.$on("vue-tables.loaded", () => {
      this.loading = false;
    });
  },
  data() {
    return {
      formattedSummary: "",
      loading: false,
      columns: ["productNumber", "status", "updatedFields", "details"],
      options: {
        headings: {
          productNumber: "Product Number",
          status: "Status",
          updatedFields: "Updated fields (Old value ➜ New value)",
          details: "Details",
        },
        filterable: false,
        sortable: [],
        requestFunction: (data) => {
          this.storeProductImportId(this.$route.params.id);

          return productImportService
            .getProductImportDetailsList(
              data,
              this.$store.state.productImport.productImportId
            )
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .then((response) => {
              var summary = response.data.summary;
              this.formattedSummary = `Import summary: Created: ${summary.created}, Updated: ${summary.updated}, Ignored: ${summary.ignored}`;

              return response;
            });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
      },
    };
  },
  computed: {},
  methods: {
    ...mapMutations("productImport", [storeProductImportId]),
    formatUpdateDetails(updatedField) {
      return `${updatedField.column} (${updatedField.oldValue} ➜ ${updatedField.newValue})`;
    },
    shouldDisplayUpdatedFields(status) {
      var isStatusUpdate = status.endsWith("Updated");

      return isStatusUpdate;
    },
  },
  watch: {
    "$route.params.id": function (id) {
      this.$refs.table.refresh();
    },
  },
});
</script>

<template>
  <div>
    <el-dialog
      width="35%"
      title="Edit file"
      :visible.sync="visible"
      :append-to-body="true"
      :before-close="closeForm"
    >
      <el-form
        :model="editedItem"
        :rules="rules"
        ref="fileForm"
        label-width="150px"
      >
        <el-form-item label="Location" prop="location">
          <el-input v-model="editedItem.location"></el-input>
        </el-form-item>
        <el-form-item label="Countries" prop="countries">
          <el-cascader
            v-model="editedItem.countries"
            :options="availableRegions"
            :props="cascaderProps"
            :show-all-levels="false"
            placeholder="Choose countries"
            collapse-tags
            filterable
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="Languages" prop="languages">
          <el-select
            v-model="editedItem.languages"
            multiple
            clearable
            collapse-tags
            filterable
            placeholder="None selected"
            style="width: 100%"
          >
            <el-option
              v-for="language in availableLanguages"
              :key="language.value"
              :label="language.label"
              :value="language.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeForm">Cancel</el-button>
        <el-button type="primary" @click="submitForm">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import _ from "lodash";

import { getRegions } from "../../../store/modules/mutationTypes";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          id: null,
          location: null,
          region: null,
          languages: null,
        };
      },
    },
  },
  data() {
    return {
      rules: {
        location: [{ required: true, message: "Location is required" }],
        countries: [
          {
            required: true,
            message: "At least one country is required",
            trigger: "blur",
          },
        ],
        languages: [
          {
            required: true,
            message: "At least one language is required",
            trigger: "blur",
          },
        ],
      },
      editedItem: {
        type: Object,
        default: {
          id: null,
          location: null,
          countries: [],
          languages: null,
        },
      },
      cascaderProps: {
        value: "id",
        label: "name",
        multiple: true,
      },
    };
  },
  created() {
    if (this.availableRegions == null || this.availableRegions.length === 0) {
      this.getRegions();
    }
  },
  computed: {
    availableLanguages() {
      return this.$store.state.document.availableLanguages;
    },
    availableRegions() {
      return this.$store.state.document.availableRegions;
    },
  },
  methods: {
    ...mapActions("document", [getRegions]),
    chosenRegion(documentFile) {
      if (!documentFile.region) {
        return "None";
      }

      if (!documentFile.region.isImplicit || documentFile.region.name) {
        return documentFile.region.name;
      }

      var listOfCountries = documentFile.region.children
        .map((country) => country.name)
        .join(", ");
      return listOfCountries;
    },
    submitForm() {
      this.$refs.fileForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            id: this.editedItem.id,
            location: this.editedItem.location,
            countries: this.editedItem.countries.map((c) => c[1]),
            languages: this.editedItem.languages,
          });
        }
        return false;
      });
    },
    closeForm() {
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.$refs.fileForm.resetFields();
      this.editedItem = {
        id: null,
        location: null,
        countries: [],
        languages: null,
      };
    },
  },
  watch: {
    visible: function (isVisible) {
      if (isVisible) {
        const countries = this.item.region.children.map(
          (country) => country.id
        );

        const flattenedCountries = this.availableRegions
          .map((r) =>
            r.children.map((c) => ({
              regionId: r.id,
              countryId: c.id,
            }))
          )
          .flat();

        const mappedCountries = countries.map((c) => [
          flattenedCountries.find((f) => f.countryId === c).regionId,
          c,
        ]);

        this.editedItem = {
          id: this.item.id,
          location: this.item.location,
          countries: mappedCountries,
          languages: this.item.languages,
        };
      }
    },
  },
});
</script>
<style lang="scss">
.el-cascader-menu__wrap {
  height: 235px;
}
</style>

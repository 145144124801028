import { SelectControlStringItemModel } from "./SelectControlStringItemModel";

export class ChangeTypeFilterSettingsModel {
  changeType: string;
  filterType: number = null;
  oldFieldDisabled = false;
  newFieldDisabled = false;
  changeTypeSelectOptions: SelectControlStringItemModel[] = [];

  constructor(model: any) {
    if (model) {
      this.changeType = model.changeType;
      this.filterType = model.filterType;
      this.oldFieldDisabled = model.oldFieldDisabled;
      this.newFieldDisabled = model.newFieldDisabled;
      if (
        model.changeTypeSelectOptions != null &&
        model.changeTypeSelectOptions.length > 0
      ) {
        for (const dataItem of model.changeTypeSelectOptions) {
          const newItem = new SelectControlStringItemModel(dataItem);
          this.changeTypeSelectOptions.push(newItem);
        }
      }
    }
  }
}

import {
  storeDocumentId,
  getDivisions,
  getDocumentTypes,
  getPortals,
  getRegions,
  getLanguages,
} from "./mutationTypes";
import { documentService } from "../../services/edoc/document.service";

const getters = {};

const state = {
  documentId: null,
  availableDivisions: [],
  availableTypes: [],
  availablePortals: [],
  availableRegions: null,
  availableLanguages: [],
};

const actions = {
  [getDivisions]({ commit }) {
    if (state.availableDivisions.length === 0) {
      documentService.getDivisions().then((response) => {
        commit(getDivisions, response.data);
      });
    }
  },
  [getDocumentTypes]({ commit }) {
    if (state.availableTypes.length === 0) {
      documentService.getDocumentTypes().then((response) => {
        commit(getDocumentTypes, response.data);
      });
    }
  },
  [getPortals]({ commit }) {
    if (state.availablePortals.length === 0) {
      documentService.getPortals().then((response) => {
        commit(getPortals, response.data);
      });
    }
  },
  [getRegions]({ commit }) {
    if (state.availableRegions == null) {
      documentService.getRegions().then((response) => {
        commit(getRegions, response.data);
      });
    }
  },
  [getLanguages]({ commit }) {
    if (state.availableLanguages.length === 0) {
      documentService.getLanguages().then((response) => {
        commit(getLanguages, response.data);
      });
    }
  },
};

const mutations = {
  [storeDocumentId](state, value) {
    state.documentId = value;
  },
  [getDivisions](state, data) {
    state.availableDivisions = data.map((division) => {
      return { label: division.name, value: division.id };
    });
  },
  [getDocumentTypes](state, data) {
    state.availableTypes = data.map((documentType) => {
      return { label: documentType.name, value: documentType.id };
    });
  },
  [getPortals](state, data) {
    state.availablePortals = data.map((portal) => {
      return { label: portal.name, value: portal.id };
    });
  },
  [getRegions](state, data) {
    state.availableRegions = data;
  },
  [getLanguages](state, data) {
    state.availableLanguages = data.map((language) => {
      return { label: language.name, value: language.id };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

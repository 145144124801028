import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users";
import documents from "./modules/documents";
import products from "./modules/products";
import productImports from "./modules/productImports";
import permissions from "./modules/permissions";
import combinedOrders from "./modules/combinedOrders";
import changes from "./modules/changes";
import filterManagement from "./modules/filterManagement";
import fulfilmentPlanList from "./modules/fulfilmentPlanList";
import fulfilmentPlanDetails from "./modules/fulfilmentPlanDetails";
import manufacturingCycleList from "./modules/manufacturingCycleList";
import manufacturingCycleDetails from "./modules/manufacturingCycleDetails";
import prototypeManufacturingCycleDetails from "./modules/prototypeManufacturingCycleDetails";

import { showLoading, hideLoading } from "./mutation-types";

import { oidcSettings } from "../oidc/oidc-settings";
import { vuexOidcCreateStoreModule } from "vuex-oidc";

import { HTTP } from "../services/HttpCommon";
import { DocumentationHttpClient } from "../shared/edoc/DocumentationHttpClient";
import { DesignHttpClient } from "../shared/DesignHttpClient";

Vue.use(Vuex);
const getters = {
  isLoading: (state) => state.loadingRefCount > 0,
};

const state = {
  loadingRefCount: 0,
};

const actions = {};

const mutations = {
  [showLoading](state) {
    state.loadingRefCount++;
  },
  [hideLoading](state) {
    if (state.loadingRefCount > 0) {
      state.loadingRefCount--;
    }
  },
};

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    users: users,
    permissions: permissions,
    document: documents,
    product: products,
    productImport: productImports,
    combinedOrder: combinedOrders,
    changes: changes,
    filterManagement: filterManagement,
    fulfilmentPlanList: fulfilmentPlanList,
    fulfilmentPlanDetails: fulfilmentPlanDetails,
    manufacturingCycleList: manufacturingCycleList,
    manufacturingCycleDetails: manufacturingCycleDetails,
    prototypeManufacturingCycleDetails: prototypeManufacturingCycleDetails,
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        routeBase: "/CRT",
      }
      // {
      //   userLoaded: (user) => console.log("OIDC user is loaded:", user),
      //   userUnloaded: () => console.log("OIDC user is unloaded"),
      //   accessTokenExpiring: () => console.log("Access token will expire"),
      //   accessTokenExpired: () => console.log("Access token did expire"),
      //   silentRenewError: () => console.log("OIDC user is unloaded"),
      //   userSignedOut: () => console.log("OIDC user is signed out"),
      //   oidcError: () => console.log(`An error occured at`),
      // }
    ),
  },
});

HTTP.interceptors.request.use(requestHandler, errorHandler);
DocumentationHttpClient.interceptors.request.use(requestHandler, errorHandler);

DesignHttpClient.interceptors.request.use(requestHandler, errorHandler);

HTTP.interceptors.response.use(responseHandler, responseErrorHandler);
DocumentationHttpClient.interceptors.response.use(
  responseHandler,
  responseErrorHandler
);

DesignHttpClient.interceptors.response.use(
  responseHandler,
  responseErrorHandler
);

function requestHandler(config) {
  const token = store.getters["oidcStore/oidcAccessToken"];
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
}

function errorHandler(error) {
  return Promise.reject(error);
}

function responseHandler(response) {
  return response;
}

function responseErrorHandler(error) {
  return error;
}

export default store;

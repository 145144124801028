import {
  getAssignedEDocPermissions,
  getAssignedPermissions,
  getAssignedDesignSessionPermissions,
} from "./mutationTypes";
import { userService } from "../../services/users.service";

const getters = {
  allPermissionsLoaded: (state) => {
    return state.permissionsLoaded && state.eDocPermissionsLoaded;
  },
};

const state = {
  userPermissions: {
    CanManageFulfilmentPlan: false,
    ReadOnlyFulfilmentPlanAccess: false,
    CanManageManufacturingCycles: false,
    ReadOnlyManufacturingCycleAccess: false,
    CanViewDesignSessions: false,
    CanManageDesignSessions: false,
    IsDesigner: false,
    CanManageDesignSessionSettings: false,
    CanManageUsers: false,
    CanManageMappings: false,
    CanManageMilestoneLeadTimes: false,
    CanManageProductTypes: false,
    CanManageReportDefinitions: false,
    CanManageWorkflowStepEfforts: false,
    CanManageWorkflows: false,
    CanManageLocalCalendar: false,
    CanFulfilmentPlanAdministrate: false,
    CanManageImportSettings: false,
    CanManageSurgeonPreferences: false,
    CanManageGlobalFilters: false,
    CanExportFulfilmentPlans: false,
  },
  permissionsLoaded: false,
  designSessionPermissionsLoaded: false,
  designSessionPermissions: {
    IsDesigner: false,
    CanViewDesignSessions: false,
    CanManageDesignSessionSettings: false,
    CanManageDesignSessions: false,
  },
  eDocPermissions: {
    CanManageProducts: false,
    CanManageDocuments: false,
    CanReadProducts: false,
    CanReadDocuments: false,
    CanImportDocuments: false,
    CanImportProducts: false,
    CanExportDocuments: false,
    CanExportProducts: false,
    CanManageEDocSystemConfiguration: false,
    CanManagePermissions: false,
    CanRemoveData: false,
  },
  eDocPermissionsLoaded: false,
};

const actions = {
  [getAssignedEDocPermissions]({ commit }) {
    if (state.eDocPermissionsLoaded) {
      return new Promise((resolve, reject) => resolve(state.eDocPermissions));
    }
    return new Promise((resolve, reject) => {
      userService.getGrantedEDocPermissions().then(
        (response) => {
          commit("setAssignedEDocPermissions", response.data);
          commit("eDocPermissionsLoaded", true);
          resolve(state.eDocPermissions);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [getAssignedPermissions]({ commit }) {
    if (state.permissionsLoaded) {
      return new Promise((resolve, reject) => resolve(state.userPermissions));
    }

    return new Promise((resolve, reject) => {
      userService
        .getGrantedPermissions(Object.keys(state.userPermissions))
        .then(
          (response) => {
            commit("setAssignedPermissions", response.data);
            commit("permissionsLoaded", true);
            resolve(state.userPermissions);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },
  [getAssignedDesignSessionPermissions]({ commit }) {
    if (state.designSessionPermissionsLoaded) {
      return new Promise((resolve, reject) => resolve(state.userPermissions));
    }

    return new Promise((resolve, reject) => {
      userService.getGrantedDesignSessionPermissions().then(
        (response) => {
          commit("setDesignSessionAssignedPermissions", response.data);
          commit("designSessionPermissionsLoaded", true);
          resolve(state.userPermissions);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  setAssignedPermissions(state, data) {
    data.forEach((element, index, array) => {
      state.userPermissions[element.permissionName] = element.assigned;
    });
  },
  permissionsLoaded(state, data) {
    state.permissionsLoaded = data;
  },
  setAssignedEDocPermissions(state, data) {
    data.forEach((element, index, array) => {
      state.eDocPermissions[element.permissionName] = element.assigned;
    });
  },
  eDocPermissionsLoaded(state, data) {
    state.eDocPermissionsLoaded = data;
  },
  setDesignSessionAssignedPermissions(state, data) {
    data.forEach((element, index, array) => {
      state.designSessionPermissions[element] = true;
    });
  },
  designSessionPermissionsLoaded(state, data) {
    state.designSessionPermissionsLoaded = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <el-collapse-item name="jobStepAlertsSettings">
      <template slot="title">
        <div class="el-collapse_header-container">
          <h4 class="el-collapse-header-bold">Job Step Alerts</h4>
          <el-button
            size="mini"
            class="mar-r-5"
            @click.stop="onAddJobStepAlert"
            plain
            >Add</el-button
          >
        </div>
      </template>
      <div class="el-container-item" v-for="item in settings" :key="item.id">
        <span>{{ item.alertDescription }}</span>
        <div class="nowrap mar-b-15-l-5">
          <el-button
            size="mini"
            icon="el-icon-edit"
            @click="onEditJobStepAlert(item)"
          />
          <el-button
            size="mini"
            icon="el-icon-delete"
            type="danger"
            @click="onDeleteJobStepAlert(item)"
          />
        </div>
      </div>
    </el-collapse-item>
    <job-step-alert-popup
      :visible.sync="showJobStepAlertPopup"
      ref="jobStepAlertPopup"
      :editMode="isEdit"
      @submit="addEditJobStepAlertNotificationSetting"
      :definitions="jobStepAlertsNotificationsDialog"
    ></job-step-alert-popup>
  </div>
</template>
<script>
import Vue from "vue";

import JobStepAlertPopup from "./JobStepAlertPopup.vue";
import { notificationSettingsService } from "../../../services/notificationsettings.service";
import { OperationResultType } from "../../../enums/enums";

export default Vue.extend({
  components: {
    JobStepAlertPopup,
  },
  props: {
    jobStepAlertsSettings: {
      type: Array,
      default() {
        return [];
      },
    },
    jobStepAlertsNotificationsDialog: {
      type: Object,
      default() {
        return [];
      },
    },
    notificationsSettingsId: {
      type: Number,
      default: 0,
    },
    refreshPage: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    jobStepAlertsSettings() {
      this.settings = this.jobStepAlertsSettings;
    },
  },
  data() {
    return {
      settings: [],
      showJobStepAlertPopup: false,
      isEdit: false,
    };
  },
  methods: {
    onAddJobStepAlert() {
      this.isEdit = false;
      this.showJobStepAlertPopup = true;
    },
    onEditJobStepAlert(item) {
      this.isEdit = true;
      this.$refs.jobStepAlertPopup.item = {
        id: item.id,
        selectedMaterialId: item.materialId,
        selectedJobStepId: item.jobStepId,
        threshold: item.threshold < 0 ? -item.threshold : item.threshold,
        selectedDirection: item.threshold < 0 ? "0" : "1",
        selectedProductTypeId: item.productTypeId,
        selectedManufacturingLocationId: item.manufacturingLocationId,
      };
      this.$refs.jobStepAlertPopup.getAvailableMaterials();
      this.$refs.jobStepAlertPopup.getAvailableJobSteps();
      this.showJobStepAlertPopup = true;
    },
    onDeleteJobStepAlert(item) {
      this.$confirm(
        "Do you want to remove " + item.alertDescription + "?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.deleteJobStepAlertNotificationSetting(item.id);
      });
    },
    addEditJobStepAlertNotificationSetting: function (item) {
      if (this.isEdit) {
        this.editJobStepAlertNotificationSetting(item);
      } else {
        this.addJobStepAlertNotificationSetting(item);
      }
    },
    addJobStepAlertNotificationSetting: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService
        .addJobStepAlertNotificationSetting(item)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Job Step Alert added succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    editJobStepAlertNotificationSetting: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService
        .editJobStepAlertNotificationSetting(item)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Job Step Alert changed succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    deleteJobStepAlertNotificationSetting: function (id) {
      notificationSettingsService
        .deleteJobStepAlertNotificationSetting(id)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Job Step Alert deleted succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">KEYCODE EXPORT</h1>
      </div>
    </el-row>
    <el-row>
      <el-form
        :model="item"
        :inline="true"
        :rules="rules"
        ref="exportForm"
        label-position="top"
        :key="'form' + item.mode"
      >
        <el-form-item label="Export Mode">
          <el-radio
            v-model="currentMode"
            :label="keyCodeExportMode.Manual"
            class="vertical-option"
            >Manual</el-radio
          >
          <el-radio
            v-model="currentMode"
            :label="keyCodeExportMode.Bulk"
            class="vertical-option"
            >Load list</el-radio
          >
        </el-form-item>
        <template v-if="currentMode === keyCodeExportMode.Manual">
          <el-form-item label="Products">
            <el-select
              v-model="productGtins"
              filterable
              remote
              multiple
              reserve-keyword
              placeholder="None selected"
              :remote-method="searchProductGtins"
              class="product-select"
            >
              <el-option
                v-for="item in availableProductGtins"
                :key="item.value"
                :label="item.label"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="currentMode === keyCodeExportMode.Bulk">
          <el-form-item label="Product GTIN's or Catalog Numbers" prop="bulk">
            <el-input
              type="textarea"
              :autosize="{ minRows: 10 }"
              v-model="bulkData"
            ></el-input>
            <div style="margin-top: 5px">
              <el-button @click="$refs.fileInput.click()"
                >Load Product GTIN's from import file</el-button
              >
              <input type="file" @change="onFileChange" ref="fileInput" />
            </div>
            <el-button style="margin-top: 5px" @click="loadList"
              >Load list</el-button
            >
          </el-form-item>
        </template>
        <el-form-item label="Products" prop="products" class="tree-form-item">
          <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            ref="tree"
            :props="productProps"
          ></el-tree>
        </el-form-item>
        <el-form-item>
          <template slot="label">&nbsp;</template>
          <el-button style="margin-bottom: 5px" @click="onSubmit"
            >Export</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { keyCodeExportService } from "../../../services/edoc/keyCodeExport.service";
import { KeyCodeExportMode } from "../../../models/edoc/KeyCodeExportMode";

export default Vue.extend({
  data() {
    return {
      keyCodeExportMode: KeyCodeExportMode,
      currentMode: KeyCodeExportMode.Manual,
      bulkData: null,
      loading: false,
      item: {
        products: [],
      },
      rules: {
        products: [
          {
            required: true,
            message: "Choose at least one product",
            trigger: "blur",
          },
        ],
      },
      productGtins: [],
      availableProductGtins: [],
      data: [],
      productProps: {
        label: "label",
        disabled: "disabled",
        children: "children",
      },
    };
  },
  methods: {
    searchProductGtins: function (query = "") {
      keyCodeExportService.getFilteredProductGtins(query).then((response) => {
        this.availableProductGtins = response.data.map((product) => {
          return { label: product.gtin, value: product.id };
        });
      });
    },
    handleAdd(product) {
      this.loading = true;
      keyCodeExportService
        .getKeyCodeExportItem(product.value)
        .then((response) => {
          this.handleTreeAdd([response.data]);

          this.loading = false;
        });
    },
    handleTreeAdd(items) {
      let newItems = items.filter((item) => {
        var index = this.data.findIndex(
          (treeNode) => treeNode.label === item.label
        );

        if (index != -1) {
          return false;
        }

        return true;
      });

      this.data.push(...newItems);
      this.$nextTick(() => {
        newItems.forEach((item) => {
          const checked = !(item.children == null && item.disabled);
          this.$refs.tree.setChecked(item.id, checked, true);
        });
      });
    },
    handleDelete(product) {
      var index = this.data.findIndex(
        (treeNode) => treeNode.label === product.label
      );

      if (index != -1) {
        this.data.splice(index, 1);
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];

      if (file != null) {
        this.loading = true;
        keyCodeExportService.extractGtins(file).then((response) => {
          this.bulkData = response.data.join("\n");
          this.loading = false;
        });
      }
    },
    loadList() {
      if (!this.bulkData) {
        return;
      }

      this.loading = true;
      keyCodeExportService
        .getKeyCodeExportItems(this.bulkData)
        .then((response) => {
          this.data = [];
          this.item.products = [];
          this.handleTreeAdd(response.data);
          this.loading = false;
        });
    },
    onSubmit() {
      this.item.products = this.$refs.tree.getCheckedNodes(true, false);

      this.$refs.exportForm.validate((valid) => {
        if (valid) {
          this.exportKeyCodes();
        }
      });
    },
    exportKeyCodes() {
      this.loading = true;

      keyCodeExportService
        .exportKeyCodes(this.item.products)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `export-keycodes.txt`);
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        });
    },
  },
  watch: {
    productGtins: function (newVal, oldVal) {
      if (newVal.length === oldVal.length) {
        return;
      }

      var isDelete = oldVal != null && oldVal.length > newVal.length;

      if (isDelete) {
        for (var i in oldVal) {
          if (!newVal.includes(oldVal[i])) {
            this.handleDelete(oldVal[i]);
            break;
          }
        }
      } else {
        this.handleAdd(newVal[newVal.length - 1]);
      }
    },
    currentMode: function () {
      this.productGtins = [];
      this.availableProductGtins = [];
      this.bulkData = null;
      this.data = [];
      this.item.products = [];
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
  },
});
</script>
<style lang="scss">
.vertical-option {
  display: block;
  line-height: normal;
  margin-right: 50px;
}

.tree-form-item {
  width: 300px;
  margin-left: 20px;
}

.product-select {
  width: 350px;
}
</style>

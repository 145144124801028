<template>
  <el-card>
    <div slot="header">
      <h4>ASSOCIATED DOCUMENTS</h4>
      <el-button
        type="primary"
        size="medium"
        class="fright"
        @click="showAddAssociationDialog"
        :disabled="!eDocPermissions.CanManageProducts"
        >Associate New Document</el-button
      >
    </div>
    <el-table :data="associatedDocuments.associations" stripe>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.versionAssociations">
            <el-table-column
              prop="documentVersionName"
              label="DOCUMENT VERSION NAME"
            ></el-table-column>
            <el-table-column
              prop="productVersionName"
              label="PRODUCT VERSION NAME"
            ></el-table-column>
            <el-table-column align="right" width="200">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="medium"
                  class="el-icon-edit"
                  @click="
                    showEditVersionsAssociationDialog(
                      scope.row,
                      props.row.documentId,
                      props.row.id
                    )
                  "
                  :disabled="!eDocPermissions.CanManageProducts"
                ></el-button>
                <el-popconfirm
                  title="Do you want to remove this versions association?"
                  @confirm="deleteVersionsAssociation(scope.row.id)"
                >
                  <el-button
                    slot="reference"
                    type="primary"
                    size="medium"
                    class="el-icon-delete"
                    :disabled="!eDocPermissions.CanRemoveData"
                  ></el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="Document Number" prop="number" align="center">
        <template slot-scope="scope">
          <router-link
            v-if="eDocPermissions.CanReadDocuments"
            :to="'/Documentation/Document/' + scope.row.documentId"
            :key="scope.row.documentId"
            style="display: block"
            >{{ scope.row.number }}</router-link
          >
          <p v-else :key="scope.row.documentId" style="display: block">
            {{ scope.row.number }}
          </p>
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="medium"
            class="el-icon-edit"
            @click="showEditAssociationDialog(scope.row)"
            :disabled="
              scope.row.versionAssociations.length > 0 ||
              !eDocPermissions.CanManageProducts
            "
          ></el-button>
          <el-popconfirm
            title="Do you want to remove this association?"
            @confirm="deleteAssociation(scope.row.id)"
          >
            <el-button
              slot="reference"
              type="primary"
              size="medium"
              class="el-icon-delete"
              :disabled="!eDocPermissions.CanRemoveData"
            ></el-button>
          </el-popconfirm>
          <el-button
            type="primary"
            size="medium"
            @click="
              showAddVersionsAssociationDialog(
                scope.row.documentId,
                scope.row.id
              )
            "
            :disabled="
              productVersions.productVersions.length == 0 ||
              !eDocPermissions.CanManageProducts
            "
            >Associate Document Version</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <add-association-dialog
      :visible.sync="addAssociationDialogVisible"
      @submit="addAssociation"
      ref="addAssociationDialog"
    ></add-association-dialog>
    <edit-association-dialog
      :visible.sync="editAssociationDialogVisible"
      @submit="editAssociation"
      :item="editedAssociation"
    ></edit-association-dialog>
    <add-versions-association-dialog
      :visible.sync="addVersionsAssociationDialogVisible"
      @submit="addVersionsAssociation"
      :product-versions="productVersions"
      :association-id="addedAssociationId"
      :document-id="addedVersionsAssociationDocumentId"
      ref="addVersionsAssociationDialog"
    ></add-versions-association-dialog>
    <edit-versions-association-dialog
      :visible.sync="editVersionsAssociationDialogVisible"
      @submit="editVersionsAssociation"
      :product-versions="productVersions"
      :document-id="editedVersionsAssociationDocumentId"
      :association-id="editedVersionsAssociationId"
      :item="editedVersionsAssociation"
    ></edit-versions-association-dialog>
  </el-card>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import AssociatedDocumentListModel from "../../../models/edoc/AssociatedDocumentListModel";
import ProductVersionsListModel from "../../../models/edoc/ProductVersionsListModel";
import { productService } from "../../../services/edoc/product.service";
import { ResourcesHelper } from "../../../shared/edoc/ResourcesHelper";
import { Resources } from "../../../shared/edoc/Resources";

import AddAssociationDialog from "./AddAssociationDialog.vue";
import EditAssociationDialog from "./EditAssociationDialog.vue";
import AddVersionsAssociationDialog from "./AddVersionsAssociationDialog.vue";
import EditVersionsAssociationDialog from "./EditVersionsAssociationDialog.vue";

export default Vue.extend({
  components: {
    AddAssociationDialog,
    EditAssociationDialog,
    AddVersionsAssociationDialog,
    EditVersionsAssociationDialog,
  },
  props: {
    associatedDocuments: {
      type: Object,
      default: () => new AssociatedDocumentListModel(),
    },
    productVersions: {
      type: Object,
      default: () => new ProductVersionsListModel(),
    },
  },
  data() {
    return {
      addAssociationDialogVisible: false,
      editAssociationDialogVisible: false,
      addVersionsAssociationDialogVisible: false,
      editVersionsAssociationDialogVisible: false,
      editedAssociation: null,
      editedVersionsAssociation: null,
      addedAssociationId: null,
      addedVersionsAssociationDocumentId: null,
      editedVersionsAssociationDocumentId: null,
      editedVersionsAssociationId: null,
    };
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
  },
  methods: {
    showAddAssociationDialog() {
      this.addAssociationDialogVisible = true;
    },
    showEditAssociationDialog(documentAssociation) {
      this.editedAssociation = documentAssociation;
      this.editAssociationDialogVisible = true;
    },
    showAddVersionsAssociationDialog(documentId, associationId) {
      this.addedVersionsAssociationDocumentId = documentId;
      this.addedAssociationId = associationId;
      this.addVersionsAssociationDialogVisible = true;
    },
    showEditVersionsAssociationDialog(
      versionsAssociation,
      documentId,
      associationId
    ) {
      this.editedVersionsAssociationDocumentId = documentId;
      this.editedVersionsAssociationId = associationId;
      this.editedVersionsAssociation = versionsAssociation;
      this.editVersionsAssociationDialogVisible = true;
    },
    addAssociation(item) {
      this.addAssociationDialogVisible = false;

      productService
        .addAssociation(this.$store.state.product.productId, item.documentIds)
        .then(
          () => {
            this.$emit("reload-data");
            this.$refs.addAssociationDialog.clearForm();
            this.$message({
              type: "success",
              message: Resources.AddAssociationSuccess,
            });
          },
          (error) => {
            this.onError(
              error,
              Resources.AddAssociationError,
              this.showAddAssociationDialog
            );
          }
        );
    },
    editAssociation(item) {
      this.editAssociationDialogVisible = false;

      productService
        .editAssociation(
          this.$store.state.product.productId,
          item.documentId,
          item.id
        )
        .then(
          () => {
            this.$emit("reload-data");
            this.$message({
              type: "success",
              message: Resources.EditAssociationSuccess,
            });
          },
          (error) => {
            this.onError(error, Resources.EditAssociationError, () =>
              this.showEditAssociationDialog(this.editedAssociation)
            );
          }
        );
    },
    addVersionsAssociation(item) {
      this.addVersionsAssociationDialogVisible = false;

      productService
        .addVersionsAssociation(
          item.associationId,
          item.productVersionId,
          item.documentVersionId
        )
        .then(
          () => {
            this.$emit("reload-data");
            this.$refs.addVersionsAssociationDialog.clearForm();
            this.$message({
              type: "success",
              message: Resources.AddVersionsAssociationSuccess,
            });
          },
          (error) => {
            this.onError(error, Resources.AddVersionsAssociationError, () =>
              this.showAddVersionsAssociationDialog(
                this.addedVersionsAssociationDocumentId,
                this.addedAssociationId
              )
            );
          }
        );
    },
    editVersionsAssociation(item) {
      this.editVersionsAssociationDialogVisible = false;

      productService
        .editVersionsAssociation(
          item.id,
          item.associationId,
          item.productVersionId,
          item.documentVersionId
        )
        .then(
          () => {
            this.$emit("reload-data");
            this.$message({
              type: "success",
              message: Resources.EditVersionsAssociationSuccess,
            });
          },
          (error) => {
            this.onError(error, Resources.EditVersionsAssociationError, () =>
              this.showEditVersionsAssociationDialog(
                this.editedVersionsAssociation,
                this.editedVersionsAssociationDocumentId,
                this.editedVersionsAssociationId
              )
            );
          }
        );
    },
    deleteAssociation(associationId) {
      productService.deleteAssociation(associationId).then(
        () => {
          this.$emit("reload-data");
          this.$message({
            type: "success",
            message: Resources.DeleteAssociationSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.DeleteAssociationError);
        }
      );
    },
    deleteVersionsAssociation(versionsAssociationId) {
      productService.deleteVersionsAssociation(versionsAssociationId).then(
        () => {
          this.$emit("reload-data");
          this.$message({
            type: "success",
            message: Resources.DeleteVersionsAssociationSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.DeleteVersionsAssociationError);
        }
      );
    },
    onError(
      error,
      defaultErrorContent,
      callbackFunction = () => {
        // do nothing.
      }
    ) {
      const alertTitle =
        error.response.status === 409 ? "Validation Error" : "Error";
      const alertContent =
        error.response.status === 409
          ? ResourcesHelper.getValidationMessage(error.response.data.errors)
          : defaultErrorContent;

      this.$alert(alertContent, alertTitle, {
        confirmButtonText: "OK",
        type: "error",
        callback: (action) => {
          callbackFunction();
        },
      });
    },
  },
});
</script>
<style lang="scss">
h4 {
  padding-left: 20px;
  display: inline-block;
}
</style>

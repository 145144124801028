import { HTTP } from "./HttpCommon";

class LocalCalendarService {
  getCalendars() {
    return HTTP.get("LocalCalendar/GetCalendars");
  }

  getLocations() {
    return HTTP.get("ManufacturingLocations/GetLocations");
  }

  loadCalendar(calendarId: number, start: string, end: string, locationId: number) {
    return HTTP.get(
      `LocalCalendar/EventsList?calendarId=${calendarId}&start=${start}&end=${end}&locationId=${locationId}`
    );
  }

  getItem(date: string, calendarId: number) {
      return HTTP.get(
        `LocalCalendar/GetItem?date=${date}&calendarId=${calendarId}`
      );
  }

  addItem(calendarId: number, date: string, name: string) {
    return HTTP.post(`LocalCalendar/Item`, {
      calendarId,
      date,
      name,
    });
  }

  editItem(id: number, name: string) {
    return HTTP.post(`LocalCalendar/Item`, {
      id,
      name,
    });
  }

  deleteItem(id: number) {
    return HTTP.delete(`LocalCalendar/DeleteItem/${id}`);
  }

  deleteCapacity(id: number) {
    return HTTP.delete(`LocalCalendar/DeleteCapacity/${id}`);
  }

  getCapacityItem(date: string, calendarId: number, locationId: number) {
    return HTTP.get(
      `LocalCalendar/GetCapacityItem?date=${date}&calendarId=${calendarId}&locationId=${locationId}`
    );
  }

  editCapacity(id: number, value: number, date: string, dateTo: string, locationId: number, calendarId: number) {
    return HTTP.post(`LocalCalendar/CapacityItem`, {
      id,
      value,
      date,
      dateTo,
      locationId,
      calendarId
    });
  }

  addCapacity(calendarId: number, date: string, value: number, dateTo: string, locationId: number) {
    return HTTP.post(`LocalCalendar/CapacityItem`, {
      calendarId,
      date,
      value,
      dateTo,
      locationId
    });
  }

  getCapacityDefaultValue(calendarId: number, locationId: number) {
    return HTTP.get(
      `LocalCalendar/GetCapacityDefaultValue?calendarId=${calendarId}&locationId=${locationId}`
    );
  } 

  editCapacityDefaultValue(calendarId: number, locationId: number, value: number) {
    return HTTP.post(`LocalCalendar/CapacityDefaultValue`, {
      calendarId,
      locationId,
      value
    });
  }
}

export const localCalendarService = new LocalCalendarService();

<template>
  <el-card>
    <div slot="header">
      <h4>ASSOCIATED PRODUCTS</h4>
    </div>
    <v-client-table
      style="margin-top: 15px"
      ref="table"
      :columns="columns"
      :options="options"
      :data="associations"
    >
      <div slot="productDescription" class="links" slot-scope="props">
        <router-link
          v-if="eDocPermissions.CanReadProducts"
          :to="'/Documentation/Product/' + props.row.productId"
          :key="props.row.productId"
          style="display: block"
          >{{ props.row.productDescription }}</router-link
        >
        <p v-else :key="props.row.productId" style="display: block">
          {{ props.row.productDescription }}
        </p>
      </div>
    </v-client-table>
  </el-card>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  components: {},
  props: {
    associations: {
      type: Array,
    },
  },
  data() {
    return {
      columns: [
        "productDescription",
        "productGTIN",
        "productVersionName",
        "documentVersionName",
      ],
      options: {
        headings: {
          productDescription: "Product Name",
          productGTIN: "Product GTIN",
          productVersionName: "Product Version Name",
          documentVersionName: "Document Version Name",
        },
        filterable: true,
        sortable: [
          "productDescription",
          "productGTIN",
          "productVersionName",
          "documentVersionName",
        ],
      },
    };
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
  },
});
</script>
<style lang="scss">
h4 {
  padding-left: 20px;
  display: inline-block;
}
</style>

<template>
  <el-card>
    <div slot="header">
      <h4>PRODUCT INFORMATION</h4>
    </div>
    <el-form size="mini" label-width="180px">
      <el-form-item label="Family" class="form-item-padding">
        <div>
          <editable-select
            v-model="productInformation.productFamilyId"
            :select-list="availableProductFamilies"
            @input="editProductFamily"
            :editable="eDocPermissions.CanManageProducts"
          ></editable-select>
        </div>
      </el-form-item>
      <el-form-item label="Name" class="form-item-padding">
        <div class="readonly-data">
          <editable-input
            v-model="productInformation.description"
            @input="editProductDescription"
            :editable="eDocPermissions.CanManageProducts"
          ></editable-input>
        </div>
      </el-form-item>
      <el-form-item label="Catalog Number" class="form-item-padding">
        <div class="readonly-data">{{ productInformation.catalogNumber }}</div>
      </el-form-item>
      <el-form-item label="GTIN" class="form-item-padding">
        <div class="readonly-data">{{ productInformation.gtin }}</div>
      </el-form-item>
      <el-form-item label="Division" class="form-item-padding">
        <div>
          <editable-select
            v-model="productInformation.divisionId"
            :select-list="availableDivisions"
            @input="editDivision"
            :editable="eDocPermissions.CanManageProducts"
          ></editable-select>
        </div>
      </el-form-item>
      <el-form-item label="Describing Documents" class="form-item-padding">
        <div
          v-for="{ number, documentId } in productInformation.describedBy"
          :key="documentId"
        >
          <router-link
            class="readonly-data links"
            v-if="eDocPermissions.CanReadDocuments"
            :to="'/Documentation/Document/' + documentId"
            :key="documentId"
            style="display: block"
            >{{ number }}</router-link
          >
          <p
            v-else
            class="readonly-data"
            :key="documentId"
            style="display: block"
          >
            {{ number }}
          </p>
        </div>
      </el-form-item>
      <el-form-item label="Needs Keycode" class="form-item-padding">
        <el-switch
          class="switch"
          v-model="productInformation.needsKeyCode"
          :disabled="
            !eDocPermissions.CanManageProducts ||
            !productInformation.isNeedsKeyCodeEditable
          "
          @change="editNeedsKeyCode"
        ></el-switch>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import Vue from "vue";
import { EditableInput, EditableSelect } from "../../shared/Shared.vue";
import { mapActions, mapState } from "vuex";
import ProductInformationModel from "../../../models/edoc/ProductInformationModel";
import {
  getProductFamilies,
  getDivisions,
} from "../../../store/modules/mutationTypes";
import { productService } from "../../../services/edoc/product.service";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  components: {
    EditableInput,
    EditableSelect,
  },
  props: {
    productInformation: {
      type: Object,
      default: () => new ProductInformationModel(),
    },
  },
  created() {
    if (
      this.availableProductFamilies == null ||
      this.availableProductFamilies.length === 0
    ) {
      this.getProductFamilies();
    }
    if (
      this.availableDivisions == null ||
      this.availableDivisions.length === 0
    ) {
      this.getDivisions();
    }
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
    availableProductFamilies() {
      return this.$store.state.product.availableProductFamilies;
    },
    availableDivisions() {
      return this.$store.state.document.availableDivisions;
    },
  },
  methods: {
    ...mapActions("product", [getProductFamilies]),
    ...mapActions("document", [getDivisions]),
    editProductDescription() {
      productService
        .editProductDescription(
          this.$store.state.product.productId,
          this.productInformation.description
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    editProductFamily() {
      productService
        .editProductFamily(
          this.$store.state.product.productId,
          this.productInformation.productFamilyId
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    editDivision() {
      productService
        .editDivision(
          this.$store.state.product.productId,
          this.productInformation.divisionId
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    editNeedsKeyCode() {
      productService
        .editNeedsKeyCode(
          this.$store.state.product.productId,
          this.productInformation.needsKeyCode
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    processEditSuccessResponse() {
      this.showNotification(Resources.ProductEditSuccess, "success");
    },
    processEditErrorResponse() {
      this.showNotification(Resources.ProductEditError, "error");
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    reloadData() {
      this.$nextTick(() => {
        this.$emit("reload-data");
      });
    },
  },
});
</script>
<style lang="scss">
.readonly-data {
  font-size: 12px;
  padding-left: 15px;
}

.links {
  padding-top: 5px;
  line-height: 18px;
}

h4 {
  padding-left: 20px;
}

.switch {
  margin-left: 14px;
}
</style>

import { HTTP } from "./HttpCommon";
import { DocumentationHttpClient } from "../shared/edoc/DocumentationHttpClient";
import { DesignHttpClient } from "../shared/DesignHttpClient";

import UserInfoModel from "../models/UserInfoModel";

class UserService {
  basePath = "users/";

  getUsers(token: string = null) {
    return HTTP.get(this.basePath);
  }

  getUserInfo(userId: number) {
    return HTTP.get(`${this.basePath}${userId}`);
  }

  addUser(model: UserInfoModel) {
    return HTTP.post(this.basePath, model);
  }

  saveUserInfo(model: UserInfoModel) {
    return HTTP.put(`${this.basePath}${model.id}`, model);
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
    passwordConfirmation: string
  ) {
    return HTTP.patch(`${this.basePath}change-password`, {
      oldPassword: oldPassword,
      newPassword: newPassword,
      passwordConfirmation: passwordConfirmation,
    });
  }

  resetPassword(userId: number) {
    return HTTP.patch(`${this.basePath}${userId}/reset-password`);
  }

  getCrtUserPermissions(userId: number) {
    return HTTP.get("UserPermissions/GetPermissions/" + userId);
  }

  getUserManagementPermissions() {
    return HTTP.get(`${this.basePath}permissions`);
  }

  getAssignedUserManagementPermissions(userId: number) {
    return HTTP.get(`${this.basePath}${userId}/permissions`);
  }

  getEDocPermissions(userId: number) {
    return DocumentationHttpClient.get(`/Permission/${userId}`);
  }

  getEDocRoles(userId: number) {
    return DocumentationHttpClient.get(`/Role/${userId}`);
  }

  grantCrtPermissions(userId: number, ids: number[]) {
    return HTTP.post("UserPermissions/Grant", { userId, ids });
  }

  grantUserPermissions(userId: number, ids: number[]) {
    return HTTP.put(`${this.basePath}${userId}/permissions`, ids);
  }

  grantEDocPermissions(userId: number, ids: number[]) {
    return DocumentationHttpClient.post(`/Permission/${userId}`, ids);
  }

  grantEDocRoles(userId: number, ids: number[]) {
    return DocumentationHttpClient.post(`/Role/${userId}`, ids);
  }

  revokeCrtPermissions(userId: number, ids: number[]) {
    return HTTP.post("UserPermissions/Revoke", { userId, ids });
  }

  revokeEDocPermissions(userId: number, ids: number[]) {
    return DocumentationHttpClient.put(`/Permission/${userId}`, ids);
  }

  revokeEDocRoles(userId: number, ids: number[]) {
    return DocumentationHttpClient.put(`/Role/${userId}`, ids);
  }

  getUserRoles(userId: number) {
    return HTTP.get("UserRoles/GetRoles/" + userId);
  }

  assignRoles(userId: number, ids: number[]) {
    return HTTP.post("UserRoles/Assign", { userId, ids });
  }

  dissociateRoles(userId: number, ids: number[]) {
    return HTTP.post("UserRoles/Dissociate", { userId, ids });
  }

  getGrantedEDocPermissions() {
    return DocumentationHttpClient.get("/Permission/Granted");
  }

  getGrantedPermissions(permissions) {
    return HTTP.post(`Login/CheckUserPermissions`, permissions);
  }

  getGrantedDesignSessionPermissions() {
    return DesignHttpClient.get("/permissions");
  }
}

export const userService = new UserService();

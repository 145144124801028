export class RepeatOrderModel {
    id: number;
    planId: string;
    designStatus: string;
    processingStatus: string;
    manufacturingProgressPercentage: number;
    comments: string;
    distributionCenter: string;
    productNumber: string;
    shipmentDueDate: string;
    timeZone: string;
    children: Array<RepeatOrderModel>;

    constructor(model: any) {
        if (model) {
            this.id = model.id;
            this.planId = model.planId;
            this.designStatus = model.designStatus;
            this.processingStatus = model.processingStatus;
            this.manufacturingProgressPercentage = model.manufacturingProgressPercentage;
            this.comments = model.comments;
            this.distributionCenter = model.distributionCenter;
            this.productNumber = model.productNumber;
            this.shipmentDueDate = model.shipmentDueDate;
            this.timeZone = model.timeZone;
            this.children = model.children;
        }
    }
}

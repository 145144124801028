export default {
    namespaced: true,
    state: {
        timestamp: "",
        refreshPrototypeData: false,
    },
    mutations: {
        storeTimestamp(state, value) {
            state.timestamp = value;
        },
        setRefreshData(state, value = true) {
            state.refreshPrototypeData = value;
        },
    },
    actions: {
        storeTimestamp(context, value) {
            context.commit("storeTimestamp", value);
        },
        setRefreshData(context, value = true) {
            context.commit("setRefreshData", value);
        },
    }
}
<template>
  <div>
    <h1 class="details-header">General Session Properties</h1>
    <el-button class="fright" @click="openAddModal">Create New</el-button>
    <el-table :data="generalSessionProperties">
      <el-table-column label="Product type">
        <template slot-scope="scope">
          {{ getProduct(scope.row.productTypeId) }}
          {{ scope.row.priority ? " Priority" : "" }}
        </template>
      </el-table-column>
      <el-table-column label="Preparation time">
        <template slot-scope="scope">
          {{ Math.floor(scope.row.preparationTime.asHours() / 8) }} days and
          {{ scope.row.preparationTime.asHours() % 8 }} hours
        </template>
      </el-table-column>
      <el-table-column label="Design session time">
        <template slot-scope="scope">
          {{ scope.row.designSessionTime.hours() }} hours and
          {{ scope.row.designSessionTime.minutes() }} minutes
        </template>
      </el-table-column>
      <el-table-column prop="postProcessingDays" label="Post-processing time">
        <template slot-scope="scope">
          {{ Math.floor(scope.row.postProcessingTime.asHours() / 8) }} days and
          {{ scope.row.postProcessingTime.asHours() % 8 }} hours
        </template>
      </el-table-column>
      <el-table-column
        prop="sessionLimit"
        label="Session limit"
      ></el-table-column>
      <el-table-column width="160">
        <template slot-scope="scope">
          <el-button @click="openEditModal(scope.row)" size="mini"
            >Edit</el-button
          >
          <el-button
            @click="openDeleteConfirmModal(scope.row)"
            size="mini"
            type="danger"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="dialogVisible"
      title="Session properties"
      width="700px"
      @closed="onDialogClose"
    >
      <el-form
        label-width="160px"
        :model="modal"
        ref="propertiesForm"
        :rules="validationRules"
      >
        <el-form-item label="Product type" prop="productTypeId">
          <el-select
            v-model="modal.productTypeId"
            :disabled="modal.id !== null"
          >
            <el-option
              v-for="productType in productTypes"
              :key="productType.id"
              :label="productType.name"
              :value="productType.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Priority">
          <el-checkbox
            :disabled="modal.id !== null"
            v-model="modal.priority"
          ></el-checkbox>
        </el-form-item>
        <el-form-item label="Preparation time">
          <el-select id="preparationDays" v-model="preparationDays">
            <el-option
              v-for="day in availableDays"
              :key="day.id"
              :label="day.text"
              :value="day.id"
            ></el-option>
          </el-select>
          <el-select id="preparationHours" v-model="preparationHours">
            <el-option
              v-for="hour in availableHours"
              :key="hour.id"
              :label="hour.text"
              :value="hour.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Design Session time">
          <el-select id="sessionHours" v-model="sessionHours">
            <el-option
              v-for="hour in availableHours"
              :key="hour.id"
              :label="hour.text"
              :value="hour.id"
            ></el-option>
          </el-select>
          <el-select id="sessionMinutes" v-model="sessionMinutes">
            <el-option
              v-for="minute in availableMinutes"
              :key="minute.id"
              :label="minute.text"
              :value="minute.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Post processing time">
          <el-select id="postProcessingDays" v-model="postProcessingDays">
            <el-option
              v-for="day in availableDays"
              :key="day.id"
              :label="day.text"
              :value="day.id"
            ></el-option>
          </el-select>
          <el-select id="postProcessingHours" v-model="postProcessingHours">
            <el-option
              v-for="hour in availableHours"
              :key="hour.id"
              :label="hour.text"
              :value="hour.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Session limit">
          <el-input-number
            :min="0"
            v-model="modal.sessionLimit"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addOrEditProperties">OK</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import "moment-duration-format";
import moment from "moment";
import "moment-timezone";
import _ from "lodash";
import { OperationResultType } from "../../enums/enums";
import { designerService } from "../../services/design/designer.service";
import { generalSessionPropertiesService } from "../../services/design/generalsessionproperties.service";
import { configurationService } from "../../services/design/configuration.service";

export default Vue.extend({
  created() {
    this.loadData();

    configurationService.getProducts().then((response) => {
      this.productTypes = response.data;
    });
  },
  data() {
    return {
      OperationResultType,
      generalSessionProperties: [],
      productTypes: [],
      modal: this.clearModal(),
      dialogVisible: false,
      validationRules: {
        productTypeId: [
          { required: true, message: "Product type is required." },
        ],
      },
    };
  },
  computed: {
    preparationDays: {
      get: function () {
        return Math.floor(this.modal.preparationTime.asHours() / 8);
      },
      set: function (newValue) {
        const hours = this.modal.preparationTime.asHours() % 8;

        this.modal.preparationTime = moment.duration(
          newValue * 8 + hours,
          "hours"
        );
      },
    },
    preparationHours: {
      get: function () {
        return this.modal.preparationTime.asHours() % 8;
      },
      set: function (newValue) {
        const days = Math.floor(this.modal.preparationTime.asHours() / 8);

        this.modal.preparationTime = moment.duration(
          days * 8 + newValue,
          "hours"
        );
      },
    },
    sessionHours: {
      get: function () {
        return this.modal.designSessionTime.hours();
      },
      set: function (newValue) {
        this.modal.designSessionTime = moment.duration({
          hours: newValue,
          minutes: this.modal.designSessionTime.minutes(),
        });
      },
    },
    sessionMinutes: {
      get: function () {
        return this.modal.designSessionTime.minutes();
      },
      set: function (newValue) {
        this.modal.designSessionTime = moment.duration({
          hours: this.modal.designSessionTime.hours(),
          minutes: newValue,
        });
      },
    },
    postProcessingDays: {
      get: function () {
        return Math.floor(this.modal.postProcessingTime.asHours() / 8);
      },
      set: function (newValue) {
        const hours = this.modal.postProcessingTime.asHours() % 8;

        this.modal.postProcessingTime = moment.duration(
          newValue * 8 + hours,
          "hours"
        );
      },
    },
    postProcessingHours: {
      get: function () {
        return this.modal.postProcessingTime.asHours() % 8;
      },
      set: function (newValue) {
        const days = Math.floor(this.modal.postProcessingTime.asHours() / 8);

        this.modal.postProcessingTime = moment.duration(
          days * 8 + newValue,
          "hours"
        );
      },
    },
    availableDays: function () {
      return _.range(8).map((i) => ({ id: i, text: `${i} days` }));
    },
    availableHours: function () {
      return _.range(8).map((i) => ({ id: i, text: `${i} hours` }));
    },
    availableMinutes: function () {
      return _.range(0, 60, 5).map((i) => ({ id: i, text: `${i} minutes` }));
    },
  },
  methods: {
    loadData() {
      generalSessionPropertiesService
        .getGeneralSessionProperties()
        .then((response) => {
          this.generalSessionProperties = response.data;

          response.data.forEach(function (item) {
            item.preparationTime = moment.duration(item.preparationTime);
            item.designSessionTime = moment.duration(item.designSessionTime);
            item.postProcessingTime = moment.duration(item.postProcessingTime);
          });

          this.generalSessionProperties = response.data;
        });
    },
    clearModal() {
      return {
        id: null,
        productTypeId: null,
        priority: false,
        preparationTime: moment.duration(),
        designSessionTime: moment.duration(),
        postProcessingTime: moment.duration(),
        sessionLimit: 1,
      };
    },
    openAddModal() {
      this.modal = this.clearModal();
      this.dialogVisible = true;
    },
    openEditModal(item) {
      this.dialogVisible = true;
      this.modal = Object.assign({}, item);
    },
    addOrEditProperties() {
      this.$refs.propertiesForm.validate((valid) => {
        if (valid) {
          if (this.modal.id !== null) {
            generalSessionPropertiesService
              .updateGeneralSessionProperties(
                this.modal.id,
                this.modal.preparationTime,
                this.modal.designSessionTime,
                this.modal.postProcessingTime,
                this.modal.sessionLimit
              )
              .then((response) => {
                const data = response.data;

                if (data.result === OperationResultType.Error) {
                  this.$message({
                    message: data.messages.join(" "),
                    type: "error",
                  });
                } else {
                  this.loadData();
                  this.dialogVisible = false;
                }
              });
          } else {
            generalSessionPropertiesService
              .addGeneralSessionProperties(
                this.modal.productTypeId,
                this.modal.priority,
                this.modal.preparationTime,
                this.modal.designSessionTime,
                this.modal.postProcessingTime,
                this.modal.sessionLimit
              )
              .then((response) => {
                const data = response.data;

                if (data.result === OperationResultType.Error) {
                  this.$message({
                    message: data.messages.join(" "),
                    type: "error",
                  });
                } else {
                  this.loadData();
                  this.dialogVisible = false;
                }
              });
          }
        } else {
          return false;
        }
      });
    },
    openDeleteConfirmModal(item) {
      this.$confirm("Do you want to delete this session property?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          generalSessionPropertiesService
            .deleteGeneralSessionProperties(item.id)
            .then((response) => {
              const data = response.data;

              if (data.result === OperationResultType.Error) {
                this.$message({
                  message: data.messages.join(" "),
                  type: "error",
                });
              } else {
                this.loadData();
                this.dialogVisible = false;
              }
            });
        })
        .catch(() => {
          // do nothing.
        });
    },
    getProduct(productId) {
      const productType = this.productTypes.find((pt) => pt.id === productId);

      return (productType && productType.name) || "";
    },
    openDesignerDetailsModel: function (item) {
      this.modal = item;
      this.editModalVisible = true;
    },
    editDesignerProperties: function () {
      designerService.editDesignerProperties(this.modal).then((response) => {
        this.editModalVisible = false;
      });
    },
    onDialogClose() {
      this.$refs.propertiesForm.resetFields();
    },
  },
});
</script>

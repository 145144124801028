<template>
  <div class="grid-content" v-if="showShipmentInformation">
    <el-card style="width: 100%" v-loading="loadingShipmentInformation">
      <div slot="header">
        <h4 style="padding-left: 20px; display: inline-block">
          Shipment Information
        </h4>
      </div>
      <el-form
        ref="form"
        label-width="180px"
        size="mini"
        style="width: 100%"
        v-loading="loadingShipmentInformation || editionLock"
      >
        <el-form-item label="Tracking Url" class="form-item-padding">
          <div style="font-size: 12px; padding-left: 15px">
            <div
              v-if="model.trackingUrl == null || model.trackingUrl.length == 0"
              style="color: #dd1144; float: left; padding-right: 16px"
            >
              Empty
            </div>
            <el-link
              v-else
              type="primary"
              target="_blank"
              style="padding-right: 15px"
              :href="model.trackingUrl"
              :underline="false"
              >Click to track</el-link
            >
            <el-button
              v-if="canManageFulfilmentPlan"
              plain
              icon="el-icon-edit"
              @click="dialogVisible = true"
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="Tracking Number" class="form-item-padding">
          <div style="font-size: 12px; padding-left: 15px">
            <editable-input
              v-model="model.trackingNumber"
              v-on:input="trackingNumberChanged"
              :editable="canManageFulfilmentPlan"
            >
            </editable-input>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
    <shipment-information-dialog
      :visible.sync="dialogVisible"
      :tracking-url="model.trackingUrl"
      @close="dialogVisible = false"
      @submit="trackingUrlChanged"
    >
    </shipment-information-dialog>
  </div>
</template>
<script>
import ShipmentInformationModel from "../../models/fulfilment-plan/ShipmentInformationModel";
import EditableInput from "../shared/EditableInput";
import ShipmentInformationDialog from "./ShipmentInformationDialog.vue";
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    EditableInput,
    ShipmentInformationDialog,
  },
  data() {
    return {
      model: new ShipmentInformationModel(null),
      loadingShipmentInformation: false,
      showShipmentInformation: false,
      dialogVisible: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getShowShipmentInformation(this.$route.params.id);
    });
  },
  computed: {
    ...mapState("fulfilmentPlanDetails", [
      "canManageFulfilmentPlan",
      "shipmentInformationRefresh",
      "editionLock",
    ]),
  },
  watch: {
    shipmentInformationRefresh: function () {
      if (this.shipmentInformationRefresh) {
        this.getShipmentInformation(this.model.id);
        this.setShipmentInformationRefresh(false);
      }
    },
  },
  methods: {
    ...mapActions("fulfilmentPlanDetails", [
      "setEditionLock",
      "setChangesRefresh",
      "setShipmentInformationRefresh",
    ]),
    getShipmentInformation(id) {
      this.loadingShipmentInformation = true;
      fulfilmentPlanDetailsService
        .getShipmentInformation(id)
        .then((response) => {
          const data = response.data;
          this.model = new ShipmentInformationModel(data.model);
          this.loadingShipmentInformation = false;
        });
    },
    getShowShipmentInformation(id) {
      fulfilmentPlanDetailsService
        .getShowShipmentInformation(id)
        .then((response) => {
          const data = response.data;
          this.showShipmentInformation = data.model;
          if (this.showShipmentInformation) {
            this.getShipmentInformation(id);
          }
        });
    },
    trackingUrlChanged(newValue) {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editTrackingUrl(this.model.id, newValue)
        .then((response) => {
          const data = response.data;
          this.$message({
            message: "Tracking Url changed successfully",
            type: "success",
            duration: 4000,
          });

          this.setChangesRefresh(true);
          this.setEditionLock(false);
          this.getShipmentInformation(this.model.id);
          this.dialogVisible = false;
        })
        .catch(this.trackingUrlErrorHandler);
    },
    trackingNumberChanged() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editTrackingNumber(this.model.id, this.model.trackingNumber)
        .then((response) => {
          this.$message({
            message: "Tracking Number changed successfully",
            type: "success",
            duration: 4000,
          });

          this.setChangesRefresh(true);
          this.setEditionLock(false);
        })
        .catch(this.trackingNumberErrorHandler);
    },
    trackingUrlErrorHandler() {
      this.setEditionLock(false);

      this.$message.error({
        message: "Error occured while changing Tracking Url",
        duration: 4000,
      });
    },
    trackingNumberErrorHandler() {
      this.setEditionLock(false);

      this.$message.error({
        message: "Error occured while changing Tracking Number",
        duration: 4000,
      });
    },
  },
};
</script>

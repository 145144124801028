import { storeProductId, getProductFamilies } from "./mutationTypes";
import { productService } from "../../services/edoc/product.service";

const getters = {};

const state = {
  productId: null,
  availableProductFamilies: [],
};

const actions = {
  [getProductFamilies]({ commit }) {
    if (state.availableProductFamilies.length === 0) {
      productService.getProductFamilies().then((response) => {
        commit(getProductFamilies, response.data);
      });
    }
  },
};

const mutations = {
  [storeProductId](state, value) {
    state.productId = value;
  },
  [getProductFamilies](state, data) {
    state.availableProductFamilies = data.map((productFamily) => {
      return { label: productFamily.name, value: productFamily.id };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default {
  methods: {
    handleRemove(row) {
      this.$confirm("Are you sure you want to delete this item?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$emit("row-remove", row, () => {
            this.$message({
              type: "success",
              message: "Delete operation succeeded",
            });
          });
        })
        .catch(() => {
          // do nothing.
        });
    },
  },
};

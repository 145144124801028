import FiltersModel from "../../models/fulfilment-plan/FiltersModel";
import FulfilmentPlanAdditionalFiltersModel from "../../models/fulfilment-plan/FulfilmentPlanAdditionalFiltersModel";
import FulfilmentPlanAdditionalFilterModel from "../../models/fulfilment-plan/FulfilmentPlanAdditionalFilterModel";
import ChosenFiltersModel from "../../models/fulfilment-plan/ChosenFiltersModel";
import { fulfilmentPlanListService } from "../../services/fulfilmentplanlist.service";
import { DateFrequency } from "../../enums/enums";
import moment from "moment";
import { debounce, omit, pick, isEmpty, merge } from "lodash";

export const nonFilterParams: Array<string> = [
  "storedQueryId",
  "index",
  "selectedRecordId",
];

function prepareRelativeDateFilter(filters: ChosenFiltersModel) {
  if (
    filters.relativeDateAmount != null &&
    Number(filters.relativeDateAmount) >= 0
  ) {
    if (filters.relativeDateModifier > 0) {
      filters.endDate = moment()
        .endOf("day")
        .add(
          filters.relativeDateModifier === 0
            ? -filters.relativeDateAmount
            : filters.relativeDateAmount,
          DateFrequency[
            filters.relativeDateFrequency
          ].toLowerCase() as moment.unitOfTime.DurationConstructor
        )
        .toDate();
      filters.startDate = moment().startOf("day").toDate();
    } else {
      filters.startDate = moment()
        .startOf("day")
        .add(
          filters.relativeDateModifier === 0
            ? -filters.relativeDateAmount
            : filters.relativeDateAmount,
          DateFrequency[
            filters.relativeDateFrequency
          ].toLowerCase() as moment.unitOfTime.DurationConstructor
        )
        .toDate();
      filters.endDate = moment().endOf("day").toDate();
    }

    filters.isRelativeDate = true;
  } else {
    filters.startDate = null;
    filters.endDate = null;
    filters.isRelativeDate = false;
  }

  filters.dateRange = [];
}

export default {
  namespaced: true,
  state: {
    filtersLoaded: false,
    availableProductTypes: [],
    availableRequestTypes: [],
    availableManufacturingLocations: [],
    availableDesignStatuses: [],
    availableProcessingStatuses: [],
    availableDates: [],
    availableDateTypes: [],
    availableDistributionCenters: [],

    availableColumns: [],
    filtersInitialized: false,
    additionalFiltersInitialized: false,

    sortedColumns: [],
    initSortedColumns: false,
    updateSortedColumns: false,
    selectedRecordId: null,
    filters: new ChosenFiltersModel(null),
    additionalFilters: new FulfilmentPlanAdditionalFiltersModel(null),
    refresh: false,
  },
  getters: {
    filtersLoaded: (state) => {
      return state.filtersInitialized && state.additionalFiltersInitialized;
    },
    activeColumns: (state) => {
      return state.availableColumns
        .filter((c) => c.visible)
        .map((c) => c.field);
    },
    selectedAdditionalFilters: (state) => {
      return state.additionalFilters.filters.filter((f) => f.checked);
    },
    chosenDateFilter: (state) => {
      return state.filters.chosenMilestoneId != null
        ? state.filters.chosenMilestoneId
        : state.filters.chosenDate;
    },
    modifiedFilters: (state, getters) => {
      const defaultFilters = new ChosenFiltersModel(null);
      const data = omit(state.filters, [
        "startDate",
        "endDate",
        "currentDateTabValue",
        "storedQueryActiveDisplayName",
      ]);
      const modifiedFields = Object.keys(data).filter(
        (key) => data[key] !== defaultFilters[key]
      );

      const selectedAdditionalFilters = getters.selectedAdditionalFilters.map(
        (f) => ({
          id: f.id,
          selectedValues: f.selectedValues,
        })
      );

      return {
        ...pick(data, modifiedFields),
        additionalFilters: selectedAdditionalFilters,
      };
    },
  },
  mutations: {
    setRequestId(state, value) {
      if (value) {
        state.filters.requestId = value;
      } else {
        state.filters.requestId = null;
      }
    },
    setChosenProducts(state, value) {
      state.filters.chosenProducts = value;
    },
    setChosenDesignStatuses(state, value) {
      state.filters.chosenDesignStatuses = value;
    },
    setChosenProcessingStatuses(state, value) {
      state.filters.chosenProcessingStatuses = value;
    },
    setChosenDateFilter(state, value) {
      if (value !== 0) {
        const intValue = parseInt(value);
        state.filters.chosenMilestoneId = isNaN(intValue) ? null : intValue;
        state.filters.chosenDate = null;
      } else {
        state.filters.chosenDate = 0;
        state.filters.chosenMilestoneId = null;
      }
    },
    setDateType(state, value) {
      state.filters.dateType = value;
    },
    setCurrentDateTabValue(state, value) {
      state.filters.currentDateTabValue = value;
    },
    setDateRange(state, value) {
      state.filters.relativeDateFrequency = DateFrequency.Day;
      state.filters.relativeDateModifier = 0;
      state.filters.relativeDateAmount = null;

      if (value && value.length > 0) {
        state.filters.startDate = moment.parseZone(value[0]).utc().format();
        state.filters.endDate = moment
          .parseZone(value[1])
          .endOf("day")
          .utc()
          .format();
      } else {
        state.filters.startDate = null;
        state.filters.endDate = null;
      }
      state.filters.isRelativeDate = false;
      state.filters.dateRange = value;
    },
    setRelativeDateAmount(state, value) {
      state.filters.relativeDateAmount = value;
      prepareRelativeDateFilter(state.filters);
    },
    setRelativeDateModifier(state, value) {
      state.filters.relativeDateModifier = value;
      prepareRelativeDateFilter(state.filters);
    },
    setRelativeDateFrequency(state, value) {
      state.filters.relativeDateFrequency = value;
      prepareRelativeDateFilter(state.filters);
    },
    setChosenDistributionCenters(state, value) {
      state.filters.chosenDistributionCenters = value;
    },
    clearDateFilters(state) {
      state.filters.relativeDateAmount = null;
      state.filters.relativeDateModifier = 0;
      state.filters.relativeDateFrequency = DateFrequency.Day;
      state.filters.isRelativeDate = false;
      state.filters.dateRange = [];
      state.filters.startDate = null;
      state.filters.endDate = null;
    },
    clearFilters(state) {
      state.filters.storedQueryActiveDisplayName = "";
      state.filters.requestId = "";
      state.filters.planId = null;
      state.filters.chosenProducts = [];
      state.filters.chosenMilestoneId = null;
      state.filters.chosenDateFilter = null;
      state.filters.chosenDesignStatuses = [];
      state.filters.chosenProcessingStatuses = [];
      state.filters.chosenDate = null;
      state.filters.dateType = 0;
      state.filters.startDate = null;
      state.filters.endDate = null;
      state.filters.dateRange = [];
      state.filters.isRelativeDate = false;
      state.filters.relativeDateAmount = null;
      state.filters.relativeDateFrequency = DateFrequency.Day;
      state.filters.relativeDateModifier = 0;
      state.filters.chosenDistributionCenters = [];
      state.filters.currentDateTabValue = "0";
    },
    storeFilters(state, value) {
      state.filters = value;
    },
    storeFiltersInitialized(state, value) {
      state.filtersInitialized = value;
    },
    storeQueryActiveDisplayName(state, value) {
      state.filters.storedQueryActiveDisplayName = value;
    },

    storeFulfilmentPlanListData(state, value: FiltersModel) {
      state.availableProductTypes = value.availableProductTypes;
      state.availableRequestTypes = value.availableRequestTypes;
      state.availableManufacturingLocations =
        value.availableManufacturingLocations;
      state.availableDesignStatuses = value.availableDesignStatuses;
      state.availableProcessingStatuses = value.availableProcessingStatuses;
      state.availableDates = value.availableDates;
      state.availableDateTypes = value.availableDateTypes;
      state.availableDistributionCenters = value.availableDistributionCenters;
      state.filtersInitialized = true;
    },
    storeAvailableColumns(state, value) {
      state.availableColumns = value;
    },
    storeSortedColumns(state, value) {
      state.sortedColumns = value;
    },
    storeInitSortedColumns(state, value) {
      state.initSortedColumns = value;
    },
    storeUpdateSortedColumns(state, value) {
      state.updateSortedColumns = value;
    },
    storeSelectedRecordId(state, value) {
      state.selectedRecordId = value;
    },

    storeAdditionalFiltersInitialized(state, value) {
      state.additionalFiltersInitialized = value;
    },
    storeAdditionalFilters(state, value) {
      state.additionalFilters = value;
    },
    selectAdditionalFilter(state, value: FulfilmentPlanAdditionalFilterModel) {
      if (value) {
        const filter = state.additionalFilters.filters.find(
          (f) => f.id === value.id
        );
        filter.checked = true;
        filter.selectedValues = value.selectedValues;
        if (filter.filterType === 0 || filter.filterType === 1)
        {
          filter.selectedValue = value.selectedValue;
        }
        const index = state.additionalFilters.filters.indexOf(filter);
        state.additionalFilters.filters.splice(index, 1, filter);
      }
    },
    unselectAdditionalFilter(
      state,
      value: FulfilmentPlanAdditionalFilterModel
    ) {
      if (value) {
        const filter = state.additionalFilters.filters.find(
          (f) => f.id === value.id
        );
        filter.checked = false;
        filter.selectedValues = [];
        filter.selectedValue = "";
        const index = state.additionalFilters.filters.indexOf(filter);
        state.additionalFilters.filters.splice(index, 1, filter);
      }
    },
    clearAdditionalFilters(state) {
      for (let i = 0; i < state.additionalFilters.filters.length; i++) {
        if (state.additionalFilters.filters[i].checked) {
          state.additionalFilters.filters[i].selectedValues = [];
          state.additionalFilters.filters[i].selectedValue = "";
          state.additionalFilters.filters[i].checked = false;
          state.additionalFilters.filters[i].initiated = false;
        }
      }
    },

    refresh(state) {
      state.refresh = true;
    },
    refreshed(state) {
      state.refresh = false;
    },
  },
  actions: {
    setRequestId({ commit, dispatch }, value) {
      commit("setRequestId", value);
      dispatch("refresh");
    },
    setChosenProducts({ commit, dispatch }, value) {
      commit("setChosenProducts", value);
      dispatch("refresh");
    },
    setChosenDesignStatuses({ commit, dispatch }, value) {
      commit("setChosenDesignStatuses", value);
      dispatch("refresh");
    },
    setChosenProcessingStatuses({ commit, dispatch }, value) {
      commit("setChosenProcessingStatuses", value);
      dispatch("refresh");
    },
    setChosenDateFilter({ commit, dispatch }, value) {
      commit("setChosenDateFilter", value);
      dispatch("refresh");
    },
    setDateType({ commit, dispatch }, value) {
      commit("setDateType", value);
      dispatch("refresh");
    },
    setCurrentDateTabValue({ commit, dispatch }, value) {
      commit("setCurrentDateTabValue", value);
    },
    setDateRange({ commit, dispatch }, value) {
      commit("setDateRange", value);
      dispatch("refresh");
    },
    setRelativeDateAmount({ commit, dispatch }, value) {
      commit("setRelativeDateAmount", value);
      dispatch("refresh");
    },
    setRelativeDateModifier({ commit, dispatch }, value) {
      commit("setRelativeDateModifier", value);
      dispatch("refresh");
    },
    setRelativeDateFrequency({ commit, dispatch }, value) {
      commit("setRelativeDateFrequency", value);
      dispatch("refresh");
    },
    setChosenDistributionCenters({ commit, dispatch }, value) {
      commit("setChosenDistributionCenters", value);
      dispatch("refresh");
    },

    clearDateFilters({ commit, dispatch }) {
      commit("clearDateFilters");
      dispatch("refresh");
    },

    clearFilters({ commit, getters, dispatch }) {
      commit("clearFilters");
      getters.selectedAdditionalFilters.forEach((f) =>
        commit("unselectAdditionalFilter", f)
      );
      dispatch("refresh");
    },

    storeFiltersInitialized(context, value) {
      context.commit("storeFiltersInitialized", value);
    },
    storeSortedColumns(context, value) {
      context.commit("storeSortedColumns", value);
    },
    storeAvailableColumns(context, value) {
      context.commit("storeAvailableColumns", value);
    },
    storeInitSortedColumns(context, value) {
      context.commit("storeInitSortedColumns", value);
    },
    async loadFilters({ commit, state }, storedQueryId = null) {
      if (state.filtersLoaded) {
        return;
      }
      const response = await fulfilmentPlanListService.getListFilters();
      const filters = new FiltersModel(response.data);

      commit("storeFulfilmentPlanListData", filters);

      const additionalFiltersResponse =
        await fulfilmentPlanListService.getListAdditionalFilters(storedQueryId);
      const data = additionalFiltersResponse.data;
      commit(
        "storeAdditionalFilters",
        new FulfilmentPlanAdditionalFiltersModel(data)
      );
      commit("storeAdditionalFiltersInitialized", true);
    },
    async loadChosenFilters(
      { commit, dispatch, state, getters },
      query = null
    ) {
      const response = await fulfilmentPlanListService.getChosenFilters(
        query.storedQueryId
      );
      const data = merge(response.data, omit(query, nonFilterParams));
      const filters = new ChosenFiltersModel(data);
      if (filters.isRelativeDate) {
        prepareRelativeDateFilter(filters);
      }

      if (!isEmpty(query.additionalFilters)) {
        data.selectedAdditionalFilters = query.additionalFilters.map((f) => ({
          id: Number(f.id),
          selectedValues: f.selectedValues,
        }));
      }

      commit("storeFilters", filters);
      getters.selectedAdditionalFilters.forEach((f) =>
        commit("unselectAdditionalFilter", f)
      );
      if (data.selectedAdditionalFilters) {
        data.selectedAdditionalFilters.forEach((f) =>
          commit("selectAdditionalFilter", f)
        );
      }
      commit("storeAvailableColumns", response.data.availableColumns);
      const list = [];
      if (data.sortColumns && data.sortColumns.length > 0) {
        for (let i = 0; data.sortColumns.length > i; i++) {
          const currentSortColumn = state.availableColumns.find((x) => {
            return (
              x.name.toUpperCase() ===
              data.sortColumns[i].columnName.toUpperCase()
            );
          });
          list.push({
            column: currentSortColumn.field,
            ascending: data.sortColumns[i].sortOrder === 1,
          });
        }
      }
      commit("storeSortedColumns", list);
      commit("storeInitSortedColumns", true);

      if (data.storedQueryActiveDisplayName) {
        commit(
          "storeQueryActiveDisplayName",
          data.storedQueryActiveDisplayName
        );
      }
      if (query.storedQueryId == null) {
        dispatch("refresh");
      }
    },
    async restoreDefaultColumns({ commit }) {
      const response = await fulfilmentPlanListService.getDefaultColumns();

      commit("storeAvailableColumns", response.data.content);
    },
    storeUpdateSortedColumns(context, value) {
      context.commit("storeUpdateSortedColumns", value);
    },
    storeQueryActiveDisplayName(context, value) {
      context.commit("storeQueryActiveDisplayName", value);
    },
    storeSelectedRecordId(context, value) {
      context.commit("storeSelectedRecordId", value);
    },
    storeAdditionalFilters(
      context,
      value: FulfilmentPlanAdditionalFiltersModel
    ) {
      context.commit("storeAdditionalFilters", value);
    },
    selectAdditionalFilter(
      context,
      value: FulfilmentPlanAdditionalFilterModel
    ) {
      context.commit("selectAdditionalFilter", value);
    },
    unselectAdditionalFilter(
      context,
      value: FulfilmentPlanAdditionalFilterModel
    ) {
      context.commit("unselectAdditionalFilter", value);
    },
    clearAdditionalFilters({ commit }) {
      commit("clearAdditionalFilters");
    },

    refresh: debounce((context) => {
      context.commit("refresh");
    }, 500),
    refreshed(context) {
      context.commit("refreshed");
    },
  },
};

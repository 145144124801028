<template>
  <div v-on-clickaway="closeEditMode">
    <div v-if="editMode" style="display: flex; padding-left: 15px">
      <el-input-number
        ref="editableNumberInput"
        :min="min"
        :max="max"
        :step="1"
        placeholder=""
        style="margin-right: 8px"
        v-model="numberValue"
      ></el-input-number>
      <el-button
        class="share-button"
        icon="el-icon-check"
        type="primary"
        v-on:click="applyChanges"
      ></el-button>
      <el-button
        class="share-button"
        icon="el-icon-close"
        type="primary"
        v-on:click="closeEditMode"
      ></el-button>
    </div>
    <div v-else>
      <div style="font-size: 12px; padding-left: 15px">
        <div v-if="editable">
          <el-button
            v-if="numberValue"
            type="text"
            v-on:click="editMode = true"
            >{{ numberValue }}</el-button
          >
          <el-button
            v-else
            type="text"
            v-on:click="editMode = true"
            style="color: #dd1144"
            >{{ emptyFieldPlaceHolderText }}</el-button
          >
        </div>
        <div v-else>
          <div v-if="numberValue" type="text">{{ numberValue }}</div>
          <div v-else>Empty</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  data() {
    return {
      editMode: false,
      numberValue: this.value,
    };
  },
  watch: {
    value: function () {
      this.numberValue = this.value;
    },
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 999,
    },
    emptyFieldPlaceHolderText: {
      type: String,
      default: "Empty",
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    applyChanges() {
      this.$emit("input", parseInt(this.$refs.editableNumberInput.value));
      this.editMode = false;
    },
    closeEditMode: function () {
      this.numberValue = this.value;
      this.editMode = false;
    },
  },
};
</script>

export default class DocumentInformationModel {
  name: string;
  number: string;
  typeId: number;
  divisionId: number;
  latestRevisionName: string;
  isVersioned: boolean;
  isVersionedEditable: boolean;

  constructor(data: any = null) {
    if (data !== null) {
      this.name = data.name;
      this.number = data.number;
      this.typeId = data.typeId;
      this.divisionId = data.divisionId;
      this.latestRevisionName = data.latestRevisionName;
      this.isVersioned = data.isVersioned;
      this.isVersionedEditable = data.isVersionedEditable;
    }
  }
}

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">LIST OF IMPORTS</h1>
      </div>
    </el-row>
    <el-row>
      <v-server-table
        style="margin-top: 15px"
        ref="table"
        :columns="columns"
        :options="options"
      >
        <div slot="view" slot-scope="props">
          <router-link
            :to="
              '/Documentation/ProductImport/' +
              props.row.id +
              currentFilterQuery
            "
            >View</router-link
          >
        </div>
        <div slot="details" slot-scope="props">
          <span
            v-for="details in props.row.details"
            :key="details"
            style="display: block"
            >{{ details }}</span
          >
        </div>
        <div slot="importDate" slot-scope="props">
          <span>{{ props.row.importDate | formatDateTimeOffset }}</span>
        </div>
        <div slot="userId" slot-scope="props">
          <span>{{ getUserInfo(props.row.userId) }}</span>
        </div>
      </v-server-table>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Event } from "vue-tables-2";
import { mapActions, mapState } from "vuex";
import { productImportService } from "../../../services/edoc/productImport.service";
import { getUsers } from "../../../store/modules/mutationTypes";
import qs from "qs";

export default Vue.extend({
  created() {
    this.getUsers();
  },
  mounted() {
    Event.$on("vue-tables.loading", () => {
      this.loading = true;
    });

    Event.$on("vue-tables.loaded", () => {
      this.loading = false;
    });
  },
  data() {
    return {
      loading: false,
      columns: ["importDate", "userId", "details", "view"],
      options: {
        headings: {
          importDate: "Import date",
          userId: "User",
          details: "Details",
        },
        filterable: false,
        sortable: [],
        requestFunction: (data) => {
          return productImportService
            .getProductImportsList(data)
            .catch(function (e) {
              this.dispatch("error", e);
            });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
      },
    };
  },
  computed: {
    ...mapState("users", ["users"]),
    currentFilterQuery: function () {
      var result = qs.stringify(this.$route.query);

      return result ? "?" + result : "";
    },
  },
  methods: {
    ...mapActions("users", [getUsers]),
    getUserInfo(userId) {
      const user = this.users.find((u) => u.id === userId);
      if (user == null) {
        return "";
      }

      return `${user.firstName} ${user.lastName} (${user.symbol})`;
    },
  },
});
</script>

<template>
  <div>
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">FILTER MANAGEMENT</h1>
      </div>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <crt-filters-list></crt-filters-list>
      </el-col>
      <el-col :span="12">
        <edoc-filters-list></edoc-filters-list>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";

import CrtFiltersList from "./CrtFiltersList.vue";
import EdocFiltersList from "./EdocFiltersList.vue";

export default Vue.extend({
  components: {
    CrtFiltersList,
    EdocFiltersList,
  },
});
</script>

import { render, staticRenderFns } from "./InsufficientPermissions.vue?vue&type=template&id=42bd2630&"
import script from "./InsufficientPermissions.vue?vue&type=script&lang=js&"
export * from "./InsufficientPermissions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
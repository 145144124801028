import { render, staticRenderFns } from "./DocumentImport.vue?vue&type=template&id=3e40a5f5&scoped=true&"
import script from "./DocumentImport.vue?vue&type=script&lang=js&"
export * from "./DocumentImport.vue?vue&type=script&lang=js&"
import style0 from "./DocumentImport.vue?vue&type=style&index=0&id=3e40a5f5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e40a5f5",
  null
  
)

export default component.exports
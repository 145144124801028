import WorkflowStepModel from "./WorkflowStepModel";

export default class WorkflowModel {
  id: number;
  symbol: string;
  name: string;
  displayName: string;
  workflowSteps: WorkflowStepModel[];

  constructor(data: any = null) {
    this.id = null;
    this.symbol = null;
    this.name = null;
    this.displayName = null;
    this.workflowSteps = [];

    if (data != null) {
      this.id = data.id;
      this.symbol = data.symbol;
      this.name = data.name;
      this.displayName = data.displayName;

      for (const workflowStep of data.workflowSteps) {
        const workflowStepModel = new WorkflowStepModel(workflowStep);
        this.workflowSteps.push(workflowStepModel);
      }
    }
  }
}

<template>
  <div>
    <el-popover
      ref="changetype-popover"
      placement="right"
      width="530"
      trigger="click"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="Change Types Filter" type="border-card">
          <label for="changeType" class="field-label">Change Type</label>
          <div id="changeType" style="margin-top: 8px; margin-bottom: 8px">
            <el-select
              v-model="changeType"
              filterable
              clearable
              style="width: 100%"
              placeholder="None selected"
              @change="setChangeTypeFilter"
            >
              <el-option
                v-for="item in changeTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <label for="oldValue" class="field-label">Old Value</label>
          <div
            id="oldValue"
            v-if="settings.filterType == null"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-input
              placeholder="None selected"
              @change="refreshChangeTypeFilter"
              v-model="oldValue"
              :disabled="true"
            ></el-input>
          </div>
          <div
            id="oldValue"
            v-if="settings.filterType == 0"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-input
              placeholder="None selected"
              v-model="oldValue"
              @change="refreshChangeTypeFilter"
              :disabled="settings.oldFieldDisabled"
            ></el-input>
          </div>
          <div
            id="oldValue"
            v-if="settings.filterType == 1"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-input
              placeholder="None selected"
              type="number"
              @change="refreshChangeTypeFilter"
              v-model="oldValue"
              :disabled="settings.oldFieldDisabled"
            ></el-input>
          </div>
          <div
            id="oldValue"
            v-if="settings.filterType == 2"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-select
              v-model="oldValue"
              filterable
              clearable
              style="width: 100%"
              @change="refreshChangeTypeFilter"
              placeholder="None selected"
            >
              <el-option
                v-for="item in settings.changeTypeSelectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div
            id="oldValue"
            v-if="settings.filterType == 3"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-date-picker
              v-model="oldValue"
              type="date"
              style="width: 100%"
              format="dd/MM/yyyy"
              @change="refreshChangeTypeFilter"
              value-format="dd/MM/yyyy"
              :disabled="settings.oldFieldDisabled"
              placeholder="None selected"
            ></el-date-picker>
          </div>
          <label for="newValue" class="field-label">New Value</label>
          <div
            id="newValue"
            v-if="settings.filterType == null"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-input
              placeholder="None selected"
              @change="refreshChangeTypeFilter"
              v-model="newValue"
              :disabled="true"
            ></el-input>
          </div>
          <div
            id="newValue"
            v-if="settings.filterType == 0"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-input
              placeholder="None selected"
              @change="refreshChangeTypeFilter"
              v-model="newValue"
              :disabled="settings.newFieldDisabled"
            ></el-input>
          </div>
          <div
            id="newValue"
            v-if="settings.filterType == 1"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-input
              placeholder="None selected"
              type="number"
              @change="refreshChangeTypeFilter"
              v-model="newValue"
              :disabled="settings.NewFieldDisabled"
            ></el-input>
          </div>
          <div
            id="newValue"
            v-if="settings.filterType == 2"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-select
              v-model="newValue"
              filterable
              clearable
              @change="refreshChangeTypeFilter"
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in settings.changeTypeSelectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div
            id="newValue"
            v-if="settings.filterType == 3"
            style="margin-top: 8px; margin-bottom: 8px"
          >
            <el-date-picker
              v-model="newValue"
              type="date"
              @change="refreshChangeTypeFilter"
              style="width: 100%"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              placeholder="None selected"
              :disabled="settings.newFieldDisabled"
            ></el-date-picker>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-popover>
    <label class="field-label">Change Types</label>
    <el-input
      placeholder="None selected"
      suffix-icon="el-icon-tickets"
      v-model="changeTypeText"
      readonly="readonly"
      clearable
      @change="clearChangeTypeFilter"
      v-popover:changetype-popover
    ></el-input>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import { changesService } from "../../services/changes.service";
import { ChangeTypeFilterSettingsModel } from "../../models/ChangeTypeFilterSettingsModel";

export default Vue.extend({
  props: {
    changeTypes: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      settings: new ChangeTypeFilterSettingsModel(null),
    };
  },
  watch: {
    "chosenFilters.changeTypeFilter": function () {
      this.loadChangeType();
    },
  },
  computed: {
    ...mapState("changes", ["chosenFilters"]),
    changeType: {
      get() {
        return this.chosenFilters.changeTypeFilter.changeType;
      },
      set(value) {
        this.updateChangeType(value);
      },
    },
    oldValue: {
      get() {
        return this.chosenFilters.changeTypeFilter.oldValue;
      },
      set(value) {
        this.updateChangeTypeOldValue(value);
      },
    },
    newValue: {
      get() {
        return this.chosenFilters.changeTypeFilter.newValue;
      },
      set(value) {
        this.updateChangeTypeNewValue(value);
      },
    },
    changeTypeText() {
      const chosenFilters = this.chosenFilters.changeTypeFilter;
      let text = "";
      if (
        this.changeTypes.length > 0 &&
        chosenFilters.changeType != null &&
        chosenFilters.changeType !== ""
      ) {
        text = this.changeTypes.find(
          (x) => x.value.toString() == chosenFilters.changeType
        ).label;
        if (
          chosenFilters.oldValue != null &&
          chosenFilters.oldValue !== "" &&
          !this.settings.oldFieldDisabled
        ) {
          if (this.settings.filterType == 2) {
            text +=
              " old value: " +
              chosenFilters.changeTypeSelectOptions.find(
                (x) => x.value == chosenFilters.oldValue
              ).label +
              ";";
          } else {
            text += " old value: " + chosenFilters.oldValue + ";";
          }
        }
        if (
          chosenFilters.newValue != null &&
          chosenFilters.newValue !== "" &&
          !this.settings.newFieldDisabled
        ) {
          if (this.settings.filterType == 2) {
            text +=
              " new value: " +
              this.settings.changeTypeSelectOptions.find(
                (x) => x.value == chosenFilters.newValue
              ).label +
              ";";
          } else {
            text += " new value: " + chosenFilters.newValue + ";";
          }
        }
      }

      return text;
    },
  },
  methods: {
    ...mapMutations("changes", [
      "updateChangeType",
      "updateChangeTypeOldValue",
      "updateChangeTypeNewValue",
    ]),
    ...mapMutations([showLoading, hideLoading]),
    refreshChangeTypeFilter: function () {
      this.filterResult();
    },
    clearChangeTypeFilter: function () {
      this.filterResult();
    },
    setChangeTypeFilter() {
      this.loadChangeType();
      this.filterResult();
    },
    filterResult() {
      this.$emit("change");
    },
    loadChangeType() {
      this.updateChangeTypeOldValue(null);
      this.updateChangeTypeNewValue(null);

      const changeType = this.chosenFilters.changeTypeFilter.changeType;
      if (changeType != null && changeType !== "") {
        this.showLoading();
        changesService
          .getChangeTypeFilterDetails(changeType)
          .then((response) => {
            this.settings = new ChangeTypeFilterSettingsModel(response.data);
            this.hideLoading();
          });
      } else {
        this.settings = new ChangeTypeFilterSettingsModel(null);
      }
    },
  },
});
</script>

<template>
  <div>
    <el-table :data="this.manufacturingCyclesToProcess">
      <el-table-column label="Processing Status">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status.type">{{
            scope.row.status.name
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Affected Case">
        <template slot-scope="scope">
          <router-link
            :to="
              '/ManufacturingCycle/Details/' +
              scope.row.manufacturingCycleId +
              '?useContext=true'
            "
            >{{ scope.row.name }}</router-link
          >
        </template>
      </el-table-column>
      <el-table-column prop="workflow" label="Workflow"></el-table-column>
      <el-table-column
        prop="workflowStep"
        label="Workflow Step"
      ></el-table-column>
      <el-table-column label="State Transition">
        <template slot-scope="scope">
          <el-tag>
            {{ scope.row.stateTransitionPre }}
          </el-tag>
          ➔
          <el-tag>
            {{ scope.row.stateTransitionPost }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["manufacturingCyclesToProcess"],
};
</script>

<template>
  <div>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :add-template="template"
      :add-rules="validationRules"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
    ></crud-screen>
  </div>
</template>
<script>
import Vue from "vue";
import { Event } from "vue-tables-2";

import _ from "lodash";

import { jobStepService } from "../../services/jobstep.service";

import CrudScreen from "../shared/crud/CrudScreen.vue";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      columns: ["name"],
      template: {
        id: {
          component: {
            show: false,
          },
        },
        name: {
          title: "Name",
          value: "",
          component: {
            name: "el-input",
          },
        },
      },
      validationRules: {
        name: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getRecords() {
      jobStepService.loadJobSteps().then((data) => {
        this.records = data.data;
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      jobStepService.addJobStep(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      jobStepService.editJobStep(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

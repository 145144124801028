<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">TRACKING INFORMATION</h1>
      </div>
    </el-row>

    <el-form label-position="top" inline>
      <el-form-item label="REQUEST ID">
        <el-autocomplete
          v-model="filters.requestId"
          :fetch-suggestions="querySearchAsync"
          placeholder="None selected"
          clearable
          style="width: 100%"
          @select="handlePlanIdSelect"
          @clear="handlePlanIdClear"
        >
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="STATUS">
        <el-select
          v-model="chosenStatuses"
          multiple
          clearable
          collapse-tags
          placeholder="None selected"
          style="width: 250px"
        >
          <el-option
            v-for="(status, index) in statuses"
            :key="index"
            :label="status"
            :value="status"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <v-server-table ref="table" :columns="columns" :options="options">
      <div slot="requestId" slot-scope="props">
        <router-link
          v-if="props.row.fulfilmentPlanId !== null"
          :to="'/FulfilmentPlan/Details/' + props.row.fulfilmentPlanId"
          >{{ props.row.requestId }}</router-link
        >
        <div v-else>{{ props.row.requestId }}</div>
      </div>
      <div slot="date" slot-scope="props">
        {{ props.row.date | datetime }}
      </div>
      <div slot="arrivalDate" slot-scope="props">
        {{ props.row.arrivalDate | datetime }}
      </div>
    </v-server-table>
  </div>
</template>
<script>
import Vue from "vue";
import { Event } from "vue-tables-2";
import DateTime from "../../mixins/DateTime";

import _ from "lodash";

import { trackingInformationService } from "../../services/trackinginformation.service";

import { fulfilmentPlanListService } from "../../services/fulfilmentplanlist.service";

export default Vue.extend({
  mixins: [DateTime],
  created() {
    this.loadStatuses();
  },
  mounted() {
    Event.$on("vue-tables.loading", () => {
      this.loading = true;
    });

    Event.$on("vue-tables.loaded", () => {
      this.loading = false;
    });
  },
  computed: {
    chosenStatuses: {
      get: function () {
        return this.filters.chosenStatuses;
      },
      set: function (newValue) {
        this.filters.chosenStatuses = newValue;
        this.$refs.table.getData();
      },
    },
  },
  data() {
    return {
      loading: false,
      statuses: [],
      filters: {
        chosenStatuses: [],
        chosenRequestId: "",
      },
      columns: [
        "requestId",
        "date",
        "trackingNumber",
        "status",
        "additionalInformation",
        "arrivalDate",
        "carrier",
      ],
      options: {
        headings: {
          requestId: "Request Id",
          date: "Date",
          status: "Status",
          additionalInformation: "Additional Information",
          arrivalDate: "Arrival Date",
          trackingNumber: "Tracking Number",
          carrier: "Carrier",
        },
        sortable: [],
        requestFunction: (data) => {
          data.requestId = this.filters.chosenRequestId;
          data.statuses = this.filters.chosenStatuses;

          return trackingInformationService
            .getTrackingInformation(data)
            .catch(function (e) {
              this.dispatch("error", e);
            });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
        filterable: false,
        customFilters: ["chosenRequestId", "chosenStatuses"],
        initFilters: {
          chosenRequestId: this.filter && this.filters.chosenRequestId,
          chosenStatuses: (this.filters && this.filters.chosenStatuses) || [],
        },
      },
    };
  },
  methods: {
    loadStatuses() {
      return trackingInformationService
        .getTrackingInformationStatuses()
        .then((response) => {
          this.statuses = response.data;
        });
    },
    querySearchAsync(queryString, cb) {
      fulfilmentPlanListService.filterRequests(queryString).then((response) => {
        const result = response.data;
        cb(
          result.data.map(function (x) {
            return { value: x };
          })
        );
      });
    },
    handlePlanIdSelect(item) {
      this.filters.chosenRequestId = item.value;
      this.$refs.table.getData();
    },
    handlePlanIdClear() {
      this.filters.chosenRequestId = "";
      this.$refs.table.getData();
    },
  },
});
</script>

<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "OidcCallback",
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback"]),
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        window.history.replaceState(
          {},
          window.document.title,
          window.location.origin + window.location.pathname
        );
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        console.error(err);
        this.$router.push("/oidc-callback-error"); // Handle errors any way you want
      });
  },
};
</script>

import moment from "moment";
export default class ChangesModel {
  field: string;
  user: string;
  date: string;
  oldValue: string;
  newValue: string;

  constructor(data) {
    if (data !== null) {
      this.user = data.userSymbol;
      this.date = moment(data.time).format("YYYY-MM-DD HH:mm:ss");
      this.oldValue = data.oldValue;
      this.newValue = data.newValue;
      this.field = data.field;
    }
  }
}

<template>
  <el-card>
    <div slot="header">
      <h4>EIFU DATA</h4>
    </div>
    <el-form size="mini" label-width="150px">
      <el-form-item label="Description" class="form-item-padding">
        <div style="padding-left: 15px">
          <editable-input
            v-model="eIfuData.eIFUName"
            @input="editName"
            :editable="eDocPermissions.CanManageDocuments"
          ></editable-input>
        </div>
      </el-form-item>
      <el-form-item label="Portal" class="form-item-padding">
        <div>
          <editable-select
            v-model="eIfuData.portalId"
            :select-list="availablePortals"
            @input="editPortal"
            :editable="eDocPermissions.CanManageDocuments"
          ></editable-select>
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { showLoading, hideLoading } from "../../../store/mutation-types";
import { EditableInput, EditableSelect } from "../../shared/Shared.vue";
import DocumentEIFUDataModel from "../../../models/edoc/DocumentEIFUDataModel";
import { getPortals } from "../../../store/modules/mutationTypes";
import { documentService } from "../../../services/edoc/document.service";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  components: {
    EditableInput,
    EditableSelect,
  },
  props: {
    eIfuData: {
      type: Object,
      default: () => new DocumentEIFUDataModel(),
    },
  },
  created() {
    if (this.availablePortals == null || this.availablePortals.length === 0) {
      this.getPortals();
    }
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
    availablePortals() {
      return this.$store.state.document.availablePortals;
    },
    availableRegions() {
      return this.$store.state.document.availableRegions;
    },
    documentId() {
      return this.$store.state.document.documentId;
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapActions("document", [getPortals]),
    editName() {
      this.showLoading();
      documentService
        .editEIFUName(this.documentId, this.eIfuData.eIFUName)
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    editPortal() {
      this.showLoading();
      documentService
        .editEIFUPortal(this.documentId, this.eIfuData.portalId)
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    processEditSuccessResponse() {
      this.showNotification(Resources.DocumentEditSuccess, "success");
    },
    processEditErrorResponse() {
      this.showNotification(Resources.DocumentEditError, "error");
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    reloadData() {
      this.hideLoading();
      this.$nextTick(() => {
        this.$emit("reload-data");
      });
    },
  },
});
</script>
<style lang="scss">
h4 {
  padding-left: 20px;
}

.region {
  font-size: 12px;
  padding-left: 15px;
}

.region button {
  white-space: normal;
  text-align: left;
  word-wrap: break-word;
}
</style>

import operationType from "../enums/OperationType";

import _ from "lodash";

export default {
  methods: {
    handleEdit(item) {
      this.formMode = operationType.edit;
      this.isDialogVisible = true;

      this.formData = this.editTemplate ? _.cloneDeep(this.editTemplate) : {};
      this.editTemplateStorage = this.editTemplate
        ? _.cloneDeep(this.editTemplate)
        : {};

      _.forEach(this.formData, (value, key) => {
        this.formData[key] = Object.prototype.hasOwnProperty.call(item, key)
          ? item[key]
          : "";
      });
    },
  },
};

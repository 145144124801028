import { manufacturingCycleListService } from "../../services/manufacturingcyclelist.service";
import FiltersModel from "../../models/manufacturing-cycle/FiltersModel";
import ChosenFiltersModel from "../../models/manufacturing-cycle/ChosenFiltersModel";
import ManufacturingCycleTabsModel from "../../models/manufacturing-cycle/ManufacturingCycleTabsModel";
import { ManufacturingCycleTabType } from "../../enums/ManufacturingCycleTabType";
import moment from "moment";
import ManufacturingCycleAdditionalFiltersModel from "../../models/manufacturing-cycle/ManufacturingCycleAdditionalFiltersModel";
import ManufacturingCycleAdditionalFiltersItem from "../../models/manufacturing-cycle/ManufacturingCycleAdditionalFiltersItem";
import { DateFrequency } from "../../enums/enums";
import { debounce } from "lodash";

function prepareRelativeDateFilter(filters: ChosenFiltersModel) {
  filters.startDate = null;
  filters.endDate = null;
  filters.dateRange = null;

  if (!filters.relativeDateModifier) {
    filters.relativeDateModifier = 0;
  }
  if (!filters.relativeDateFrequency) {
    filters.relativeDateFrequency = 0;
  }

  if (filters.relativeDateAmount && Number(filters.relativeDateAmount) >= 0) {
    if (filters.relativeDateModifier > 0) {
      filters.endDate = moment()
        .endOf("day")
        .add(
          filters.relativeDateModifier === 0
            ? -filters.relativeDateAmount
            : filters.relativeDateAmount,
          DateFrequency[
            filters.relativeDateFrequency
          ].toLowerCase() as moment.unitOfTime.DurationConstructor
        )
        .toDate();
      filters.startDate = moment().startOf("day").toDate();
    } else {
      filters.startDate = moment()
        .startOf("day")
        .add(
          filters.relativeDateModifier === 0
            ? -filters.relativeDateAmount
            : filters.relativeDateAmount,
          DateFrequency[
            filters.relativeDateFrequency
          ].toLowerCase() as moment.unitOfTime.DurationConstructor
        )
        .toDate();
      filters.endDate = moment().endOf("day").toDate();
    }

    filters.isRelativeDate = true;
  } else {
    filters.startDate = null;
    filters.endDate = null;
    filters.isRelativeDate = false;
  }
}

export default {
  namespaced: true,
  state: {
    availableProductTypes: [],
    availableDesignStatuses: [],
    availableProcessingStatuses: [],
    availableManufacturingLocations: [],
    availableJobSteps: [],
    allTab: new ManufacturingCycleTabsModel(
      { name: "All" },
      ManufacturingCycleTabType.All
    ),
    criticalTab: new ManufacturingCycleTabsModel(
      { name: "Critical" },
      ManufacturingCycleTabType.Critical
    ),
    filtersLoaded: false,

    productTypeTabs: [],
    requestTypeTabs: [],

    filters: new ChosenFiltersModel(null),
    additionalFilters: new ManufacturingCycleAdditionalFiltersModel(null),

    filtersInitialized: false,
    availableSortColumnsOrder: [
      "requestIdWithManufacturingRevision",
      "dueDate",
      "manufacturingProgress",
      "productSymbol",
      "designStatusDescription",
    ],
    activeColumns: [],
    sortedColumns: [],
    initSortedColumns: false,
    clearFilters: false,
    selectedRecordId: null,
    page: null,
    updateSortedColumns: false,

    refresh: false,
  },
  getters: {
    leadTimesColumns: (state) => {
      const jobSteps = state.availableJobSteps;
      return state.filters.selectedCycleMilestones.map((m) => ({
        jobStepId: m.jobStepId,
        name: `${jobSteps.find((js) => js.value == m.jobStepId).label} ${
          m.criteria == 0 ? "Actual" : "Planned"
        } End`,
      }));
    },
    selectedAdditionalFilters: (state) => {
      return state.additionalFilters.filters.filter((f) => f.checked);
    },
  },
  mutations: {
    storeManufacturingCycleListData(state, value) {
      state.availableColumns = value.availableColumns;
      state.availableProductTypes = value.availableProductTypes;
      state.availableDesignStatuses = value.availableDesignStatuses;
      state.availableProcessingStatuses = value.availableProcessingStatuses;
      state.requestTypeTabs = value.requestTypeTabs;
      state.productTypeTabs = value.productTypeTabs;
      state.availableManufacturingLocations =
        value.availableManufacturingLocations;
      state.availableJobSteps = value.availableJobSteps;

      state.filtersLoaded = true;
    },
    setRequestId(state, value) {
      if (value) {
        state.filters.requestId = value;
      } else {
        state.filters.requestId = null;
      }
    },
    setChosenDesignStatuses(state, value) {
      state.filters.chosenDesignStatuses = value;
    },
    setChosenProcessingStatuses(state, value) {
      state.filters.chosenProcessingStatuses = value;
    },
    setChosenManufacturingLocations(state, value) {
      state.filters.chosenManufacturingLocations = value;
    },
    setCurrentDateTabValue(state, value) {
      state.filters.currentDateTabValue = value;
    },
    setChosenJobSteps(state, value) {
      state.filters.chosenJobSteps = value;
    },
    setDateRange(state, value) {
      state.filters.relativeDateFrequency = 0;
      state.filters.relativeDateModifier = 0;
      state.filters.relativeDateAmount = null;

      if (value && value.length > 0) {
        state.filters.startDate = moment.parseZone(value[0]).utc().format();
        state.filters.endDate = moment
          .parseZone(value[1])
          .endOf("day")
          .utc()
          .format();
      } else {
        state.filters.startDate = null;
        state.filters.endDate = null;
      }
      state.filters.isRelativeDate = false;

      state.filters.dateRange = value;
    },
    setRelativeDateModifier(state, value) {
      state.filters.relativeDateModifier = value;
      prepareRelativeDateFilter(state.filters);
    },
    setRelativeDateAmount(state, value) {
      state.filters.relativeDateAmount = value;
      prepareRelativeDateFilter(state.filters);
    },
    setRelativeDateFrequency(state, value) {
      state.filters.relativeDateFrequency = value;
      prepareRelativeDateFilter(state.filters);
    },
    setChosenTab(state, value: ManufacturingCycleTabsModel) {
      state.filters.chosenTab = value;

      state.filters.productType = null;
      state.filters.isCritical = false;
      state.filters.requestTypeId = null;

      if (value.type === ManufacturingCycleTabType.ProductType) {
        state.filters.productType = value.id;
      } else if (value.type === ManufacturingCycleTabType.RequestType) {
        state.filters.requestTypeId = value.id;
      } else if (value.type === ManufacturingCycleTabType.Critical) {
        state.filters.isCritical = true;
      }
    },
    filterByProductType(state, value) {
      state.filters.isCritical = null;
      state.filters.requestTypeId = null;
      state.filters.productType = value.id;
    },
    filterByAllProductType(state) {
      state.filters.isCritical = null;
      state.filters.requestTypeId = null;
      state.filters.productType = 0;
    },
    filterByRequestType(state, value) {
      state.filters.isCritical = null;
      state.filters.productType = 0;
      state.filters.requestTypeId = value.Id;
    },
    filterCritical(state) {
      state.filters.isCritical = true;
      state.filters.requestTypeId = null;
      state.filters.productType = 0;
    },

    clearFilters(state) {
      state.filters.storedQueryActiveDisplayName = "";
      state.filters.planId = null;
      state.filters.requestId = "";
      state.filters.chosenProductTypes = [];
      state.filters.chosenDateFilter = null;
      state.filters.chosenDesignStatuses = [];
      state.filters.chosenProcessingStatuses = [];
      state.filters.chosenDate = null;
      state.filters.dateType = 0;
      state.filters.startDate = null;
      state.filters.endDate = null;
      state.filters.dateRange = [];
      state.filters.isRelativeDate = false;
      state.filters.relativeDateAmount = null;
      state.filters.relativeDateFrequency = 0;
      state.filters.relativeDateModifier = 0;
      state.filters.chosenManufacturingLocations = [];
      state.filters.currentDateTabValue = "0";
      state.filters.chosenJobSteps = [];
      state.filters.selectedCycleMilestones = [];
      state.sortedColumns = [];
    },
    clearDateFilters(state) {
      state.filters.startDate = null;
      state.filters.endDate = null;
      state.filters.dateRange = [];
      state.filters.relativeDateFrequency = 0;
      state.filters.relativeDateModifier = 0;
      state.filters.relativeDateAmount = null;
      state.filters.isRelativeDate = false;
    },
    storeFilters(state, value) {
      state.filters = value;
    },
    storeFiltersInitialized(state, value) {
      state.filtersInitialized = value;
    },
    storeAdditionalFiltersInitialized(state, value) {
      state.additionalFiltersInitialized = value;
    },
    storeAdditionalFilters(state, value) {
      state.additionalFilters = value;
    },
    selectAdditionalFilter(
      state,
      value: ManufacturingCycleAdditionalFiltersItem
    ) {
      if (value) {
        const filter = state.additionalFilters.filters.find(
          (f) => f.id === value.id
        );
        filter.checked = true;
        filter.selectedValues = value.selectedValues;
        const index = state.additionalFilters.filters.indexOf(filter);
        state.additionalFilters.filters.splice(index, 1, filter);
      }
    },
    unselectAdditionalFilter(
      state,
      value: ManufacturingCycleAdditionalFiltersItem
    ) {
      if (value) {
        const filter = state.additionalFilters.filters.find(
          (f) => f.id === value.id
        );
        filter.checked = false;
        filter.selectedValues = [];
        const index = state.additionalFilters.filters.indexOf(filter);
        state.additionalFilters.filters.splice(index, 1, filter);
      }
    },

    loadNotificationNumbers(state, value) {
      value.productTypeNotificationCategoryNumbers.forEach((item) => {
        state.productTypeTabs.find(
          (x) => x.id === item.id
        ).notificationsNumber = item.count;
      });
      value.requestTypeNotificationCategoryNumbers.forEach((item) => {
        state.requestTypeTabs.find(
          (x) => x.id === item.id
        ).notificationsNumber = item.count;
      });
      state.allTab.notificationsNumber = value.allCategory.count;
      state.criticalTab.notificationsNumber = value.critical.count;
    },

    storeActiveColumns(state, value) {
      state.activeColumns = value;
    },

    storeAvailableColumns(state, value) {
      state.availableColumns = value;
    },
    storeSortedColumns(state, value) {
      state.sortedColumns = value;
    },
    storeInitSortedColumns(state, value) {
      state.initSortedColumns = value;
    },
    storeUpdateSortedColumns(state, value) {
      state.updateSortedColumns = value;
    },
    storeQueryActiveDisplayName(state, value) {
      state.storedQueryActiveDisplayName = value;
    },
    storeSelectedRecordId(state, value) {
      state.selectedRecordId = value;
    },
    storePage(state, value) {
      state.page = value;
    },

    refresh(state) {
      state.refresh = true;
    },
    refreshed(state) {
      state.refresh = false;
    },
  },
  actions: {
    async loadFilters({ commit, dispatch, state }) {
      if (state.filtersLoaded) {
        return;
      }

      const response = await manufacturingCycleListService.getListFilters();
      const model = new FiltersModel(response.data);
      commit("storeManufacturingCycleListData", model);

      dispatch("loadNotificationNumbers");

      const additionalFiltersResponse =
        await manufacturingCycleListService.getListAdditionalFilters();
      const data = additionalFiltersResponse.data;
      commit(
        "storeAdditionalFilters",
        new ManufacturingCycleAdditionalFiltersModel(data)
      );
      commit("storeAdditionalFiltersInitialized", true);
    },
    async loadChosenFilters(
      { commit, dispatch, getters, state },
      storedQueryId = null
    ) {
      const response = await manufacturingCycleListService.getChosenFilters(
        storedQueryId
      );
      const data = response.data;
      const filters = new ChosenFiltersModel(data.filter);

      if (filters.isRelativeDate) {
        prepareRelativeDateFilter(filters);
      }

      if (filters.productType === 0) {
        if (filters.requestTypeId === null) {
          filters.chosenTab = state.allTab;
        } else if (filters.requestTypeId !== null) {
          filters.chosenTab = state.requestTypeTabs.find(
            (x) => x.id === filters.requestTypeId
          );
        }
        if (filters.isCritical) {
          filters.chosenTab = state.criticalTab;
        }
      } else {
        filters.chosenTab = state.productTypeTabs.find(
          (x) => x.id === filters.productType
        );
      }

      commit("storeFilters", filters);

      getters.selectedAdditionalFilters.forEach((f) =>
        commit("unselectAdditionalFilter", f)
      );
      if (data.filter.selectedAdditionalFilters) {
        data.filter.selectedAdditionalFilters.forEach((f) =>
          commit("selectAdditionalFilter", f)
        );
      }
      let list = [];
      if (data.filter.sortColumns && data.filter.sortColumns.length > 0) {
        list = data.filter.sortColumns.map((sortColumn) => {
          const currentSortColumn =
            state.availableSortColumnsOrder[sortColumn.sortedColumn];

          if (currentSortColumn == null && sortColumn.jobStepId != null) {
            const column = getters.leadTimesColumns.find(
              (c) => c.jobStepId === sortColumn.jobStepId
            ).name;
            return {
              column: column,
              ascending: sortColumn.sortOrder === 1,
            };
          }

          return {
            column: currentSortColumn,
            ascending: sortColumn.sortOrder === 1,
          };
        });
      }
      commit("storeSortedColumns", list);
      commit("storeInitSortedColumns", true);

      if (filters.storedQueryActiveDisplayName) {
        dispatch(
          "storeQueryActiveDisplayName",
          data.filter.storedQueryActiveDisplayName
        );
      }

      if (storedQueryId == null) {
        dispatch("loadNotificationNumbers");
        dispatch("refresh");
      }
    },
    async loadNotificationNumbers({ commit }) {
      manufacturingCycleListService
        .getNotificationNumbers()
        .then((response) => {
          commit("loadNotificationNumbers", response.data.notificationNumbers);
        });
    },

    setRequestId({ commit, dispatch }, value) {
      commit("setRequestId", value);
      dispatch("refresh");
    },
    setChosenDesignStatuses({ commit, dispatch }, value) {
      commit("setChosenDesignStatuses", value);
      dispatch("refresh");
    },
    setChosenProcessingStatuses({ commit, dispatch }, value) {
      commit("setChosenProcessingStatuses", value);
      dispatch("refresh");
    },
    setChosenManufacturingLocations({ commit, dispatch }, value) {
      commit("setChosenManufacturingLocations", value);
      dispatch("refresh");
    },
    setCurrentDateTabValue({ commit, dispatch }, value) {
      commit("setCurrentDateTabValue", value);
    },
    setChosenJobSteps({ commit, dispatch }, value) {
      commit("setChosenJobSteps", value);
      dispatch("refresh");
    },
    setDateRange({ commit, dispatch }, value) {
      commit("setDateRange", value);
      dispatch("refresh");
    },
    setRelativeDateModifier({ commit, dispatch }, value) {
      commit("setRelativeDateModifier", value);
      dispatch("refresh");
    },
    setRelativeDateAmount({ commit, dispatch }, value) {
      commit("setRelativeDateAmount", value);
      dispatch("refresh");
    },
    setRelativeDateFrequency({ commit, dispatch }, value) {
      commit("setRelativeDateFrequency", value);
      dispatch("refresh");
    },
    setChosenTab({ commit, dispatch }, value) {
      commit("setChosenTab", value);
      dispatch("refresh");
    },
    clearFilters({ commit, getters, dispatch }) {
      commit("clearFilters");
      getters.selectedAdditionalFilters.forEach((f) =>
        commit("unselectAdditionalFilter", f)
      );
      dispatch("refresh");
    },
    clearDateFilters({ commit, dispatch }) {
      commit("clearDateFilters");
      dispatch("refresh");
    },

    filterByProductType({ commit, dispatch }, value) {
      commit("filterByProductType", value);
      dispatch("refresh");
    },
    filterByRequestType({ commit, dispatch }, value) {
      commit("filterByRequestType", value);
      dispatch("refresh");
    },
    filterCritical({ commit, dispatch }) {
      commit("filterCritical");
      dispatch("refresh");
    },
    filterByAllProductType({ commit, dispatch }) {
      commit("filterByAllProductType");
      dispatch("refresh");
    },

    selectAdditionalFilter({ commit }, value) {
      commit("selectAdditionalFilter", value);
    },
    unselectAdditionalFilter({ commit }, value) {
      commit("unselectAdditionalFilter", value);
    },

    storeFiltersInitialized(context, value) {
      context.commit("storeFiltersInitialized", value);
    },
    storeActiveColumns(context, value) {
      context.commit("storeActiveColumns", value);
    },
    storeSortedColumns(context, value) {
      context.commit("storeSortedColumns", value);
    },
    storeAvailableColumns(context, value) {
      context.commit("storeAvailableColumns", value);
    },
    storeInitSortedColumns(context, value) {
      context.commit("storeInitSortedColumns", value);
    },
    storeManufacturingCycleListData(context, value) {
      context.commit("storeManufacturingCycleListData", value);
    },
    storeUpdateSortedColumns(context, value) {
      context.commit("storeUpdateSortedColumns", value);
    },
    storeQueryActiveDisplayName(context, value) {
      context.commit("storeQueryActiveDisplayName", value);
    },
    storeSelectedRecordId(context, value) {
      context.commit("storeSelectedRecordId", value);
    },
    storePage(context, value) {
      context.commit("storePage", value);
    },
    refresh: debounce((context) => {
      context.commit("refresh");
    }, 500),
    refreshed(context) {
      context.commit("refreshed");
    },
  },
};

import DocumentFileModel from "./DocumentFileModel";
import moment from "moment";
export default class DocumentRevisionModel {
  id: number;
  name: string;
  issueDate: string;
  incompatible: boolean;
  hasAssociatedProductVersions: boolean;
  isPartOfKeyCode: boolean;
  versionName: string;
  files: DocumentFileModel[];

  constructor(data: any = null) {
    if (data !== null) {
      this.id = data.id;
      this.name = data.name;
      this.issueDate = moment(data.issueDate).format("YYYY-MM-DD");
      this.incompatible = !data.isCompatible;
      this.hasAssociatedProductVersions = data.hasAssociatedProductVersions;
      this.isPartOfKeyCode = data.isPartOfKeyCode;
      this.versionName = data.versionName;
      this.files = [];

      for (const file of data.documentFiles) {
        const fileModel = new DocumentFileModel(file);
        this.files.push(fileModel);
      }
    }
  }
}

import { Resources } from "./Resources";

export class ResourcesHelper {
  public static getValidationMessage(errors) {
    const translatedErrors = errors.map(this.translateErrorCode);
    const formattedErrors = translatedErrors.join(" ");

    return formattedErrors;
  }
  private static translateErrorCode(errorCode) {
    let translatedError = Resources[errorCode];

    if (translatedError === null) {
      translatedError = errorCode;
    }

    return translatedError;
  }
}

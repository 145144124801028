



import Vue from "vue";
import { merge } from "lodash";
export default Vue.extend({
  async created() {
    const getEDocPermissions = this.$store.dispatch(
      "permissions/getAssignedEDocPermissions"
    );
    const getPermissions = this.$store.dispatch(
      "permissions/getAssignedPermissions"
    );
    const getDesignSessionPermissions = this.$store.dispatch(
      "permissions/getAssignedDesignSessionPermissions"
    );
    const permissionsPromise = await Promise.all([
      getPermissions,
      getEDocPermissions,
      getDesignSessionPermissions,
    ]);

    const permissions = merge(
      {},
      permissionsPromise[0],
      permissionsPromise[1],
      permissionsPromise[2]
    );

    if (
      permissions["CanManageFulfilmentPlan"] ||
      permissions["ReadOnlyFulfilmentPlanAccess"]
    ) {
      return this.$router.replace("./FulfilmentPlan/List");
    }
    if (
      permissions["CanManageManufacturingCycles"] ||
      permissions["ReadOnlyManufacturingCycleAccess"]
    ) {
      return this.$router.replace("./ManufacturingCycle/List");
    }
    if (permissions["IsDesigner"]) {
      return this.$router.replace("./Designer/Schedule");
    }
    if (
      permissions["CanManageDesignSessionSettings"] ||
      permissions["CanManageDesignSessions"]
    ) {
      return this.$router.replace("./Designer/Composite");
    }

    return this.$router.replace("./Documentation/Document");
  },
});

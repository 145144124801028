<template>
  <div>
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">DOCUMENT DETAILS</h1>
      </div>
      <div class="fright" v-if="allDocuments">
        <router-link
          :to="previousDocumentUrl"
          :event="previousDocument === null ? '' : 'click'"
        >
          <el-button
            :disabled="previousDocument === null"
            icon="el-icon-arrow-up"
            round
            size="small"
            :title="previousDocumentText"
          ></el-button>
        </router-link>
        <span>{{ currentItemIndex + 1 }} of {{ allDocuments.length }}</span>
        <router-link
          :to="nextDocumentUrl"
          :event="nextDocument === null ? '' : 'click'"
        >
          <el-button
            :disabled="nextDocument === null"
            icon="el-icon-arrow-down"
            round
            size="small"
            :title="nextDocumentText"
          ></el-button>
        </router-link>
      </div>
      <div class="fright">
        <router-link
          :to="'/Documentation/Document' + queryString"
          class="el-button el-button--text"
          style="padding: 10px 0px 0px 0px"
          >Go to documents list</router-link
        >
      </div>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <document-information
          :document-information="documentInformation"
          @reload-data="loadData"
        ></document-information>
      </el-col>
      <el-col :span="12">
        <document-eifu-data
          :e-ifu-data="eIfuData"
          @reload-data="loadData"
        ></document-eifu-data>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <document-revisions-list
          :revisions-list="revisionsList"
          @reload-data="loadData"
        ></document-revisions-list>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <associated-products-list
          :associations="associations"
        ></associated-products-list>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <changes-list :changes="changes"></changes-list>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import qs from "qs";

import { documentService } from "../../../services/edoc/document.service";
import DocumentInformation from "./DocumentInformation.vue";
import DocumentEifuData from "./DocumentEifuData.vue";
import DocumentRevisionsList from "./DocumentRevisionsList.vue";
import AssociatedProductsList from "./AssociatedProductsList.vue";
import ChangesList from "../shared/ChangesList.vue";

import DocumentInformationModel from "../../../models/edoc/DocumentInformationModel";
import DocumentEIFUDataModel from "../../../models/edoc/DocumentEIFUDataModel";
import DocumentRevisionsListModel from "../../../models/edoc/DocumentRevisionsListModel";

import { storeDocumentId } from "../../../store/modules/mutationTypes";
import { showLoading, hideLoading } from "../../../store/mutation-types";

export default Vue.extend({
  components: {
    DocumentInformation,
    DocumentEifuData,
    DocumentRevisionsList,
    AssociatedProductsList,
    ChangesList,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      documentInformation: new DocumentInformationModel(),
      eIfuData: new DocumentEIFUDataModel(),
      revisionsList: new DocumentRevisionsListModel(),
      associations: [],
      changes: [],
      allDocuments: null,
      loadedQuery: null,
    };
  },
  computed: {
    queryString: function () {
      var result = qs.stringify(this.$route.query);

      return result ? "?" + result : "";
    },
    previousDocument() {
      let currentItem = this.allDocuments.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return (
        this.allDocuments[this.allDocuments.indexOf(currentItem) - 1] || null
      );
    },
    previousDocumentUrl() {
      return this.previousDocument !== null
        ? "/Documentation/Document/" +
            this.previousDocument.id +
            this.queryString
        : "";
    },
    previousDocumentText() {
      return this.previousDocument !== null
        ? "Previous Document: " + this.previousDocument.number
        : "Not available";
    },
    nextDocument() {
      let currentItem = this.allDocuments.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return (
        this.allDocuments[this.allDocuments.indexOf(currentItem) + 1] || null
      );
    },
    nextDocumentUrl() {
      return this.nextDocument !== null
        ? "/Documentation/Document/" + this.nextDocument.id + this.queryString
        : "";
    },
    nextDocumentText() {
      return this.nextDocument !== null
        ? "Next Document: " + this.nextDocument.number
        : "Not available";
    },
    currentItemIndex() {
      let currentItem = this.allDocuments.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return this.allDocuments.indexOf(currentItem);
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapMutations("document", [storeDocumentId]),
    loadData() {
      this.showLoading();
      this.storeDocumentId(this.$route.params.id);

      this.getDocument().then(() => {
        this.hideLoading();
      });

      if (this.loadedQuery !== this.queryString) {
        this.getAllDocuments();
      }
    },
    getDocument() {
      var promise = documentService.getDocument(
        this.$store.state.document.documentId
      );
      promise.then((response) => {
        this.documentInformation = new DocumentInformationModel(response.data);
        this.eIfuData = new DocumentEIFUDataModel(response.data);
        this.revisionsList = new DocumentRevisionsListModel(response.data);
        this.associations = response.data.associations;
        this.changes = response.data.changes;
      });

      return promise;
    },
    getAllDocuments() {
      this.loadedQuery = this.queryString;

      documentService
        .getDocuments(qs.parse(this.$route.query))
        .then((response) => {
          this.allDocuments = response.data.data;
        });
    },
  },
  watch: {
    "$route.params.id": function (id) {
      this.loadData();
    },
  },
});
</script>

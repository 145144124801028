import { ChangesChosenFiltersModel } from "../../models/ChangesChosenFiltersModel";
const getters = {};

const state = {
  chosenFilters: new ChangesChosenFiltersModel(null),
};

const actions = {};

const mutations = {
  clearChosenFilters(state) {
    state.chosenFilters = new ChangesChosenFiltersModel(null);
  },
  setChosenFilters(state, value) {
    state.chosenFilters = value;
  },
  chooseRequestId(state, value) {
    state.chosenFilters.requestId = value;
  },
  chooseProducts(state, value) {
    state.chosenFilters.chosenProducts = value;
  },
  chooseDistributionCenters(state, value) {
    state.chosenFilters.chosenDistributionCenters = value;
  },
  chooseUserId(state, value) {
    state.chosenFilters.userId = value;
  },
  updateChangeType(state, value) {
    state.chosenFilters.changeTypeFilter.changeType = value;
  },
  updateChangeTypeOldValue(state, value) {
    state.chosenFilters.changeTypeFilter.oldValue = value;
  },
  updateChangeTypeNewValue(state, value) {
    state.chosenFilters.changeTypeFilter.newValue = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

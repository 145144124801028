<template>
  <el-card>
    <div slot="header">
      <h4>DOCUMENT INFORMATION</h4>
    </div>
    <el-form size="mini" label-width="140px">
      <el-form-item label="Division" class="form-item-padding">
        <div>
          <editable-select
            v-model="documentInformation.divisionId"
            :select-list="availableDivisions"
            @input="editDivision"
            :editable="eDocPermissions.CanManageDocuments"
          ></editable-select>
        </div>
      </el-form-item>
      <el-form-item label="Name" class="form-item-padding">
        <div style="padding-left: 15px">
          <editable-input
            v-model="documentInformation.name"
            @input="editDocumentName"
            :editable="eDocPermissions.CanManageDocuments"
          ></editable-input>
        </div>
      </el-form-item>
      <el-form-item label="Type" class="form-item-padding">
        <div>
          <editable-select
            v-model="documentInformation.typeId"
            :select-list="availableTypes"
            @input="editType"
            :editable="eDocPermissions.CanManageDocuments"
          ></editable-select>
        </div>
      </el-form-item>
      <el-form-item label="Number" class="form-item-padding">
        <div class="readonly-data">{{ documentInformation.number }}</div>
      </el-form-item>
      <el-form-item label="Latest Revision" class="form-item-padding">
        <div class="readonly-data">
          {{ documentInformation.latestRevisionName }}
        </div>
      </el-form-item>
      <el-form-item label="Is Versioned" class="form-item-padding">
        <el-switch
          class="switch"
          v-model="documentInformation.isVersioned"
          :disabled="
            !documentInformation.isVersionedEditable ||
            !eDocPermissions.CanManageDocuments
          "
          @change="editIsVersioned"
        ></el-switch>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import Vue from "vue";
import { EditableInput, EditableSelect } from "../../shared/Shared.vue";
import { mapActions, mapState } from "vuex";
import DocumentInformationModel from "../../../models/edoc/DocumentInformationModel";
import {
  getDivisions,
  getDocumentTypes,
} from "../../../store/modules/mutationTypes";
import { documentService } from "../../../services/edoc/document.service";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  components: {
    EditableInput,
    EditableSelect,
  },
  props: {
    documentInformation: {
      type: Object,
      default: () => new DocumentInformationModel(),
    },
  },
  created() {
    if (
      this.availableDivisions == null ||
      this.availableDivisions.length === 0
    ) {
      this.getDivisions();
    }
    if (this.availableTypes == null || this.availableTypes.length === 0) {
      this.getDocumentTypes();
    }
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
    availableDivisions() {
      return this.$store.state.document.availableDivisions;
    },
    availableTypes() {
      return this.$store.state.document.availableTypes;
    },
  },
  methods: {
    ...mapActions("document", [getDivisions, getDocumentTypes]),
    editDocumentName() {
      documentService
        .editDocumentName(
          this.$store.state.document.documentId,
          this.documentInformation.name
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    editDivision() {
      documentService
        .editDocumentDivision(
          this.$store.state.document.documentId,
          this.documentInformation.divisionId
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    editType() {
      documentService
        .editDocumentType(
          this.$store.state.document.documentId,
          this.documentInformation.typeId
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    editIsVersioned() {
      documentService
        .editIsVersioned(
          this.$store.state.document.documentId,
          this.documentInformation.isVersioned
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    processEditSuccessResponse() {
      this.showNotification(Resources.DocumentEditSuccess, "success");
    },
    processEditErrorResponse() {
      this.showNotification(Resources.DocumentEditError, "error");
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    reloadData() {
      this.$nextTick(() => {
        this.$emit("reload-data");
      });
    },
  },
});
</script>
<style lang="scss">
.readonly-data {
  font-size: 12px;
  padding-left: 15px;
}

h4 {
  padding-left: 20px;
}
</style>

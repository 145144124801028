<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row v-if="addTemplate">
      <el-col>
        <el-button v-if="addTemplate" class="crud-add-button" @click="handleAdd"
          >Add</el-button
        >
      </el-col>
    </el-row>
    <v-client-table
      class="crud-table"
      :data="data"
      :columns="allTableColumns"
      :options="allTableOptions"
    >
      <div
        v-if="editTemplate || deletable"
        slot="operations"
        slot-scope="scope"
      >
        <el-button
          :disabled="!editTemplate"
          size="mini"
          @click="handleEdit(scope.row)"
          >Edit</el-button
        >
        <el-button
          :disabled="!deletable || scope.row.canBeDeleted === false"
          size="mini"
          type="danger"
          @click="handleRemove(scope.row)"
          >Delete</el-button
        >
      </div>
    </v-client-table>
    <el-dialog
      v-if="addTemplate || editTemplate"
      :title="dialogTitle"
      :visible.sync="isDialogVisible"
      v-bind="formOptions"
    >
      <el-form
        ref="form"
        v-if="formData"
        :model="formData"
        :rules="handleFormRulesMode()"
        v-bind="formOptions"
      >
        <template v-for="(value, key) in formData">
          <template
            v-if="
              handleFormTemplateMode(key).component
                ? handleAttribute(
                    handleFormTemplateMode(key).component.show,
                    true
                  )
                : true
            "
          >
            <el-form-item
              :label="handleFormTemplateMode(key).title"
              :prop="key"
              :key="key"
            >
              <el-input
                v-if="
                  !handleFormTemplateMode(key).component ||
                  !handleFormTemplateMode(key).component.name ||
                  handleFormTemplateMode(key).component.name === 'el-input'
                "
                v-model="formData[key]"
                v-bind="handleFormTemplateMode(key).component"
              ></el-input>
              <el-select
                v-else-if="
                  handleFormTemplateMode(key).component.name === 'el-select'
                "
                v-model="formData[key]"
                v-bind="handleFormTemplateMode(key).component"
              >
                <el-option
                  v-for="option in handleFormTemplateMode(key).component
                    .options"
                  :key="option.value"
                  v-bind="option"
                ></el-option>
              </el-select>
              <el-checkbox
                v-else-if="
                  handleFormTemplateMode(key).component.name === 'el-checkbox'
                "
                v-model="formData[key]"
                v-bind="handleFormTemplateMode(key).component"
              ></el-checkbox>
              <el-time-select
                v-else-if="
                  handleFormTemplateMode(key).component.name ===
                  'el-time-select'
                "
                v-model="formData[key]"
                v-bind="handleFormTemplateMode(key).component"
              ></el-time-select>
            </el-form-item>
          </template>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDialogCancel">Cancel</el-button>
        <el-button type="primary" @click="handleDialogSave">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";

import utils from "./mixins/utils";
import add from "./mixins/add";
import edit from "./mixins/edit";
import remove from "./mixins/remove";
import dialog from "./mixins/dialog";

export default Vue.extend({
  mixins: [utils, add, edit, remove, dialog],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: Array,
    columns: Array,
    tableOptions: {
      type: Object,
      default: null,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allTableOptions: function () {
      let defaults = {
        sortable: this.columns,
      };

      return {
        ...defaults,
        ...this.tableOptions,
      };
    },
    allTableColumns: function () {
      if (this.editTemplate) {
        return [...this.columns, "operations"];
      }

      return this.columns;
    },
  },
});
</script>
























































import { manufacturingCycleBulkOperationsService } from "@/services/manufacturingcyclebulkoperations.service";
import { mapState, mapMutations } from "vuex";
import { showLoading, hideLoading } from "@/store/mutation-types";

export default {
  props: ["ids", "manufacturingCyclesToProcess"],
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {
      model: {
        workflows: [],
      },
    };
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    getData() {
      this.showLoading();
      let model = {
        ids: this.ids,
      };

      manufacturingCycleBulkOperationsService
        .getWorkflowData(model)
        .then((response) => {
          this.model.workflows = response.data.result.workflows;
          this.hideLoading();
        });
    },
    handleSelectionChange(selection, workflowId): void {
      let selectedWorkflows = this.manufacturingCyclesToProcess.filter(
        (x) => x.workflowId !== workflowId
      );

      for (const key in selection) {
        if (Object.prototype.hasOwnProperty.call(selection, key)) {
          const element = selection[key];

          for (const affectedCaseKey in element.affectedCases) {
            if (
              Object.prototype.hasOwnProperty.call(
                element.affectedCases,
                affectedCaseKey
              )
            ) {
              const affectedCase = element.affectedCases[affectedCaseKey];

              selectedWorkflows.push({
                workflowId: workflowId,
                name: affectedCase.requestId,
                workflow: element.workflowName,
                workflowStep: element.name,
                stateTransitionPre:
                  element.transitionAction.statePreOperation.name,
                stateTransitionPost:
                  element.transitionAction.statePostOperation.name,
                manufacturingCycleId: affectedCase.id,
                manufacturingJobId: affectedCase.manufacturingJobId,
                status: {
                  name: "Pending",
                  type: "info",
                },
                transitionActionType: element.transitionActionType,
              });
            }
          }
        }
      }

      this.$emit("update:manufacturingCyclesToProcess", selectedWorkflows);
    },
  },
};

<template>
  <div>
    <div style="display: block; height: auto">
      <el-table
        :data="model.milestones"
        stripe
        v-loading="loadingMilestones || editionLock"
        style="width: 100%; display: inline-block; height: auto"
      >
        <el-table-column
          prop="name"
          label="MILESTONES"
          align="center"
        ></el-table-column>
        <el-table-column align="center">
          <template
            slot="header"
            slot-scope="{}"
            style="vertical-align: middle"
          >
            DUE DATE
            <el-popconfirm
              v-if="model.lockedMilestones"
              title="Automatic schedule will be enabled. Are you sure to unlock milestone dates?"
              @confirm="unlockMilestones"
            >
              <el-button
                slot="reference"
                class="glyphicon glyphicon glyphicon-lock"
                size="mini"
                circle
                style="margin-bottom: 2px"
              ></el-button>
            </el-popconfirm>
          </template>
          <template slot-scope="scope">
            <editable-date-picker
              v-model="scope.row.dueDate"
              v-on:input="editMilestonePlannedDate(scope.row, false)"
              :editable="canManageFulfilmentPlan"
            ></editable-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="ACTUAL DATE" align="center">
          <template slot-scope="scope">
            <editable-date-picker
              v-model="scope.row.actualDate"
              v-on:input="editMilestoneActualDate(scope.row, false)"
              :editable="canManageFulfilmentPlan"
            ></editable-date-picker>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="clear: both"></div>
  </div>
</template>
<script>
import { EditableDatePicker } from "../shared/Shared.vue";
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";
import MilestoneListModel from "@/models/fulfilment-plan/MilestoneListModel";

export default {
  components: {
    EditableDatePicker,
  },
  data() {
    return {
      model: new MilestoneListModel(null),
      loadingMilestones: true,
    };
  },
  mounted() {
    this.getMilestones(this.$route.params.id);
  },
  computed: {
    ...mapState("fulfilmentPlanDetails", [
      "timestamp",
      "milestoneRefresh",
      "canManageFulfilmentPlan",
      "editionLock",
    ]),
  },
  watch: {
    milestoneRefresh: function () {
      this.loadingMilestones = true;
      if (this.milestoneRefresh) {
        this.reloadMilestones(this.model.fulfilmentPlanId);
        this.setMilestoneRefresh(false);
      }
    },
  },
  props: ["value"],
  methods: {
    ...mapActions("fulfilmentPlanDetails", [
      "setMilestoneRefresh",
      "setTimelineRefresh",
      "setShowTimeline",
      "setEditionLock",
      "storeTimestamp",
      "setChangesRefresh",
    ]),
    getMilestones(id) {
      this.loadingMilestones = true;
      fulfilmentPlanDetailsService.getMilestones(id).then((response) => {
        const data = response.data;
        this.model = new MilestoneListModel(data);
        this.loadingMilestones = false;
        this.calculateTimeline();
      });
    },
    reloadMilestones(id) {
      this.loadingMilestones = true;
      fulfilmentPlanDetailsService.getMilestones(id).then((response) => {
        const data = response.data;
        this.model.refresh(data);
        this.loadingMilestones = false;
        this.calculateTimeline();
      });
    },
    calculateTimeline() {
      if (this.model.showTimeline) {
        this.setTimelineRefresh(true);
        this.setShowTimeline(true);
      } else {
        this.setShowTimeline(false);
      }
    },
    editMilestonePlannedDate(item, ignoreWarning) {
      this.setEditionLock(true);
      var timeZoneOffset = new Date().getTimezoneOffset();
      fulfilmentPlanDetailsService
        .editMilestonePlannedDate(
          this.model.fulfilmentPlanId,
          item.id,
          item.dueDate,
          this.timestamp,
          ignoreWarning,
          timeZoneOffset
        )
        .then(({ data }) => {
          if (data.result === 0) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Milestone Due Date changed succesfully.",
              "success"
            );
            this.reloadMilestones(this.model.fulfilmentPlanId);
            this.setChangesRefresh(true);
          } else if (data.result === 1) {
            this.openConfirmPopup(data.message, (confirmAction) => {
              this.editMilestonePlannedDate(true);
            });
          } else if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.reloadMilestones(this.model.fulfilmentPlanId);
          }
          this.setEditionLock(false);
        });
    },
    editMilestoneActualDate(item, ignoreWarning) {
      this.setEditionLock(true);
      var timeZoneOffset = new Date().getTimezoneOffset();
      fulfilmentPlanDetailsService
        .editMilestoneActualDate(
          this.model.fulfilmentPlanId,
          item.id,
          item.actualDate,
          this.timestamp,
          ignoreWarning,
          timeZoneOffset
        )
        .then(({ data }) => {
          if (data.result === 0) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Milestone Actual Date changed succesfully.",
              "success"
            );
            this.reloadMilestones(this.model.fulfilmentPlanId);
            this.setChangesRefresh(true);
          } else if (data.result === 1) {
            this.openConfirmPopup(data.message, (confirmAction) => {
              this.editMilestoneActualDate(true);
            });
          } else if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.reloadMilestones(this.model.fulfilmentPlanId);
          }
          this.setEditionLock(false);
        });
    },
    unlockMilestones() {
      fulfilmentPlanDetailsService
        .unlockMilestones(this.model.fulfilmentPlanId)
        .then(({ data }) => {
          if (data.result === 0) {
            this.showNotification(
              "Milestones unlocked succesfully.",
              "success"
            );
            this.reloadMilestones(this.model.fulfilmentPlanId);
            this.setChangesRefresh(true);
          } else if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.reloadMilestones(this.model.fulfilmentPlanId);
          }
          this.setEditionLock(false);
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    openConfirmPopup(message, confirmAction) {
      this.$confirm(message, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          confirmAction();
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Action aborted.",
          });
        });
    },
  },
};
</script>

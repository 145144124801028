import { HTTP } from "./HttpCommon";
import moment from "moment";
import "moment-timezone";

class ManufacturingCycleListService {
  getGlobalNotificationsForRequestId(id) {
    return HTTP.get(
      `ManufacturingCycle/GetGlobalNotificationsForRequestId/` + id
    );
  }

  getGlobalNotifications(requestIds) {
    return HTTP.post(`ManufacturingCycle/GetGlobalNotificationsList`, {
      requestIds: requestIds,
    });
  }

  acknowledgeGlobalNotification(id) {
    return HTTP.post(`ManufacturingCycle/AcknowledgeGlobalNotification/${id}`);
  }

  getListFilters() {
    return HTTP.get(`ManufacturingCycle/GetListFilters`);
  }

  getChosenFilters(storedQueryId) {
    return HTTP.get(
      `ManufacturingCycle/GetChosenFilters?storedQueryId=` + storedQueryId
    );
  }

  getListAdditionalFilters() {
    return HTTP.get(`ManufacturingCycle/GetListAdditionalFilters/`);
  }

  filterRequests(query) {
    return HTTP.get(
      `ManufacturingCycle/FilterRequests/?containsString=` + query
    );
  }

  getNotificationNumbers() {
    return HTTP.get(`ManufacturingCycle/GetNotificationNumbers`);
  }

  saveFiltersAsPresetAction(model) {
    return HTTP.post(`ManufacturingCycle/SaveFiltersPreset`, model);
  }

  executeFilter(id) {
    return HTTP.post(`ManufacturingCycle/ExecuteFilter/${id}`);
  }

  export() {
    return HTTP.get(
      "ManufacturingCycle/Export?timezone=" +
        encodeURIComponent(moment.tz.guess()),
      {
        responseType: "blob",
      }
    );
  }
}

export const manufacturingCycleListService =
  new ManufacturingCycleListService();

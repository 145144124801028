import { SelectControlStringItemModel } from "../SelectControlStringItemModel";
import _ from "lodash";
export default class ManufacturingCycleAdditionalFiltersItem {
  name: string;
  id: number;
  filterType: number = null;
  checked = false;
  initiated = false;
  selectOptions: SelectControlStringItemModel[] = [];
  selectedValues: string[] = [];

  constructor(model: any) {
    if (model) {
      this.name = model.name;
      this.filterType = model.filterType;
      this.id = model.id;
      this.initiated = model.checked;
      this.checked = model.checked;
      if (model.selectedValues != null && model.selectedValues.length > 0) {
        for (const dataItem of model.selectedValues) {
          this.selectedValues.push(dataItem);
        }
      }

      if (model.selectOptions != null && model.selectOptions.length > 0) {
        for (const dataItem of model.selectOptions) {
          const newItem = new SelectControlStringItemModel(dataItem);
          this.selectOptions.push(newItem);
        }
      }
    }
  }
}

<template>
    <div>
        <label>User:</label>
        <el-select
        placeholder="None selected"
        suffix-icon="el-icon-tickets"
        v-model="chosenUser"
        :filterable="true"
        remote
        reserve-keyword
        :remote-method="getAvailableUsers"
        :loading="false"
        :clearable="true"
        style="width: 176px; margin-left: 8px"
        >
        <el-option
            v-for="item in availableUsers"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        ></el-option>
        </el-select>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import _ from "lodash";
  import { mapState, mapMutations, mapGetters } from "vuex";
  
  import { filterService } from "../../services/filter.service";
  
  export default Vue.extend({
    data() {
      return {
        availableUsers: []
      };
    },
    computed: {
      ...mapState("filterManagement", ["userId"]),
      ...mapGetters("oidcStore", ["oidcUser"]),
      chosenUser: {
        get() {
          return this.userId;
        },
        set(newValue) {
          this.chooseUserId(newValue);
          this.emitFilterChange();
        },
      },
    },
    created() {
      this.availableUsers = this.getAvailableUsers("");
    },
    methods: {
      ...mapMutations("filterManagement", [
        "chooseUserId",
      ]),
      getAvailableUsers(enteredValue) {
        filterService.getFilteredUsers(enteredValue).then((response) => {
          this.availableUsers = response.data;
          this.$emit("userChange", Number(this.oidcUser.sub));
          this.chooseUserId(Number(this.oidcUser.sub));
        });
      },
      emitFilterChange: _.debounce(function () {
        this.$emit("userChange", this.chosenUser);
      }, 500),
      showNotification(message, type) {
        this.$message({
          message: message,
          type: type,
          duration: 4000,
        });
      },
    },
  });
  </script>
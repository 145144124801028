<template>
  <div>
    <template v-for="(manufacturingJob, index) in model.manufacturingJobs">
      <manufacturing-job
        :style="{
          'margin-bottom':
            index < model.manufacturingJobs.length - 1 ? '30px' : '0px',
        }"
        style="padding-left: 30px"
        :key="manufacturingJob.id"
        :model="manufacturingJob"
        :material-number="model.materialNumber"
        :manufacturing-cycle-id="manufacturingCycleId"
        :timestamp="timestamp"
        @change="manufacturingJobChanged"
      ></manufacturing-job>
    </template>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";

import ManufacturingJob from "./ManufacturingJob.vue";

export default Vue.extend({
  components: {
    ManufacturingJob,
  },
  props: {
    model: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState("manufacturingCycleDetails", ["timestamp"]),
    manufacturingCycleId() {
      return Number(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions("manufacturingCycleDetails", ["setRefreshData"]),
    manufacturingJobChanged() {
      this.setRefreshData();
    },
  },
});
</script>

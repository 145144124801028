import SimpleSelectControlItemModel from "./SimpleSelectControlItemModel";
import ProductTypeFilterModel from "./ProductTypeFilterModel";

export class ChangesFiltersModel {
  productTypes = new Array<ProductTypeFilterModel>();
  changeTypes = new Array<SimpleSelectControlItemModel>();
  distributionCenters = new Array<SimpleSelectControlItemModel>();

  constructor(model: any) {
    if (model) {
      this.productTypes = model.productTypes.map(
        (item) => new ProductTypeFilterModel(item)
      );
      this.changeTypes = model.changeTypes.map(
        (item) => new SimpleSelectControlItemModel(item)
      );
      this.distributionCenters = model.distributionCenters.map(
        (item) => new SimpleSelectControlItemModel(item)
      );
    }
  }
}

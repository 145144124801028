<style lang="scss">
input[type="file"] {
  display: none;
}
</style>

<template>
  <el-container v-loading="loading">
    <el-header>
      <h1 class="details-header">IMPORT REQUEST DETAILS</h1>
    </el-header>
    <el-main>
      <el-form label-width="140px">
        <el-form-item label="File to import">
          <el-upload
            ref="upload"
            action="string"
            :limit="1"
            :multiple="false"
            :http-request="uploadFile"
            :auto-upload="false"
          >
            <el-button slot="trigger">Choose file</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="updateExistingPlans"
            >Update existing plans</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="submitUpload">Submit</el-button>
        </el-form-item>
      </el-form>
      <el-alert
        v-for="item in messages"
        :type="getType(item.type)"
        :key="item.message"
        >{{ item.message }}</el-alert
      >
    </el-main>
  </el-container>
</template>
<script>
import Vue from "vue";
import { requestImportService } from "../../services/requestimport.service";
import { OperationResultType } from "../../enums/enums";
import MessageTypeHelper from "../../utils/MessageTypeHelper";
import { connection } from "signalr-no-jquery";

export default Vue.extend({
  data() {
    return {
      updateExistingPlans: false,
      messages: [],
      loading: false,
    };
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    waitForImportResults(response) {
      var hub = connection.importRequestHub;
      connection.hub.qs = {
        importedRequestId: response.data.importedRequestId,
      };
      hub.client.importFinished = (importedRequestId) => {
        this.redirectToDetails(importedRequestId);
      };
      connection.hub.start();
    },
    uploadFile(param) {
      this.showNotification("Uploading file...", "success");
      this.loading = true;
      requestImportService
        .importRequests(param.file, this.updateExistingPlans)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification("Processing...", "success");
            requestImportService
              .getImportStatus(response.data.importedRequestId)
              .then((statusResponse) => {
                if (!statusResponse.data.isImported) {
                  this.waitForImportResults(response);
                } else {
                  this.redirectToDetails(response.data.importedRequestId);
                }
              });
          } else {
            this.loading = false;
            this.showNotification("Something went wrong...", "error");
            this.messages = response.data.messages;
          }
        });
    },
    redirectToDetails(id) {
      this.$router.push({ name: "importRequestDetails", params: { id: id } });
    },
    getType(type) {
      return MessageTypeHelper.getDescription(type);
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

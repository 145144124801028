<template>
  <div>
    <div class="heading-bottom-border">
      <h4>Work Procedures</h4>
      <el-button class="crud-add-button" @click="onAddWorkProcedure" plain
        >Add Work Procedure</el-button
      >
    </div>
    <draggable
      :list="workProcedureList"
      ghost-class="ghost"
      @start="dragging = true"
      @end="dragging = false"
    >
      <div
        v-for="(workProcedure, index) in workProcedureList"
        :key="index"
        class="card-wp"
      >
        <WorkProcedure
          :work-procedure="workProcedure"
          :identifier="index"
          @delete="onDeleteWorkProcedure"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";

import WorkProcedure from "./WorkProcedure.vue";
import WorkProcedureModel from "../../models/WorkProcedureModel";

export default Vue.extend({
  props: {
    workProcedureList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    draggable,
    WorkProcedure,
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    onAddWorkProcedure() {
      this.workProcedureList.push(new WorkProcedureModel());
    },
    onDeleteWorkProcedure(identifier) {
      this.workProcedureList.splice(identifier, 1);
    },
  },
});
</script>

<style lang="scss" scoped>
.heading-bottom-border {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dcdfe6;
}

.card-wp {
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-left: 25px;
}
</style>

import _ from "lodash";

import { OperationResultType } from "../../../../enums/OperationResultType";
import operationType from "../enums/OperationType";

export default {
  props: {
    addTemplate: {
      type: Object,
      default: null,
    },
    editTemplate: {
      type: Object,
      default: null,
    },
    addRules: {
      type: Object,
      default: null,
    },
    editRules: {
      type: Object,
      default: null,
    },
    formOptions: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      isDialogVisible: false,
      formData: {},
      formMode: operationType.edit,
      editDataStorage: {},
    };
  },
  computed: {
    dialogTitle: function () {
      if (this.formMode === operationType.edit) {
        return "Edit";
      }
      return "Add";
    },
  },
  methods: {
    handleDialogSave() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }

        let rowData = {};

        if (this.formMode === operationType.edit) {
          rowData = _.cloneDeep(this.editDataStorage);
          _.forEach(this.formData, (value, key) => {
            _.set(rowData, key, value);
          });

          this.$emit("row-edit", rowData, (param = null) => {
            this.showOperationResultMessage(param);
            this.formData = {};
          });
        } else if (this.formMode === operationType.add) {
          _.forEach(this.formData, (value, key) => {
            _.set(rowData, key, value);
          });

          this.$emit("row-add", rowData, (param = null) => {
            this.showOperationResultMessage(param);
            this.formData = {};
          });
        }

        this.handleCloseDialog();
      });
    },
    handleDialogCancel() {
      this.$emit("dialog-cancel");
      this.handleCloseDialog();
    },
    handleCloseDialog() {
      this.isDialogVisible = false;
      this.$refs.form.resetFields();
    },
    handleFormTemplateMode(key) {
      if (this.formMode === operationType.edit) {
        return this.editTemplateStorage[key];
      } else if (this.formMode === operationType.add) {
        return this.addTemplateStorage[key];
      }
    },
    handleFormRulesMode() {
      if (this.formMode === operationType.edit) {
        return this.editRules;
      } else if (this.formMode === operationType.add) {
        return this.addRules;
      }
    },
    showOperationResultMessage(result) {
      if (result.result === OperationResultType.Success) {
        let successMessage = "";
        if (this.formMode === operationType.edit) {
          successMessage = "Record edited";
        } else if (this.formMode === operationType.add) {
          successMessage = "Record added";
        }

        this.$message({
          message: successMessage,
          type: "success",
        });
      }

      if (result.result === OperationResultType.Warning) {
        this.$message({
          message: result.message,
          type: "warning",
        });
      }

      if (result.result === OperationResultType.Error) {
        this.$message.error(result.message);
      }
    },
  },
};

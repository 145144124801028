<template>
  <el-container v-loading.fullscreen.lock="loading">
    <el-header>
      <h1 class="details-header">Workflow Step Efforts</h1>
    </el-header>
    <el-main>
      <label class="field-label" for="manufacturingLocation">
        Manufacturing location
      </label>
      <el-select
        id="manufacturingLocation"
        v-model="selectedManufacturingLocationId"
        @change="reloadEfforts"
      >
        <el-option
          v-for="manufacturingLocation in manufacturingLocations"
          :key="manufacturingLocation.id"
          :label="manufacturingLocation.name"
          :value="manufacturingLocation.id"
        ></el-option>
      </el-select>
      <div v-for="effort in records" :key="effort.id">
        <el-divider></el-divider>
        <el-table :data="effort.items" style="width: 100%">
          <el-table-column prop="name" :label="effort.name"></el-table-column>
          <el-table-column label="Effort">
            <template slot-scope="scope">
              <div>{{ convertMinutes(scope.row.effort) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Criticality 1 (In hours)">
            <template slot-scope="scope">
              <div>{{ getHours(scope.row.criticalities, 1) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Criticality 2 (In hours)">
            <template slot-scope="scope">
              <div>{{ getHours(scope.row.criticalities, 2) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Criticality 3 (In hours)">
            <template slot-scope="scope">
              <div>{{ getHours(scope.row.criticalities, 3) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Show Expected Time" width="175">
            <template slot-scope="scope">
              <div>
                {{ scope.row.showExpectedTime.toString().toUpperCase() }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Requires Worker" width="175">
            <template slot-scope="scope">
              <div>{{ scope.row.requiresWorker.toString().toUpperCase() }}</div>
            </template>
          </el-table-column>
          <el-table-column width="90">
            <template slot-scope="scope">
              <el-button @click="openEditModal(scope.row)">Edit</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <el-dialog :visible.sync="dialogVisible" title="Edit Effort" width="425px">
      <el-form v-if="editForm !== null" :model="editForm" label-width="180px">
        <el-form-item label="Effort (In minutes)">
          <el-input-number v-model="editForm.effort" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="Requires Worker">
          <el-checkbox v-model="editForm.requiresWorker"></el-checkbox>
        </el-form-item>
        <el-form-item label="Show Expected Time">
          <el-checkbox v-model="editForm.showExpectedTime"></el-checkbox>
        </el-form-item>
        <el-form-item
          v-for="criticality in editForm.criticalities"
          :key="criticality.level"
          :label="'Criticality ' + criticality.level + ' (In hours)'"
        >
          <el-input-number
            v-model="criticality.hours"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="editEffort">Confirm</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import { manufacturingLocationService } from "../../services/manufacturinglocation.service";
import { workflowStepEffortService } from "../../services/workflowstepeffort.service";
import { OperationResultType } from "../../enums/enums";

export default Vue.extend({
  created() {
    manufacturingLocationService
      .loadManufacturingLocations()
      .then((response) => {
        this.manufacturingLocations = response.data.records;
        this.selectedManufacturingLocationId =
          this.manufacturingLocations[0].id;
        workflowStepEffortService
          .getEfforts(this.selectedManufacturingLocationId)
          .then((response) => {
            this.records = response.data.efforts;
            this.manufacturingLocationWorkDayDuration =
              response.data.workDayDuration;
            this.loading = false;
          });
      });
  },
  data() {
    return {
      loading: true,
      records: [],
      manufacturingLocations: [],
      selectedManufacturingLocationId: null,
      dialogVisible: false,
      editForm: null,
      manufacturingLocationWorkDayDuration: null,
    };
  },
  methods: {
    getHours(criticalities, level) {
      const criticality = criticalities.find(
        (criticality) => criticality.level === level
      );

      return (criticality && criticality.hours) || "-";
    },
    reloadEfforts() {
      this.loading = true;
      workflowStepEffortService
        .getEfforts(this.selectedManufacturingLocationId)
        .then((response) => {
          this.records = response.data.efforts;
          this.manufacturingLocationWorkDayDuration =
            response.data.workDayDuration;
          this.loading = false;
        });
    },
    openEditModal(record) {
      this.editForm = _.cloneDeep(record);
      this.dialogVisible = true;
    },
    editEffort() {
      this.loading = true;
      workflowStepEffortService
        .editWorkflowStepEffort(this.editForm)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.reloadEfforts();
            this.dialogVisible = false;
          } else if (response.data.result === OperationResultType.Error) {
            this.loading = false;
            this.showNotification(response.data.message, "error");
          }
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    convertMinutes(minutes) {
      const days = Math.floor(
        minutes / this.manufacturingLocationWorkDayDuration
      );
      const minutesLeft = minutes % this.manufacturingLocationWorkDayDuration;
      const hours = Math.floor(minutesLeft / 60);
      let resultText = "";
      if (days > 0) {
        resultText += `${days}d `;
      }
      if (hours !== 0) {
        resultText += `${hours}h `;
      }
      if (minutesLeft % 60 !== 0) {
        resultText += `${minutesLeft % 60}m`;
      }
      if (resultText === "") {
        resultText = "0m";
      }
      return resultText;
    },
  },
});
</script>

export default class ProductInformationModel {
  description: string;
  catalogNumber: string;
  gtin: string;
  productFamilyId: number;
  divisionId: number;
  needsKeyCode: boolean;
  isNeedsKeyCodeEditable: boolean;
  implicitVersionKeyCode: string;
  isImplicitVersionKeyCodeAssignable: boolean;
  describedBy: any[];
  hasVersions: boolean;

  constructor(data: any = null) {
    if (data !== null) {
      this.description = data.description;
      this.catalogNumber = data.catalogNumber;
      this.gtin = data.gtin;
      this.productFamilyId = data.productFamilyId;
      this.divisionId = data.divisionId;
      this.needsKeyCode = data.needsKeyCode;
      this.isNeedsKeyCodeEditable = data.isNeedsKeyCodeEditable;
      this.implicitVersionKeyCode = data.implicitVersionKeyCode;
      this.isImplicitVersionKeyCodeAssignable =
        data.isImplicitVersionKeyCodeAssignable;
      this.describedBy = data.associations;
      this.hasVersions = data.productVersions.length > 0;
    }
  }
}

<style scoped>
.el-form--label-top::v-deep .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-cascader-menu__wrap {
  height: 335px;
}

.drag {
  display: inline-block;
}
</style>

<template>
  <div>
    <el-form label-position="top" inline>
      <el-row style="margin-bottom: 0px">
        <el-col :span="6">
          <el-form-item label="PLAN ID" style="width: 100%; padding-right: 5px">
            <el-select
              v-model="requestId"
              filterable
              remote
              :remote-method="querySearchAsync"
              placeholder="None selected"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="option in requestIdOptions"
                :key="option"
                :label="option"
                :value="option"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="PRODUCT" style="width: 100%; padding-right: 5px">
            <el-cascader
              :options="availableProductTypes"
              v-model="chosenProducts"
              style="width: 100%"
              placeholder="None selected"
              :props="props"
              collapse-tags
              filterable
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="DESIGN STATUS"
            style="width: 100%; padding-right: 5px"
          >
            <el-select
              v-model="chosenDesignStatuses"
              multiple
              clearable
              collapse-tags
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in availableDesignStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="PROCESSING STATUS" style="width: 100%">
            <el-select
              v-model="chosenProcessingStatuses"
              multiple
              clearable
              collapse-tags
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in availableProcessingStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="DATE" style="width: 100%; padding-right: 5px">
            <el-select
              v-model="chosenDateFilter"
              collapse-tags
              clearable
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in availableDates"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="DATE TYPE"
            style="width: 100%; padding-right: 5px"
          >
            <el-select
              v-model="dateType"
              collapse-tags
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in availableDateTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="DATE RANGE"
            style="width: 100%; padding-right: 5px"
          >
            <el-popover
              ref="date-popover"
              placement="bottom"
              width="480"
              trigger="click"
            >
              <el-tabs type="border-card" v-model="currentDateTabValue">
                <el-button
                  style="float: right; padding: 3px 0"
                  type="text"
                  @click="clearDateFilters"
                  >Clear filter</el-button
                >
                <el-tab-pane label="Absolute" type="border-card">
                  <label for="startDate" class="field-label"
                    >Select Range</label
                  >
                  <div id="startDate">
                    <el-date-picker
                      v-model="dateRange"
                      type="daterange"
                      align="right"
                      style="width: 100%; word-break: initial"
                      range-separator="To"
                      clearable
                      start-placeholder="Start date"
                      end-placeholder="End date"
                    ></el-date-picker>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Relative">
                  <el-radio-group v-model="relativeDateModifier">
                    <el-radio :label="0">Last</el-radio>
                    <el-radio :label="1">Next</el-radio>
                  </el-radio-group>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-input
                        placeholder="Number"
                        v-model="relativeDateAmount"
                        type="number"
                        min="0"
                        clearable
                      ></el-input>
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        v-model="relativeDateFrequency"
                        placeholder="Select frequency"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in dateFrequencyOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-popover>
            <el-input
              placeholder="None selected"
              suffix-icon="el-icon-date"
              v-model="dateRangeText"
              readonly="readonly"
              v-popover:date-popover
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="DISTRIBUTION CENTER" style="width: 100%">
            <el-select
              v-model="chosenDistributionCenters"
              multiple
              clearable
              collapse-tags
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in availableDistributionCenters"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <additional-filters
      ref="additionalFilters"
      @clear="clearFilters"
      @export="exportPlans"
      @save="saveFilters"
    ></additional-filters>
    <el-row>
      <div v-if="filters.storedQueryActiveDisplayName">
        Active filter: {{ filters.storedQueryActiveDisplayName }}
      </div>
      <span class="pull-left" style="margin: 6px 6px 0 0">
        Sort Order :
        <span v-if="sortedColumns.length == 0"
          >Shift + click on columns to multisort</span
        >
      </span>
      <div class="pull-left">
        <draggable v-model="sortedColumns" @end="handleDropColumn">
          <transition-group>
            <el-tag
              style="margin-right: 6px"
              closable
              @click="handleClickColumn(item)"
              @close="handleCloseColumn(item)"
              v-for="item in sortedColumns"
              :key="item.column"
            >
              {{ getDescription(item.column) }}
              <i v-if="item.ascending" class="el-icon-arrow-up"></i>
              <i v-else class="el-icon-arrow-down"></i>
            </el-tag>
          </transition-group>
        </draggable>
      </div>
      <el-popover
        placement="bottom"
        width="340"
        class="pull-right"
        trigger="click"
      >
        <div>
          <div style="height: 300px; overflow-y: scroll">
            <el-row
              style="margin-bottom: 0px"
              v-for="item in availableColumns"
              :key="item.name"
            >
              <el-checkbox
                v-model="item.visible"
                @change="columnVisibilityChanged"
                >{{ item.name }}</el-checkbox
              >
            </el-row>
          </div>
          <el-row>
            <el-button size="small" @click="setAsDefaultColumns"
              >Save as default</el-button
            >
            <el-button size="small" @click="restoreDefaultColumns"
              >Default</el-button
            >
          </el-row>
        </div>
        <el-button style="margin-bottom: 5px" slot="reference" size="medium"
          >Columns</el-button
        >
      </el-popover>
    </el-row>
  </div>
</template>

<script>
import { fulfilmentPlanListService } from "../../services/fulfilmentplanlist.service";
import AdditionalFilters from "./AdditionalFilters.vue";
import draggable from "vuedraggable";
import { nonFilterParams } from "../../store/modules/fulfilmentPlanList";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import _ from "lodash";
import { OperationResultType, DateFrequency } from "../../enums/enums";
import { showLoading, hideLoading } from "../../store/mutation-types";

export default {
  components: {
    draggable,
    AdditionalFilters,
  },
  data() {
    return {
      OperationResultType,
      DateFrequency,
      props: { multiple: true },
      requestIdOptions: [],
    };
  },
  async mounted() {
    let urlQuery = this.$route.query;

    if (urlQuery.selectedRecordId) {
      this.storeSelectedRecordId(urlQuery.selectedRecordId);
    }

    this.showLoading();
    await this.loadFilters();
    await this.loadChosenFilters(urlQuery);
    this.hideLoading();
    this.refresh();
  },
  computed: {
    dateFrequencyOptions: function () {
      return Object.values(DateFrequency)
        .filter((value) => typeof value === "string")
        .map((key) => {
          return {
            value: DateFrequency[key],
            label: key,
          };
        });
    },

    requestId: {
      get() {
        return this.filters.requestId;
      },
      set(value) {
        this.setRequestId(value);
      },
    },
    chosenProducts: {
      get() {
        return this.filters.chosenProducts.map((p) => {
          const splitted = p.split("-");
          return [splitted[0], p];
        });
      },
      set(value) {
        const products = value.map((p) => p[1]);
        this.setChosenProducts(products);
      },
    },
    chosenDesignStatuses: {
      get() {
        return this.filters.chosenDesignStatuses;
      },
      set(value) {
        this.setChosenDesignStatuses(value);
      },
    },
    chosenProcessingStatuses: {
      get() {
        return this.filters.chosenProcessingStatuses;
      },
      set(value) {
        this.setChosenProcessingStatuses(value);
      },
    },
    chosenDateFilter: {
      get() {
        return this.chosenDateFilterState;
      },
      set(value) {
        this.setChosenDateFilter(value);
      },
    },
    dateType: {
      get() {
        return this.filters.dateType;
      },
      set(value) {
        this.setDateType(value);
      },
    },
    currentDateTabValue: {
      get() {
        return this.filters.currentDateTabValue;
      },
      set(value) {
        this.setCurrentDateTabValue(value);
      },
    },
    dateRange: {
      get() {
        return this.filters.dateRange;
      },
      set(value) {
        this.setDateRange(value);
      },
    },
    relativeDateAmount: {
      get() {
        return this.filters.relativeDateAmount;
      },
      set(value) {
        this.setRelativeDateAmount(value);
      },
    },
    relativeDateModifier: {
      get() {
        return this.filters.relativeDateModifier;
      },
      set(value) {
        this.setRelativeDateModifier(value);
      },
    },
    relativeDateFrequency: {
      get() {
        return this.filters.relativeDateFrequency;
      },
      set(value) {
        this.setRelativeDateFrequency(value);
      },
    },
    chosenDistributionCenters: {
      get() {
        return this.filters.chosenDistributionCenters;
      },
      set(value) {
        this.setChosenDistributionCenters(value);
      },
    },
    ...mapGetters("fulfilmentPlanList", [
      "filtersLoaded",
      "selectedAdditionalFilters",
      "modifiedFilters",
    ]),
    ...mapGetters("fulfilmentPlanList", {
      chosenDateFilterState: "chosenDateFilter",
    }),
    ...mapState("fulfilmentPlanList", {
      sortedColumnState: "sortedColumns",
    }),
    ...mapState("fulfilmentPlanList", [
      "availableProductTypes",
      "availableDesignStatuses",
      "availableProcessingStatuses",
      "availableDates",
      "availableDateTypes",
      "availableDistributionCenters",
      "availableColumns",
      "filters",
    ]),
    dateRangeText: function () {
      if (
        this.filters.isRelativeDate &&
        this.filters.relativeDateAmount >= 0 &&
        typeof this.filters.relativeDateModifier !== "undefined" &&
        typeof this.filters.relativeDateFrequency !== "undefined"
      ) {
        return (
          (this.filters.relativeDateModifier === 0 ? "Last " : "Next ") +
          this.filters.relativeDateAmount +
          " " +
          DateFrequency[this.filters.relativeDateFrequency] +
          (this.filters.relativeDateAmount > 0 ? "s" : "")
        );
      } else {
        if (this.filters.dateRange) {
          if (this.filters.dateRange.length > 0) {
            return (
              moment(this.filters.dateRange[0]).format("DD/MM/YYYY") +
              " - " +
              moment(this.filters.dateRange[1]).format("DD/MM/YYYY")
            );
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },
    sortedColumns: {
      get() {
        return this.sortedColumnState;
      },
      set(value) {
        this.storeSortedColumns(value);
      },
    },
  },
  watch: {
    modifiedFilters: function (newValue) {
      const params = _.pick(this.$route.query, nonFilterParams);

      this.$router
        .replace({ query: { ...params, ...newValue } })
        .catch((err) => err);
    },
  },
  methods: {
    ...mapActions("fulfilmentPlanList", [
      "setRequestId",
      "setChosenProducts",
      "setChosenDesignStatuses",
      "setChosenProcessingStatuses",
      "setChosenDateFilter",
      "setDateType",
      "setCurrentDateTabValue",
      "setDateRange",
      "setRelativeDateModifier",
      "setRelativeDateFrequency",
      "setRelativeDateAmount",
      "setChosenDistributionCenters",

      "clearDateFilters",
      "clearFilters",

      "loadChosenFilters",

      "storeSortedColumns",
      "storeSelectedRecordId",
      "storePage",
      "loadFilters",
      "storeUpdateSortedColumns",
      "refresh",
      "restoreDefaultColumns",
    ]),
    ...mapMutations("fulfilmentPlanList", [
      "storeQueryActiveDisplayName",
      "storeStoredQueryId",
    ]),
    ...mapMutations([showLoading, hideLoading]),
    exportPlans() {
      fulfilmentPlanListService.export().then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      });
    },
    saveFilters(filterName) {
      let storedQueryId = this.$route.query.storedQueryId || null;

      if (filterName !== this.filters.storedQueryActiveDisplayName) {
        storedQueryId = null;
      }

      let sortColumns = [];

      for (let sortColumn of this.sortedColumnState) {
        let currentSortColumn = this.availableColumns.find((x) => {
          return x.field === sortColumn.column;
        });
        sortColumns.push({
          columnName: currentSortColumn.name,
          sortedColumn: Number.isInteger(currentSortColumn.milestoneId)
            ? null
            : currentSortColumn.column,
          milestoneDateType: currentSortColumn.milestoneDateType,
          milestoneId: currentSortColumn.milestoneId,
          sortOrder: sortColumn.ascending ? 1 : 0,
        });
      }

      const request = {
        filters: {
          ...this.filters,
          sortColumns: sortColumns,
          availableColumns: this.availableColumns,
          selectedAdditionalFilters: this.selectedAdditionalFilters,
          storedQueryId: storedQueryId,
        },
        sortModel: sortColumns,
        name: filterName,
      };

      fulfilmentPlanListService
        .saveFiltersAsPresetAction(request)
        .then((response) => {
          const data = response.data;
          if (data.result == OperationResultType.Error) {
            this.$message({
              type: "error",
              message: data.message,
            });
          } else {
            this.storeQueryActiveDisplayName(filterName);
            this.filterName = "";
            this.$message({
              type: "success",
              message: "Filter saved succesfully.",
            });
          }
        });
    },
    querySearchAsync(queryString) {
      fulfilmentPlanListService.filterRequests(queryString).then((response) => {
        const result = response.data;
        this.requestIdOptions = result.data;
      });
    },
    handleDropColumn() {
      this.storeUpdateSortedColumns(true);
    },
    handleCloseColumn(item) {
      this.sortedColumns.splice(this.sortedColumns.indexOf(item), 1);
      this.storeUpdateSortedColumns(true);
    },
    handleClickColumn(item) {
      this.sortedColumns[this.sortedColumns.indexOf(item)].ascending =
        !this.sortedColumns[this.sortedColumns.indexOf(item)].ascending;
      this.storeUpdateSortedColumns(true);
    },
    getDescription(item) {
      return this.availableColumns.find((x) => {
        return x.field === item;
      }).name;
    },
    columnVisibilityChanged: _.debounce(function () {
      let filters = _.cloneDeep(this.filters);
      filters.sortColumns = [];

      for (let sortColumn of this.sortedColumns) {
        let currentSortColumn = this.availableColumns.find((x) => {
          return x.field === sortColumn.column;
        });
        filters.sortColumns.push({
          columnName: currentSortColumn.name,
          sortedColumn: Number.isInteger(currentSortColumn.milestoneId)
            ? null
            : currentSortColumn.column,
          milestoneDateType: currentSortColumn.milestoneDateType,
          milestoneId: currentSortColumn.milestoneId,
          sortOrder: sortColumn.ascending ? 1 : 0,
        });
      }
      filters.availableColumns = this.availableColumns;

      fulfilmentPlanListService.saveFiltersInSession({
        ...filters,
        selectedAdditionalFilters: this.selectedAdditionalFilters,
      });
    }, 500),
    setAsDefaultColumns() {
      fulfilmentPlanListService.saveColumnsConfigurationAsUserDefault();
    },
  },
};
</script>

<template>
  <div>
    <el-form label-position="top" inline>
      <el-row style="margin-bottom: 0px" :gutter="5">
        <el-col :span="3">
          <el-form-item label="PLAN ID" style="width: 100%">
            <el-select
              v-model="requestId"
              filterable
              remote
              :remote-method="querySearchAsync"
              placeholder="None selected"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="option in requestIdOptions"
                :key="option"
                :label="option"
                :value="option"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="DESIGN STATUS" style="width: 100%">
            <el-select
              v-model="chosenDesignStatuses"
              multiple
              clearable
              collapse-tags
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in availableDesignStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="PROCESSING STATUS" style="width: 100%">
            <el-select
              v-model="chosenProcessingStatuses"
              multiple
              clearable
              collapse-tags
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in availableProcessingStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="MANUFACTURING LOCATION" style="width: 100%">
            <el-select
              v-model="chosenManufacturingLocations"
              multiple
              clearable
              collapse-tags
              style="width: 100%"
              placeholder="None selected"
            >
              <el-option
                v-for="item in availableManufacturingLocations"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="OUT-OF-MANUF. PERIOD" style="width: 100%">
            <el-popover
              ref="date-popover"
              placement="bottom"
              width="480"
              trigger="click"
            >
              <el-tabs type="border-card" v-model="currentDateTabValue">
                <el-button
                  style="float: right; padding: 3px 0"
                  type="text"
                  @click="clearDateFilters"
                  >Clear filter</el-button
                >
                <el-tab-pane label="Absolute" type="border-card">
                  <label for="startDate" class="field-label"
                    >Select Range</label
                  >
                  <div id="startDate">
                    <el-date-picker
                      v-model="dateRange"
                      type="daterange"
                      align="right"
                      style="width: 100%; word-break: initial"
                      range-separator="To"
                      clearable
                      start-placeholder="Start date"
                      end-placeholder="End date"
                    ></el-date-picker>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Relative">
                  <el-radio-group v-model="relativeDateModifier">
                    <el-radio :label="0">Last</el-radio>
                    <el-radio :label="1">Next</el-radio>
                  </el-radio-group>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-input
                        placeholder="Number"
                        v-model="relativeDateAmount"
                        type="number"
                        min="0"
                        clearable
                      ></el-input>
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        v-model="relativeDateFrequency"
                        placeholder="Select frequency"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in dateFrequencyOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-popover>
            <el-input
              placeholder="None selected"
              suffix-icon="el-icon-date"
              v-model="dateRangeText"
              readonly="readonly"
              v-popover:date-popover
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="UPCOMING JOB STEP" style="width: 100%">
            <el-cascader
              :options="availableJobSteps"
              v-model="chosenJobSteps"
              style="width: 100%"
              placeholder="None selected"
              :props="props"
              collapse-tags
              filterable
              :filter-method="jobStepFilter"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <additional-filters @save="saveFilters"></additional-filters>
    <div v-if="filters.storedQueryActiveDisplayName">
      Active filter: {{ filters.storedQueryActiveDisplayName }}
    </div>
    <el-radio-group v-model="chosenTab">
      <el-badge
        v-for="tab in productTypeTabs"
        :hidden="tab.notificationsNumber === 0"
        :value="tab.notificationsNumber"
        :key="'product-type-' + tab.id"
        class="notification"
      >
        <el-radio-button :label="tab" class="product-type-tabs">
          {{ tab.name }}
        </el-radio-button>
      </el-badge>
      <el-badge
        :value="allTab.notificationsNumber"
        :hidden="allTab.notificationsNumber === 0"
        class="notification"
      >
        <el-radio-button :label="allTab" class="product-type-tabs">
          {{ allTab.name }}
        </el-radio-button>
      </el-badge>
      <el-badge
        v-for="tab in requestTypeTabs"
        :hidden="tab.notificationsNumber === 0"
        :value="tab.notificationsNumber"
        :key="'request-type-' + tab.id"
        class="notification"
      >
        <el-radio-button :label="tab" class="product-type-tabs">
          {{ tab.name }}
        </el-radio-button>
      </el-badge>
      <el-badge
        :value="criticalTab.notificationsNumber"
        :hidden="criticalTab.notificationsNumber === 0"
        :key="'critical'"
        class="notification"
      >
        <el-radio-button :label="criticalTab" class="product-type-tabs">
          {{ criticalTab.name }}
          <i class="el-icon-warning"></i>
        </el-radio-button>
      </el-badge>
    </el-radio-group>
  </div>
</template>

<script>
import moment from "moment";
import "moment-timezone";
import { manufacturingCycleListService } from "../../services/manufacturingcyclelist.service";
import AdditionalFilters from "./AdditionalFilters.vue";
import { DateFrequency, OperationResultType } from "../../enums/enums";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import _ from "lodash";

export default {
  components: {
    AdditionalFilters,
  },
  data() {
    return {
      DateFrequency,
      props: { multiple: true },
      requestIdOptions: [],
    };
  },
  async mounted() {
    const urlQuery = this.$route.query;

    if (urlQuery.selectedRecordId) {
      this.storeSelectedRecordId(urlQuery.selectedRecordId);
      this.storePage(Math.ceil(urlQuery.index / 10));
    }
    this.showLoading();
    await this.loadFilters();
    await this.loadChosenFilters(urlQuery.storedQueryId);
    this.hideLoading();
    this.refresh();
  },
  computed: {
    ...mapState("manufacturingCycleList", [
      "availableDesignStatuses",
      "availableProcessingStatuses",
      "availableManufacturingLocations",
      "availableJobSteps",
      "criticalTab",
      "allTab",
      "filters",
      "productTypeTabs",
      "requestTypeTabs",
      "filtersLoaded",
    ]),
    ...mapGetters("manufacturingCycleList", ["selectedAdditionalFilters"]),
    requestId: {
      get: function () {
        return this.filters.requestId;
      },
      set: function (newValue) {
        this.setRequestId(newValue);
      },
    },
    chosenDesignStatuses: {
      get: function () {
        return this.filters.chosenDesignStatuses;
      },
      set: function (newValue) {
        this.setChosenDesignStatuses(newValue);
      },
    },
    chosenProcessingStatuses: {
      get: function () {
        return this.filters.chosenProcessingStatuses;
      },
      set: function (newValue) {
        this.setChosenProcessingStatuses(newValue);
      },
    },
    chosenManufacturingLocations: {
      get: function () {
        return this.filters.chosenManufacturingLocations;
      },
      set: function (newValue) {
        this.setChosenManufacturingLocations(newValue);
      },
    },
    currentDateTabValue: {
      get: function () {
        return this.filters.currentDateTabValue;
      },
      set: function (newValue) {
        this.setCurrentDateTabValue(newValue);
      },
    },
    chosenJobSteps: {
      get: function () {
        return this.filters.chosenJobSteps;
      },
      set: function (newValue) {
        this.setChosenJobSteps(newValue);
      },
    },
    dateRange: {
      get: function () {
        return this.filters.dateRange;
      },
      set: function (newValue) {
        this.setDateRange(newValue);
      },
    },
    relativeDateModifier: {
      get: function () {
        return this.filters.relativeDateModifier;
      },
      set: function (newValue) {
        this.setRelativeDateModifier(newValue);
      },
    },
    relativeDateAmount: {
      get: function () {
        return this.filters.relativeDateAmount;
      },
      set: function (newValue) {
        this.setRelativeDateAmount(newValue);
      },
    },
    relativeDateFrequency: {
      get: function () {
        return this.filters.relativeDateFrequency;
      },
      set: function (newValue) {
        this.setRelativeDateFrequency(newValue);
      },
    },
    chosenTab: {
      get: function () {
        return this.filters.chosenTab;
      },
      set: function (newValue) {
        this.setChosenTab(newValue);
      },
    },

    dateFrequencyOptions: function () {
      return Object.values(DateFrequency)
        .filter((value) => typeof value === "string")
        .map((key) => {
          return {
            value: DateFrequency[key],
            label: key,
          };
        });
    },

    dateRangeText: function () {
      if (
        this.filters.isRelativeDate &&
        this.filters.relativeDateAmount >= 0 &&
        typeof this.filters.relativeDateModifier !== "undefined" &&
        typeof this.filters.relativeDateFrequency !== "undefined"
      ) {
        return (
          (this.filters.relativeDateModifier === 0 ? "Last " : "Next ") +
          this.filters.relativeDateAmount +
          " " +
          this.dateFrequencyOptions[this.filters.relativeDateFrequency].label +
          (this.filters.relativeDateAmount > 0 ? "s" : "")
        );
      } else {
        if (this.filters.dateRange) {
          if (this.filters.dateRange.length > 0) {
            return (
              moment(this.filters.dateRange[0]).format("DD/MM/YYYY") +
              " - " +
              moment(this.filters.dateRange[1]).format("DD/MM/YYYY")
            );
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },
    ...mapState("manufacturingCycleList", [
      "additionalFiltersInitialized",
      "availableSortColumnsOrder",
      "sortedColumns",
    ]),
  },
  methods: {
    ...mapActions("manufacturingCycleList", [
      "setRequestId",
      "setChosenDesignStatuses",
      "setChosenProcessingStatuses",
      "setChosenManufacturingLocations",
      "setChosenJobSteps",
      "setCurrentDateTabValue",
      "setChosenTab",
      "setDateRange",

      "setRelativeDateModifier",
      "setRelativeDateFrequency",
      "setRelativeDateAmount",

      "loadFilters",
      "loadChosenFilters",
      "clearDateFilters",

      "storeSelectedRecordId",
      "storePage",
      "refresh",
      "storeQueryActiveDisplayName",
    ]),
    ...mapMutations([showLoading, hideLoading]),
    saveFilters(filterName) {
      this.storeQueryActiveDisplayName(filterName);
      let storedQueryId = this.$route.query.storedQueryId || null;
      if (filterName !== this.filters.storedQueryActiveDisplayName) {
        storedQueryId = null;
      }
      this.storeQueryActiveDisplayName(filterName);

      const request = {
        filters: {
          ...this.filters,
          selectedAdditionalFilters: this.selectedAdditionalFilters,
          storedQueryId: storedQueryId,
        },
        sortModel: this.sortedColumns.map((sortColumn) => {
          const currentSortColumnIndex =
            this.availableSortColumnsOrder.findIndex(
              (x) => x == sortColumn.column
            );

          if (currentSortColumnIndex == -1 && sortColumn.column != null) {
            const milestoneColumn = this.leadTimesColumns.find(
              (c) => c.name.toLowerCase() == sortColumn.column.toLowerCase()
            );

            return {
              jobStepId: milestoneColumn.jobStepId,
              sortOrder: sortColumn.ascending ? 1 : 0,
            };
          }
          return {
            sortedColumn: currentSortColumnIndex,
            sortOrder: sortColumn.ascending ? 1 : 0,
          };
        }),
        name: filterName,
      };

      if (request.filters.chosenJobSteps) {
        var chosenSteps = [];
        for (let step of request.filters.chosenJobSteps) {
          chosenSteps.push({ stepId: step[0] });
        }
        request.filters.selectedWorkflowSteps = chosenSteps;
      }

      manufacturingCycleListService
        .saveFiltersAsPresetAction(request)
        .then((response) => {
          const data = response.data;
          if (data.result == OperationResultType.Error) {
            this.$message({
              type: "error",
              message: data.message,
            });
          } else {
            this.filterName = "";
            this.$message({
              type: "success",
              message: "Filter saved succesfully.",
            });
          }
        });
    },
    querySearchAsync(queryString, cb) {
      manufacturingCycleListService
        .filterRequests(queryString)
        .then((response) => {
          const result = response.data;
          this.requestIdOptions = result.data;
        });
    },
    jobStepFilter(node, keyword) {
      if (node.text.toLowerCase().includes(keyword.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form--label-top::v-deep .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-cascader-menu__wrap {
  height: 335px;
}

.el-cascader__search-input {
  min-width: 20px;
}
</style>

import operationType from "../enums/OperationType";

export default {
  methods: {
    handleAttribute(attribute, defaultValue) {
      if (attribute === false || attribute === 0 || attribute === "") {
        return attribute;
      }
      return attribute || defaultValue;
    },
    handleFormRulesMode() {
      if (this.formMode === operationType.edit) {
        return this.editRules;
      } else if (this.formMode === operationType.add) {
        return this.addRules;
      }
    },
  },
};

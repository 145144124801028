export default class LinkedPlanModel {
  id: number;
  requestId: string;
  linkType: number;
  linkDescription: string;
  comment: string;
  hasWarning: boolean;
  warningMessage: string;
  fulfilmentplanId: number;

  constructor(data) {
    if (data !== null) {
      this.id = data.id;
      this.fulfilmentplanId = data.fulfilmentPlanId;
      this.requestId = data.requestId;
      this.linkType = data.linkType;
      this.linkDescription = data.linkDescription;
      this.comment = data.comment;
      this.hasWarning = data.warning;
      this.warningMessage = data.warningMessage;
    }
  }
}

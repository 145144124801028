<template>
  <div>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :form-options="formOptions"
      :table-options="tableOptions"
      :add-template="template"
      :add-rules="validationRules"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";

import _ from "lodash";

import { productTypeService } from "../../services/producttype.service";

import CrudScreen from "../shared/crud/CrudScreen.vue";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      productTypes: [],
      manufacturingCycleDefinitions: [],
      manufacturingLocations: [],
      columns: [
        "name",
        "code",
        "defaultManufacturingLocation",
        "manufacturingAtRisk",
        "hidden",
      ],
      formOptions: {
        width: "800px",
        labelWidth: "300px",
      },
      tableOptions: {
        headings: {
          defaultManufacturingLocation: "Default Manufacturing Location",
          manufacturingAtRisk: "Manufacturing At Risk",
        },
      },
      validationRules: {
        name: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
        code: [
          { required: true, message: "Code is required", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    template() {
      return {
        id: {
          component: {
            show: false,
          },
        },
        name: {
          title: "Name",
        },
        code: {
          title: "Code",
        },
        defaultManufacturingLocationId: {
          title: "Manufacturing Location",
          component: {
            name: "el-select",
            filterable: true,
            clearable: true,
            options: this.manufacturingLocations.map(
              (manufacturingLocation) => ({
                value: manufacturingLocation.id,
                label: manufacturingLocation.name,
                disabled: !manufacturingLocation.isActive,
              })
            ),
          },
        },
        isHidden: {
          title: "Hidden",
          component: {
            name: "el-checkbox",
          },
        },
      };
    },
  },
  methods: {
    getRecords() {
      productTypeService.loadProductTypes().then((data) => {
        this.manufacturingLocations = data.data.manufacturingLocations;
        this.records = data.data.records.map((item) => ({
          ...item,
          defaultManufacturingLocation:
            item.defaultManufacturingLocationId != null
              ? this.manufacturingLocations.find(
                  (manufacturingLocation) =>
                    manufacturingLocation.id ==
                    item.defaultManufacturingLocationId
                ).name
              : "-",
          hidden: item.isHidden.toString(),
          manufacturingAtRisk: item.manufacturingAtRisk.toString(),
        }));
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      productTypeService.addProductType(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      productTypeService.editProductType(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

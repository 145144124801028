import { HTTP } from "./HttpCommon";
import WorkflowModel from "../models/WorkflowModel";

class WorkflowService {
  basePath = "Workflow/";

  getWorkflow(workflowId) {
    return HTTP.get(`${this.basePath}GetWorkflow/${workflowId}`);
  }

  getRecords() {
    return HTTP.get(`${this.basePath}GetRecords`);
  }

  getAvailableJobSteps() {
    return HTTP.get(`${this.basePath}GetAllJobSteps`);
  }

  addWorkflow(model: WorkflowModel) {
    return HTTP.post(`${this.basePath}Add`, model);
  }

  updateWorkflow(model: WorkflowModel) {
    return HTTP.put(`${this.basePath}Update`, model);
  }

  canBeUpdated(model: WorkflowModel) {
    return HTTP.post(`${this.basePath}CanBeUpdated`, model);
  }
}

export const workflowService = new WorkflowService();

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-container>
      <el-header>
        <el-row>
          <div class="fleft">
            <h1 class="details-header no-margin">DOCUMENTS</h1>
          </div>
        </el-row>
        <document-list-filters
          @show-dialog="showDialog"
          @reload-data="reloadData"
        ></document-list-filters>
      </el-header>
      <el-main>
        <v-server-table
          style="margin-top: 60px"
          ref="table"
          :columns="columns"
          :options="options"
        >
          <router-link
            :to="'/Documentation/Document/' + props.row.id + currentFilterQuery"
            slot="name"
            slot-scope="props"
            >{{ props.row.name }}</router-link
          >
          <div slot="actions" slot-scope="props">
            <el-popconfirm
              title="Do you want to remove this document?"
              @confirm="deleteDocument(props.row.id)"
            >
              <el-button
                slot="reference"
                type="primary"
                size="medium"
                class="el-icon-delete"
                :disabled="props.row.locked || !eDocPermissions.CanRemoveData"
              ></el-button>
            </el-popconfirm>
          </div>
        </v-server-table>
      </el-main>
    </el-container>
    <add-document-dialog
      :visible.sync="addNewDialogVisible"
      @submit="addNewDocument"
    ></add-document-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import AddDocumentDialog from "./AddDocumentDialog.vue";
import DocumentListFilters from "./DocumentListFilters.vue";
import { documentService } from "../../../services/edoc/document.service";
import { Event } from "vue-tables-2";
import qs from "qs";
import { ResourcesHelper } from "../../../shared/edoc/ResourcesHelper";
import { Resources } from "../../../shared/edoc/Resources";

import _ from "lodash";

import {
  getDivisions,
  getDocumentTypes,
  getPortals,
} from "../../../store/modules/mutationTypes";

export default Vue.extend({
  components: {
    AddDocumentDialog,
    DocumentListFilters,
  },
  created() {
    this.getDivisions();
    this.getDocumentTypes();
    this.getPortals();
  },
  mounted() {
    Event.$on("vue-tables.loading", () => {
      this.loading = true;
    });

    Event.$on("vue-tables.loaded", () => {
      this.loading = false;
    });
  },
  data() {
    return {
      availableDocuments: [],
      loading: false,
      columns: ["name", "division", "number", "type", "portal", "actions"],
      options: {
        headings: {
          name: "Name",
          division: "Division",
          number: "Number",
          type: "Type",
          portal: "Portal",
          actions: "",
        },
        requestFunction: (data) => {
          let query = _.pick(data, [
            "chosenDivisions",
            "chosenTypes",
            "chosenPortals",
          ]);

          if (data.orderBy) {
            query.orderBy = data.orderBy;
            query.ascending = data.ascending;
          }

          query = _.omitBy(query, _.isNil);

          if (!_.isEqual(query, this.$route.query)) {
            this.$router.replace({ query });
          }

          return documentService.getDocuments(data).catch(function (e) {
            this.dispatch("error", e);
          });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
        filterable: false,
        customFilters: ["chosenDivisions", "chosenTypes", "chosenPortals"],
        sortable: ["name", "division", "number", "type", "portal"],
        initFilters: {
          chosenDivisions:
            (this.$route.query.chosenDivisions &&
              this.$route.query.chosenDivisions.map(Number)) ||
            [],
          chosenTypes:
            (this.$route.query.chosenTypes &&
              this.$route.query.chosenTypes.map(Number)) ||
            [],
          chosenPortals:
            (this.$route.query.chosenPortals &&
              this.$route.query.chosenPortals.map(Number)) ||
            [],
        },
        orderBy: {
          column: this.$route.query.orderBy || false,
          ascending: this.$route.query.ascending !== "0",
        },
      },
      addNewDialogVisible: false,
    };
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
    canRemove() {
      return this.getAssignedPermissions["CanRemoveData"];
    },
    currentFilterQuery: function () {
      var result = qs.stringify(this.$route.query);

      return result ? "?" + result : "";
    },
  },
  methods: {
    ...mapActions("document", [getDivisions, getDocumentTypes, getPortals]),
    addNewDocument: function (item) {
      this.addNewDialogVisible = false;

      documentService.addDocument(item).then(
        (response) => {
          this.$router.push({
            path: `/Documentation/Document/${response.data}`,
          });
          this.$message({
            type: "success",
            message: Resources.AddDocumentSuccess,
          });
        },
        (error) => {
          this.onError(error, this.showDialog, Resources.AddDocumentError);
        }
      );
    },
    deleteDocument(documentId) {
      documentService.deleteDocument(documentId).then(
        (response) => {
          this.$refs.table.refresh();
          this.$message({
            type: "success",
            message: Resources.DeleteDocumentSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.DeleteDocumentError);
        }
      );
    },
    onError(
      error,
      defaultError,
      callbackFunction = () => {
        // do nothing.
      }
    ) {
      const alertTitle =
        error.response.status === 409 ? "Validation Error" : "Error";
      const alertContent =
        error.response.status === 409
          ? ResourcesHelper.getValidationMessage(error.response.data.errors)
          : defaultError;

      this.$alert(alertContent, alertTitle, {
        confirmButtonText: "OK",
        type: "error",
        callback: (action) => {
          callbackFunction();
        },
      });
    },
    showDialog: function () {
      this.addNewDialogVisible = true;
    },
    reloadData() {
      if (this.$route.query.handleFilter) {
        const customQueries = {
          chosenDivisions:
            (this.$route.query.chosenDivisions &&
              this.$route.query.chosenDivisions.map(Number)) ||
            [],
          chosenTypes:
            (this.$route.query.chosenTypes &&
              this.$route.query.chosenTypes.map(Number)) ||
            [],
          chosenPortals:
            (this.$route.query.chosenPortals &&
              this.$route.query.chosenPortals.map(Number)) ||
            [],
        };
        this.$refs.table.customQueries = customQueries;

        if (this.$route.query.orderBy) {
          this.$refs.table.orderBy.column = this.$route.query.orderBy;
          this.$refs.table.orderBy.ascending =
            this.$route.query.ascending !== "0";
        } else {
          this.$refs.table.orderBy.column = false;
          this.$refs.table.orderBy.ascending = true;
        }
      }

      this.$refs.table.refresh();
    },
  },
});
</script>

<template>
  <el-dialog
    width="25%"
    title="Associate document"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="item"
      :rules="rules"
      ref="associationForm"
      label-width="150px"
    >
      <el-form-item label="Document Number" prop="documentIds">
        <el-select
          v-model="item.documentIds"
          multiple
          filterable
          remote
          reserve-keyword
          clearable
          placeholder="None selected"
          :remote-method="searchDocumentNumbers"
        >
          <el-option
            v-for="item in availableDocumentNumbers"
            :key="item.id"
            :label="item.number"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Associate</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import { productService } from "../../../services/edoc/product.service";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        documentIds: [
          {
            type: "array",
            required: true,
            message: "Document Number is required",
          },
        ],
      },
      item: {
        documentIds: [],
      },
      availableDocumentNumbers: [],
    };
  },
  methods: {
    submitForm() {
      this.$refs.associationForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.item);
        }
      });
    },
    closeForm() {
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.item = {
        documentIds: [],
      };
      this.availableDocumentNumbers = [];
      this.$refs.associationForm.resetFields();
    },
    searchDocumentNumbers: function (query = "") {
      productService
        .getFilteredDocumentNumbersForProduct(
          query,
          this.$store.state.product.productId
        )
        .then((response) => {
          this.availableDocumentNumbers = response.data;
        });
    },
  },
});
</script>

<template>
  <el-dialog
    width="25%"
    title="Edit associated versions"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="editedItem"
      :rules="rules"
      ref="versionsAssociationForm"
      label-width="150px"
    >
      <el-form-item label="Document Version" prop="documentVersionId">
        <el-select
          v-model="editedItem.documentVersionId"
          :loading="documentVersionsLoading"
          filterable
          reserve-keyword
          clearable
          collapse-tags
          placeholder="None selected"
        >
          <el-option
            v-for="item in availableDocumentVersions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Product Version" prop="productVersionId">
        <el-select
          v-model="editedItem.productVersionId"
          filterable
          reserve-keyword
          clearable
          collapse-tags
          placeholder="None selected"
        >
          <el-option
            v-for="item in productVersions.productVersions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Associate</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import { productService } from "../../../services/edoc/product.service";
import ProductVersionsListModel from "../../../models/edoc/ProductVersionsListModel";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    productVersions: {
      type: Object,
      default: () => new ProductVersionsListModel(),
    },
    documentId: {
      type: Number,
      default: null,
    },
    associationId: {
      type: Number,
      default: null,
    },
    item: {
      type: Object,
      default() {
        return {
          id: null,
          documentVersionId: null,
          productVersionId: null,
        };
      },
    },
  },
  data() {
    return {
      rules: {
        documentVersionId: [
          { required: true, message: "Document Version is required" },
        ],
        productVersionId: [
          { required: true, message: "Product Version is required" },
        ],
      },
      editedItem: {
        type: Object,
        default: {
          id: null,
          associationId: null,
          documentVersionId: null,
          productVersionId: null,
        },
      },
      availableDocumentVersions: [],
      documentVersionsLoading: false,
    };
  },
  computed: {},
  methods: {
    submitForm() {
      this.$refs.versionsAssociationForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.editedItem);
        }
      });
    },
    closeForm() {
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.$refs.versionsAssociationForm.resetFields();
      this.editedItem = {
        id: null,
        associationId: null,
        documentVersionId: null,
        productVersionId: null,
      };
      this.availableDocumentVersions = [];
    },
    searchDocumentNumbers: function (query = "") {
      productService
        .getFilteredDocumentNumbersForProduct(
          query,
          this.$store.state.product.productId
        )
        .then((response) => {
          this.availableDocumentNumbers = response.data;
        });
    },
    getDocumentVersions: function (documentId) {
      this.documentVersionsLoading = true;

      productService.getDocumentVersions(documentId).then((response) => {
        this.availableDocumentVersions = response.data;
        this.documentVersionsLoading = false;
      });
    },
  },
  watch: {
    visible: function (isVisible) {
      if (isVisible) {
        this.getDocumentVersions(this.documentId);
        this.editedItem = {
          id: this.item.id,
          associationId: this.associationId,
          documentVersionId: this.item.documentVersionId,
          productVersionId: this.item.productVersionId,
        };
      }
    },
  },
});
</script>

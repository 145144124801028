<template>
    <div>
        <template v-for="(manufacturingJob, index) in model.prototypeManufacturingJobs">
            <prototype-manufacturing-job
                :style="{
                    'margin-bottom':
                    index < model.prototypeManufacturingJobs.length - 1 ? '30px' : '0px',
                }"
                style="padding-left: 30px;"
                :key="manufacturingJob.id"
                :model="manufacturingJob"
                :material-number="model.materialNumber"
                :manufacturing-cycle-id="manufacturingCycleId"
                :timestamp="timestamp"
                @change="manufacturingJobChanged"
            ></prototype-manufacturing-job>
        </template>
    </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import PrototypeManufacturingJob from "./PrototypeManufacturingJob.vue";

export default Vue.extend({
    components: {
        PrototypeManufacturingJob,
    },
    props: {
        model: {
            required: true,
            type: Object,
        },
    },
    computed: {
        ...mapState("prototypeManufacturingCycleDetails", ["timestamp"]),
        manufacturingCycleId() {
            return Number(this.$route.params.id);
        },
    },
    methods: {
        ...mapActions("prototypeManufacturingCycleDetails", ["setRefreshData"]),
        manufacturingJobChanged() {
            this.setRefreshData();
        },
    },
});
</script>

import { HTTP } from "./HttpCommon";

class ChangesService {
  basePath = "Changes/";

  loadFilters() {
    return HTTP.get(`${this.basePath}LoadFilters`);
  }
  getChangesItems(filter) {
    return HTTP.post(`${this.basePath}GetFulfilmentPlanChanges`, filter);
  }
  getChangeTypeFilterDetails(changeType: string) {
    return HTTP.get(
      `${this.basePath}GetChangeTypeFilterDetails?changeType=${changeType}`
    );
  }
  getFilteredRequestIds(containsString: string) {
    return HTTP.get(
      `${this.basePath}GetRequestIds?containsString=${containsString}`
    );
  }
  getFilteredUsers(containsString: string) {
    return HTTP.get(
      `${this.basePath}GetUsers?containsString=${containsString}`
    );
  }
  saveChangesFilter(filter) {
    return HTTP.post(`${this.basePath}SaveChangesFilter`, filter);
  }
  getFiltersList() {
    return HTTP.get(`${this.basePath}GetFiltersList`);
  }
  loadState(storedQueryId) {
    return HTTP.get(`${this.basePath}LoadState/${storedQueryId}`);
  }
}

export const changesService = new ChangesService();

import { HTTP } from "./HttpCommon";

class WorkflowStepEffortService {
  getEfforts(manufacturingLocationId: number) {
    return HTTP.get(
      `WorkflowStepEffort/GetEfforts?manufacturingLocationId=${manufacturingLocationId}`
    );
  }

  editWorkflowStepEffort(data) {
    return HTTP.post(`WorkflowStepEffort/EditWorkflowStepEffort`, data);
  }
}

export const workflowStepEffortService = new WorkflowStepEffortService();

export default class LinkTypeModel {
  type: number;
  name: string;

  constructor(data) {
    if (data !== null) {
      this.type = data.type;
      this.name = data.name;
    }
  }
}

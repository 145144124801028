<template>
  <div>
    <el-form label-width="140px" size="mini" style="width: 100%">
      <el-form-item label="Material Number">{{
        model.materialNumber !== null ? model.materialNumber : "-"
      }}</el-form-item>
      <el-form-item label="Combined Orders">
        <div v-if="model.combinedOrders.length === 0">-</div>
        <template v-else v-for="combinedOrder in model.combinedOrders">
          <router-link
            :to="`/CombinedOrders/${combinedOrder.id}`"
            :key="combinedOrder.id"
          >
            {{ combinedOrder.identifier }}
          </router-link>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    model: {
      required: true,
      type: Object,
    },
  },
});
</script>

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
const instrumentationKey: string = process.env.VUE_APP_INSTRUMENTATION_KEY;
export default {
  install: (Vue, options) => {
    const config = options.appInsightsConfig || {};
    config.instrumentationKey = config.instrumentationKey || instrumentationKey;

    if (!config.instrumentationKey) {
      return;
    }
    Vue.appInsights = new ApplicationInsights({ config });
    Vue.appInsights.loadAppInsights();

    const router = options.router;
    const baseName = "crt-app";
    router.beforeEach((route, from, next) => {
      if (route.meta.isOidcCallback) {
        return next();
      }
      const name = baseName + route.fullPath;
      Vue.appInsights.startTrackPage(name);
      next();
    });

    router.afterEach((to, from) => {
      if (from.meta.isOidcCallback) {
        return;
      }
      const name = baseName + from.fullPath;
      const url = location.protocol + "//" + location.host + from.fullPath;
      Vue.appInsights.stopTrackPage(name, url);
      Vue.appInsights.flush();
    });

    Object.defineProperty(Vue.prototype, "$appInsights", {
      get: () => Vue.appInsights,
    });
  },
};

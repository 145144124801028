<template>
  <el-card>
    <div slot="header">
      <h4>PRODUCT VERSIONS</h4>
      <el-button
        type="primary"
        size="medium"
        class="fright"
        @click="openAddProductVersionDialog"
        :disabled="!eDocPermissions.CanManageProducts"
        >Add New Product Version</el-button
      >
    </div>
    <el-table :data="productVersions.productVersions" stripe>
      <el-table-column
        label="VERSION"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="ECN NUMBER"
        property="ecnNumber"
        align="center"
      ></el-table-column>
      <el-table-column label="DOCUMENTS" align="center">
        <template slot-scope="scope">
          <div class="links">
            <router-link
              v-for="documentVersion in scope.row.documentVersions"
              :to="'/Documentation/Document/' + documentVersion.documentId"
              :key="documentVersion.documentVersionId"
              style="display: block"
              >{{ getDocumentName(documentVersion) }}</router-link
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="KEYCODE" prop="keyCode" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="medium"
            @click="assignKeyCode(scope.row.id)"
            v-if="!scope.row.keyCode"
            :disabled="
              !eDocPermissions.CanManageProducts &&
              (!productVersions.needsKeyCode ||
                scope.row.documentVersions.length == 0)
            "
            >Assign Keycode</el-button
          >
          <span v-if="scope.row.keyCode">{{ scope.row.keyCode }}</span>
        </template>
      </el-table-column>
      <el-table-column align="right" width="240">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="medium"
            class="el-icon-edit"
            @click="openEditProductVersionDialog(scope.row, scope.$index)"
            :disabled="!eDocPermissions.CanManageProducts"
          ></el-button>
          <el-popconfirm
            title="Do you want to remove this version?"
            @confirm="deleteVersion(scope.row.id)"
          >
            <el-button
              slot="reference"
              type="primary"
              size="medium"
              class="el-icon-delete"
              :disabled="!eDocPermissions.CanRemoveData"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <add-product-version-dialog
      :visible.sync="addProductVersionDialogVisible"
      @submit="addProductVersion"
      ref="addProductVersionDialog"
    ></add-product-version-dialog>
    <edit-product-version-dialog
      :visible.sync="editProductVersionDialogVisible"
      :item="editedProductVersion"
      @submit="editProductVersion"
      ref="editProductVersionDialog"
    ></edit-product-version-dialog>
  </el-card>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import _ from "lodash";
import AddProductVersionDialog from "./AddProductVersionDialog.vue";
import EditProductVersionDialog from "./EditProductVersionDialog.vue";
import ProductVersionsListModel from "../../../models/edoc/ProductVersionsListModel";
import { productService } from "../../../services/edoc/product.service";
import { ResourcesHelper } from "../../../shared/edoc/ResourcesHelper";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  components: {
    AddProductVersionDialog,
    EditProductVersionDialog,
  },
  props: {
    productVersions: {
      type: Object,
      default: () => new ProductVersionsListModel(),
    },
  },
  data() {
    return {
      addProductVersionDialogVisible: false,
      editProductVersionDialogVisible: false,
      editedProductVersion: null,
    };
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
  },
  methods: {
    openAddProductVersionDialog() {
      this.addProductVersionDialogVisible = true;
    },
    openEditProductVersionDialog(productVersion, index) {
      this.editedProductVersion = productVersion;
      this.editProductVersionDialogVisible = true;
    },
    addProductVersion(item) {
      this.addProductVersionDialogVisible = false;
      const productId = this.$store.state.product.productId;

      productService.addProductVersion(item, productId).then(
        () => {
          this.reloadData();
          this.$refs.addProductVersionDialog.clearForm();
          this.$message({
            type: "success",
            message: Resources.AddProductVersionSuccess,
          });
        },
        (error) => {
          this.onError(
            error,
            Resources.AddProductVersionError,
            this.openAddProductVersionDialog
          );
        }
      );
    },
    editProductVersion(item) {
      this.editProductVersionDialogVisible = false;
      const productId = this.$store.state.product.productId;

      productService.editProductVersion(item, productId).then(
        () => {
          this.reloadData();
          this.$refs.editProductVersionDialog.clearForm();
          this.$message({
            type: "success",
            message: Resources.EditProductVersionSuccess,
          });
        },
        (error) => {
          this.onError(
            error,
            Resources.EditProductVersionError,
            this.openAddProductVersionDialog
          );
        }
      );
    },
    assignKeyCode(versionId) {
      const productId = this.$store.state.product.productId;
      productService.assignKeyCode(productId, versionId).then(
        () => {
          this.reloadData();
          this.$message({
            type: "success",
            message: Resources.AssignKeyCodeSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.AssignKeyCodeError);
        }
      );
    },
    deleteVersion(versionId) {
      productService.deleteProductVersion(versionId).then(
        () => {
          this.reloadData();
          this.$message({
            type: "success",
            message: Resources.DeleteProductVersionSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.DeleteProductVersionError);
        }
      );
    },
    onError(error, defaultErrorContent, callbackFunction = {}) {
      const alertTitle =
        error.response.status === 409 ? "Validation Error" : "Error";
      const alertContent =
        error.response.status === 409
          ? ResourcesHelper.getValidationMessage(error.response.data.errors)
          : defaultErrorContent;

      this.$alert(alertContent, alertTitle, {
        confirmButtonText: "OK",
        type: "error",
        callback: (action) => {
          callbackFunction();
        },
      });
    },
    reloadData() {
      this.$emit("reload-data");
    },
    getDocumentName(documentVersion) {
      if (!documentVersion) {
        return "";
      }

      var documentName = documentVersion.documentNumber;

      if (documentVersion.versionName) {
        documentName += ` (${documentVersion.versionName})`;
      }

      return documentName;
    },
  },
});
</script>
<style lang="scss">
h4 {
  padding-left: 20px;
  display: inline-block;
}

.links {
  padding-top: 5px;
  line-height: 18px;
}
</style>

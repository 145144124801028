<template>
  <el-card>
    <div slot="header">
      <h4>REVISIONS</h4>
      <el-button
        type="primary"
        size="medium"
        class="fright"
        @click="openAddDocumentRevisionDialog"
        :disabled="!eDocPermissions.CanManageDocuments"
        >Add New Revision</el-button
      >
    </div>
    <el-table
      :data="revisionsList.revisions"
      row-key="id"
      :expand-row-keys="expandedRevisions"
      stripe
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-card>
            <div slot="header">
              <h4>FILES</h4>
            </div>
            <el-table :data="props.row.files">
              <el-table-column label="eIFU Name" width="300">
                <template slot-scope="scope">
                  <span class="long-text">{{ scope.row.eifuName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Location">
                <template slot-scope="scope">
                  <a v-bind:href="scope.row.location" class="long-text">
                    {{ scope.row.location }}
                  </a>
                </template>
              </el-table-column>
              <el-table-column label="Countries" width="120">
                <template slot-scope="scope">
                  <span>{{ chosenRegion(scope.row) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Languages" width="120">
                <template slot-scope="scope">
                  <span
                    class="languages"
                    v-for="languageId in scope.row.languages"
                    :key="languageId"
                    >{{ chosenLanguage(languageId) }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column align="right" width="150">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="medium"
                    class="el-icon-edit"
                    @click="openEditDocumentFileDialog(scope.row)"
                    :disabled="!eDocPermissions.CanManageDocuments"
                  ></el-button>
                  <el-popconfirm
                    title="Do you want to remove this file?"
                    @confirm="deleteFile(scope.row.id)"
                  >
                    <el-button
                      slot="reference"
                      type="primary"
                      size="medium"
                      class="el-icon-delete"
                      :disabled="!eDocPermissions.CanRemoveData"
                    ></el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </template>
      </el-table-column>
      <el-table-column
        label="Revision"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Issue Date"
        prop="issueDate"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Incompatible"
        align="center"
        v-if="revisionsList.isDocumentVersioned"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.incompatible"
            :disabled="true"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        label="Version"
        prop="versionName"
        v-if="revisionsList.isDocumentVersioned"
      ></el-table-column>
      <el-table-column align="right" width="300">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="medium"
            class="el-icon-edit"
            @click="openEditDocumentRevisionDialog(scope.row)"
            :disabled="!eDocPermissions.CanManageDocuments"
          ></el-button>
          <el-popconfirm
            title="Do you want to remove this revision?"
            @confirm="deleteRevision(scope.row.id)"
          >
            <el-button
              slot="reference"
              type="primary"
              size="medium"
              class="el-icon-delete"
              :disabled="
                scope.row.hasAssociatedProductVersions ||
                scope.row.isPartOfKeyCode ||
                scope.row.incompatible ||
                !eDocPermissions.CanRemoveData
              "
            ></el-button>
          </el-popconfirm>
          <el-button
            type="primary"
            size="medium"
            @click="openAddDocumentFileDialog(scope.row.id)"
            :disabled="!eDocPermissions.CanManageDocuments"
            >Add New File</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <add-document-revision-dialog
      :visible.sync="addRevisionDialogVisible"
      :isFirstVersionInDocument="isAddedRevisionFirstInDocument"
      :isDocumentVersioned="revisionsList.isDocumentVersioned"
      @submit="addDocumentRevision"
      ref="addRevisionDialog"
    ></add-document-revision-dialog>
    <edit-document-revision-dialog
      :visible.sync="editRevisionDialogVisible"
      :item="editedRevision"
      :isFirstVersionInDocument="isEditedRevisionFirstInDocument"
      :isDocumentVersioned="revisionsList.isDocumentVersioned"
      :isFirstRevisionInVersion="isEditedRevisionFirstInVersion"
      @submit="editDocumentRevision"
    ></edit-document-revision-dialog>
    <add-document-file-dialog
      :visible.sync="addFileDialogVisible"
      @submit="addDocumentFile"
      ref="addFileDialog"
    ></add-document-file-dialog>
    <edit-document-file-dialog
      :visible.sync="editFileDialogVisible"
      :item="editedFile"
      @submit="editDocumentFile"
    ></edit-document-file-dialog>
  </el-card>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { getLanguages } from "../../../store/modules/mutationTypes";
import AddDocumentRevisionDialog from "./AddDocumentRevisionDialog.vue";
import EditDocumentRevisionDialog from "./EditDocumentRevisionDialog.vue";
import AddDocumentFileDialog from "./AddDocumentFileDialog.vue";
import EditDocumentFileDialog from "./EditDocumentFileDialog.vue";
import DocumentRevisionsListModel from "../../../models/edoc/DocumentRevisionsListModel";
import DocumentRevisionModel from "../../../models/edoc/DocumentRevisionModel";
import DocumentFileModel from "../../../models/edoc/DocumentFileModel";
import { documentService } from "../../../services/edoc/document.service";
import _ from "lodash";
import { ResourcesHelper } from "../../../shared/edoc/ResourcesHelper";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  components: {
    AddDocumentRevisionDialog,
    EditDocumentRevisionDialog,
    AddDocumentFileDialog,
    EditDocumentFileDialog,
  },
  props: {
    revisionsList: {
      type: Object,
      default: () => new DocumentRevisionsListModel(),
    },
  },
  mounted() {
    this.getLanguages();
  },
  data() {
    return {
      addRevisionDialogVisible: false,
      editRevisionDialogVisible: false,
      addFileDialogVisible: false,
      editFileDialogVisible: false,
      editedRevision: new DocumentRevisionModel(),
      addFileRevisionId: null,
      editedFile: new DocumentFileModel(),
      isAddedRevisionFirstInDocument: false,
      isEditedRevisionFirstInDocument: false,
      isEditedRevisionFirstInVersion: false,
      expandedRevisions: [],
    };
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
    availableLanguages() {
      return this.$store.state.document.availableLanguages;
    },
  },
  methods: {
    ...mapActions("document", [getLanguages]),
    openAddDocumentRevisionDialog() {
      this.isAddedRevisionFirstInDocument =
        this.revisionsList.revisions == null ||
        this.revisionsList.revisions.length == 0;
      this.addRevisionDialogVisible = true;
    },
    openEditDocumentRevisionDialog(revision) {
      this.editedRevision = revision;
      var revisionsInSameVersion = this.revisionsList.revisions.filter(
        (r) => r.versionName === revision.versionName
      );
      var firstRevisionInVersion = this.getRevisionWithMinIssueDate(
        revisionsInSameVersion
      );

      this.isEditedRevisionFirstInDocument = this.isFirstRevisionInDocument(
        this.editedRevision.id
      );
      this.isEditedRevisionFirstInVersion =
        firstRevisionInVersion.id === this.editedRevision.id;
      this.editRevisionDialogVisible = true;
    },
    openAddDocumentFileDialog(revisionId) {
      this.addFileRevisionId = revisionId;
      this.addFileDialogVisible = true;
    },
    openEditDocumentFileDialog(file) {
      this.editedFile = file;
      this.editFileDialogVisible = true;
    },
    chosenLanguage(languageId) {
      return this.availableLanguages.filter((l) => l.value === languageId)[0]
        .label;
    },
    chosenRegion(documentFile) {
      if (!documentFile.region) {
        return "None";
      }

      if (!documentFile.region.isImplicit || documentFile.region.name) {
        return documentFile.region.name;
      }

      var listOfCountries = documentFile.region.children
        .map((country) => country.name)
        .join(", ");
      return listOfCountries;
    },
    addDocumentRevision(item) {
      this.addRevisionDialogVisible = false;

      documentService
        .addRevision(this.$store.state.document.documentId, item)
        .then(
          () => {
            this.$refs.addRevisionDialog.clearForm();
            this.reloadData();
            this.$message({
              type: "success",
              message: Resources.AddRevisionSuccess,
            });
          },
          (error) => {
            this.onError(
              error,
              Resources.AddRevisionError,
              this.openAddDocumentRevisionDialog
            );
          }
        );
    },
    editDocumentRevision(item) {
      this.editRevisionDialogVisible = false;

      documentService
        .editRevision(this.$store.state.document.documentId, item)
        .then(
          () => {
            this.reloadData();
            this.$message({
              type: "success",
              message: Resources.EditRevisionSuccess,
            });
          },
          (error) => {
            this.onError(error, Resources.EditRevisionError, () =>
              this.openEditDocumentRevisionDialog(this.editedRevision)
            );
          }
        );
    },
    addDocumentFile(item) {
      this.addFileDialogVisible = false;

      documentService
        .addFile(
          this.$store.state.document.documentId,
          this.addFileRevisionId,
          item
        )
        .then(
          () => {
            this.$refs.addFileDialog.clearForm();
            this.expandedRevisions = [this.addFileRevisionId];
            this.reloadData();
            this.$message({
              type: "success",
              message: Resources.AddDocumentFileSuccess,
            });
          },
          (error) => {
            this.onError(error, Resources.AddDocumentFileError, () =>
              this.openAddDocumentFileDialog(this.addFileRevisionId)
            );
          }
        );
    },
    editDocumentFile(item) {
      this.editFileDialogVisible = false;

      documentService.editFile(item).then(
        () => {
          this.reloadData();
          this.$message({
            type: "success",
            message: Resources.EditDocumentFileSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.EditDocumentFileError, () =>
            this.openEditDocumentFileDialog(this.editedFile)
          );
        }
      );
    },
    deleteRevision(revisionId) {
      documentService.deleteRevision(revisionId).then(
        () => {
          this.reloadData();
          this.$message({
            type: "success",
            message: Resources.DeleteDocumentRevisionSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.DeleteDocumentRevisionError, () => {
            // do nothing.
          });
        }
      );
    },
    deleteFile(fileId) {
      documentService.deleteFile(fileId).then(
        () => {
          this.reloadData();
          this.$message({
            type: "success",
            message: Resources.DeleteDocumentFileSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.DeleteDocumentFileError, () => {
            // do nothing.
          });
        }
      );
    },
    onError(error, defaultErrorContent, callbackFunction) {
      const alertTitle =
        error.response.status === 409 ? "Validation Error" : "Error";
      const alertContent =
        error.response.status === 409
          ? ResourcesHelper.getValidationMessage(error.response.data.errors)
          : defaultErrorContent;

      this.$alert(alertContent, alertTitle, {
        confirmButtonText: "OK",
        type: "error",
        callback: (action) => {
          callbackFunction();
        },
      });
    },
    reloadData() {
      this.$emit("reload-data");
    },
    getRevisionWithMinIssueDate(revisions) {
      var sortedRevisions = _.orderBy(
        revisions,
        ["issueDate", "id"],
        ["asc", "asc"]
      );
      return sortedRevisions[0];
    },
    isFirstRevisionInDocument(revisionId) {
      const firstRevisionInDocument = this.getRevisionWithMinIssueDate(
        this.revisionsList.revisions
      );
      const isFirstRevisionInDocument =
        firstRevisionInDocument.id === revisionId;

      return isFirstRevisionInDocument;
    },
  },
});
</script>
<style lang="scss">
h4 {
  padding-left: 20px;
  display: inline-block;
}

.languages {
  display: block;
}

.long-text {
  word-wrap: break-word;
}
</style>

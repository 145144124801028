<template>
  <div class="card-w">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-button @click="onSelectWorkflow" plain>Select Workflow</el-button>
      </el-col>
      <el-col :span="4">
        <div class="label-16">Name</div>
        <el-input v-model="workflowModel.name"></el-input>
      </el-col>
      <el-col :span="4">
        <div class="label-16">Display name</div>
        <el-input v-model="workflowModel.displayName"></el-input>
      </el-col>
      <el-col :span="12">
        <el-button
          type="danger"
          class="crud-add-button"
          icon="el-icon-delete"
          @click="onDeleteWorkflow(model)"
        ></el-button>
      </el-col>
    </el-row>
    <el-row>
      <div class="label-16" v-if="workflowModel.workflowSteps.length > 0">
        Steps:
      </div>
    </el-row>
    <div
      v-for="workflowStep in workflowModel.workflowSteps"
      :key="workflowStep.Id"
      class="panel panel-success card-element"
    >
      <div class="panel-body job-step-definition-body">
        <div class="job-step-definition-name">
          <span class="details">{{ workflowStep.name }}</span>
        </div>
        <div
          class="job-step-definition-successor success-successor"
          slot="reference"
        >
          {{ workflowStep.successName || "Next success" }}
        </div>
        <div
          class="job-step-definition-successor fail-successor"
          slot="reference"
        >
          {{ workflowStep.failureName || "Next fail" }}
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="Workflows"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <div v-loading="isDataLoading">
        <el-row>
          <el-table
            ref="singleTable"
            height="50vh"
            :data="availableWorkflows"
            highlight-current-row
            @current-change="handleCurrentChange"
          >
            <el-table-column property="symbol" label="Symbol" />
          </el-table>
        </el-row>
      </div>
      <el-row>
        <el-button class="crud-add-button" @click="onApplyWorkflow" plain
          >Apply</el-button
        >
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";

import WorkflowModel from "../../models/WorkflowModel";
import { manufacturingCycleDefinitionService } from "../../services/manufacturingCycleDefinition.service";

export default Vue.extend({
  props: {
    workflowModel: {
      type: Object,
      default: () => new WorkflowModel(),
    },
    identifier: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      isDataLoading: false,
      dialogVisible: false,
      model: this.workflowModel,
      availableWorkflows: [],
      selectedWorkflow: null,
    };
  },
  created() {
    this.model = this.workflowModel;
  },
  methods: {
    onSelectWorkflow() {
      this.dialogVisible = true;
      this.getRecords().then(() => {
        this.setSelectedWorkflow();
      });
    },
    getRecords() {
      this.isDataLoading = true;
      const promise = manufacturingCycleDefinitionService.getAllWorkflows();
      promise
        .then((response) => {
          this.availableWorkflows = response.data.data.map(
            (item) => new WorkflowModel(item)
          );
        })
        .finally(() => {
          this.isDataLoading = false;
        });

      return promise;
    },
    setSelectedWorkflow() {
      if (this.workflowModel) {
        const selectedWorkflow = this.availableWorkflows.find(
          (w) => w.id === this.workflowModel.id
        );
        this.setCurrent(selectedWorkflow);
      }
    },
    onApplyWorkflow() {
      this.$emit(
        "update:workflow-model",
        new WorkflowModel(this.selectedWorkflow)
      );
      this.dialogVisible = false;
    },
    onDeleteWorkflow(item) {
      this.$confirm(
        "Do you want to remove " +
          (!item.name ? "this workflow" : item.name) +
          "?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.$emit("delete", this.identifier);
      });
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this? ").then(() => {
        done();
      });
    },
    setCurrent(row) {
      if (this.$refs.singleTable) {
        this.$refs.singleTable.setCurrentRow(row);
      }
    },
    handleCurrentChange(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.selectedWorkflow = newVal;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.card-w {
  border: 1px solid #dcdfe6;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
}
</style>

export default class PrototypeWorkflowStepModel {
    id: number;
    workflowStepId: number;
    name: string;
    successStepId: number;
    sequenceNumber: number;

    constructor(data: any = null) {
        this.id = null;
        this.workflowStepId = null;
        this.name = null;
        this.successStepId = null;
        this.sequenceNumber = null;

        if (data != null) {
            this.id = data.id;
            this.workflowStepId = data.workflowStepId;
            this.name = data.name;
            this.successStepId = data.successStepId;
            this.sequenceNumber = data.sequenceNumber;
        }
    }

}
import { HTTP } from "./HttpCommon";

class FulfilmentPlanService {
  getPlanId(requestId: string) {
    return HTTP.get(`FulfilmentPlan/GetPlanId/${requestId}`);
  }

  createExternalPlan(model) {
    return HTTP.post("FulfilmentPlan/SaveNewPlan", {
      planId: model.requestId,
      distributionCenterId: model.distributionCenterId,
      productType: model.productTypeId,
      productNumber: model.productId,
      additionalEmails: model.emailAddresses,
      patientName: model.patientName,
      surgeryDate: model.surgeryDate,
      requestTypeId: 5,
    });
  }
}

export const fulfilmentPlanService = new FulfilmentPlanService();

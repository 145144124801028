<template>
  <div>
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">DOCUMENT TYPES</h1>
      </div>
    </el-row>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :form-options="{ width: '20%' }"
      :add-template="template"
      :add-rules="validationRules"
      :table-options="tableOptions"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
      @row-remove="onDelete"
      deletable
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";
import { configurationService } from "../../../services/edoc/configuration.service";
import CrudScreen from "../../shared/crud/CrudScreen.vue";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      columns: ["name", "eifuValue"],
      tableOptions: {
        headings: {
          eifuValue: "eIFU Value",
        },
      },
      template: {
        id: {
          component: {
            show: false,
          },
        },
        name: {
          title: "Name",
          value: "",
          component: {
            name: "el-input",
          },
        },
        eifuValue: {
          title: "eIFU Value",
          value: "",
          component: {
            name: "el-input",
          },
        },
      },
      validationRules: {
        name: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getRecords() {
      configurationService.loadDocumentTypes().then((data) => {
        this.records = data.data;
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      configurationService.addDocumentType(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onChange(item, callback) {
      this.loading = true;
      configurationService.editDocumentType(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
    onDelete(item, callback) {
      this.loading = true;
      configurationService.deleteDocumentType(item).then((data) => {
        callback(data.data);
        this.getRecords();
      });
    },
  },
});
</script>

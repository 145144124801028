<template>
  <div></div>
</template>

<script>
import { mapMutations } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import { manufacturingCycleListService } from "../../services/manufacturingcyclelist.service";

export default {
  async mounted() {
    const id = this.$route.params.id;
    this.showLoading();
    if (id) {
      await manufacturingCycleListService.executeFilter(id);
      this.$router.push({
        path: `/ManufacturingCycle/List?storedQueryId=${id}`,
      });
    } else {
      this.$router.push({ path: `/ManufacturingCycle/List` });
    }

    this.hideLoading();
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
  },
};
</script>

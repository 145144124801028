<template>
  <el-card style="width: 100%">
    <div slot="header">
      <h4 style="padding-left: 20px; display: inline-block">Information</h4>
    </div>
    <el-form size="mini" label-width="140px">
      <el-form-item :label="identifierLabelName" class="form-item-padding">
        <div style="font-size: 12px">
          <editable-input
            style="padding-left: 15px"
            v-model="information.identifier"
            v-on:input="editIdentifier"
            :editable="true"
            :allowEmpty="false"
          ></editable-input>
        </div>
      </el-form-item>
      <el-form-item label="Definition" class="form-item-padding">
        <div style="font-size: 12px; padding-left: 15px">
          {{ information.combinedOrderDefinitionName }}
        </div>
      </el-form-item>
      <el-form-item :label="orderNumberLabelName" class="form-item-padding">
        <div style="font-size: 12px">
          <editable-input
            style="padding-left: 15px"
            v-model="information.orderNumber"
            v-on:input="editOrderNumber"
            :editable="true"
          ></editable-input>
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import Vue from "vue";
import { combinedOrderService } from "../../services/combinedorder.service";
import { EditableInput } from "../shared/Shared.vue";
import {
  CombinedOrderKeyType,
  getCombinedOrderLabel,
} from "./CombinedOrderKeyType";
import CombinedOrderInformationModel from "@/models/CombinedOrderInformationModel";

export default Vue.extend({
  components: {
    EditableInput,
  },
  data() {
    return {
      combinedOrderKeyType: CombinedOrderKeyType,
    };
  },
  props: {
    information: {
      type: Object,
      default: () => new CombinedOrderInformationModel(),
    },
  },
  computed: {
    combinedOrderDefinitions() {
      return this.$store.state.combinedOrder.combinedOrderDefinitions;
    },
    identifierLabelName() {
      if (this.information.combinedOrderDefinitionName) {
        if (this.information.combinedOrderDefinitionName === "Sterilization") {
          return "Sterile Lot Number";
        }
        const chosenCombinedOrderDefinition =
          this.combinedOrderDefinitions.filter(
            (cod) => cod.name === this.information.combinedOrderDefinitionName
          );
        if (chosenCombinedOrderDefinition.length === 1) {
          return getCombinedOrderLabel(
            chosenCombinedOrderDefinition[0].keyType
          );
        }
      }
      return "Identifier";
    },
    orderNumberLabelName() {
      if (this.information.combinedOrderDefinitionName === "Sterilization") {
        return "Tracking Number";
      }
      return "Order Number";
    },
  },
  methods: {
    editOrderNumber() {
      combinedOrderService.editOrderNumber(
        this.$store.state.combinedOrder.combinedOrderId,
        this.information.orderNumber,
        (data) => {
          if (data.result == 0) {
            this.showNotification(data.message, "success");
          }
        }
      );
    },
    editIdentifier() {
      combinedOrderService.editIdentifier(
        this.$store.state.combinedOrder.combinedOrderId,
        this.information.identifier,
        (data) => {
          if (data.result === 0) {
            this.showNotification(data.message, "success");
          }
          if (data.result === 2) {
            this.showNotification(data.message, "error");
            this.$emit("reloadData");
          }
        }
      );
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

import { AvailabilityType } from "../../enums/design/AvailabilityType";

export default class Availability {
  id: number = null;
  type: AvailabilityType;

  constructor(type: AvailabilityType) {
    this.type = type;
  }
}

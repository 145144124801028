import ManufacturingCycleTabsModel from "./ManufacturingCycleTabsModel";
import { DateFrequency } from "../../enums/enums";
import moment from "moment";
import "moment-timezone";
import { ManufacturingCycleTabType } from "../../enums/ManufacturingCycleTabType";

export default class ChosenFiltersModel {
  requestId: string = null;
  chosenDesignStatuses: number[] = [];
  chosenProcessingStatuses: number[] = [];
  chosenDate: number;
  dateType: number;
  dateRange: any = [];
  startDate: any;
  endDate: any;
  period: string;
  isRelativeDate = false;
  relativeDateAmount = 0;
  relativeDateFrequency = 0;
  relativeDateModifier = 0;
  chosenManufacturingLocations: number[] = [];
  currentDateTabValue = "0";
  chosenDateFilter: number;
  storedQueryId: string;
  chosenJobSteps: number[] = [];
  selectedCycleMilestones: any[] = [];
  chosenTab: ManufacturingCycleTabsModel = new ManufacturingCycleTabsModel(
    { name: "All" },
    ManufacturingCycleTabType.All
  );
  isCritical = false;
  requestTypeId = null;
  productType = 0;
  storedQueryActiveDisplayName = "";
  constructor(data) {
    if (data !== null) {
      this.storedQueryActiveDisplayName = data.storedQueryActiveDisplayName;

      if (data.requestId !== null) {
        this.requestId = data.requestId;
      }

      if (data.chosenDesignStatuses !== null) {
        for (const item of data.chosenDesignStatuses) {
          if (!this.chosenDesignStatuses.some((x) => x === item)) {
            this.chosenDesignStatuses.push(item);
          }
        }
      }

      if (data.chosenProcessingStatuses != null) {
        for (const item of data.chosenProcessingStatuses) {
          if (!this.chosenProcessingStatuses.some((x) => x === item)) {
            this.chosenProcessingStatuses.push(item);
          }
        }
      }

      if (data.chosenDate != null) {
        this.chosenDate = data.chosenDate;
      }

      if (data.dateType != null) {
        this.dateType = data.dateType;
      } else {
        this.dateType = 0;
      }
      this.isRelativeDate = data.isRelativeDate;
      if (this.isRelativeDate) {
        this.isRelativeDate = data.isRelativeDate;
        if (data.relativeDateAmount != null && this.isRelativeDate) {
          this.relativeDateAmount = data.relativeDateAmount.toString();
        }

        if (data.relativeDateFrequency != null && this.isRelativeDate) {
          this.relativeDateFrequency = data.relativeDateFrequency;
        }

        if (data.relativeDateModifier != null && this.isRelativeDate) {
          this.relativeDateModifier = data.relativeDateModifier;
        }

        if (this.relativeDateAmount >= 0) {
          this.startDate = moment()
            .startOf("day")
            .add(
              this.relativeDateModifier === 0
                ? -this.relativeDateAmount
                : this.relativeDateAmount,
              DateFrequency[
                this.relativeDateFrequency
              ].toLowerCase() as moment.unitOfTime.DurationConstructor
            )
            .toDate();
          this.endDate = moment().endOf("day").toDate();
        }
      } else {
        this.isRelativeDate = false;
        if (data.startDate) {
          this.startDate = moment(data.startDate).utc().format();
          if (!this.isRelativeDate) {
            this.dateRange.push(this.startDate);
          }
        }

        if (data.endDate) {
          this.endDate = moment(data.endDate).utc().format();
          if (!this.isRelativeDate) {
            this.dateRange.push(this.endDate);
          }
        }
      }

      if (data.chosenManufacturingLocations != null) {
        for (const item of data.chosenManufacturingLocations) {
          this.chosenManufacturingLocations.push(item);
        }
      }
      if (this.isRelativeDate) {
        this.currentDateTabValue = "1";
      } else {
        this.currentDateTabValue = "0";
      }

      if (data.productType) {
        this.productType = data.productType;
      }

      if (data.requestTypeId) {
        this.requestTypeId = data.requestTypeId;
      }
      if (data.selectedWorkflowSteps != null) {
        this.chosenJobSteps = data.selectedWorkflowSteps.map((step) => [
          step.stepId,
        ]);
      }

      if (data.selectedCycleMilestones != null) {
        this.selectedCycleMilestones = data.selectedCycleMilestones;
      }
    }
  }
}

import { DesignHttpClient } from "../../shared/DesignHttpClient";

class SessionTimesService {
  getAvailableTimesForPlan(
    start: string,
    end: string,
    planId: string,
    preparationTime: any,
    sessionTime: any,
    postProcessingTime: any
  ) {
    return DesignHttpClient.get(
      `sessionTimes?dateTimeFrom=${start}&dateTimeTo=${end}&requestId=${planId}&preparationTime=${preparationTime.toFormat(
        "d.hh:mm:ss"
      )}&sessionTime=${sessionTime.toFormat(
        "hh:mm:ss"
      )}&postprocessingTime=${postProcessingTime.toFormat("d.hh:mm:ss")}`
    );
  }

  getAvailableTimes(
    start: string,
    end: string,
    productTypeId: number,
    priority: boolean,
    preparationTime: any,
    sessionTime: any,
    postProcessingTime: any
  ) {
    return DesignHttpClient.get(
      `sessionTimes?dateTimeFrom=${start}&dateTimeTo=${end}&productTypeId=${productTypeId}&priority=${priority}&preparationTime=${preparationTime.toFormat(
        "d.hh:mm:ss"
      )}&sessionTime=${sessionTime.toFormat(
        "hh:mm:ss"
      )}&postprocessingTime=${postProcessingTime.toFormat("d.hh:mm:ss")}`
    );
  }
}

export const sessionTimesService = new SessionTimesService();

import { userService } from "../../services/users.service";
import { getUsers, setUsers, setPasswordSetupUrl } from "./mutationTypes";

const state = {
  users: [],
  passwordSetupUrl: "",
};

const mutations = {
  [setUsers](state, payload) {
    state.users = payload;
  },
  [setPasswordSetupUrl](state, payload) {
    state.passwordSetupUrl = payload;
  },
};

const actions = {
  [getUsers]({ commit }) {
    return userService
      .getUsers(this.state.oidcStore.access_token)
      .then((response) => commit(setUsers, response.data));
  },
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: {},
};

<template>
  <div>
    <label class="field-label">Date</label>
    <el-popover
      ref="date-popover"
      placement="right"
      width="278"
      trigger="click"
    >
      <el-tabs type="border-card" v-model="chosenFilters.currentDateTabValue">
        <el-tab-pane label="Absolute" type="border-card">
          <label for="startDate" class="field-label">Start Date</label>
          <div id="startDate">
            <el-date-picker
              v-model="absoluteStartDate"
              type="date"
              placeholder="Start date"
              @change="filterDate"
            ></el-date-picker>
          </div>
          <label for="endDate" style="margin-top: 10px" class="field-label"
            >End Date</label
          >
          <div id="endDate">
            <el-date-picker
              v-model="absoluteEndDate"
              type="date"
              placeholder="End date"
              @change="filterDate"
            ></el-date-picker>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Relative">
          <el-popover ref="date" placement="right" width="450" trigger="hover">
            <ul>
              <li>
                You can specify a time unit after a time value 'X', such as Xw,
                Xd, Xh or Xm, to represent weeks(w), days (d), hours (h) and
                minutes (m), respectively.
              </li>
              <li>Press "Enter" key to apply filter</li>
            </ul>
          </el-popover>
          <el-input
            placeholder="eg. 4w 2d 3h 55m"
            v-model="chosenFilters.relativeDate"
            @change="filterDate"
            clearable
          >
            <i
              slot="prefix"
              class="el-icon-info el-input__icon"
              v-popover:date
            ></i>
          </el-input>
        </el-tab-pane>
      </el-tabs>
    </el-popover>
    <el-input
      placeholder="None selected"
      suffix-icon="el-icon-date"
      readonly="readonly"
      clearable
      @change="clearDate"
      v-popover:date-popover
      v-model="dateValue"
    ></el-input>
  </div>
</template>

<script>
import Vue from "vue";
import { DateTime } from "luxon";

export default Vue.extend({
  props: {
    chosenFilters: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      dateValue: null,
      MS_MILLISECOND: 1,
      MS_SECOND: 1000,
      humantimeCache: null,

      regex: "/^([0-9]+w)?[ ]*([0-9]+d)?[ ]*([0-9]+h)?[ ]*([0-9]+m)?$/",
    };
  },
  created() {
    this.changeDateDisplay();
  },
  methods: {
    filterDate() {
      this.changeDateDisplay();
      this.$emit("changed", this.chosenFilters);
    },
    clearDate() {
      this.clearAllDates();
    },
    changeDateDisplay() {
      this.dateValue = "";
      if (
        (this.chosenFilters.absoluteStartDate != null ||
          this.chosenFilters.absoluteEndDate != null) &&
        this.chosenFilters.currentDateTabValue === "0"
      ) {
        if (this.chosenFilters.absoluteStartDate != null) {
          this.dateValue =
            this.dateValue +
            DateTime.fromISO(this.chosenFilters.absoluteStartDate).toFormat(
              "dd/MM/yyyy"
            );
        }

        this.dateValue = this.dateValue = this.dateValue + " - ";

        if (this.chosenFilters.absoluteEndDate != null) {
          this.dateValue =
            this.dateValue +
            DateTime.fromISO(this.chosenFilters.absoluteEndDate).toFormat(
              "dd/MM/yyyy"
            );
        }

        this.clearRelativeDate();
      } else if (
        this.chosenFilters.currentDateTabValue === "1" &&
        this.chosenFilters.relativeDate !== null &&
        this.chosenFilters.relativeDate !== ""
      ) {
        var relativeDateMiliseconds = this.relativeDateToMiliseconds(
          this.chosenFilters.relativeDate
        );
        this.chosenFilters.relativeDateMiliseconds = relativeDateMiliseconds;
        var relativeDateText = this.humanize(relativeDateMiliseconds);
        if (relativeDateText !== null && relativeDateText !== "") {
          this.dateValue = "Last " + relativeDateText;
        } else {
          this.dateValue = null;
        }
        this.clearAbsoluteDate();
      } else {
        this.clearAllDates();
      }
    },

    clearAllDates() {
      this.dateValue = null;
      this.chosenFilters.relativeDateMiliseconds = null;
      this.clearAbsoluteDate();
      this.clearRelativeDate();
    },

    clearAbsoluteDate() {
      this.chosenFilters.absoluteStartDate = null;
      this.chosenFilters.absoluteEndDate = null;
    },

    clearRelativeDate() {
      this.chosenFilters.relativeDate = null;
      this.chosenFilters.relativeDateMiliseconds = null;
    },
    relativeDateToMiliseconds(relativeDate) {
      var input = relativeDate.trim();
      var result = 0;
      if (this.regex.test(relativeDate)) {
        var matcher = this.regex.exec(relativeDate);
        var sum = 0;
        sum += this.dehumanizeUnit(matcher[1], this.MS_WEEK);
        sum += this.dehumanizeUnit(matcher[2], this.MS_DAY);
        sum += this.dehumanizeUnit(matcher[3], this.MS_HOUR);
        sum += this.dehumanizeUnit(matcher[4], this.MS_MINUTE);
        result = sum;
      }
      return result;
    },

    dehumanizeUnit(group, unit) {
      var result = 0;
      if (group) {
        var intString = group.replace(/[a-z ]/, "");
        result = parseInt(intString) * unit;
      }
      return result;
    },

    humanize(time) {
      var separator = " ";
      var calculationObject = { timeLeft: time, result: "" };
      calculationObject = this.appendUnit(
        calculationObject,
        separator,
        this.MS_WEEK,
        " week(s)"
      );
      calculationObject = this.appendUnit(
        calculationObject,
        separator,
        this.MS_DAY,
        " day(s)"
      );
      calculationObject = this.appendUnit(
        calculationObject,
        separator,
        this.MS_HOUR,
        " hour(s)"
      );
      calculationObject = this.appendUnit(
        calculationObject,
        separator,
        this.MS_MINUTE,
        " minute(s)"
      );

      return calculationObject.result.toString().trim();
    },
    appendUnit(calculationObject, separator, unit, text) {
      if (calculationObject.timeLeft >= unit) {
        var hours = Math.floor(calculationObject.timeLeft / unit);
        calculationObject.result += hours;
        calculationObject.result += text;
        calculationObject.result += separator;
        calculationObject.timeLeft -= unit * hours;
      }
      return {
        timeLeft: calculationObject.timeLeft,
        result: calculationObject.result,
      };
    },
  },
  computed: {
    absoluteStartDate: {
      get: function () {
        return this.chosenFilters.absoluteStartDate !== null
          ? DateTime.fromISO(this.chosenFilters.absoluteStartDate).toJSDate()
          : null;
      },
      set: function (newValue) {
        if (newValue !== null) {
          this.chosenFilters.absoluteStartDate =
            DateTime.fromJSDate(newValue).toISODate();
        } else {
          this.chosenFilters.absoluteStartDate = null;
        }
      },
    },
    absoluteEndDate: {
      get: function () {
        return this.chosenFilters.absoluteEndDate !== null
          ? DateTime.fromISO(this.chosenFilters.absoluteEndDate).toJSDate()
          : null;
      },
      set: function (newValue) {
        if (newValue !== null) {
          this.chosenFilters.absoluteEndDate =
            DateTime.fromJSDate(newValue).toISODate();
        } else {
          this.chosenFilters.absoluteEndDate = null;
        }
      },
    },
    MS_MINUTE: function () {
      return this.MS_SECOND * 60;
    },
    MS_HOUR: function () {
      return this.MS_MINUTE * 60;
    },
    MS_DAY: function () {
      return this.MS_HOUR * 24;
    },
    MS_WEEK: function () {
      return this.MS_DAY * 7;
    },
  },
});
</script>

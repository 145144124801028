import Availability from "./Availability";
import { AvailabilityType } from "../../enums/design/AvailabilityType";
import moment from "moment";

export default class SingleAvailability extends Availability {
  startDate: moment.Moment = moment().startOf("day");
  endDate: moment.Moment = moment().startOf("day");
  startTime = "00:00";
  endTime = "00:00";

  constructor(model: any, timezone: string) {
    super(AvailabilityType.Single);

    if (model != null) {
      this.id = model.id;

      const startDateZoned = moment(model.startDate).tz(timezone);
      const endDateZoned = moment(model.endDate).tz(timezone);

      this.startTime = moment(startDateZoned).format("HH:mm");
      this.endTime = moment(endDateZoned).format("HH:mm");

      this.startDate = startDateZoned.startOf("day").utc();
      this.endDate = this.startDate.clone();
    }
  }
}

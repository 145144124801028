export default class ShipmentInformationModel {
  id: number;
  trackingNumber: string;
  trackingUrl: string;

  constructor(data) {
    if (data !== null) {
      this.id = data.id;
      this.trackingNumber = data.trackingNumber;

      if (
        data.trackingUrl === null ||
        data.trackingUrl.match(/^ *$/) !== null ||
        data.trackingUrl.includes("http://") ||
        data.trackingUrl.includes("https://")
      ) {
        this.trackingUrl = data.trackingUrl;
      } else {
        this.trackingUrl = "https://" + data.trackingUrl;
      }
    }
  }
}

import CommentModel from "./CommentModel";

export default class CombinedOrderInformationModel {
  identifier: string;
  combinedOrderDefinitionName: string;
  orderNumber = "";
  comments: CommentModel[] = [];

  constructor(data: any = null) {
    if (data !== null) {
      this.combinedOrderDefinitionName = data.combinedOrderDefinitionName;
      this.identifier = data.identifier;
      this.orderNumber = data.orderNumber;
      this.comments = data.comments.map((c) => new CommentModel(c));
    }
  }
}

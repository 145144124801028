<template>
  <el-card
    v-loading.fullscreen.lock="loading"
    v-if="filters && filters.length > 0"
  >
    <div slot="header">
      <h4>eDOC Filters</h4>
    </div>
    <v-client-table
      style="margin-top: 15px"
      ref="table"
      :columns="columns"
      :options="options"
      :data="filters"
    >
      <div slot="name" slot-scope="props">
        <a :href="getLink(props.row)">{{ props.row.name }}</a>
      </div>
      <div slot="accessibility" slot-scope="props">
        <span v-if="props.row.isPrivate">Private</span>
        <span v-else>Public</span>
      </div>
      <div slot="actions" slot-scope="props">
        <div>
          <el-button
            type="primary"
            size="medium"
            class="el-icon-edit"
            @click="openEditDialog(props.row)"
          ></el-button>
          <el-popconfirm
            title="Do you want to remove this filter?"
            @confirm="deleteStoredQuery(props.row)"
          >
            <el-button
              slot="reference"
              type="primary"
              size="medium"
              class="el-icon-delete"
            ></el-button>
          </el-popconfirm>
        </div>
      </div>
    </v-client-table>
    <edit-filter-dialog
      :visible.sync="editFilterDialogVisible"
      :item="editedFilter"
      @submit="editStoredQuery"
    ></edit-filter-dialog>
  </el-card>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import EditFilterDialog from "./EditFilterDialog.vue";
import { filterService } from "../../services/filter.service";
import { StoredQueryType } from "../../models/edoc/StoredQueryType";
import { Resources } from "../../shared/edoc/Resources";

export default Vue.extend({
  components: {
    EditFilterDialog,
  },
  mounted() {
    this.getFilters();
  },
  data() {
    return {
      editFilterDialogVisible: false,
      editedFilter: null,
      loading: true,
      filters: [],
      columns: ["name", "accessibility", "actions"],
      options: {
        headings: {
          name: "Name",
          accessibility: "Accessibility",
          actions: "Actions",
        },
        filterable: true,
        sortable: ["name"],
      },
    };
  },
  methods: {
    getFilters() {
      filterService.getEDocStoredQueries().then((response) => {
        this.filters = response.data;
        this.loading = false;
      });
    },
    openEditDialog(storedQuery) {
      this.editFilterDialogVisible = true;
      this.editedFilter = storedQuery;
    },
    editStoredQuery(storedQuery) {
      filterService.editEDocStoredQuery(storedQuery).then(
        (response) => {
          this.getFilters();
          this.editFilterDialogVisible = false;
        },
        (error) => {
          this.showNotification(Resources.EditStoredQueryError, "error");
        }
      );
    },
    deleteStoredQuery(storedQuery) {
      filterService.deleteEDocStoredQuery(storedQuery.id).then(
        (response) => {
          this.getFilters();
        },
        (error) => {
          this.showNotification(Resources.DeleteStoredQueryError, "error");
        }
      );
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    getLink(storedQuery) {
      let url = "/CRT/Documentation/";

      if (storedQuery.storedQueryType === StoredQueryType.Documents) {
        url += "Document";
      } else if (storedQuery.storedQueryType === StoredQueryType.Products) {
        url += "Product";
      }

      url += "?handleFilter=1&";
      url += storedQuery.query;

      return url;
    },
  },
});
</script>

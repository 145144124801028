const getters = {};

const state = {
    userId: null,
};

const actions = {};

const mutations = {
  chooseUserId(state, value) {
    state.userId = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { DateTime } from "luxon";

export class ChangesDateFilterModel {
  relativeDate = "";
  relativeDateMiliseconds: number;
  absoluteStartDate: DateTime = null;
  absoluteEndDate: DateTime = null;
  currentDateTabValue = "0";

  constructor(model: any) {
    if (model) {
      this.relativeDateMiliseconds = model.relativeDateMiliseconds;
      this.relativeDate = model.relativeDate;

      if (model.absoluteStartDate != null) {
        this.absoluteStartDate = DateTime.fromISO(model.absoluteStartDate);
      }

      if (model.absoluteEndDate != null) {
        this.absoluteEndDate = DateTime.fromISO(model.absoluteEndDate);
      }
      this.currentDateTabValue = String(model.currentDateTabValue);
    }
  }
}

import { DocumentationHttpClient } from "../../shared/edoc/DocumentationHttpClient";
import qs from "qs";

class DocumentImportService {
  importDocument(file) {
    const formData = new FormData();
    formData.append("file", file);

    return DocumentationHttpClient.post("/DocumentImport", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  getDocumentImportsList(filter) {
    return DocumentationHttpClient.get("/DocumentImport", {
      params: filter,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
  getDocumentImportDetailsList(importId) {
    return DocumentationHttpClient.get(`/DocumentImport/${importId}`);
  }
}

export const documentImportService = new DocumentImportService();

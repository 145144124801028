<template>
  <div>
    <el-button
      type="primary"
      class="fright"
      @click="showAddUserDialog"
      style="margin-bottom: 5px"
      >Add User</el-button
    >
    <v-client-table
      name="table"
      :data="users"
      :columns="columns"
      :options="options"
    >
      <div slot="isActive" slot-scope="props">
        {{ props.row.isActive ? "Yes" : "No" }}
      </div>
      <router-link
        :to="'/Users/' + props.row.id"
        slot="details"
        slot-scope="props"
        >Details</router-link
      >
    </v-client-table>
    <el-dialog title="Add User" :visible.sync="addUserDialogVisible">
      <user-info
        :model="userInfo"
        @save="addUser"
        :disabled="isAddingUser"
      ></user-info>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import {
  getUsers,
  setUsers,
  setPasswordSetupUrl,
} from "../../store/modules/mutationTypes";

import UserInfo from "./UserInfo";
import UserInfoModel from "../../models/UserInfoModel";

import { userService } from "../../services/users.service";
import { OperationResultType } from "../../enums/enums";

export default Vue.extend({
  components: {
    UserInfo,
  },
  created() {
    this.getUsers();
  },
  data() {
    return {
      columns: [
        "userName",
        "firstName",
        "lastName",
        "email",
        "symbol",
        "isActive",
        "details",
      ],
      options: {
        filterable: true,
        sortable: [
          "userName",
          "firstName",
          "lastName",
          "email",
          "symbol",
          "isActive",
        ],
        headings: {
          firstName: "First Name",
          lastName: "Last Name",
          isActive: "Is Active",
        },
      },
      addUserDialogVisible: false,
      userInfo: new UserInfoModel(),
      isAddingUser: false,
    };
  },
  computed: {
    ...mapState("users", ["users"]),
    ...mapGetters("oidcStore", ["oidcIsAuthenticated"]),
  },
  watch: {
    oidcIsAuthenticated: function (newValue, oldValue) {
      if (newValue) {
        this.getUsers();
      }
    },
  },
  methods: {
    ...mapActions("users", [getUsers, setUsers]),
    ...mapMutations("users", [setPasswordSetupUrl]),
    showAddUserDialog() {
      this.addUserDialogVisible = true;
    },
    addUser() {
      this.isAddingUser = true;

      userService.addUser(this.userInfo).then(
        (response) => {
          var data = response.data;
          this.setPasswordSetupUrl(data.setPasswordUrl);

          this.$router.push({ path: "/Users/" + data.userId });
          this.isAddingUser = false;
        },
        (error) => {
          let data = error.response.data;

          this.$message({
            message: data.errors[0],
            type: "error",
          });
        }
      );
    },
  },
});
</script>

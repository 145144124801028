import { HTTP } from "./HttpCommon";
import qs from "qs";

class RequestImportService {
  getRequestImports(filter) {
    return HTTP.get("ImportRequest/GetImports", {
      params: filter,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }

  getImportDetails(data) {
    return HTTP.post("ImportRequest/GetImportedRequestDetails", data);
  }

  getImportMessages(id) {
    return HTTP.get("ImportRequest/GetImportMessages/" + id);
  }

  getImportStatus(id) {
    return HTTP.get("ImportRequest/GetImportStatus/" + id);
  }

  importRequests(file, updateExistingPlans) {
    const formData = new FormData();
    formData.append("requestsFile", file);
    formData.append("updateExistingPlans", updateExistingPlans);
    return HTTP.post("ImportRequest/Import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const requestImportService = new RequestImportService();

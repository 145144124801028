var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}]},[_c('el-row',{staticStyle:{"margin-bottom":"0px"}},[_c('div',{staticClass:"fleft"},[_c('h1',{staticClass:"details-header no-margin"},[_vm._v("KEYCODES")])]),_c('el-button',{staticClass:"fright",staticStyle:{"margin-bottom":"5px"},attrs:{"disabled":!_vm.eDocPermissions.CanManageKeyCodes},on:{"click":_vm.addNewKeyCode}},[_vm._v("Create new keycode")])],1),_c('el-row',[_c('v-client-table',{ref:"table",staticStyle:{"margin-top":"15px"},attrs:{"columns":_vm.columns,"options":_vm.options,"data":_vm.keyCodes,"v-if":_vm.keyCodes},scopedSlots:_vm._u([{key:"documents",fn:function(props){return _c('div',{staticClass:"links"},_vm._l((props.row.documents),function(ref){
var number = ref.number;
var versionName = ref.versionName;
var documentId = ref.documentId;
return _c('div',{key:documentId},[(_vm.eDocPermissions.CanReadDocuments)?_c('router-link',{key:number + versionName,staticStyle:{"display":"block"},attrs:{"to":'/Documentation/Document/' + documentId}},[_vm._v(_vm._s(_vm.formatDocumentData(number, versionName)))]):_c('p',{key:number + versionName,staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.formatDocumentData(number, versionName))+" ")])],1)}),0)}},{key:"products",fn:function(props){return _c('div',{staticClass:"links"},_vm._l((props.row.products),function(ref){
var gtin = ref.gtin;
var versionName = ref.versionName;
var productId = ref.productId;
return _c('div',{key:productId},[(_vm.eDocPermissions.CanReadProducts)?_c('router-link',{key:gtin + versionName,staticStyle:{"display":"block"},attrs:{"to":'/Documentation/Product/' + productId}},[_vm._v(_vm._s(_vm.formatProductData(gtin, versionName)))]):_c('p',{key:gtin + versionName,staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.formatProductData(gtin, versionName))+" ")])],1)}),0)}},{key:"assignedAt",fn:function(props){return _c('div',{},_vm._l((props.row
            .products),function(ref){
            var gtin = ref.gtin;
            var versionName = ref.versionName;
            var keyCodeAssignedAt = ref.keyCodeAssignedAt;
return _c('div',{key:gtin + versionName},[_vm._v(" "+_vm._s(_vm.toLocalDate(keyCodeAssignedAt))+" ")])}),0)}},{key:"edit",fn:function(props){return _c('div',{},[_c('el-button',{attrs:{"size":"mini","disabled":!_vm.eDocPermissions.CanManageKeyCodes},on:{"click":function($event){return _vm.editKeyCodeDocument(props.row)}}},[_vm._v("Edit Documents")]),_c('el-button',{attrs:{"size":"mini","disabled":!_vm.eDocPermissions.CanManageKeyCodes},on:{"click":function($event){return _vm.editKeyCodeProduct(props.row)}}},[_vm._v("Edit Products")])],1)}}])})],1),_c('edit-key-code-document-dialog',{attrs:{"visible":_vm.editDocumentDialogVisible,"item":_vm.editedKeyCode},on:{"update:visible":function($event){_vm.editDocumentDialogVisible=$event},"submit":_vm.updateDocuments}}),_c('edit-key-code-product-dialog',{attrs:{"visible":_vm.editProductDialogVisible,"item":_vm.editedKeyCode},on:{"update:visible":function($event){_vm.editProductDialogVisible=$event},"submit":_vm.updateProducts}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
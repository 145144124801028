export default class CombinedManufacturingJobModel {
  id: number;
  fulfilmentPlanId: number;
  requestId: string;
  workflow: string;
  passNumber: number;
  isReadyForManufacturing: boolean;

  constructor(data: any = null) {
    if (data !== null) {
      this.id = data.id;
      this.requestId = data.requestId;
      this.fulfilmentPlanId = data.fulfilmentPlanId;
      this.workflow = data.workflow;
      this.passNumber = data.passNumber;
      this.isReadyForManufacturing = data.isReadyForManufacturing;
    }
  }
}

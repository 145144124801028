<template>
  <el-dialog
    title="Edit tracking URL"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form :model="model" ref="model" label-width="120px">
      <el-form-item label="Tracking URL" prop="requestId">
        <el-input
          type="textarea"
          :editable="canManageFulfilmentPlan"
          :rows="2"
          placeholder="Please input"
          v-model="model.modifiedTrackingUrl"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button
        type="primary"
        @click="$emit('submit', model.modifiedTrackingUrl)"
        >Confirm</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    trackingUrl: null,
  },
  data() {
    return {
      model: {
        modifiedTrackingUrl: this.trackingUrl,
      },
    };
  },
  computed: {
    ...mapState("fulfilmentPlanDetails", ["canManageFulfilmentPlan"]),
  },
  watch: {
    trackingUrl: function (newValue) {
      this.model.modifiedTrackingUrl = newValue;
    },
  },
  methods: {
    closeForm() {
      this.$emit("close");
    },
  },
};
</script>

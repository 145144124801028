<template>
  <div v-loading="editionLock">
    <el-table
      :data="model.linkedPlans"
      stripe
      v-loading="loadingLinkedPlans"
      height="500"
      style="width: 100%"
    >
      <el-table-column prop="requestId" label="PLAN ID" align="center">
        <template slot-scope="scope">
          <span>
            <router-link
              :to="underlyingRequestUrl(scope.row.fulfilmentplanId)"
              >{{ scope.row.requestId }}</router-link
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="linkDescription"
        label="LINK TYPE"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="showCommentColumn"
        prop="comment"
        label="COMMENT"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="showWarningMessageColumn"
        align="center"
        width="35px"
      >
        <template slot-scope="scope">
          <el-popover
            v-if="scope.row.hasWarning"
            trigger="hover"
            placement="top"
          >
            {{ scope.row.warningMessage }}
            <div slot="reference">
              <i class="el-icon-warning"></i>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template slot="header">
          <el-button
            size="mini"
            icon="el-icon-plus"
            @click="dialogVisible = true"
          ></el-button>
        </template>
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.id !== 0 && scope.row.linkType !== 2"
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <linked-plan-dialog
      :visible.sync="dialogVisible"
      @close="dialogVisible = false"
      @submit="handleAdd"
      :link-types="linkTypes"
    ></linked-plan-dialog>
  </div>
</template>
<script>
import LinkedPlanListModel from "../../models/fulfilment-plan/LinkedPlanListModel";
import LinkTypeModel from "../../models/fulfilment-plan/LinkTypeModel";
import LinkedPlanDialog from "./LinkedPlanDialog.vue";
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    LinkedPlanDialog,
  },
  data() {
    return {
      model: new LinkedPlanListModel(null),
      linkTypes: [],
      dialogVisible: false,
      loadingLinkedPlans: false,
    };
  },
  computed: {
    showCommentColumn: function () {
      return this.model.linkedPlans.some(function (linkedPlan) {
        return linkedPlan.comment !== null;
      });
    },
    showWarningMessageColumn: function () {
      return this.model.linkedPlans.some(function (linkedPlan) {
        return linkedPlan.hasWarning;
      });
    },
    ...mapState("fulfilmentPlanDetails", ["editionLock"]),
  },
  mounted() {
    this.getLinkedPlans(this.$route.params.id);
    this.getLinkTypes();
  },
  methods: {
    ...mapActions("fulfilmentPlanDetails", ["setEditionLock"]),
    getLinkedPlans(id) {
      this.loadingLinkedPlans = true;
      fulfilmentPlanDetailsService.getLinkedPlans(id).then((response) => {
        const data = response.data;
        this.model = new LinkedPlanListModel(data);
        this.loadingLinkedPlans = false;
      });
    },
    underlyingRequestUrl(id) {
      return "/FulfilmentPlan/Details/" + id;
    },
    getLinkTypes() {
      fulfilmentPlanDetailsService.getLinkTypes().then((response) => {
        const data = response.data;
        if (data !== null && data.types.length > 0) {
          for (let item of data.types) {
            var newLinkType = new LinkTypeModel(item);
            this.linkTypes.push(newLinkType);
          }
        }
      });
    },
    handleAdd(item) {
      this.setEditionLock(true);
      const id = this.$route.params.id;
      this.dialogVisible = false;
      fulfilmentPlanDetailsService
        .saveLink(id, item.requestId, item.linkType, item.comment)
        .then((response) => {
          const result = response.data;
          if (result.result !== 0) {
            this.$message({
              message: result.message,
              type: "error",
            });
          } else {
            this.getLinkedPlans(id);
            this.$message({
              message: "Link added.",
              type: "success",
            });
          }
        })
        .catch(() => {
          this.$message.error("Error during adding link. Please try again.");
        });
      this.setEditionLock(false);
    },
    handleDelete(index, row) {
      this.$confirm(
        "Do you want to remove link with Request " + row.requestId + "?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.setEditionLock(true);
          const id = this.$route.params.id;
          fulfilmentPlanDetailsService.deleteLink(id, row.id).then(
            (response) => {
              this.getLinkedPlans(id);
              this.$message({
                message: "Link deleted.",
                type: "success",
              });
              this.setEditionLock(false);
            },
            () => {
              this.$message.error(
                "Error during deleting link. Please try again."
              );
              this.setEditionLock(false);
            }
          );
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Action aborted.",
          });
        });
    },
  },
};
</script>

import { HTTP } from "./HttpCommon";
import moment from "moment";
import "moment-timezone";

class FulfilmentPlanListService {
  getChosenFilters(storedQueryId) {
    return HTTP.get(
      `FulfilmentPlan/GetChosenFilters?storedQueryId=` + storedQueryId
    );
  }

  getListFilters() {
    return HTTP.get(`FulfilmentPlan/GetListFilters`);
  }

  getListAdditionalFilters(storedQueryId) {
    return HTTP.get(
      `FulfilmentPlan/GetListAdditionalFilters?storedQueryId=${storedQueryId}`
    );
  }

  filterRequests(query) {
    return HTTP.get(`FulfilmentPlan/FilterRequests/?containsString=` + query);
  }

  getPossibleUnderlyingRequestIds(query) {
    return HTTP.get(
      `FulfilmentPlan/GetPossibleUnderlyingRequestIds/?containsString=` + query
    );
  }

  getProductsForFulfilmentPlan(type) {
    return HTTP.get(`FulfilmentPlan/GetProductsForFulfilmentPlan?type=` + type);
  }

  saveNewPlan(model) {
    return HTTP.post(`FulfilmentPlan/SaveNewPlan`, model);
  }

  saveFiltersAsPresetAction(model) {
    return HTTP.post(`FulfilmentPlan/SaveFiltersPreset`, model);
  }

  getLinkedPlans(id) {
    return HTTP.get(`FulfilmentPlan/GetLinkedPlans/` + id);
  }

  saveFiltersInSession(model) {
    return HTTP.post("FulfilmentPlan/SaveFiltersInSession", model);
  }

  saveColumnsConfigurationAsUserDefault() {
    return HTTP.post("FulfilmentPlan/SaveColumnsConfigurationAsUserDefault");
  }

  getDefaultColumns() {
    return HTTP.post("FulfilmentPlan/GetDefaultColumns");
  }

  executeFilter(id) {
    return HTTP.post(`FulfilmentPlan/ExecuteFilter/${id}`);
  }

  export() {
    return HTTP.get(
      "FulfilmentPlan/Export?timezone=" + encodeURIComponent(moment.tz.guess()),
      {
        responseType: "blob",
      }
    );
  }
}

export const fulfilmentPlanListService = new FulfilmentPlanListService();

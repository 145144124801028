<template>
  <div>
    <el-collapse-item name="manufacturingCycleChanges">
      <template slot="title">
        <div class="el-collapse_header-container">
          <h4 class="el-collapse-header-bold">Manufacturing Cycle Changes</h4>
          <el-button
            size="mini"
            class="mar-r-5"
            @click.stop="onAddManufacturingCycleChange"
            plain
            >Add</el-button
          >
        </div>
      </template>
      <div
        class="el-container-item"
        v-for="item in changesNotificationSettings.manufacturingCycleChanges"
        :key="item.id"
      >
        <span>{{ item.displayText }}</span>
        <div class="nowrap mar-b-15-l-5">
          <el-button
            size="mini"
            icon="el-icon-edit"
            @click="onEditManufacturingCycleChange(item)"
          />
          <el-button
            size="mini"
            icon="el-icon-delete"
            type="danger"
            @click="onDeleteManufacturingCycleChange(item)"
          />
        </div>
      </div>
    </el-collapse-item>
    <manufacturing-cycle-change-popup
      :visible.sync="showManufacturingCycleChangePopup"
      ref="manufacturingCycleChangePopup"
      :editMode="isEdit"
      @submit="addEditManufacturingCycleChange"
      :definitions="changeNotificationSettingDialog"
    ></manufacturing-cycle-change-popup>
  </div>
</template>
<script>
import Vue from "vue";

import ManufacturingCycleChangePopup from "./ManufacturingCycleChangePopup.vue";
import { notificationSettingsService } from "../../../services/notificationsettings.service";
import { OperationResultType } from "../../../enums/enums";

export default Vue.extend({
  components: {
    ManufacturingCycleChangePopup,
  },
  props: {
    changesNotificationSettings: {
      type: Object,
      default() {
        return [];
      },
    },
    changeNotificationSettingDialog: {
      type: Object,
      default() {
        return [];
      },
    },
    notificationsSettingsId: {
      type: Number,
      default: 0,
    },
    refreshPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showManufacturingCycleChangePopup: false,
      isEdit: false,
    };
  },
  methods: {
    onAddManufacturingCycleChange() {
      this.isEdit = false;
      this.showManufacturingCycleChangePopup = true;
    },
    onEditManufacturingCycleChange(item) {
      this.isEdit = true;
      this.$refs.manufacturingCycleChangePopup.item = {
        id: item.id,
        selectedProductTypeId: item.selectedProductTypeId,
        selectedManufacturingLocationId: item.selectedManufacturingLocationId,
        selectedChangeType: item.selectedChangeType.toString(),
      };
      this.showManufacturingCycleChangePopup = true;
    },
    onDeleteManufacturingCycleChange(item) {
      this.$confirm(
        "Do you want to remove " + item.displayText + "?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.deleteManufacturingCycleChange(item.id);
      });
    },
    addEditManufacturingCycleChange: function (item) {
      if (this.isEdit) {
        this.editManufacturingCycleChange(item);
      } else {
        this.addManufacturingCycleChange(item);
      }
    },
    addManufacturingCycleChange: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService
        .addManufacturingCycleChange(item)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Manufacturing Cycle Change added succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    editManufacturingCycleChange: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService
        .editManufacturingCycleChange(item)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Manufacturing Cycle Change edited succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    deleteManufacturingCycleChange: function (id) {
      notificationSettingsService
        .deleteManufacturingCycleChange(id)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Manufacturing Cycle Change deleted succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

<template>
  <el-card>
    <div slot="header">
      <h4>EIFU DATA</h4>
    </div>
    <el-form size="mini" label-width="140px">
      <el-form-item label="Description" class="form-item-padding">
        <div>
          <editable-input
            v-model="eIfuData.eIFUDescription"
            @input="editDescription"
            :editable="eDocPermissions.CanManageProducts"
          ></editable-input>
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { EditableInput } from "../../shared/Shared.vue";
import ProductEIFUDataModel from "../../../models/edoc/ProductEIFUDataModel";
import { productService } from "../../../services/edoc/product.service";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  components: {
    EditableInput,
  },
  props: {
    eIfuData: {
      type: Object,
      default: () => new ProductEIFUDataModel(),
    },
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
  },
  methods: {
    editDescription() {
      productService
        .editProductEIFUDescription(
          this.$store.state.product.productId,
          this.eIfuData.eIFUDescription
        )
        .then(this.processEditSuccessResponse, this.processEditErrorResponse)
        .then(this.reloadData);
    },
    processEditSuccessResponse() {
      this.showNotification(Resources.ProductEditSuccess, "success");
    },
    processEditErrorResponse() {
      this.showNotification(Resources.ProductEditError, "error");
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
    reloadData() {
      this.$nextTick(() => {
        this.$emit("reload-data");
      });
    },
  },
});
</script>
<style lang="scss">
h4 {
  padding-left: 20px;
}
</style>

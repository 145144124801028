<template>
  <div>
    <v-server-table
      name="planList"
      ref="planList"
      :columns="activeColumns"
      :options="options"
      @sorted="onSortColumns"
    >
      <div slot="requestIdWithRevision" slot-scope="props">
        <router-link
          class="fulfilment-plan-request-link"
          :to="'/FulfilmentPlan/Details/' + props.row.id + '?useContext=true'"
          rel="noreferrer"
          >{{ props.row.requestIdWithRevision }}</router-link
        >
      </div>
      <div slot="underlyingRequestId" slot-scope="props">
        <a
          v-if="props.row.underlyingFulfilmentPlanUrl"
          class="fulfilment-plan-request-link"
          :href="props.row.underlyingFulfilmentPlanUrl"
          rel="noreferrer"
          >{{ props.row.underlyingRequestId }}</a
        >
      </div>
      <div slot="designSessionDate" slot-scope="props">
        {{ props.row.designSessionDate | moment }}
      </div>
      <div slot="inDesign" slot-scope="props">
        {{ props.row.inDesign | moment }}
      </div>
      <div slot="processingStatusDescription" slot-scope="props">
        <div>
          <div
            v-bind:class="{
              'processing-status-list-left':
                props.row.isManufacturingProgressVisible,
            }"
          >
            {{ props.row.processingStatusDescription }}
          </div>
          <div
            v-if="props.row.isManufacturingProgressVisible"
            style="position: relative; margin-bottom: 0; overflow: hidden"
          >
            <el-progress
              :stroke-width="16"
              :text-inside="true"
              :percentage="props.row.manufacturingProgress"
            ></el-progress>
          </div>
        </div>
      </div>
      <div slot="poNumberDate" slot-scope="props">
        {{ props.row.poNumberDate | moment }}
      </div>
      <div slot="fileUploadedDate" slot-scope="props">
        {{ props.row.fileUploadedDate | moment }}
      </div>
      <div slot="scanDataAvailableActual" slot-scope="props">
        {{
          props.row.scanDataAvailableActual
            | momenttz(props.row.scanDataAvailableActualTimezone)
        }}
      </div>
      <div slot="scanDataAvailablePlanned" slot-scope="props">
        {{
          props.row.scanDataAvailablePlanned
            | momenttz(props.row.scanDataAvailablePlannedTimezone)
        }}
      </div>
      <div slot="scanDataAcceptedActual" slot-scope="props">
        {{
          props.row.scanDataAcceptedActual
            | momenttz(props.row.scanDataAcceptedActualTimezone)
        }}
      </div>
      <div slot="scanDataAcceptedPlanned" slot-scope="props">
        {{
          props.row.scanDataAcceptedPlanned
            | momenttz(props.row.scanDataAcceptedPlannedTimezone)
        }}
      </div>
      <div slot="designStartedActual" slot-scope="props">
        {{
          props.row.designStartedActual
            | momenttz(props.row.designStartedActualTimezone)
        }}
      </div>
      <div slot="designStartedPlanned" slot-scope="props">
        {{
          props.row.designStartedPlanned
            | momenttz(props.row.designStartedPlannedTimezone)
        }}
      </div>
      <div slot="designFinalizedActual" slot-scope="props">
        {{
          props.row.designFinalizedActual
            | momenttz(props.row.designFinalizedActualTimezone)
        }}
      </div>
      <div slot="designFinalizedPlanned" slot-scope="props">
        {{
          props.row.designFinalizedPlanned
            | momenttz(props.row.designFinalizedPlannedTimezone)
        }}
      </div>
      <div slot="manufacturingDataAvailableActual" slot-scope="props">
        {{
          props.row.manufacturingDataAvailableActual
            | momenttz(props.row.manufacturingDataAvailableActualTimezone)
        }}
      </div>
      <div slot="manufacturingDataAvailablePlanned" slot-scope="props">
        {{
          props.row.manufacturingDataAvailablePlanned
            | momenttz(props.row.manufacturingDataAvailablePlannedTimezone)
        }}
      </div>
      <div slot="designApprovedActual" slot-scope="props">
        {{
          props.row.designApprovedActual
            | momenttz(props.row.designApprovedActualTimezone)
        }}
      </div>
      <div slot="designApprovedPlanned" slot-scope="props">
        {{
          props.row.designApprovedPlanned
            | momenttz(props.row.designApprovedPlannedTimezone)
        }}
      </div>
      <div slot="customerOrderActual" slot-scope="props">
        {{
          props.row.customerOrderActual
            | momenttz(props.row.customerOrderActualTimezone)
        }}
      </div>
      <div slot="customerOrderPlanned" slot-scope="props">
        {{
          props.row.customerOrderPlanned
            | momenttz(props.row.customerOrderPlannedTimezone)
        }}
      </div>
      <div slot="outofManufacturingActual" slot-scope="props">
        {{
          props.row.outOfManufacturingActual
            | momenttz(props.row.outOfManufacturingActualTimezone)
        }}
      </div>
      <div slot="outofManufacturingPlanned" slot-scope="props">
        {{
          props.row.outOfManufacturingPlanned
            | momenttz(props.row.outOfManufacturingPlannedTimezone)
        }}
      </div>
      <div slot="shipmentActual" slot-scope="props">
        {{
          props.row.shipmentActual | momenttz(props.row.shipmentActualTimezone)
        }}
      </div>
      <div slot="shipmentPlanned" slot-scope="props">
        {{
          props.row.shipmentPlanned
            | momenttz(props.row.shipmentPlannedTimezone)
        }}
      </div>
      <div slot="arrivalActual" slot-scope="props">
        {{
          props.row.arrivalActual | momenttz(props.row.arrivalActualTimezone)
        }}
      </div>
      <div slot="arrivalPlanned" slot-scope="props">
        {{
          props.row.arrivalPlanned | momenttz(props.row.arrivalPlannedTimezone)
        }}
      </div>
      <div slot="arrivalActual" slot-scope="props">
        {{
          props.row.arrivalActual | momenttz(props.row.arrivalActualTimezone)
        }}
      </div>
      <div slot="arrivalPlanned" slot-scope="props">
        {{
          props.row.arrivalPlanned | momenttz(props.row.arrivalPlannedTimezone)
        }}
      </div>
      <div slot="surgeryActual" slot-scope="props">
        {{
          props.row.surgeryActual | momenttz(props.row.surgeryActualTimezone)
        }}
      </div>
      <div slot="surgeryPlanned" slot-scope="props">
        {{ props.row.plannedSurgeryDate | moment }}
      </div>
      <div slot="manufacturingStartedActual" slot-scope="props">
        {{
          props.row.manufacturingStartedActual
            | momenttz(props.row.manufacturingStartedActualTimezone)
        }}
      </div>
      <div slot="manufacturingStartedPlanned" slot-scope="props">
        {{
          props.row.manufacturingStartedPlanned
            | momenttz(props.row.manufacturingStartedPlannedTimezone)
        }}
      </div>
      <div slot="links" slot-scope="props">
        <el-popover
          placement="left"
          v-if="props.row.linksCount > 0"
          width="500"
          trigger="click"
        >
          <div class="block">
            <el-table
              :data="linkModel.linkedPlans"
              v-loading="loadingLinkedPlans"
            >
              <el-table-column
                prop="requestId"
                label="PLAN ID"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <span>
                    <router-link
                      :to="underlyingRequestUrl(scope.row.fulfilmentplanId)"
                      rel="noreferrer"
                      >{{ scope.row.requestId }}</router-link
                    >
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="linkDescription"
                label="LINK TYPE"
                width="130"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="comment"
                label="COMMENT"
                width="200"
                align="center"
              ></el-table-column>
              <el-table-column
                v-if="showWarningMessageColumn"
                align="center"
                width="35px"
              >
                <template slot-scope="scope">
                  <el-popover
                    v-if="scope.row.hasWarning"
                    trigger="hover"
                    placement="top"
                  >
                    {{ scope.row.warningMessage }}
                    <div slot="reference">
                      <i class="el-icon-warning"></i>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-button
            slot="reference"
            size="mini"
            icon="el-icon-link"
            @click="getLinkedPlans(props.row.id)"
            >{{ props.row.linksCount }}</el-button
          >
        </el-popover>
      </div>
    </v-server-table>
  </div>
</template>

<script>
import { HTTP } from "../../services/HttpCommon";
import { fulfilmentPlanListService } from "../../services/fulfilmentplanlist.service";
import LinkedPlanListModel from "../../models/fulfilment-plan/LinkedPlanListModel";
import DateTime from "../../mixins/DateTime";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { Event } from "vue-tables-2";
import { showLoading, hideLoading } from "../../store/mutation-types";
import _ from "lodash";
export default {
  mixins: [DateTime],
  mounted() {
    this.storeInitSortedColumns(false);
    Event.$on("vue-tables.loading", () => {
      this.showLoading();
    });

    Event.$on("vue-tables.loaded", () => {
      this.hideLoading();
    });
  },
  computed: {
    ...mapGetters("fulfilmentPlanList", [
      "filtersLoaded",
      "selectedAdditionalFilters",
      "activeColumns",
    ]),
    ...mapState("fulfilmentPlanList", [
      "updateSortedColumns",
      "availableColumns",
      "initSortedColumns",
      "sortedColumns",
      "selectedRecordId",
      "filters",
      "additionalFilters",
      "refresh",
    ]),
    showWarningMessageColumn: function () {
      return this.linkModel.linkedPlans.some(function (linkedPlan) {
        return linkedPlan.hasWarning;
      });
    },
  },
  watch: {
    refresh: function (newValue) {
      if (newValue) {
        this.$refs.planList.getData();
      }
    },
    initSortedColumns: function () {
      this.initColumns();
    },
    updateSortedColumns: function () {
      if (this.updateSortedColumns) {
        this.updateColumns();
        this.$refs.planList.getData();
        this.storeUpdateSortedColumns(false);
      }
    },
  },
  data() {
    return {
      linkModel: new LinkedPlanListModel(null),
      loadingLinkedPlans: false,
      options: {
        filterable: false,
        sendInitialRequest: false,
        headings: {
          requestIdWithRevision: "Plan Id",
          productType: "Product Type",
          isPriority: "Priority",
          designStatusDescription: "Design Status",
          designSessionDate: "Design Session",
          processingStatusDescription: "Processing Status",
          lastComment: "Comments",
          designDaysInQueue: "Design Days In Queue",
          manufacturingDaysInQueue: "Manufacturing Days In Queue",
          processingDaysInQueue: "Processing Days In Queue",
          inDesign: "In Design",
          productOryginal: "Product",
          strykerRepresentative: "Stryker Rep.",
          distributionCenter: "Distribution Center",
          requestStatus: "Request Status",
          productNumber: "Product Number",
          productSymbol: "Product Symbol",
          manufacturingLocation: "Manufacturing Location",
          underlyingRequestId: "Request Id",
          poNumberDate: "PO Number Date",
          poNumber: "PO Number",
          fileUploadedDate: "Planning Data Uploaded Date",
          scanDataAvailablePlanned: "Scan Data Available Due Date",
          scanDataAvailableActual: "Scan Data Available Actual Date",
          scanDataAcceptedPlanned: "Scan Data Accepted Due Date",
          scanDataAcceptedActual: "Scan Data Accepted Actual Date",
          designStartedPlanned: "Design Started Due Date",
          designStartedActual: "Design Started Actual Date",
          designFinalizedPlanned: "Design Finalized Due Date",
          designFinalizedActual: "Design Finalized Actual Date",
          manufacturingDataAvailablePlanned:
            "Manufacturing Data Available Due Date",
          manufacturingDataAvailableActual:
            "Manufacturing Data Available Actual Date",
          designApprovedPlanned: "Design Approved Due Date",
          designApprovedActual: "Design Approved Actual Date",
          customerOrderPlanned: "Customer Order Due Date",
          customerOrderActual: "Customer Order Actual Date",
          outofManufacturingPlanned: "Out-of-Manufacturing Due Date",
          outofManufacturingActual: "Out-of-Manufacturing Actual Date",
          shipmentPlanned: "Shipment Due Date ",
          shipmentActual: "Shipment Actual Date",
          arrivalPlanned: "Arrival Due Date",
          arrivalActual: "Arrival Actual Date",
          surgeryPlanned: "Surgery Due Date",
          surgeryActual: "Surgery Actual Date",
          manufacturingStartedPlanned: "Manufacturing Started Due Date",
          manufacturingStartedActual: "Manufacturing Started Actual Date",
          quantity: "Quantity",
        },
        sortable: [
          "requestIdWithRevision",
          "productType",
          "isPriority",
          "designStatusDescription",
          "designSessionDate",
          "processingStatusDescription",
          "designDaysInQueue",
          "manufacturingDaysInQueue",
          "processingDaysInQueue",
          "inDesign",
          "productOryginal",
          "designer",
          "surgeon",
          "strykerRepresentative",
          "distributionCenter",
          "requestStatus",
          "productNumber",
          "productSymbol",
          "manufacturingLocation",
          "category",
          "underlyingRequestId",
          "poNumberDate",
          "poNumber",
          "fileUploadedDate",
          "scanDataAvailablePlanned",
          "scanDataAvailableActual",
          "scanDataAcceptedPlanned",
          "scanDataAcceptedActual",
          "designStartedPlanned",
          "designStartedActual",
          "designFinalizedPlanned",
          "designFinalizedActual",
          "manufacturingDataAvailablePlanned",
          "manufacturingDataAvailableActual",
          "designApprovedPlanned",
          "designApprovedActual",
          "customerOrderPlanned",
          "customerOrderActual",
          "outofManufacturingPlanned",
          "outofManufacturingActual",
          "shipmentPlanned",
          "shipmentActual",
          "arrivalPlanned",
          "arrivalActual",
          "surgeryPlanned",
          "surgeryActual",
          "manufacturingStartedPlanned",
          "manufacturingStartedActual",
          "quantity",
        ],
        perPage: 10,
        perPageValues: [10, 20, 50, 100],
        initialPage: Math.ceil(this.$route.query.index / 10) || 1,
        preserveState: true,
        saveState: true,
        rowAttributesCallback: (row) => {
          let isSelected = row.id == this.selectedRecordId;

          return isSelected ? { style: "background-color: #E6E6E6" } : {};
        },
        serverMultiSorting: true,
        requestFunction: (data) => {
          let filters = _.cloneDeep(this.filters);
          filters.sortColumns = [];

          for (let sortColumn of this.sortedColumns) {
            let currentSortColumn = this.availableColumns.find((x) => {
              return x.field === sortColumn.column;
            });
            filters.sortColumns.push({
              columnName: currentSortColumn.name,
              sortedColumn: Number.isInteger(currentSortColumn.milestoneId)
                ? null
                : currentSortColumn.column,
              milestoneDateType: currentSortColumn.milestoneDateType,
              milestoneId: currentSortColumn.milestoneId,
              sortOrder: sortColumn.ascending ? 1 : 0,
            });
          }
          filters.availableColumns = this.availableColumns;
          return HTTP.post("FulfilmentPlan/GetFilteredFulfilmentPlanItems", {
            filters: {
              ...filters,
              selectedAdditionalFilters: this.selectedAdditionalFilters,
            },
            limit: data.limit,
            page: data.page,
          }).then((data) => {
            this.refreshed();
            return data;
          });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.filteredPlansCount,
          };
        },
      },
    };
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapActions("fulfilmentPlanList", [
      "storeSortedColumns",
      "storeUpdateSortedColumns",
      "storeInitSortedColumns",
      "refreshed",
    ]),
    initColumns() {
      if (this.filtersLoaded) {
        if (this.initSortedColumns) {
          this.updateColumns();
        }
      }
    },
    updateColumns() {
      if (this.sortedColumns.length > 0) {
        this.$refs.planList.orderBy = this.sortedColumns[0];
      } else {
        this.$refs.planList.orderBy = {
          asceding: true,
          column: false,
        };
      }
      if (this.sortedColumns.length > 1) {
        this.$refs.planList.userMultiSorting = {};
        this.$refs.planList.userMultiSorting[this.sortedColumns[0].column] = [];
        for (var i = 1; i < this.sortedColumns.length; i++) {
          this.$refs.planList.userMultiSorting[
            this.sortedColumns[0].column
          ].push(this.sortedColumns[i]);
        }
      }
    },
    onSortColumns() {
      var list = [];
      if (this.$refs.planList.orderBy) {
        list.push(this.$refs.planList.orderBy);
      }
      if (
        this.$refs.planList.userMultiSorting[
          Object.keys(this.$refs.planList.userMultiSorting)[0]
        ] !== undefined &&
        this.$refs.planList.userMultiSorting[
          Object.keys(this.$refs.planList.userMultiSorting)[0]
        ] !== null
      ) {
        for (
          var i = 0;
          i <
          this.$refs.planList.userMultiSorting[
            Object.keys(this.$refs.planList.userMultiSorting)[0]
          ].length;
          i++
        ) {
          list.push(
            this.$refs.planList.userMultiSorting[
              Object.keys(this.$refs.planList.userMultiSorting)[0]
            ][i]
          );
        }
      }
      this.storeSortedColumns(list);
    },
    underlyingRequestUrl(id) {
      return "/FulfilmentPlan/Details/" + id;
    },
    getLinkedPlans(id) {
      this.loadingLinkedPlans = true;
      this.linkModel = new LinkedPlanListModel(null);
      fulfilmentPlanListService.getLinkedPlans(id).then((response) => {
        const data = response.data;
        this.linkModel = new LinkedPlanListModel(data);
        this.loadingLinkedPlans = false;
      });
    },
  },
};
</script>

<template>
  <div>
    <el-dialog
      title="Change password"
      :visible.sync="isVisible"
      @closed="resetForm"
      width="25%"
    >
      <el-form :model="model" :rules="rules" ref="form" label-position="top">
        <el-form-item label="Old password" prop="oldPassword">
          <el-input
            type="password"
            autocomplete="off"
            v-model="model.oldPassword"
          ></el-input>
        </el-form-item>
        <el-form-item label="New password" prop="newPassword">
          <el-input
            type="password"
            autocomplete="off"
            v-model="model.newPassword"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="New password confirmation"
          prop="passwordConfirmation"
        >
          <el-input
            type="password"
            autocomplete="off"
            v-model="model.passwordConfirmation"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hide">Cancel</el-button>
        <el-button type="primary" @click="submitForm"
          >Change password</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";

import { showLoading, hideLoading } from "../../store/mutation-types";
import { mapMutations } from "vuex";

import { userService } from "../../services/users.service";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    var confirmPasswordValidator = (rule, value, callback) => {
      if (value !== this.model.newPassword) {
        callback(new Error("Password confirmation doesn't match new password"));
      } else {
        callback();
      }
    };
    return {
      model: {
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "Old password is required" },
          { min: 8, message: "Old password must have at least 8 characters" },
        ],
        newPassword: [
          { required: true, message: "New password is required" },
          { min: 8, message: "New password must have at least 8 characters" },
        ],
        passwordConfirmation: [
          { required: true, message: "Password confirmation is required" },
          {
            min: 8,
            message: "Password confirmation must have at least 8 characters",
          },
          { validator: confirmPasswordValidator },
        ],
      },
    };
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (newValue) {
        this.$emit("update:visible", newValue);
      },
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    hide() {
      this.resetForm();
      this.isVisible = false;
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.showLoading();
          userService
            .changePassword(
              this.model.oldPassword,
              this.model.newPassword,
              this.model.passwordConfirmation
            )
            .then((response) => {
              this.hideLoading();

              if (response.data && response.data.succeeded) {
                this.$message({
                  message: "Password changed.",
                  type: "success",
                });

                this.hide();
              }
              else {
                this.hideLoading();

                this.$message({
                  message: response.response.data.errors[0],
                  type: "error",
                  duration: 0,
                  showClose: true,
                });
              }
            })
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
  },
});
</script>

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row class="auto-height">
      <div class="fleft">
        <h1 class="details-header no-margin">DOCUMENT IMPORT DETAILS</h1>
      </div>
    </el-row>
    <el-row>
      <el-card>
        <div slot="header">
          <h4>DOCUMENT IMPORT INFORMATION</h4>
        </div>
        <el-form size="mini" label-width="140px" v-loading="loading">
          <el-form-item label="Document" class="form-item-padding">
            <div class="readonly-data">
              <router-link
                v-if="details.documentId"
                :to="'/Documentation/Document/' + details.documentId"
                >{{ details.documentNumber }}</router-link
              >
              <p v-else>{{ details.documentNumber }}</p>
            </div>
          </el-form-item>
          <el-form-item label="Revision" class="form-item-padding">
            <div class="readonly-data">{{ details.revisionName }}</div>
          </el-form-item>
          <el-form-item label="Status" class="form-item-padding">
            <div class="readonly-data">{{ details.status }}</div>
          </el-form-item>
          <el-form-item label="Details" class="form-item-padding">
            <div class="readonly-data">
              <span
                v-for="(details, index) in details.details"
                :key="index"
                style="display: block"
                >{{ details }}</span
              >
            </div>
          </el-form-item>
          <el-form-item label="User" class="form-item-padding">
            <div class="readonly-data">{{ getUserInfo(details.userId) }}</div>
          </el-form-item>
          <el-form-item label="Import date" class="form-item-padding">
            <div class="readonly-data">
              {{ details.importDate | formatDateTimeOffset }}
            </div>
          </el-form-item>
        </el-form>
      </el-card>
    </el-row>
    <el-row>
      <el-card>
        <div slot="header">
          <h4>IMPORTED FILES</h4>
        </div>
        <v-client-table
          style="margin-top: 15px"
          ref="table"
          :columns="columns"
          :options="options"
          :data="details.files"
        >
          <div slot="details" slot-scope="props">
            <span
              v-for="(details, index) in props.row.details"
              :key="index"
              style="display: block"
              >{{ details }}</span
            >
          </div>
          <div slot="languages" slot-scope="props">
            <span
              v-for="(language, index) in props.row.languages"
              :key="index"
              style="display: block"
              >{{ language }}</span
            >
          </div>
        </v-client-table>
      </el-card>
    </el-row>
  </div>
</template>
<script>
import Vue from "vue";
import { documentImportService } from "../../../services/edoc/documentImport.service";
import { mapActions, mapState } from "vuex";
import { getUsers } from "../../../store/modules/mutationTypes";

export default Vue.extend({
  created() {
    this.getUsers();
    this.loadData();
  },
  data() {
    return {
      loading: false,
      details: { files: [] },
      columns: ["location", "languages", "status", "details"],
      options: {
        headings: {
          productNumber: "Product Number",
          status: "Status",
          updatedFields: "Updated fields (Old value ➜ New value)",
          details: "Details",
        },
        filterable: false,
        sortable: [],
      },
    };
  },
  computed: {
    ...mapState("users", ["users"]),
  },
  methods: {
    ...mapActions("users", [getUsers]),
    loadData() {
      this.loading = true;

      documentImportService
        .getDocumentImportDetailsList(this.$route.params.id)
        .then((response) => {
          this.details = response.data;
          this.loading = false;
        });
    },
    getUserInfo(userId) {
      const user = this.users.find((u) => u.id === userId);
      if (user == null) {
        return "";
      }

      return `${user.firstName} ${user.lastName} (${user.symbol})`;
    },
  },
  watch: {
    "$route.params.id": function (id) {
      this.$refs.table.refresh();
    },
  },
});
</script>

<template>
  <div v-on-clickaway="closeEditMode">
    <div v-if="editMode" style="display: flex">
      <el-date-picker v-if="withTime"
        ref="editableDatepicker"
        size="small"
        style="width: 100%"
        v-model="date"
        type="datetime"
        :placeholder="placeholder"
        >
      </el-date-picker>
      <el-date-picker v-else
        ref="editableDatepicker"
        size="small"
        style="width: 100%"
        v-model="date"
        type="date"
        :placeholder="placeholder"
      >
      </el-date-picker>
      <i
        class="el-icon-circle-check"
        style="padding: 8px 2px 8px 2px"
        v-on:click="applyChanges"
      ></i>
      <i
        class="el-icon-circle-close"
        style="padding: 8px 2px 8px 2px"
        v-on:click="closeEditMode"
      ></i>
    </div>
    <div v-else>
      <div>
        <div v-if="editable">
          <el-button
            v-if="value"
            type="text"
            v-on:click="editMode = true"
            size="mini"
            >{{ date }}</el-button
          >
          <div v-else>
            <el-button
              type="text"
              v-on:click="editMode = true"
              size="mini"
              style="color: #dd1144"
              >{{ placeholder }}</el-button
            >
          </div>
        </div>
        <div v-else>
          <div v-if="value" type="text">{{ date }}</div>
          <div v-else>{{ placeholder }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  data() {
    return {
      editMode: false,
      date: this.value,
    };
  },
  watch: {
    value: function () {
      this.date = this.value;
    },
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Empty",
    },
    withTime: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    applyChanges() {
      if (this.$refs.editableDatepicker.value) {
        this.$emit(
          "input", 
          !this.withTime
            ? moment(this.$refs.editableDatepicker.value).format("YYYY-MM-DD")
            : moment(this.$refs.editableDatepicker.value).format("YYYY-MM-DD HH:mm:ss")
        );
      } else {
        this.$emit("input", null);
      }
      this.editMode = false;
    },
    closeEditMode: function () {
      this.date = this.value;
      this.editMode = false;
    },
  },
};
</script>

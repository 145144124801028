<template>
  <div>
    <el-form :model="model" :rules="rules" ref="form" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="User name" prop="userName">
            <el-input
              :disabled="model.id != null"
              v-model="model.userName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Symbol" prop="symbol">
            <el-input :maxlength="4" v-model="model.symbol"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="First name" prop="firstName">
            <el-input v-model="model.firstName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Last name" prop="lastName">
            <el-input v-model="model.lastName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Email" prop="email">
            <el-input v-model="model.email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="User Status" prop="isActive">
            <el-switch
              v-model="model.isActive"
              inactive-text="Non-Active"
              inactive-color="#ff4949"
              active-text="Active"
              active-color="#13ce66"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">Save</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import Vue from "vue";

import UserInfoModel from "../../models/UserInfoModel";

export default Vue.extend({
  props: {
    model: {
      type: UserInfoModel,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        userName: [{ required: true, message: "User name is required" }],
        firstName: [
          { required: true, message: "First name is required" },
          { min: 2, message: "First name must be at least 2 characters" },
        ],
        lastName: [
          { required: true, message: "Last Name is required" },
          { min: 2, message: "Last name must be at least 2 characters" },
        ],
        email: [
          { type: "email", message: "Invalid email" },
          { required: true, message: "Email is required" },
        ],
        symbol: [
          { required: true, message: "Symbol is required" },
          { min: 4, max: 4, message: "Symbol must have 4 characters" },
        ],
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("save");
        }
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 0px;
}
</style>

<template>
  <div v-loading.fullscreen.lock="loading">
    <el-container>
      <el-header>
        <el-row>
          <div class="fleft">
            <h1 class="details-header no-margin">PRODUCTS</h1>
          </div>
        </el-row>
        <product-list-filters
          @show-dialog="showDialog"
          @reload-data="reloadData"
        ></product-list-filters>
      </el-header>
      <el-main>
        <v-server-table
          style="margin-top: 60px"
          ref="table"
          :columns="columns"
          :options="options"
        >
          <router-link
            :to="'/Documentation/Product/' + props.row.id + currentFilterQuery"
            slot="description"
            slot-scope="props"
            >{{ props.row.description }}</router-link
          >
          <div slot="describedBy" slot-scope="props">
            <div v-for="{ number, id } in props.row.describedBy" :key="id">
              <router-link
                v-if="eDocPermissions.CanReadDocuments"
                :to="'/Documentation/Document/' + id"
                :key="id"
                style="display: block"
                >{{ number }}</router-link
              >
              <p v-else :key="id" style="display: block">{{ number }}</p>
            </div>
          </div>
          <div slot="actions" slot-scope="props">
            <el-popconfirm
              title="Do you want to remove this product?"
              @confirm="deleteProduct(props.row.id)"
            >
              <el-button
                slot="reference"
                type="primary"
                size="medium"
                class="el-icon-delete"
                :disabled="!eDocPermissions.CanRemoveData"
              ></el-button>
            </el-popconfirm>
          </div>
        </v-server-table>
      </el-main>
    </el-container>
    <add-product-dialog
      :visible.sync="addNewDialogVisible"
      @submit="addNewProduct"
    ></add-product-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import ProductListFilters from "./ProductListFilters.vue";
import AddProductDialog from "./AddProductDialog.vue";
import { Event } from "vue-tables-2";
import qs from "qs";
import _ from "lodash";
import {
  getProductFamilies,
  getDivisions,
} from "../../../store/modules/mutationTypes";
import { productService } from "../../../services/edoc/product.service";
import { ResourcesHelper } from "../../../shared/edoc/ResourcesHelper";
import { Resources } from "../../../shared/edoc/Resources";

export default Vue.extend({
  components: {
    ProductListFilters,
    AddProductDialog,
  },
  created() {
    this.getProductFamilies();
    this.getDivisions();
  },
  mounted() {
    Event.$on("vue-tables.loading", () => {
      this.loading = true;
    });

    Event.$on("vue-tables.loaded", () => {
      this.loading = false;
    });
  },
  data() {
    return {
      availableDocuments: [],
      loading: false,
      columns: [
        "description",
        "catalogNumber",
        "gtin",
        "family",
        "division",
        "describedBy",
        "actions",
      ],
      options: {
        headings: {
          description: "Description",
          catalogNumber: "Catalog Number",
          gtin: "GTIN",
          family: "Family",
          division: "Division",
          describedBy: "Describing Documents",
          actions: "",
        },
        requestFunction: (data) => {
          let query = _.pick(data, [
            "chosenProductFamilies",
            "chosenDocumentNumbers",
            "chosenDivisions",
          ]);

          if (data.orderBy) {
            query.orderBy = data.orderBy;
            query.ascending = data.ascending;
          }

          query = _.omitBy(query, _.isNil);

          if (!_.isEqual(query, this.$route.query)) {
            this.$router.replace({ query });
          }

          return productService.getProducts(data).catch(function (e) {
            this.dispatch("error", e);
          });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
        filterable: false,
        customFilters: [
          "chosenProductFamilies",
          "chosenDocumentNumbers",
          "chosenDivisions",
        ],
        sortable: [
          "description",
          "catalogNumber",
          "gtin",
          "family",
          "division",
          "describedBy",
        ],
        initFilters: {
          chosenProductFamilies:
            (this.$route.query.chosenProductFamilies &&
              this.$route.query.chosenProductFamilies.map(Number)) ||
            [],
          chosenDocumentNumbers:
            (this.$route.query.chosenDocumentNumbers &&
              this.$route.query.chosenDocumentNumbers.map(Number)) ||
            [],
          chosenDivisions:
            (this.$route.query.chosenDivisions &&
              this.$route.query.chosenDivisions.map(Number)) ||
            [],
        },
        orderBy: {
          column: this.$route.query.orderBy || false,
          ascending: this.$route.query.ascending !== "0",
        },
      },
      addNewDialogVisible: false,
    };
  },
  computed: {
    currentFilterQuery: function () {
      var result = qs.stringify(this.$route.query);

      return result ? "?" + result : "";
    },
    ...mapState("permissions", ["eDocPermissions"]),
  },
  methods: {
    ...mapActions("product", [getProductFamilies]),
    ...mapActions("document", [getDivisions]),
    addNewProduct: function (item) {
      this.addNewDialogVisible = false;
      productService.addProduct(item).then(
        (response) => {
          this.$router.push({
            path: `/Documentation/Product/${response.data}`,
          });
          this.$message({
            type: "success",
            message: Resources.AddProductSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.AddProductError, this.showDialog);
        }
      );
    },
    deleteProduct(productId) {
      productService.deleteProduct(productId).then(
        (response) => {
          this.$refs.table.refresh();
          this.$message({
            type: "success",
            message: Resources.DeleteProductSuccess,
          });
        },
        (error) => {
          this.onError(error, Resources.DeleteProductError);
        }
      );
    },
    onError(
      error,
      defaultError,
      callback = () => {
        // do nothing.
      }
    ) {
      const alertTitle =
        error.response.status === 409 ? "Validation Error" : "Error";
      const alertContent =
        error.response.status === 409
          ? ResourcesHelper.getValidationMessage(error.response.data.errors)
          : defaultError;

      this.$alert(alertContent, alertTitle, {
        confirmButtonText: "OK",
        type: "error",
        callback: (action) => {
          if (callback) {
            callback();
          }
        },
      });
    },
    showDialog: function () {
      this.addNewDialogVisible = true;
    },
    reloadData() {
      if (this.$route.query.handleFilter) {
        const customQueries = {
          chosenProductFamilies:
            (this.$route.query.chosenProductFamilies &&
              this.$route.query.chosenProductFamilies.map(Number)) ||
            [],
          chosenDocumentNumbers:
            (this.$route.query.chosenDocumentNumbers &&
              this.$route.query.chosenDocumentNumbers.map(Number)) ||
            [],
          chosenDivisions:
            (this.$route.query.chosenDivisions &&
              this.$route.query.chosenDivisions.map(Number)) ||
            [],
        };
        this.$refs.table.customQueries = customQueries;

        if (this.$route.query.orderBy) {
          this.$refs.table.orderBy.column = this.$route.query.orderBy;
          this.$refs.table.orderBy.ascending =
            this.$route.query.ascending !== "0";
        } else {
          this.$refs.table.orderBy.column = false;
          this.$refs.table.orderBy.ascending = true;
        }
      }

      this.$refs.table.refresh();
    },
  },
});
</script>

import ChangesModel from "./ChangesModel";

export default class ChangesListModel {
  id: number;
  changes: ChangesModel[] = [];

  constructor(data) {
    if (data !== null && data.model.changes.length > 0) {
      this.id = data.model.id;
      for (const item of data.model.changes) {
        const newChanges = new ChangesModel(item);
        this.changes.push(newChanges);
      }
    }
  }
}

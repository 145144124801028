import ChangeDataResponseModel from "@/models/ChangeDataResponseModel";
import { HTTP } from "./HttpCommon";

class ManufacturingCycleService {
  getGlobalNotificationsForRequestId(id) {
    return HTTP.get(
      `ManufacturingCycle/GetGlobalNotificationsForRequestId/` + id
    );
  }
  acknowledgeGlobalNotification(id) {
    return HTTP.post(`ManufacturingCycle/AcknowledgeGlobalNotification/${id}`);
  }
  getDetails(id, useContext = false) {
    return HTTP.get(
      `ManufacturingCycle/GetDetails/${id}?useContext=${useContext}`
    );
  }
  startCurrentStep(
    manufacturingCycleId: number,
    manufacturingJobId: number,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/StartCurrentStep", {
      manufacturingCycleId: manufacturingCycleId,
      manufacturingJobId: manufacturingJobId,
      timestampBase64: timestamp,
    });
  }
  startCurrentStepBulkOperation(
    manufacturingCycleId: number,
    manufacturingJobId: number,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/StartCurrentStepBulkOperation", {
      manufacturingCycleId: manufacturingCycleId,
      manufacturingJobId: manufacturingJobId,
      timestampBase64: timestamp,
    });
  }
  markCurrentStepAsCompleted(
    manufacturingCycleId: number,
    manufacturingJobId: number,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/MarkCurrentStepAsCompleted", {
      manufacturingCycleId: manufacturingCycleId,
      manufacturingJobId: manufacturingJobId,
      timestampBase64: timestamp,
    });
  }
  markCurrentStepAsCompletedBulkOperation(
    manufacturingCycleId: number,
    manufacturingJobId: number,
    timestamp: string
  ) {
    return HTTP.post<ChangeDataResponseModel>(
      "ManufacturingCycle/MarkCurrentStepAsCompletedBulkOperation",
      {
        manufacturingCycleId: manufacturingCycleId,
        manufacturingJobId: manufacturingJobId,
        timestampBase64: timestamp,
      }
    );
  }
  undoCurrentStep(
    manufacturingCycleId: number,
    manufacturingJobId: number,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/UndoCurrentStep", {
      manufacturingCycleId: manufacturingCycleId,
      manufacturingJobId: manufacturingJobId,
      timestampBase64: timestamp,
    });
  }
  restartManufacturingJob(
    manufacturingCycleId: number,
    manufacturingJobId: number,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/RestartManufacturingJob", {
      manufacturingCycleId: manufacturingCycleId,
      manufacturingJobId: manufacturingJobId,
      timestampBase64: timestamp,
    });
  }
  startCurrentReworkStep(
    manufacturingCycleId: number,
    manufacturingJobReworkId: number,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/StartReworkStep", {
      manufacturingCycleId: manufacturingCycleId,
      manufacturingJobReworkId: manufacturingJobReworkId,
      timestampBase64: timestamp,
    });
  }
  markCurrentReworkStepAsCompleted(
    manufacturingCycleId: number,
    manufacturingJobReworkId: number,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/MarkCurrentReworkStepAsCompleted", {
      manufacturingCycleId: manufacturingCycleId,
      manufacturingJobReworkId: manufacturingJobReworkId,
      timestampBase64: timestamp,
    });
  }
  skipAllSteps(manufacturingCycleId: number, timestamp: string) {
    return HTTP.post("ManufacturingCycle/SkipAllSteps", {
      manufacturingCycleId: manufacturingCycleId,
      timestampBase64: timestamp,
    });
  }
  addRework(
    manufacturingCycleId: number,
    manufacturingJobId: number,
    jobStepId: number,
    name: string,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/AddRework", {
      manufacturingCycleId: manufacturingCycleId,
      manufacturingJobId: manufacturingJobId,
      startJobStepId: jobStepId,
      name: name,
      timestampBase64: timestamp,
    });
  }
  addComment(manufacturingCycleId: number, text: string) {
    return HTTP.post("ManufacturingCycle/AddComment", {
      manufacturingCycleId: manufacturingCycleId,
      commentText: text,
    });
  }
  editComment(manufacturingCycleId: number, commentId: number, text: string) {
    return HTTP.post("ManufacturingCycle/EditComment", {
      manufacturingCycleId: manufacturingCycleId,
      commentId: commentId,
      commentText: text,
    });
  }
  deleteComment(manufacturingCycleId: number, commentId: number) {
    return HTTP.post("ManufacturingCycle/DeleteComment", {
      manufacturingCycleId: manufacturingCycleId,
      commentId: commentId,
    });
  }
  editOrderNumber(
    manufacturingCycleId: number,
    orderNumber: string,
    timestamp: string
  ) {
    return HTTP.post("ManufacturingCycle/EditOrderNumber", {
      manufacturingCycleId: manufacturingCycleId,
      newValue: orderNumber,
      timestampBase64: timestamp,
    });
  }
  getTimestamp(id: number) {
    return HTTP.post(`ManufacturingCycle/GetTimestamp/${id}`);
  }
  rescheduleManufacturingMilestones(id) {
    return HTTP.post(
      `ManufacturingCycle/RescheduleManufacturingMilestones/${id}`
    );
  }
}
export const manufacturingCycleService = new ManufacturingCycleService();

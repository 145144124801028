<template>
  <div>
    <v-client-table
      v-model="currentPermissions"
      :columns="columns"
      :options="options"
    >
      <el-checkbox
        slot="granted"
        slot-scope="{ row, update }"
        v-model="row.granted"
        @change="update"
        :disabled="isGrantedDisabled(row)"
      ></el-checkbox>
    </v-client-table>
    <el-button type="primary" @click="$emit('save')">Save</el-button>
  </div>
</template>

<script>
import Vue from "vue";

const CAN_READ_PRODUCTS = "CANREADPRODUCTS";
const CAN_READ_DOCUMENTS = "CANREADDOCUMENTS";
const CAN_MANAGE_PRODUCTS = "CANMANAGEPRODUCTS";
const CAN_MANAGE_DOCUMENTS = "CANMANAGEDOCUMENTS";

export default Vue.extend({
  props: {
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: ["displayName", "granted"],
      options: {
        editableColumns: ["granted"],
        sortable: ["displayName"],
        orderBy: {
          column: "displayName",
          ascending: true,
        },
        headings: {
          displayName: "name",
        },
        uniqueKey: "name",
      },
    };
  },
  computed: {
    currentPermissions: {
      get: function () {
        return this.permissions;
      },
      set: function (newValue) {
        const productsReadIsGranted = newValue.find(
          (item) => item.name.toUpperCase() === CAN_READ_PRODUCTS
        ).granted;
        const documentsReadReadIsGranted = newValue.find(
          (item) => item.name.toUpperCase() === CAN_READ_DOCUMENTS
        ).granted;

        if (!productsReadIsGranted) {
          newValue.find(
            (item) => item.name.toUpperCase() === CAN_MANAGE_PRODUCTS
          ).granted = false;
        }

        if (!documentsReadReadIsGranted) {
          newValue.find(
            (item) => item.name.toUpperCase() === CAN_MANAGE_DOCUMENTS
          ).granted = false;
        }

        this.$emit("update:permissions", newValue);
      },
    },
  },
  methods: {
    isGrantedDisabled(row) {
      const productsReadIsGranted = this.permissions.find(
        (item) => item.name.toUpperCase() === CAN_READ_PRODUCTS
      ).granted;
      const documentsReadReadIsGranted = this.permissions.find(
        (item) => item.name.toUpperCase() === CAN_READ_DOCUMENTS
      ).granted;

      if (
        (row.name.toUpperCase() === CAN_MANAGE_PRODUCTS &&
          !productsReadIsGranted) ||
        (row.name.toUpperCase() === CAN_MANAGE_DOCUMENTS &&
          !documentsReadReadIsGranted)
      ) {
        return true;
      }
      return false;
    },
  },
});
</script>

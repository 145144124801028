<template>
  <div></div>
</template>

<script>
import { mapMutations } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import _ from "lodash";
import { fulfilmentPlanListService } from "../../services/fulfilmentplanlist.service";

export default {
  async mounted() {
    const id = this.$route.params.id;
    this.showLoading();
    if (id) {
      await fulfilmentPlanListService.executeFilter(id);
      this.$router.push({ path: `/FulfilmentPlan/List?storedQueryId=${id}` });
    } else {
      this.$router.push({ path: `/FulfilmentPlan/List` });
    }
    this.hideLoading();
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
  },
};
</script>


















































































import Vue from "vue";
import { mapMutations } from "vuex";
import axios from "axios";
import _ from "lodash";

import qs from "qs";

import { combinedOrderService } from "../../services/combinedorder.service";
import { HTTP } from "@/services/HttpCommon";
import CombinedOrderInformation from "./CombinedOrderInformation.vue";
import CombinedOrderRequestIds from "./CombinedOrderRequestIds.vue";
import CombinedOrderManufacturingJob from "./CombinedOrderManufacturingJob.vue";
import CommentList from "@/components/shared/CommentList.vue";

import ManufacturingJobModel from "../../models/ManufacturingJobModel";
import CombinedManufacturingJobModel from "../../models/CombinedManufacturingJobModel";
import CombinedOrderInformationModel from "../../models/CombinedOrderInformationModel";

import CommentModel from "@/models/CommentModel";
import CommentFormModel from "@/models/CommentFormModel";

import { showLoading, hideLoading } from "../../store/mutation-types";
import { OperationResultType } from "../../enums/enums";
import ChangeDataResponseModel from "../../models/ChangeDataResponseModel";

export default Vue.extend({
  components: {
    CommentList,
    CombinedOrderRequestIds,
    CombinedOrderInformation,
    CombinedOrderManufacturingJob,
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
  data() {
    return {
      comments: [],
      commentDialogVisible: false,
      manufacturingJob: new ManufacturingJobModel(),
      combinedManufacturingJobs: [],
      information: new CombinedOrderInformationModel(),
      allCombinedOrders: null,
    };
  },
  computed: {
    queryString: function () {
      var result = qs.stringify(this.$route.query);

      return result ? "?" + result : "";
    },
    canAddPlans: function () {
      return (
        this.manufacturingJob.currentSequenceNumber <= 1 &&
        (this.manufacturingJob.canStart ||
          (!this.manufacturingJob.canStart && !this.manufacturingJob.canFinish))
      );
    },
    previousCombinedOrder() {
      let currentItem = this.allCombinedOrders.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return (
        this.allCombinedOrders[
          this.allCombinedOrders.indexOf(currentItem) - 1
        ] || null
      );
    },
    previousCombinedOrderUrl() {
      return this.previousCombinedOrder !== null
        ? "/CombinedOrders/" + this.previousCombinedOrder.id + this.queryString
        : "";
    },
    previousCombinedOrderText() {
      return this.previousCombinedOrder !== null
        ? "Previous Combined Order: " + this.previousCombinedOrder.identifier
        : "Not available";
    },
    nextCombinedOrder() {
      let currentItem = this.allCombinedOrders.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return (
        this.allCombinedOrders[
          this.allCombinedOrders.indexOf(currentItem) + 1
        ] || null
      );
    },
    nextCombinedOrderUrl() {
      return this.nextCombinedOrder !== null
        ? "/CombinedOrders/" + this.nextCombinedOrder.id + this.queryString
        : "";
    },
    nextCombinedOrderText() {
      return this.nextCombinedOrder !== null
        ? "Next Combined Order: " + this.nextCombinedOrder.buildId
        : "Not available";
    },
    currentItemIndex() {
      let currentItem = this.allCombinedOrders.find(
        (item) => item.id === parseInt(this.$route.params.id)
      );

      return this.allCombinedOrders.indexOf(currentItem);
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapMutations("combinedOrder", ["storeCombinedOrderId"]),
    loadData() {
      this.showLoading();
      this.storeCombinedOrderId(this.$route.params.id);
      axios
        .all([this.getWorkOrder(), this.getCombinedManufacturingJobs()])
        .then(() => {
          this.hideLoading();
        });

      HTTP.post(
        "CombinedOrder/GetCombinedOrders",
        qs.parse(this.$route.query)
      ).then((data) => {
        this.allCombinedOrders = data.data.data;
      });
    },
    onRequestIdChanged(id) {
      this.showLoading();

      Promise.all([
        this.getCombinedManufacturingJobs(),
        this.getWorkOrder(),
      ]).then(() => {
        this.hideLoading();
      });
    },
    reloadWorkOrder() {
      this.showLoading();
      this.getWorkOrder().then(() => {
        this.hideLoading();
      });
    },
    getWorkOrder() {
      var promise = combinedOrderService.getWorkOrder(
        this.$store.state.combinedOrder.combinedOrderId
      );
      promise.then((data) => {
        this.manufacturingJob = new ManufacturingJobModel(
          data.data.manufacturingJob
        );
        this.information = new CombinedOrderInformationModel(data.data);
      });

      return promise;
    },
    getCombinedManufacturingJobs() {
      var promise = combinedOrderService.getCombinedManufacturingJobs(
        this.$store.state.combinedOrder.combinedOrderId
      );
      promise.then((data) => {
        this.combinedManufacturingJobs = data.data.map((item) => {
          return new CombinedManufacturingJobModel(item);
        });
      });

      return promise;
    },
    addComment(comment: CommentFormModel) {
      this.commentDialogVisible = false;
      combinedOrderService
        .addComment(
          this.$store.state.combinedOrder.combinedOrderId,
          comment.text
        )
        .then((response) => {
          this.reloadWorkOrder();
          this.showCommentResponseMessage(response.data);
        });
    },
    editComment(comment: CommentModel) {
      this.commentDialogVisible = false;
      combinedOrderService.editComment(comment).then((response) => {
        this.reloadWorkOrder();
        this.showCommentResponseMessage(response.data);
      });
    },
    deleteComment(comment: CommentModel) {
      combinedOrderService.deleteComment(comment).then((response) => {
        this.reloadWorkOrder();
        this.showCommentResponseMessage(response.data);
      });
    },
    showCommentResponseMessage(data: ChangeDataResponseModel) {
      this.$message({
        message: data.message,
        type: data.result == OperationResultType.Success ? "success" : "error",
        duration: 4000,
      });
    },
  },
  watch: {
    "$route.params.id": function (id) {
      this.loadData();
    },
  },
});

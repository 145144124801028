export default class ManufacturingCycleBulkOperationsAffectedCasesModel {
  filteredCases: Array<BigInt> = [];
  selectedCaseId: BigInt;

  constructor(data = null) {
    if (data !== null) {
      this.filteredCases = data.filteredCases;
      this.selectedCaseId = data.selectedCaseId;
    }
  }
}

import axios from "axios";
export default {};

const axiosConfig = axios.create({
  baseURL: "/api",
});

axiosConfig.defaults.headers.patch["Content-Type"] = "application/json";

export const HTTP = axiosConfig;

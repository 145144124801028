import moment from "moment";
import "moment-timezone";
export default class DesignSession {
  id: number = null;
  requestId: string = null;
  designerId: string = null;
  distributionCenterId: number = null;
  productTypeId: number = null;
  productId: number = null;
  emailAddresses: Array<string> = [];
  patientName: string = null;
  startDate: moment.Moment = moment.utc();
  preparationTime: moment.Duration = moment.duration();
  sessionTime: moment.Duration = moment.duration();
  postProcessingTime: moment.Duration = moment.duration();

  constructor(model: any = null) {
    if (model != null) {
      this.id = model.id;
      this.designerId = model.designerId;
      this.requestId = model.requestId;
      this.startDate = moment(model.startDate);
      this.preparationTime = moment.duration(model.preparationTime);
      this.sessionTime = moment.duration(model.sessionTime);
      this.postProcessingTime = moment.duration(model.postProcessingTime);
    }
  }
}

export default class DocumentFileModel {
  id: number;
  eifuName: string;
  location: string;
  languages: number[];

  region: any;

  constructor(data: any = null) {
    if (data !== null) {
      this.id = data.id;
      this.eifuName = data.eifuName;
      this.languages = data.languages;
      this.location = data.location;
      this.region = data.region;
    }
  }
}

<style lang="scss" scoped>
.el-form--label-top::v-deep .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.icon-sharing-level {
  float: right;
  color: #8492a6;
  font-size: 13px;
}

.el-button--primary:focus {
  color: #fff;
  background-color: #4682af;
  border-color: #5591be;
}
</style>

<template>
  <div>
    <el-dialog
      title="Apply filters"
      :visible.sync="filtersModalVisible"
      :before-close="handleCloseFiltersModal"
      width="30%"
    >
      <span>
        <el-select
          v-model="selectedFilter"
          placeholder="Select filter..."
          style="width: 100%"
        >
          <el-option
            v-for="item in savedFilters"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
            <span
              v-if="item.sharingLevel === SharingLevel.Private"
              class="icon-sharing-level glyphicon glyphicon-user center-icon"
            ></span>
            <span
              v-if="item.sharingLevel === SharingLevel.Public"
              class="icon-sharing-level glyphicon glyphicon-globe center-icon"
            ></span>
          </el-option>
        </el-select>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCloseFiltersModal"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          :disabled="selectedFilter === null"
          @click="applyFilters"
          >Apply</el-button
        >
      </span>
    </el-dialog>
    <div class="row" style="margin: 12px 0 0 0">
      <div v-if="!disabled" class="form-group fright">
        <div id="ExportButton" class="fleft mar-r-10">
          <el-button
            type="primary"
            size="small"
            @click="exportFulfilmentPlans"
            v-if="this.userPermissions.CanExportFulfilmentPlans"
            >Export</el-button
          >
        </div>
        <div id="AddNewPlanButton" class="fleft mar-r-10">
          <el-button
            type="primary"
            size="small"
            v-if="this.userPermissions.CanManageFulfilmentPlan"
            @click="showNewFulfilmentPlanPopup"
            >+ Add New Plan</el-button
          >
        </div>
        <div class="fleft mar-r-10">
          <el-button type="primary" size="small" @click="openFiltersModal"
            >Apply filters</el-button
          >
        </div>
        <div id="ClearFiltersButton" class="fleft mar-r-10">
          <el-button type="primary" size="small" @click="$emit('clear')"
            >Clear filters</el-button
          >
        </div>
        <div class="fleft mar-r-10">
          <el-popover
            placement="bottom"
            title="Save filters"
            width="200"
            trigger="click"
          >
            <el-input placeholder="Filter name" v-model="filterName"></el-input>
            <el-button
              type="primary"
              @click="saveUserFilters"
              size="small"
              style="margin-top: 5px"
            >
              <font-awesome-icon icon="save" />
              Save
            </el-button>
            <el-button slot="reference" type="primary" size="small">
              <font-awesome-icon icon="save" style="margin-right: 3px" />Save
              filters
            </el-button>
          </el-popover>
        </div>
      </div>
      <label>Additional filters:</label>
      <el-popover
        :disabled="disabled"
        v-for="filter in selectedAdditionalFilters"
        :key="filter.value"
        v-model="filter.visible"
        placement="bottom"
        width="400"
        trigger="click"
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ filter.name }}</span>
            <el-button
              :disabled="disabled"
              style="float: right; padding: 3px 0"
              type="text"
              @click="clearFilter(filter)"
              >Clear filter</el-button
            >
          </div>
          <div v-if="filter.filterType == 0">To implement 0</div>
          <div v-if="filter.filterType == 1">
            <el-input-number
              v-model="filter.selectedValue"
              style="width: 100%"
              :min="0"
              @change="onFilterChange(filter)">
            </el-input-number>
          </div>
          <div v-if="filter.filterType == 2">To implement 2</div>
          <div v-if="filter.filterType == 3">To implement 3</div>
          <div v-if="filter.filterType == 4">
            <el-select
              v-model="filter.selectedValues"
              multiple
              style="width: 100%"
              placeholder="Select"
              @change="onFilterChange(filter)"
            >
              <el-option
                v-for="item in filter.selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </el-card>
        <el-button slot="reference" size="small">
          {{ filter.name }}: {{ title(filter) }}
          <i class="el-icon-caret-bottom"></i>
          <i
            v-if="!disabled"
            class="el-icon-error"
            @click="onFilterChange(filter, false)"
          ></i>
        </el-button>
      </el-popover>
      <el-popover
        ref="date-popover"
        placement="bottom"
        v-model="filtersPopoverVisible"
        trigger="click"
      >
        <div v-for="filter in additionalFilters.filters" :key="filter.name">
          <el-checkbox
            v-model="filter.checked"
            @change="selectAdditionalFilter(filter)"
            >{{ filter.name }}</el-checkbox
          >
        </div>
      </el-popover>
      <el-button
        v-if="!disabled"
        size="small"
        v-popover:date-popover
        icon="el-icon-circle-plus"
        >More</el-button
      >
    </div>
    <add-fulfilment-plan-dialog
      v-if="!disabled"
      :visible.sync="addNewDialogVisible"
    ></add-fulfilment-plan-dialog>
  </div>
</template>
<script>
import AddFulfilmentPlanDialog from "./AddFulfilmentPlanDialog.vue";
import { SharingLevel } from "../../enums/enums";
import _ from "lodash";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import { HTTP } from "../../services/HttpCommon";

import {
  getAssignedEDocPermissions,
  getAssignedPermissions,
  getAssignedDesignSessionPermissions,
} from "../../store/modules/mutationTypes";

export default {
  components: {
    AddFulfilmentPlanDialog,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterName: "",
      filtersPopoverVisible: false,
      inputVisible: false,
      addNewDialogVisible: false,
      filtersModalVisible: false,
      initialized: false,
      SharingLevel: SharingLevel,
      savedFilters: [],
      selectedFilter: "",
    };
  },
  computed: {
    ...mapGetters("fulfilmentPlanList", ["selectedAdditionalFilters"]),
    ...mapState("fulfilmentPlanList", ["filters", "additionalFilters"]),
    storedQueryActiveDisplayName() {
      return this.filters.storedQueryActiveDisplayName;
    },
    ...mapState("permissions", [
      "userPermissions",
      "permissionsLoaded",
    ]),
  },
  watch: {
    "filters.storedQueryActiveDisplayName": function (val) {
      this.filterName = val;
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapActions("fulfilmentPlanList", [
      "selectAdditionalFilter",
      "unselectAdditionalFilter",
      "refresh",
    ]),
    title(item) {
      if (
        item.selectedValues.length == 0 ||
        item.selectedValues.length == item.selectOptions.length
      ) {
        return "All";
      } else {
        var selectedLabels = [];
        for (let i = 0; i < item.selectedValues.length; i++) {
          selectedLabels.push(
            item.selectOptions.find((x) => x.value == item.selectedValues[i])
              .label
          );
        }
        return selectedLabels.toString();
      }
    },
    showFiltersDropdown() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.filterSelect.focus();
      });
    },
    onFilterChange(filter, state) {
      const checked = state != null ? state : filter.checked;

      if (checked) {
        this.selectAdditionalFilter(filter);
      } else {
        this.unselectAdditionalFilter(filter);
      }

      this.refresh();
    },
    openFiltersModal() {
      this.selectedFilter = null;
      this.filtersModalVisible = true;
      HTTP.get("/FulfilmentPlan/GetFiltersList").then((response) => {
        this.savedFilters = response.data.content;
      });
    },
    applyFilters() {
      if (this.selectedFilter) {
        this.handleCloseFiltersModal();
        this.$router.push(`/FulfilmentPlan/LoadFilter/${this.selectedFilter}`);
      }
    },
    handleCloseFiltersModal() {
      this.filtersModalVisible = false;
    },
    showNewFulfilmentPlanPopup() {
      this.addNewDialogVisible = true;
    },
    exportFulfilmentPlans() {
      this.$emit("export");
    },
    saveUserFilters() {
      if (this.filterName === this.storedQueryActiveDisplayName) {
        this.$confirm(
          "Do you want to overwrite filter: " +
            this.storedQueryActiveDisplayName +
            "? If no click cancel and change preset name",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.saveFiltersAsPreset();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Update canceled",
            });
          });
      } else {
        this.saveFiltersAsPreset();
      }
    },
    saveFiltersAsPreset() {
      this.$emit("save", this.filterName);
    },
  },
};
</script>

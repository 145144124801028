<template>
  <el-dialog
    width="25%"
    title="Edit filter"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form
      :model="editedItem"
      :rules="rules"
      ref="filterForm"
      label-width="120px"
    >
      <el-form-item label="Name" prop="name">
        <el-input v-model="editedItem.name"></el-input>
      </el-form-item>
      <el-form-item
        v-if="showSharingLevel"
        label="Accessibility"
        prop="sharingLevel"
      >
        <el-select v-model="editedItem.sharingLevel">
          <el-option :value="0" label="Private"></el-option>
          <el-option :value="1" label="Public"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button type="primary" @click="submitForm">Save</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import _ from "lodash";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showSharingLevel: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          id: null,
          name: null,
        };
      },
    },
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: "Name is required" }],
      },
      editedItem: {
        type: Object,
        default: {
          id: null,
          name: null,
        },
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.filterForm.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.editedItem);
        }
        return false;
      });
    },
    closeForm() {
      this.clearForm();
      this.$emit("update:visible", false);
    },
    clearForm() {
      this.$refs.filterForm.resetFields();
      this.editedItem = {
        id: null,
        name: null,
      };
    },
  },
  watch: {
    visible: function (isVisible) {
      if (isVisible) {
        this.editedItem = _.cloneDeep(this.item);
      }
    },
  },
});
</script>

import { HTTP } from "./HttpCommon";
import ManufacturingCycleDefinitionModel from "../models/ManufacturingCycleDefinitionModel";

class ManufacturingCycleDefinitionService {
  basePath = "ManufacturingCycleDefinition/";

  getManufacturingCycleDefinition(manufacturingCycleDefinitionId) {
    return HTTP.get(
      `${this.basePath}GetManufacturingCycleDefinition/${manufacturingCycleDefinitionId}`
    );
  }

  getRecords() {
    return HTTP.get(`${this.basePath}GetRecords`);
  }

  getAllWorkflows() {
    return HTTP.get(`${this.basePath}GetAllWorkflows`);
  }

  save(model: ManufacturingCycleDefinitionModel) {
    return HTTP.put(`${this.basePath}Save`, model);
  }
}

export const manufacturingCycleDefinitionService =
  new ManufacturingCycleDefinitionService();

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',[_vm._v("ASSOCIATED DOCUMENTS")]),_c('el-button',{staticClass:"fright",attrs:{"type":"primary","size":"medium","disabled":!_vm.eDocPermissions.CanManageProducts},on:{"click":_vm.showAddAssociationDialog}},[_vm._v("Associate New Document")])],1),_c('el-table',{attrs:{"data":_vm.associatedDocuments.associations,"stripe":""}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-table',{attrs:{"data":props.row.versionAssociations}},[_c('el-table-column',{attrs:{"prop":"documentVersionName","label":"DOCUMENT VERSION NAME"}}),_c('el-table-column',{attrs:{"prop":"productVersionName","label":"PRODUCT VERSION NAME"}}),_c('el-table-column',{attrs:{"align":"right","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"el-icon-edit",attrs:{"type":"primary","size":"medium","disabled":!_vm.eDocPermissions.CanManageProducts},on:{"click":function($event){return _vm.showEditVersionsAssociationDialog(
                    scope.row,
                    props.row.documentId,
                    props.row.id
                  )}}}),_c('el-popconfirm',{attrs:{"title":"Do you want to remove this versions association?"},on:{"confirm":function($event){return _vm.deleteVersionsAssociation(scope.row.id)}}},[_c('el-button',{staticClass:"el-icon-delete",attrs:{"slot":"reference","type":"primary","size":"medium","disabled":!_vm.eDocPermissions.CanRemoveData},slot:"reference"})],1)]}}],null,true)})],1)]}}])}),_c('el-table-column',{attrs:{"label":"Document Number","prop":"number","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.eDocPermissions.CanReadDocuments)?_c('router-link',{key:scope.row.documentId,staticStyle:{"display":"block"},attrs:{"to":'/Documentation/Document/' + scope.row.documentId}},[_vm._v(_vm._s(scope.row.number))]):_c('p',{key:scope.row.documentId,staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(scope.row.number)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"el-icon-edit",attrs:{"type":"primary","size":"medium","disabled":scope.row.versionAssociations.length > 0 ||
            !_vm.eDocPermissions.CanManageProducts},on:{"click":function($event){return _vm.showEditAssociationDialog(scope.row)}}}),_c('el-popconfirm',{attrs:{"title":"Do you want to remove this association?"},on:{"confirm":function($event){return _vm.deleteAssociation(scope.row.id)}}},[_c('el-button',{staticClass:"el-icon-delete",attrs:{"slot":"reference","type":"primary","size":"medium","disabled":!_vm.eDocPermissions.CanRemoveData},slot:"reference"})],1),_c('el-button',{attrs:{"type":"primary","size":"medium","disabled":_vm.productVersions.productVersions.length == 0 ||
            !_vm.eDocPermissions.CanManageProducts},on:{"click":function($event){return _vm.showAddVersionsAssociationDialog(
              scope.row.documentId,
              scope.row.id
            )}}},[_vm._v("Associate Document Version")])]}}])})],1),_c('add-association-dialog',{ref:"addAssociationDialog",attrs:{"visible":_vm.addAssociationDialogVisible},on:{"update:visible":function($event){_vm.addAssociationDialogVisible=$event},"submit":_vm.addAssociation}}),_c('edit-association-dialog',{attrs:{"visible":_vm.editAssociationDialogVisible,"item":_vm.editedAssociation},on:{"update:visible":function($event){_vm.editAssociationDialogVisible=$event},"submit":_vm.editAssociation}}),_c('add-versions-association-dialog',{ref:"addVersionsAssociationDialog",attrs:{"visible":_vm.addVersionsAssociationDialogVisible,"product-versions":_vm.productVersions,"association-id":_vm.addedAssociationId,"document-id":_vm.addedVersionsAssociationDocumentId},on:{"update:visible":function($event){_vm.addVersionsAssociationDialogVisible=$event},"submit":_vm.addVersionsAssociation}}),_c('edit-versions-association-dialog',{attrs:{"visible":_vm.editVersionsAssociationDialogVisible,"product-versions":_vm.productVersions,"document-id":_vm.editedVersionsAssociationDocumentId,"association-id":_vm.editedVersionsAssociationId,"item":_vm.editedVersionsAssociation},on:{"update:visible":function($event){_vm.editVersionsAssociationDialogVisible=$event},"submit":_vm.editVersionsAssociation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
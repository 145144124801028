import { HTTP } from "./HttpCommon";

class PrototypeManufacturingCycleService {
    getDetails(id, useContext = false) {
        return HTTP.get(`PrototypeManufacturingCycle/GetDetails/${id}?useContext=${useContext}`);
    }
    updateWorkProcedure(workProcedure) {
        return HTTP.put("PrototypeManufacturingCycle/UpdateWorkProcedure", workProcedure);
    }
    deleteWorkProcedure(id) {
        return HTTP.delete("PrototypeManufacturingCycle/DeleteWorkProcedure/" + id);
    }
    createWorkflow(id) {
        return HTTP.post(`PrototypeManufacturingCycle/CreateWorkProcedure/${id}`);
    }
    startCurrentStep(prototypeManufacturingCycleId: number, prototypeManufacturingJobId: number, timestamp: string) {
        return HTTP.post("PrototypeManufacturingCycle/StartCurrentStep", {
            prototypeManufacturingCycleId: prototypeManufacturingCycleId,
            prototypeManufacturingJobId: prototypeManufacturingJobId,
            timestampBase64: timestamp
        });
    }
    markCurrentStepAsCompleted(prototypeManufacturingCycleId: number, prototypeManufacturingJobId: number, timestamp: string) {
        return HTTP.post("PrototypeManufacturingCycle/MarkCurrentStepAsCompleted", {
            prototypeManufacturingCycleId: prototypeManufacturingCycleId,
            prototypeManufacturingJobId: prototypeManufacturingJobId,
            timestampBase64: timestamp
        });
    }
    undoCurrentStep(prototypeManufacturingCycleId: number, prototypeManufacturingJobId: number, timestamp: string) {
        return HTTP.post("PrototypeManufacturingCycle/UndoCurrentStep", {
            prototypeManufacturingCycleId: prototypeManufacturingCycleId,
            prototypeManufacturingJobId: prototypeManufacturingJobId,
            timestampBase64: timestamp
        });
    }
    restartManufacturingJob(prototypeManufacturingCycleId: number, prototypeManufacturingJobId: number, timestamp: string) {
        return HTTP.post("PrototypeManufacturingCycle/RestartManufacturingJob", {
            prototypeManufacturingCycleId: prototypeManufacturingCycleId,
            prototypeManufacturingJobId: prototypeManufacturingJobId,
            timestampBase64: timestamp
        });
    }
    skipAllSteps(prototypeManufacturingCycleId: number, timestamp: string) {
        return HTTP.post("PrototypeManufacturingCycle/SkipAllSteps", {
            prototypeManufacturingCycleId: prototypeManufacturingCycleId,
            timestampBase64: timestamp
        });
    }
}
export const prototypeManufacturingCycleService = new PrototypeManufacturingCycleService();
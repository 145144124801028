import { HTTP } from "./HttpCommon";

class ProductTypeMappingService {
  getProductTypeMappingByRequestId(requestId: string) {
    return HTTP.get(
      "ProductTypeMapping/GetProductTypeMappingByRequestId?requestId=" +
        requestId
    );
  }

  loadProductTypeMappings() {
    return HTTP.get("ProductTypeMapping/GetRecords");
  }

  addProductTypeMapping(record) {
    return HTTP.post("ProductTypeMapping/Add", record);
  }

  editProductTypeMapping(record) {
    return HTTP.put("ProductTypeMapping/Edit/" + record.id, record);
  }

  removeProductTypeMapping(record) {
    return HTTP.delete("ProductTypeMapping/Remove/" + record.id);
  }
}

export const productTypeMappingService = new ProductTypeMappingService();

const appUrl: string = process.env.VUE_APP_APP_URL;
const oidcAuthority: string = process.env.VUE_APP_OIDC_AUTHORITY;

export const oidcSettings = {
  authority: oidcAuthority,
  clientId: "js",
  redirectUri: `${appUrl}/CRT/oidc-callback`,
  post_logout_redirect_uri: `${appUrl}/`,
  responseType: "code",
  scope: "openid profile user documentation design",
  silentRedirectUri: `${appUrl}/silent-renew-oidc.html`,
  automaticSilentRenew: true,
  automaticSilentSignin: true,
};

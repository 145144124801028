<template>
  <div>
    <el-row type="flex" class="mar-t-20">
      <el-col
        :span="1"
        class="
          ui-sortable-handle
          handle
          sortable-handler
          sort-work-procedure-handler
          drag-wp
        "
      >
        <i class="el-icon-sort"></i>
      </el-col>
      <el-col :span="23" class="mar-15">
        <el-row>
          <el-col :span="4">
            <div class="label-16">Material number</div>
            <el-input v-model="workProcedure.materialNumber"></el-input>
          </el-col>
          <el-col :span="20">
            <el-button
              type="danger"
              class="crud-add-button"
              icon="el-icon-delete"
              @click="onDeleteWorkProcedure(model)"
            ></el-button>
          </el-col>
        </el-row>
        <Workflows
          :key="workProcedure.id"
          :workflow-list.sync="workProcedure.workflows"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from "vue";

import Workflows from "./Workflows.vue";
import WorkProcedure from "../../models/WorkProcedureModel";

export default Vue.extend({
  props: {
    workProcedure: {
      type: Object,
      default: () => new WorkProcedure(),
    },
    identifier: {
      type: Number,
      required: true,
    },
  },
  components: {
    Workflows,
  },
  created() {
    this.model = this.workProcedure;
  },
  data() {
    return {
      loading: true,
      model: this.workProcedure,
    };
  },
  methods: {
    onDeleteWorkProcedure(item) {
      this.$confirm(
        "Do you want to remove " +
          (!item.materialNumber ? "this work procedure" : item.materialNumber) +
          "?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.$emit("delete", this.identifier);
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.mar-15 {
  margin: 15px;
}

.drag-wp {
  width: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: 26px;
}
</style>

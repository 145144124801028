import { HTTP } from "./HttpCommon";
import MilestoneLeadTimeGroupItem from "../models/MilestoneLeadTimeGroupItem";

class MilestoneLeadTimeService {
  addMilestoneLeadTime(item: MilestoneLeadTimeGroupItem) {
    return HTTP.post("MilestoneLeadTimes/AddMilestoneLeadTime", item);
  }

  updateMilestoneLeadTime(item: MilestoneLeadTimeGroupItem) {
    return HTTP.put("MilestoneLeadTimes/UpdateMilestoneLeadTime", item);
  }

  deleteMilestoneLeadTime(id: number) {
    return HTTP.delete("MilestoneLeadTimes/DeleteMilestoneLeadTime/" + id);
  }

  loadDetails(id: number) {
    return HTTP.get("MilestoneLeadTimes/GetMilestoneLeadTime/" + id);
  }

  loadMilestoneLeadTimeGroups() {
    return HTTP.get("MilestoneLeadTimes/GetMilestoneLeadTimeGroups");
  }

  loadMilestoneLeadTimeGroupItems(id: number) {
    return HTTP.get("MilestoneLeadTimes/GetMilestoneLeadTimeGroupItems/" + id);
  }

  loadManufacturingLocations() {
    return HTTP.get("MilestoneLeadTimes/GetManufacturingLocations");
  }

  loadDistributionCenters() {
    return HTTP.get("MilestoneLeadTimes/GetDistributionCenters");
  }

  loadProductTypes() {
    return HTTP.get("MilestoneLeadTimes/GetProductTypes");
  }

  loadRequestTypes() {
    return HTTP.get("MilestoneLeadTimes/GetRequestTypes");
  }

  loadMilestones(productTypeId: number, requestTypeId: number) {
    return HTTP.get(
      "MilestoneLeadTimes/GetMilestones?productTypeId=" +
        productTypeId +
        "&requestTypeId=" +
        requestTypeId
    );
  }
}

export const milestoneLeadTimeService = new MilestoneLeadTimeService();

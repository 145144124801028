import moment from "moment";
import SimpleSelectControlItemModel from "../SimpleSelectControlItemModel";

export default class RequestInformationModel {
  id: number;
  requestId: string;
  category: number;
  productType = "";
  url = "";
  status: number;
  initiated = "";
  productNumber: number;
  designRevision: number;
  quantity: number;
  distributionCenterId: number;
  poNumber: string;
  poNumberDate: string;
  orderNumber: string;
  showPONumberField: boolean;
  availableStatusList: SimpleSelectControlItemModel[] = [];
  availableProductsList: SimpleSelectControlItemModel[] = [];
  availableRequestCategories: SimpleSelectControlItemModel[] = [];
  availableDistributionCenters: SimpleSelectControlItemModel[] = [];
  raApproval: boolean;
  hasMetalAllergy: boolean;
  isProductTmj: boolean;
  plannedSurgeryDate = "";
  loanerSet: boolean;

  constructor(data) {
    if (data !== null) {
      this.id = data.model.id;
      this.requestId = data.model.requestId;
      this.category = data.model.category;
      this.productType = data.model.productTypeDescription;
      this.url = data.model.requestUrl;
      this.orderNumber = data.model.orderNumber;
      this.status = data.model.requestStatusId;
      this.initiated = moment(data.model.createdDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.productNumber = data.model.productNumber;
      this.designRevision = data.model.designRevision;
      this.quantity = data.model.quantity;
      this.distributionCenterId = data.model.distributionCenterId;
      this.poNumber = data.model.poNumber;
      this.poNumberDate = data.model.poNumberDate
        ? moment(data.model.poNumberDate).format("YYYY-MM-DD")
        : null;
      this.showPONumberField = data.model.showPONumberField;
      this.raApproval = data.model.raApproval;
      this.hasMetalAllergy = data.model.hasMetalAllergy;
      this.isProductTmj = data.model.isProductTmj;
      this.plannedSurgeryDate = data.model.plannedSurgeryDate
        ? moment(data.model.plannedSurgeryDate).format("YYYY-MM-DD")
        : null;
      this.loanerSet = data.model.loanerSet;

      for (const status of data.model.availableRequestStatuses) {
        const newStatus = new SimpleSelectControlItemModel(status);
        this.availableStatusList.push(newStatus);
      }
      for (const product of data.model.availableProducts) {
        const newProduct = new SimpleSelectControlItemModel(product);
        this.availableProductsList.push(newProduct);
      }
      for (const manufacturingLocation of data.model
        .availableDistributionCenters) {
        const newManufacturingLocation = new SimpleSelectControlItemModel(
          manufacturingLocation
        );
        this.availableDistributionCenters.push(newManufacturingLocation);
      }
      for (const requestCategory of data.model.availableRequestCategories) {
        const newRequestCategory = new SimpleSelectControlItemModel(
          requestCategory
        );
        this.availableRequestCategories.push(newRequestCategory);
      }
    }
  }
}

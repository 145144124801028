<template>
  <div>
    <el-table :data="jobSteps" stripe height="280">
      <el-table-column prop="name" label="STEP" align="center">
      </el-table-column>
      <el-table-column label="Actual Start Time" align="center">
        <template slot-scope="scope">
          {{ scope.row.actualStartDate | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="Expected End Time" align="center">
        <template slot-scope="scope">
          {{ scope.row.plannedEndDate | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="Actual End Time" align="center">
        <template slot-scope="scope">
          {{ scope.row.actualEndDate | datetime }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Vue from "vue";
import DateTime from "../../mixins/DateTime";

export default Vue.extend({
  mixins: [DateTime],
  props: {
    jobSteps: {
      required: true,
      type: Array,
    },
  },
});
</script>

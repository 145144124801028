<template>
  <div>
    <el-form v-if="model" label-width="auto">
      <el-form-item label="Start Date">
        <el-date-picker
          style="width: 100%"
          v-model="date"
          format="dd MMMM yyyy"
          placeholder="Select date"
          key="1"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="Start Time">
        <el-time-select
          style="width: 100%"
          v-model="model.startTime"
          :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '23:30',
          }"
          placeholder="Select time"
          key="2"
        ></el-time-select>
      </el-form-item>
      <el-form-item label="End Time">
        <el-time-select
          style="width: 100%"
          v-model="model.endTime"
          :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '23:30',
          }"
          placeholder="Select time"
        ></el-time-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";

import SingleAvailability from "../../models/design/SingleAvailability";

import { designerAvailabilityService } from "../../services/design/designeravailability.service";

export default Vue.extend({
  props: {
    model: null,
    designer: null,
  },
  computed: {
    date: {
      get: function () {
        return this.model.startDate;
      },
      set: function (newValue) {
        if (newValue !== null) {
          this.model.startDate = moment(newValue);
        } else {
          this.model.startDate = "";
        }
      },
    },
  },
  methods: {
    clearModel() {
      this.$emit(
        "update:model",
        new SingleAvailability(null, this.designer.timezone)
      );
    },
  },
});
</script>
<style lang="scss"></style>

<template>
  <div>
    <el-table :data="changes" stripe height="280">
      <el-table-column prop="field" label="Field" align="center">
      </el-table-column>
      <el-table-column prop="oldValue" label="Old Value" align="center">
      </el-table-column>
      <el-table-column prop="newValue" label="New Value" align="center">
      </el-table-column>
      <el-table-column label="Date" align="center">
        <template slot-scope="scope">
          {{ scope.row.time | datetime }}
        </template>
      </el-table-column>
      <el-table-column property="userSymbol" label="User" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Vue from "vue";
import DateTime from "../../mixins/DateTime";

export default Vue.extend({
  mixins: [DateTime],
  props: {
    changes: {
      required: true,
      type: Array,
    },
  },
});
</script>

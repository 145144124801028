<template>
    <el-card>
        <el-row>
            <editable-input
                v-model="model.name"
                :editable="true"
            ></editable-input>
            <el-divider></el-divider>
            <el-button type="primary" style="float: right;" @click="addWorkflowStep" icon="el-icon-plus">Add workflow step</el-button>
        </el-row>
        <el-table :data="model.jobSteps">
            <el-table-column width="150">
                <template slot-scope="scope">
                    <el-button icon="el-icon-top" circle :disabled="isFirst(scope.$index)" @click="changeOrderUp(scope.$index)"></el-button>
                    <el-button icon="el-icon-bottom" circle :disabled="isLast(scope.$index)" @click="changeOrderDown(scope.$index)"></el-button>
                </template>
            </el-table-column>
            <el-table-column type="index" label="STEP NUMBER" width="90"></el-table-column>
            <el-table-column label="STEP NAME" >
                <template slot-scope="scope">
                    <editable-input
                    v-model="scope.row.name"
                    :editable="true"
                  ></editable-input>
                </template>
            </el-table-column>
            <el-table-column label="ON SUCCESS" >
                <template slot-scope="scope">
                 <el-select v-model="scope.row.successName" style="width: 100%;" :disabled="isLast(scope.$index)" clearable @change="handleSuccessStepChange(scope.row)">
                    <el-option v-for="step in model.jobSteps.filter(
                        (x) => (x.name !== scope.row.name && x.name !== null)
                    )" :key="step.name" :label="step.name" :value="step.name">
                    </el-option>         
                </el-select>
                </template>
            </el-table-column>
            <el-table-column width="90">
                <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-delete" circle @click="handleRemoveWorkflowStep(scope.row)"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
</template>
<script>
import Vue from "vue";
import {EditableInput} from "../shared/Shared.vue";
import PrototypeWorkflowStepModel from "../../models/PrototypeWorkflowStepModel";

export default Vue.extend({
    components: {
        EditableInput
    },
    props: {
        model: {
        },
    },
    methods: {
        isFirst(value) {
            return value == 0;
        },
        isLast(value) {
            return value == this.model.jobSteps.length - 1 ;
        },
        addWorkflowStep(){
            this.model.jobSteps.push(new PrototypeWorkflowStepModel());
            this.resetSequenceNumber();
        },
        removeWorkflow(){
            this.$emit("remove", this.model);
        },
        handleRemoveWorkflowStep(item){
            let successSteps = this.model.jobSteps.filter(
                (x) => x.successStepId === item.id
            );
            successSteps.forEach(this.clearSuccessStep);
            this.model.jobSteps.splice(this.model.jobSteps.indexOf(item), 1);
            this.resetSequenceNumber();
        },
        changeOrderDown(index){
            this.model.jobSteps.splice(index + 1, 0, this.model.jobSteps.splice(index, 1)[0]);
            this.resetSequenceNumber();
            this.handleSuccessors();
        },
        changeOrderUp(index){
            this.model.jobSteps.splice(index - 1, 0, this.model.jobSteps.splice(index, 1)[0]);
            this.resetSequenceNumber();
            this.handleSuccessors();
        },
        clearSuccessStep(item){
            item.successStepId = null;
            item.successName = null;
        },
        handleSuccessStepChange(item){
            if(item.successName !== "") {
                item.successStepId = this.model.jobSteps.filter(
                    (x) => x.name === item.successName
                )[0].id
            } else {
                this.clearSuccessStep(item);
            }
        },
        resetSequenceNumber(){
            let newIndex = 1;
            this.model.jobSteps.forEach(element => element.sequenceNumber = newIndex++);
        },
        handleSuccessors(){
            this.model.jobSteps[this.model.jobSteps.length - 1].successStepId = null;
            this.model.jobSteps[this.model.jobSteps.length - 1].successName = null;
        }
    },
});
</script>

<style>
.input-button-container {
    display: flex;
    gap: 10px;
}
</style>




































































































import OperationType from "./OperationType.vue";
import ManufacturingCyclesList from "./ManufacturingCyclesList.vue";
import WorkflowProgress from "./WorkflowProgress.vue";
import WorkflowSummary from "./WorkflowSummary.vue";
import { manufacturingCycleService } from "@/services/manufacturingcycle.service";
import { TransitionActionType } from "@/enums/TransitionActionType";
import ChangeDataResponseModel from "@/models/ChangeDataResponseModel";
import { AxiosResponse } from "axios";
import { OperationResultType } from "@/enums/OperationResultType";

export default {
  components: {
    OperationType,
    ManufacturingCyclesList,
    WorkflowProgress,
    WorkflowSummary,
  },
  data() {
    return {
      activeStep: 0,
      confirmationDialogVisible: false,
      selectedManufacturingCycles: [],
      manufacturingCyclesToProcess: [],
    };
  },
  methods: {
    stepNext(): void {
      switch (this.activeStep) {
        case 0:
          this.activeStep++;
          break;
        case 1:
          if (this.selectedManufacturingCycles.length < 1) {
            this.$alert("Please select cases to process.", "Validation Error", {
              confirmButtonText: "OK",
              type: "error",
            });
            break;
          }
          this.activeStep++;
          break;
        case 2:
          if (this.manufacturingCyclesToProcess.length < 1) {
            this.$alert("Please select cases to process.", "Validation Error", {
              confirmButtonText: "OK",
              type: "error",
            });
            break;
          }
          this.activeStep++;
          break;
        case 3:
          this.activeStep++;
          break;
        case 4:
          this.activeStep++;
          break;
        default:
          break;
      }
    },
    stepBack(): void {
      switch (this.activeStep) {
        case 0:
          break;
        case 1:
          this.selectedManufacturingCycles.splice(
            0,
            this.selectedManufacturingCycles.length
          );
          this.activeStep--;
          break;
        case 2:
          this.selectedManufacturingCycles.splice(
            0,
            this.selectedManufacturingCycles.length
          );
          this.activeStep--;
          break;
        case 3:
          this.manufacturingCyclesToProcess.splice(
            0,
            this.manufacturingCyclesToProcess.length
          );
          this.activeStep--;
          break;
        case 4:
          this.activeStep--;
          break;
        default:
          break;
      }
    },
    async confirmBulkOperation(): Promise<void> {
      this.activeStep++;
      this.confirmationDialogVisible = false;

      for (const key in this.manufacturingCyclesToProcess) {
        if (
          Object.hasOwnProperty.call(this.manufacturingCyclesToProcess, key)
        ) {
          const element = this.manufacturingCyclesToProcess[key];
          var timestamp = "";

          await manufacturingCycleService
            .getTimestamp(element.manufacturingCycleId)
            .then((response) => {
              timestamp = response.data.result;
            });

          element.status.name = "Processing";
          element.status.type = "warning";

          switch (element.transitionActionType) {
            case TransitionActionType.InProgress:
              await manufacturingCycleService
                .startCurrentStepBulkOperation(
                  element.manufacturingCycleId,
                  element.manufacturingJobId,
                  timestamp
                )
                .then(() => {
                  element.status.name = "Finished";
                  element.status.type = "success";
                })
                .catch(() => {
                  element.status.name = "Error";
                  element.status.type = "error";
                });
              break;
            case TransitionActionType.Done:
              await manufacturingCycleService
                .markCurrentStepAsCompletedBulkOperation(
                  element.manufacturingCycleId,
                  element.manufacturingJobId,
                  timestamp
                )
                .then((response: AxiosResponse<ChangeDataResponseModel>) => {
                  if (response.data.result == OperationResultType.Error) {
                    element.status.name = response.data.message;
                    element.status.type = "error";
                  } else {
                    element.status.name = "Finished";
                    element.status.type = "success";
                  }
                });
              break;
            case TransitionActionType.Undo:
              await manufacturingCycleService
                .undoCurrentStep(
                  element.manufacturingCycleId,
                  element.manufacturingJobId,
                  timestamp
                )
                .then(() => {
                  element.status.name = "Finished";
                  element.status.type = "success";
                })
                .catch(() => {
                  element.status.name = "Error";
                  element.status.type = "error";
                });
              break;
            default:
              element.status.name = "Operation not implemented";
              element.status.type = "warning";
              break;
          }
        }
      }
    },
  },
};

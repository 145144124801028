import moment from "moment";
import { DateFrequency, RelativeDateModifier } from "../../enums/enums";

export default class ChosenFiltersModel {
  requestId = "";
  chosenProducts: string[] = [];
  chosenDesignStatuses: number[] = [];
  chosenProcessingStatuses: number[] = [];
  dateType = 0;
  chosenMilestoneId: number = null;
  chosenDate: number = null;
  chosenDistributionCenters: number[] = [];
  startDate: Date = null;
  endDate: Date = null;

  isRelativeDate = false;
  relativeDateModifier: RelativeDateModifier = RelativeDateModifier.Last;
  relativeDateAmount: number = null;
  relativeDateFrequency: DateFrequency = DateFrequency.Day;

  currentDateTabValue = "0";

  storedQueryId: string = null;
  storedQueryActiveDisplayName = "";

  dateRange: any = [];

  constructor(data) {
    if (data !== null) {
      this.storedQueryActiveDisplayName = data.storedQueryActiveDisplayName;

      if (data.requestId != null) {
        this.requestId = data.requestId;
      }

      if (data.chosenProducts != null) {
        for (const item of data.chosenProducts as Array<string>) {
          this.chosenProducts.push(item);
        }
      }

      if (data.chosenDesignStatuses != null) {
        for (const item of data.chosenDesignStatuses.map((i) => Number(i))) {
          if (!this.chosenDesignStatuses.some((x) => x === item)) {
            this.chosenDesignStatuses.push(item);
          }
        }
      }

      if (data.chosenProcessingStatuses != null) {
        for (const item of data.chosenProcessingStatuses.map((i) =>
          Number(i)
        )) {
          if (!this.chosenProcessingStatuses.some((x) => x === item)) {
            this.chosenProcessingStatuses.push(item);
          }
        }
      }

      if (data.chosenMilestoneId != null) {
        this.chosenMilestoneId = Number(data.chosenMilestoneId);
      }

      if (data.chosenDate != null) {
        this.chosenDate = Number(data.chosenDate);
      }

      if (data.dateType != null) {
        this.dateType = Number(data.dateType);
      } else {
        this.dateType = 0;
      }
      this.isRelativeDate = Boolean(data.isRelativeDate);
      if (this.isRelativeDate) {
        if (data.relativeDateAmount != null && this.isRelativeDate) {
          this.relativeDateAmount = Number(data.relativeDateAmount);
        }

        if (data.relativeDateFrequency != null && this.isRelativeDate) {
          this.relativeDateFrequency = data.relativeDateFrequency;
        }

        if (data.relativeDateModifier != null && this.isRelativeDate) {
          this.relativeDateModifier = data.relativeDateModifier;
        }

        if (this.relativeDateAmount >= 0) {
          const frequency =
            DateFrequency[this.relativeDateFrequency].toLowerCase();

          this.startDate = moment()
            .startOf("day")
            .add(
              this.relativeDateModifier === RelativeDateModifier.Last
                ? -this.relativeDateAmount
                : this.relativeDateAmount,
              frequency as moment.unitOfTime.DurationConstructor
            )
            .toDate();
          this.endDate = moment().endOf("day").toDate();
        }
      } else {
        this.isRelativeDate = false;
        if (data.startDate) {
          this.startDate = moment(data.startDate).utc().toDate();
          if (!this.isRelativeDate) {
            this.dateRange.push(this.startDate);
          }
        }

        if (data.endDate) {
          this.endDate = moment(data.endDate).utc().toDate();
          if (!this.isRelativeDate) {
            this.dateRange.push(this.endDate);
          }
        }
      }

      if (data.chosenDistributionCenters != null) {
        for (const item of data.chosenDistributionCenters.map((i) =>
          Number(i)
        )) {
          this.chosenDistributionCenters.push(item);
        }
      }
      if (this.isRelativeDate) {
        this.currentDateTabValue = "1";
      } else {
        this.currentDateTabValue = "0";
      }
    }
  }
}

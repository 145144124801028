<template>
  <div v-on-clickaway="closeEditMode">
    <div v-if="editMode" style="display: flex">
      <el-select
        ref="editableSelect"
        v-model="selectValue"
        v-if="selectList.length > 0"
        filterable
        placeholder="Empty"
        style="margin-right: 8px; width: 100%"
      >
        <el-option
          v-for="item in selectList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        ></el-option>
      </el-select>
      <el-button
        class="share-button"
        icon="el-icon-check"
        type="primary"
        v-on:click="applyChanges"
      ></el-button>
      <el-button
        class="share-button"
        icon="el-icon-close"
        type="primary"
        v-on:click="closeEditMode"
      ></el-button>
    </div>
    <div v-else>
      <div style="font-size: 12px; padding-left: 15px">
        <div v-if="editable">
          <el-button
            v-if="selectList.length > 0 && value"
            type="text"
            v-on:click="editMode = true"
            >{{ selectList.find((x) => x.value === value).label }}</el-button
          >
          <div v-else>
            <el-button
              type="text"
              v-on:click="editMode = true"
              style="color: #dd1144"
              >Empty</el-button
            >
          </div>
        </div>
        <div v-else>
          <div v-if="selectList.length > 0 && value" type="text">
            {{ selectList.find((x) => x.value === value).label }}
          </div>
          <div v-else>Empty</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  data() {
    return {
      editMode: false,
      selectValue: this.value,
    };
  },
  watch: {
    value: function () {
      this.selectValue = this.value;
    },
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    selectList: {
      type: Array,
      default() {
        return [];
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    applyChanges() {
      this.$emit("input", this.$refs.editableSelect.value);
      this.editMode = false;
    },
    closeEditMode: function () {
      this.selectValue = this.value;
      this.editMode = false;
    },
  },
};
</script>

<template>
  <div>
    <template v-for="workOrder in workOrders">
      <template v-for="manufacturingJob in workOrder.manufacturingJobs">
        <h3 :key="manufacturingJob.id" class="details">
          {{ manufacturingJob.name }}
        </h3>
        <milestones-list-table
          :key="'milestone-list' + manufacturingJob.id"
          :job-steps="manufacturingJob.jobSteps"
        ></milestones-list-table>
      </template>
    </template>
  </div>
</template>
<script>
import Vue from "vue";
import MilestonesListTable from "./MilestonesListTable.vue";

export default Vue.extend({
  components: {
    MilestonesListTable,
  },
  props: {
    workOrders: {
      required: true,
      type: Array,
    },
  },
});
</script>

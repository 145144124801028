import { HTTP } from "./HttpCommon";

class ReportService {
  getReport(id) {
    return HTTP.get("Report/EmbeddedReport/" + id);
  }

  loadReports() {
    return HTTP.get("Report/GetRecords");
  }

  addReport(record) {
    return HTTP.post("Report/Add", record);
  }

  editReport(record) {
    return HTTP.put("Report/Edit", record);
  }

  removeReport(id) {
    return HTTP.delete("Report/Remove/" + id);
  }
}

export const reportService = new ReportService();

<template>
  <el-form label-position="top" inline>
    <el-form-item label="Family">
      <el-select
        v-model="chosenProductFamilies"
        multiple
        clearable
        collapse-tags
        placeholder="None selected"
        style="width: 300px"
      >
        <el-option
          v-for="item in availableProductFamilies"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Division">
      <el-select
        v-model="chosenDivisions"
        multiple
        clearable
        collapse-tags
        placeholder="None selected"
      >
        <el-option
          v-for="item in availableDivisions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Describing Documents">
      <el-select
        v-model="chosenDocumentNumbers"
        filterable
        remote
        multiple
        reserve-keyword
        clearable
        collapse-tags
        placeholder="None selected"
        :remote-method="searchDocumentNumbers"
      >
        <el-option
          v-for="item in filters.availableDocumentNumbers"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item class="fright">
      <template slot="label"> &nbsp; </template>
      <el-button
        style="margin-bottom: 5px"
        @click="$emit('show-dialog')"
        :disabled="!eDocPermissions.CanManageProducts"
      >
        Add New Product
      </el-button>
    </el-form-item>
    <stored-query-filters
      :stored-query-type="storedQueryType.Products"
    ></stored-query-filters>
  </el-form>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Event } from "vue-tables-2";
import StoredQueryFilters from "../shared/StoredQueryFilters.vue";
import { StoredQueryType } from "../../../models/edoc/StoredQueryType";
import { productService } from "../../../services/edoc/product.service";
import _ from "lodash";

export default Vue.extend({
  components: {
    StoredQueryFilters,
  },
  created() {
    const documentIds =
      (this.$route.query.chosenDocumentNumbers &&
        this.$route.query.chosenDocumentNumbers.map(Number)) ||
      [];

    if (documentIds.length > 0) {
      this.getDocumentNumbersByIds(documentIds);
    }
  },

  data() {
    return {
      storedQueryType: StoredQueryType,
      filters: {
        documentNumber:
          (this.$route.query.chosenDocumentNumbers &&
            this.$route.query.chosenDocumentNumbers.map(Number)) ||
          [],
        availableDocumentNumbers: [],
        productFamilies:
          (this.$route.query.chosenProductFamilies &&
            this.$route.query.chosenProductFamilies.map(Number)) ||
          [],
        divisions:
          (this.$route.query.chosenDivisions &&
            this.$route.query.chosenDivisions.map(Number)) ||
          [],
      },
    };
  },
  computed: {
    ...mapState("permissions", ["eDocPermissions"]),
    availableProductFamilies() {
      return this.$store.state.product.availableProductFamilies;
    },
    chosenProductFamilies: {
      get: function () {
        return this.filters.productFamilies;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filters.productFamilies = null;
        } else {
          this.filters.productFamilies = newValue;
        }
        this.emitFilterChange(
          "chosenProductFamilies",
          this.filters.productFamilies
        );
      },
    },
    availableDivisions() {
      return this.$store.state.document.availableDivisions;
    },
    chosenDivisions: {
      get: function () {
        return this.filters.divisions;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filters.divisions = null;
        } else {
          this.filters.divisions = newValue;
        }
        this.emitFilterChange("chosenDivisions", this.filters.divisions);
      },
    },
    chosenDocumentNumbers: {
      get: function () {
        return this.filters.documentNumber;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filters.documentNumber = null;
        } else {
          this.filters.documentNumber = newValue;
        }
        this.emitFilterChange(
          "chosenDocumentNumbers",
          this.filters.documentNumber
        );
      },
    },
  },
  methods: {
    emitFilterChange: _.debounce(function (filterName, newValue) {
      Event.$emit("vue-tables.filter::" + filterName, newValue);
    }, 500),
    searchDocumentNumbers(query = "") {
      productService.getFilteredDocumentNumbers(query).then((response) => {
        this.filters.availableDocumentNumbers = response.data.map(
          (documentDetails) => {
            return { label: documentDetails.number, value: documentDetails.id };
          }
        );
      });
    },
    getDocumentNumbersByIds(documentIds) {
      productService.getDocumentNumbersByIds(documentIds).then((response) => {
        this.filters = {
          availableDocumentNumbers: response.data.map((documentDetails) => {
            return { label: documentDetails.number, value: documentDetails.id };
          }),
          documentNumber: documentIds,
          productFamilies:
            (this.$route.query.chosenProductFamilies &&
              this.$route.query.chosenProductFamilies.map(Number)) ||
            [],
          divisions:
            (this.$route.query.chosenDivisions &&
              this.$route.query.chosenDivisions.map(Number)) ||
            [],
        };
      });
    },
    reloadData() {
      this.$emit("reload-data");
    },
  },
  watch: {
    $route(to, from) {
      if (to.query.handleFilter) {
        const documentIds =
          (this.$route.query.chosenDocumentNumbers &&
            this.$route.query.chosenDocumentNumbers.map(Number)) ||
          [];

        if (documentIds.length > 0) {
          this.getDocumentNumbersByIds(documentIds);
        } else {
          this.filters = {
            documentNumber: documentIds,
            availableDocumentNumbers: [],
            productFamilies:
              (this.$route.query.chosenProductFamilies &&
                this.$route.query.chosenProductFamilies.map(Number)) ||
              [],
            divisions:
              (this.$route.query.chosenDivisions &&
                this.$route.query.chosenDivisions.map(Number)) ||
              [],
          };
        }

        this.reloadData();
      }
    },
  },
});
</script>

<template>
  <div>
    <el-row>
      <div class="el-collapse_header-container">
        <h4>Actual</h4>
        <el-button
          size="mini"
          class="mar-r-5"
          @click="onAddActualMilestoneChange"
          plain
          >Add</el-button
        >
      </div>
      <div
        class="el-container-item"
        v-for="item in changesNotificationSettings.actualMilestoneChanges"
        :key="item.id"
      >
        <span>{{ item.displayText }}</span>
        <div class="nowrap mar-b-15-l-5">
          <el-button
            size="mini"
            icon="el-icon-edit"
            @click="onEditActualMilestoneChange(item)"
          />
          <el-button
            size="mini"
            icon="el-icon-delete"
            type="danger"
            @click="onDeleteActualMilestoneChange(item)"
          />
        </div>
      </div>
    </el-row>
    <actual-milestone-change-popup
      :visible.sync="showActualMilestoneChangePopup"
      ref="actualMilestoneChangePopup"
      :editMode="isEdit"
      @submit="addEditActualMilestoneChange"
      :definitions="changeNotificationSettingDialog"
    ></actual-milestone-change-popup>
  </div>
</template>
<script>
import Vue from "vue";

import ActualMilestoneChangePopup from "./ActualMilestoneChangePopup.vue";
import { notificationSettingsService } from "../../../services/notificationsettings.service";
import { OperationResultType } from "../../../enums/enums";

export default Vue.extend({
  components: {
    ActualMilestoneChangePopup,
  },
  props: {
    changesNotificationSettings: {
      type: Object,
      default() {
        return [];
      },
    },
    changeNotificationSettingDialog: {
      type: Object,
      default() {
        return [];
      },
    },
    notificationsSettingsId: {
      type: Number,
      default: 0,
    },
    refreshPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showActualMilestoneChangePopup: false,
      isEdit: false,
    };
  },
  methods: {
    onAddActualMilestoneChange() {
      this.isEdit = false;
      this.showActualMilestoneChangePopup = true;
    },
    onEditActualMilestoneChange(item) {
      this.isEdit = true;
      this.$refs.actualMilestoneChangePopup.item = {
        id: item.id,
        selectedProductTypeId: item.selectedProductTypeId,
        selectedManufacturingLocationId: item.selectedManufacturingLocationId,
        selectedMilestoneId: item.selectedMilestoneId.toString(),
      };
      this.showActualMilestoneChangePopup = true;
    },
    onDeleteActualMilestoneChange(item) {
      this.$confirm(
        "Do you want to remove " + item.displayText + "?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.deleteActualMilestoneChange(item.id);
      });
    },
    addEditActualMilestoneChange: function (item) {
      if (this.isEdit) {
        this.editActualMilestoneChange(item);
      } else {
        this.addActualMilestoneChange(item);
      }
    },
    addActualMilestoneChange: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService
        .addActualMilestoneChange(item)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Actual Milestone Change added succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    editActualMilestoneChange: function (item) {
      item.notificationSettingsId = this.notificationsSettingsId;
      notificationSettingsService
        .editActualMilestoneChange(item)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Actual Milestone Change edited succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    deleteActualMilestoneChange: function (id) {
      notificationSettingsService
        .deleteActualMilestoneChange(id)
        .then((response) => {
          if (response.data.result === OperationResultType.Success) {
            this.showNotification(
              "Actual Milestone Change deleted succesfully.",
              "success"
            );
            this.$emit("update:refreshPage", true);
          } else if (response.data.result === OperationResultType.Error) {
            this.showNotification(response.data.message, "error");
            this.$emit("update:refreshPage", true);
          }
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
});
</script>

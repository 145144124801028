<template>
  <div>
    <div>
      <el-row :gutter="20">
        <el-col :span="6">
          <label class="field-label">Surgeon</label>
          <el-select
            style="width: 100%"
            v-model="filter.surgeonId"
            @change="filterChanged"
            filterable
            clearable
            placeholder="None selected"
          >
            <el-option
              v-for="item in model.surgeons"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <label class="field-label">Sales Representative</label>
          <el-select
            style="width: 100%"
            v-model="filter.representativeId"
            @change="filterChanged"
            filterable
            clearable
            placeholder="None selected"
          >
            <el-option
              v-for="item in model.representativesWithPreferences"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div>
      <div class="fright">
        <el-button type="primary" size="medium" @click="openAddDialog"
          >Create</el-button
        >
        <el-button type="primary" size="medium" @click="exportSurgeons"
          >Export</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="model.items"
        :default-sort="{ prop: 'surgeonName', order: 'ascending' }"
        ref="tableData"
        stripe
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-card class="box-card">
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-divider content-position="left"
                    >PLUS Preferences:</el-divider
                  >
                  <p>{{ props.row.plusPreferences }}</p>
                </el-col>
                <el-col :span="12">
                  <el-divider content-position="left"
                    >MEDPOR Preferences:</el-divider
                  >
                  <p>{{ props.row.medporPreferences }}</p>
                </el-col>
              </el-row>
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-divider content-position="left"
                    >PEEK Preferences:</el-divider
                  >
                  <p>{{ props.row.peekPreferences }}</p>
                </el-col>
                <el-col :span="12">
                  <el-divider content-position="left"
                    >PMMA Preferences:</el-divider
                  >
                  <p>{{ props.row.pmmaPreferences }}</p>
                </el-col>
              </el-row>
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-divider content-position="left"
                    >Design Session Preferences:</el-divider
                  >
                  <p>{{ props.row.designSessionPreferences }}</p>
                </el-col>
                <el-col :span="12">
                  <el-divider content-position="left"
                    >UMC Preferences:</el-divider
                  >
                  <p>{{ props.row.umcPreferences }}</p>
                </el-col>
              </el-row>
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-divider content-position="left"
                    >Additional Information:</el-divider
                  >
                  <p>{{ props.row.additionalInformation }}</p>
                </el-col>
              </el-row>
            </el-card>
          </template>
        </el-table-column>
        <el-table-column
          label="SURGEON"
          sortable
          align="center"
          prop="surgeonName"
        >
          <template slot-scope="scope">
            <div @click="rowClicked(scope.row)">
              {{ scope.row.surgeonName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="SALES REPRESENTATIVE"
          sortable
          align="center"
          prop="representativeName"
        >
          <template slot-scope="scope">
            <div @click="rowClicked(scope.row)">
              {{ scope.row.representativeName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" width="140" align="center">
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                type="primary"
                size="medium"
                class="el-icon-edit"
                @click="openEditDialog(scope.row)"
              ></el-button>
              <el-button
                type="primary"
                size="medium"
                class="el-icon-delete"
                @click="openDeleteDialog(scope.row)"
              ></el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :title="dialogName"
      :visible.sync="showEditModal"
      @closed="resetForm('dialogItem')"
    >
      <el-alert
        v-if="showDialogAlert"
        title="All surgeons' preferences already created."
        type="error"
        :closable="false"
        style="margin-bottom: 10px"
      ></el-alert>
      <el-form
        :model="dialogItem"
        :rules="rules"
        ref="dialogItem"
        label-width="120px"
        label-position="top"
      >
        <el-form-item label="Surgeon" prop="surgeonId">
          <el-select
            style="width: 100%"
            v-model="dialogItem.surgeonId"
            :disabled="isEditMode"
            filterable
            placeholder="None selected"
          >
            <template v-if="isEditMode">
              <el-option
                :key="dialogItem.surgeonId"
                :label="dialogItem.surgeonName"
                :value="dialogItem.surgeonId"
              ></el-option>
            </template>
            <template v-else>
              <el-option
                v-for="item in model.availableSurgeons"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="Sales Representatives" prop="rep">
          <el-select
            style="width: 100%"
            v-model="dialogItem.representativeId"
            filterable
            placeholder="None selected"
          >
            <el-option
              v-for="item in model.representatives"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-form-item label="Details" prop="rep">
            <el-tabs v-model="activeName" tab-position="left">
              <el-tab-pane label="PLUS Preferences" name="plus">
                <el-input
                  style="width: 100%"
                  v-model="dialogItem.plusPreferences"
                  type="textarea"
                  :rows="rowsNumber"
                ></el-input>
              </el-tab-pane>
              <el-tab-pane label="PEEK Preferences" name="peek">
                <el-input
                  style="width: 100%"
                  v-model="dialogItem.peekPreferences"
                  type="textarea"
                  :rows="rowsNumber"
                ></el-input>
              </el-tab-pane>
              <el-tab-pane label="MEDPOR Preferences" name="medpor">
                <el-input
                  style="width: 100%"
                  v-model="dialogItem.medporPreferences"
                  type="textarea"
                  :rows="rowsNumber"
                ></el-input>
              </el-tab-pane>
              <el-tab-pane label="PMMA Preferences" name="pmma">
                <el-input
                  style="width: 100%"
                  v-model="dialogItem.pmmaPreferences"
                  type="textarea"
                  :rows="rowsNumber"
                ></el-input>
              </el-tab-pane>
              <el-tab-pane label="Design Session Preferences" name="design">
                <el-input
                  style="width: 100%"
                  v-model="dialogItem.designSessionPreferences"
                  type="textarea"
                  :rows="rowsNumber"
                ></el-input>
              </el-tab-pane>
              <el-tab-pane label="UMC Preferences" name="umc">
                <el-input
                  style="width: 100%"
                  v-model="dialogItem.umcPreferences"
                  type="textarea"
                  :rows="rowsNumber"
                ></el-input>
              </el-tab-pane>
              <el-tab-pane label="Additional Information" name="info">
                <el-input
                  style="width: 100%"
                  v-model="dialogItem.additionalInformation"
                  type="textarea"
                  :rows="rowsNumber"
                ></el-input>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="resetForm('dialogItem')"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="submitDialog('dialogItem')"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { OperationResultType } from "../../enums/enums";
import { SurgeonPreferencesListModel } from "../../models/SurgeonPreferencesListModel";
import { SurgeonPreferenceModel } from "../../models/SurgeonPreferenceModel";
import { SurgeonPreferenceListFilter } from "../../models/SurgeonPreferenceListFilter";
import { surgeonPreferencesService } from "../../services/surgeonpreferences.service";

export default Vue.extend({
  mounted() {
    this.getData();
  },
  data() {
    return {
      OperationResultType,
      model: new SurgeonPreferencesListModel(null),
      dialogItem: new SurgeonPreferenceModel(),
      filter: new SurgeonPreferenceListFilter(),
      activeName: "plus",
      rowsNumber: 13,
      busy: false,
      rules: {
        surgeonId: [
          {
            required: true,
            message: "Please select Surgeon",
            trigger: "blur",
          },
        ],
      },
      showEditModal: false,
      showDeleteConfirmModal: false,
    };
  },
  methods: {
    getData() {
      surgeonPreferencesService.getData().then((response) => {
        this.model = new SurgeonPreferencesListModel(response.data);
        this.loading = false;
      });
    },
    rowClicked(row) {
      this.$refs.tableData.toggleRowExpansion(row);
    },
    openAddDialog() {
      this.dialogItem = new SurgeonPreferenceModel();
      this.showEditModal = true;
    },
    openEditDialog(item) {
      this.dialogItem = new SurgeonPreferenceModel(item);
      this.showEditModal = true;
    },
    submitDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isEditMode) {
            this.submitEdit();
          } else {
            this.submitAdd();
          }
        }
      });
    },
    submitEdit() {
      surgeonPreferencesService
        .editSurgeonPreferences(this.dialogItem)
        .then((response) => {
          if (response.data.Result === OperationResultType.Error) {
            this.showNotification(response.data.Message, "error");

            this.openEditDialog(this.dialogItem);
          } else {
            this.filterChanged();
            this.showNotification(
              "Surgeon preferences changed succesfully.",
              "success"
            );
          }
        });

      this.showEditModal = false;
    },
    submitAdd() {
      surgeonPreferencesService
        .addSurgeonPreferences(this.dialogItem)
        .then((response) => {
          if (response.data.Result === OperationResultType.Error) {
            this.openEditDialog(this.dialogItem);
            this.showNotification(response.data.Message, "error");
          } else {
            this.filterChanged();
            this.showNotification(
              "Surgeon preferences added succesfully.",
              "success"
            );
          }
        });

      this.showEditModal = false;
    },
    fillDialogItemNames() {
      if (this.dialogItem.surgeonId !== null) {
        this.dialogItem.surgeonName = this.model.surgeons.find(
          (s) => s.value === this.dialogItem.surgeonId
        ).label;
      }

      if (this.dialogItem.representativeId !== null) {
        this.dialogItem.representativeName = this.model.representatives.find(
          (r) => r.value === this.dialogItem.representativeId
        ).label;
      } else {
        this.dialogItem.representativeName = "";
      }
    },
    delete(item) {
      surgeonPreferencesService
        .deleteSurgeonPreferences(item.id)
        .then((response) => {
          if (response.data.Result === OperationResultType.Error) {
            this.showNotification(response.data.Message, "error");
          } else {
            this.filterChanged();
            this.showNotification(
              "Surgeon preferences deleted succesfully.",
              "success"
            );
          }
        });

      this.showDeleteConfirmModal = false;
    },
    filterChanged() {
      surgeonPreferencesService
        .getSurgeonPreferences(this.filter)
        .then((response) => {
          this.model.items = response.data.items.map((item) => {
            return new SurgeonPreferenceModel(item);
          });
        });
    },
    exportSurgeons() {
      surgeonPreferencesService.export(this.filter).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.showEditModal = false;
    },
    openDeleteDialog(item) {
      this.$confirm(
        "Do you really want to remove " + item.surgeonName + "?",
        "Delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.delete(item);
      });
      this.dialogItem = item;
      this.deleteModalText =
        "Do you really want to remove " + this.dialogItem.surgeonName + "?";
      this.showDeleteConfirmModal = true;
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
  computed: {
    isEditMode() {
      return this.dialogItem.id !== null;
    },
    dialogName() {
      return this.isEditMode
        ? "Edit surgeon preference"
        : "Add new surgeon preference";
    },
    showDialogAlert() {
      return !this.isEditMode && this.model.availableSurgeons.length === 0;
    },
  },
});
</script>

<style lang="scss" scoped>
.el-dialog__body {
  padding: 0px 20px;
}
.el-table td,
.el-table th {
  padding: 4px 0;
}
</style>

<template>
  <div>
    <el-row style="margin-bottom: 0px">
      <div class="job-step-buttons">
        <input
          type="button"
          class="btn btn-one js-start-current-step"
          :disabled="!model.canStartStep"
          @click="startCurrentStep"
          value="In Progress"
        />
        <input
          type="button"
          class="btn btn-one js-complete-current-step"
          :disabled="!model.canCompleteStep"
          @click="markCurrentStepAsCompleted"
          value="Done"
        />
        <input
          type="button"
          class="btn btn-one js-restart-job"
          :disabled="!model.canRestartJob"
          @click="openRestartManufacturingJobConfirmDialog"
          value="Restart"
        />
        <input
          type="button"
          class="btn btn-one js-undo-job"
          :disabled="!model.canUndoJob"
          @click="undoCurrentStep"
          value="Undo"
        />
        <template v-if="showRework">
          <input
            type="button"
            class="btn btn-one js-restart-job"
            :disabled="!model.canRework"
            @click="openReworkManufacturingJobDialog"
            value="Rework"
          />
        </template>
      </div>
    </el-row>
    <el-row style="margin-bottom: 0px">
      <table
        class="table manufacturing-job-table"
        style="margin-bottom: 0px"
        cellspacing="0"
      >
        <thead>
          <tr>
            <th style="min-width: 90px; max-width: 90px">{{ model.name }}</th>
            <th
              v-for="jobStep in model.jobSteps"
              :key="jobStep.id"
              class="step-name"
            >
              {{ jobStep.name }}
            </th>
          </tr>
          <tr class="separator"></tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div
                class="progress manufacturing-job-progress"
                :class="model.manufacturingStatusCss"
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="{
                    width: model.progress + '%',
                    'aria-valuenow': model.progress,
                  }"
                >
                  <div>
                    <span class="steps">
                      {{
                        model.completedNumberSteps +
                        "/" +
                        model.overallNumberSteps
                      }}
                    </span>
                    <span class="user-symbol">
                      {{ model.currentUserSymbol }}
                    </span>
                  </div>
                  <div>
                    <span class="manufacturing-job-icon"></span>
                    <span class="status">
                      {{ model.manufacturingStatusDescription }}
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <template v-for="jobStep in model.jobSteps">
              <td :key="jobStep.id" :class="jobStep.statusCss">
                <div>
                  <span></span>
                  <div>
                    <span>{{ jobStep.statusDescription }}</span>
                  </div>
                </div>
              </td>
            </template>
          </tr>
          <tr v-if="hasAnyPlannedTime">
            <td class="job-step-item job-step-planned-time-placeholder"></td>
            <template v-for="jobStep in model.jobSteps">
              <td
                :key="jobStep.id"
                v-if="jobStep.showExpectedTime"
                class="job-step-item"
              >
                <div>
                  <div
                    role="progressbar"
                    aria-valuenow="80"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="el-progress el-progress--line"
                  >
                    <div class="el-progress-bar job-step-progress-bar">
                      <div class="el-progress-bar__outer" style="height: 6px">
                        <div
                          class="el-progress-bar__inner"
                          style="background-color: #409eff"
                          :style="{
                            width:
                              jobStep.plannedTimeSpend >= 0
                                ? jobStep.plannedTimeSpend + '%'
                                : '100%',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <span>{{ jobStep.plannedEndDate | datetime }}</span>
              </td>
              <td
                :key="jobStep.id"
                class="job-step-item job-step-planned-time-placeholder"
                v-else
              ></td>
            </template>
          </tr>
        </tbody>
      </table>
    </el-row>
    <div v-if="showRework">
      <el-row
        style="margin-top: 30px"
        v-for="rework in model.reworks"
        :key="rework.id"
      >
        <div class="job-step-buttons">
          <input
            type="button"
            class="btn btn-one js-start-current-step"
            :disabled="!rework.canStartStep"
            @click="startCurrentReworkStep(rework)"
            value="In Progress"
          />
          <input
            type="button"
            class="btn btn-one js-complete-current-step"
            :disabled="!rework.canCompleteStep"
            @click="markCurrentReworkStepAsCompleted(rework)"
            value="Done"
          />
        </div>
        <table class="table manufacturing-job-table" cellspacing="0">
          <thead>
            <tr>
              <th class="rework-name-header">
                {{ rework.name + " REWORK - " + model.name }}
              </th>
              <th
                v-for="jobStep in model.jobSteps"
                :key="jobStep.id"
                class="step-name"
              >
                {{ jobStep.name }}
              </th>
            </tr>
            <tr class="separator"></tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  class="progress manufacturing-job-progress"
                  :class="model.manufacturingStatusCss"
                >
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{
                      width: model.progress + '%',
                      'aria-valuenow': model.progress,
                    }"
                  >
                    <div>
                      <span class="steps">
                        {{
                          rework.completedNumberSteps +
                          "/" +
                          rework.overallNumberSteps
                        }}
                      </span>
                      <span class="user-symbol">
                        {{ model.currentUserSymbol }}
                      </span>
                    </div>
                    <div>
                      <span class="manufacturing-job-icon"></span>
                      <span class="status">
                        {{ rework.manufacturingStatusDescription }}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
              <template v-for="jobStep in rework.jobSteps">
                <td :class="jobStep.statusCss" :key="jobStep.id">
                  <div>
                    <span></span>
                    <div>
                      <span>{{ jobStep.statusDescription }}</span>
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </el-row>
    </div>
    <el-dialog
      title="Add rework"
      :visible.sync="reworkDialogVisible"
      width="20%"
      append-to-body
    >
      <el-form
        ref="reworkForm"
        label-position="top"
        :model="reworkForm"
        :rules="reworkFormRules"
      >
        <el-form-item label="First job step">
          <el-select
            v-model="reworkForm.stepId"
            placeholder="Select job step"
            ref="addReworkSelect"
          >
            <el-option
              v-for="step in availableReworkSteps"
              :key="step.id"
              :label="step.name"
              :value="step.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="availableReworkMaterialNumbers.length > 1"
          label="Material numbers"
        >
          <el-select
            multiple
            v-model="reworkForm.materialNumbers"
            placeholder="Select job step"
          >
            <el-option
              v-for="material in availableReworkMaterialNumbers"
              :key="material"
              :label="material"
              :value="material"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeReworkDialog">Cancel</el-button>
        <el-button type="primary" @click="addRework" ref="addReworkButton"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import DateTime from "../../mixins/DateTime";
import { showLoading, hideLoading } from "../../store/mutation-types";

import { OperationResultType } from "../../enums/enums";

import { manufacturingCycleService } from "../../services/manufacturingcycle.service";

export default Vue.extend({
  mixins: [DateTime],
  props: {
    model: {
      required: true,
      type: Object,
    },
    materialNumber: {
      required: true,
      type: String,
    },
    showRework: {
      default: true,
      type: Boolean,
    },
    manufacturingCycleId: {
      required: true,
      type: Number,
    },
    timestamp: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      reworkDialogVisible: false,
      reworkForm: {
        stepId: null,
        materialNumbers: [],
      },
      reworkFormRules: {
        stepId: [
          { required: true, message: "Job step is required", trigger: "blur" },
        ],
        materialNumbers: [
          {
            required: true,
            message: "Material number is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    hasAnyPlannedTime() {
      return this.model.jobSteps.some((step) => step.showExpectedTime);
    },
    currentJobStep() {
      return this.model.jobSteps.find(
        (step) => step.id === this.model.currentJobStepId
      );
    },
    availableReworkSteps() {
      return this.model.jobSteps.filter(
        (step, index) =>
          index < this.model.completedNumberSteps &&
          (step.id !== this.currentJobStep.id || step.status == 2)
      );
    },
    availableReworkMaterialNumbers() {
      return this.materialNumber.split(", ");
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    startCurrentStep() {
      this.showLoading();
      manufacturingCycleService
        .startCurrentStep(
          this.manufacturingCycleId,
          this.model.id,
          this.timestamp
        )
        .then((response) => {
          this.handleChangeResponse(response);
        });
    },
    startCurrentReworkStep(rework) {
      this.showLoading();
      manufacturingCycleService
        .startCurrentReworkStep(
          this.manufacturingCycleId,
          rework.id,
          this.timestamp
        )
        .then((response) => {
          this.handleChangeResponse(response);
        });
    },
    markCurrentStepAsCompleted() {
      this.showLoading();
      manufacturingCycleService
        .markCurrentStepAsCompleted(
          this.manufacturingCycleId,
          this.model.id,
          this.timestamp
        )
        .then((response) => {
          this.handleChangeResponse(response);
        });
    },
    markCurrentReworkStepAsCompleted(rework) {
      this.showLoading();
      manufacturingCycleService
        .markCurrentReworkStepAsCompleted(
          this.manufacturingCycleId,
          rework.id,
          this.timestamp
        )
        .then((response) => {
          this.handleChangeResponse(response);
        });
    },
    undoCurrentStep() {
      this.showLoading();
      manufacturingCycleService
        .undoCurrentStep(
          this.manufacturingCycleId,
          this.model.id,
          this.timestamp
        )
        .then((response) => {
          this.handleChangeResponse(response);
        });
    },
    openRestartManufacturingJobConfirmDialog() {
      this.$confirm(
        "Are you sure you want to restart manufacturing job?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.restartManufacturingJob();
      });
    },
    restartManufacturingJob() {
      this.showLoading();
      manufacturingCycleService
        .restartManufacturingJob(
          this.manufacturingCycleId,
          this.model.id,
          this.timestamp
        )
        .then((response) => {
          this.$message({
            message: "Restart succeded.",
            type: "success",
          });
          this.handleChangeResponse(response);
        });
    },
    handleChangeResponse(response) {
      if (response.data.result == OperationResultType.Error) {
        this.$message.error(response.data.message);
      }

      if (response.data.result == OperationResultType.Success) {
        this.$emit("change");
      }

      this.hideLoading();
    },
    openReworkManufacturingJobDialog() {
      if (this.availableReworkMaterialNumbers.length === 1) {
        this.reworkForm.materialNumbers.push(
          this.availableReworkMaterialNumbers[0]
        );
      }

      this.reworkDialogVisible = true;
    },
    addRework() {
      this.$refs.reworkForm.validate((valid) => {
        if (valid) {
          this.$refs.addReworkButton.disabled = true;
          this.$refs.addReworkSelect.disabled = true;
          manufacturingCycleService
            .addRework(
              this.manufacturingCycleId,
              this.model.id,
              this.reworkForm.stepId,
              this.reworkForm.materialNumbers.join(", "),
              this.timestamp
            )
            .then((response) => {
              this.closeReworkDialog();
              this.handleChangeResponse(response);
              this.$refs.addReworkButton.disabled = false;
              this.$refs.addReworkSelect.disabled = false;
            });
        } else {
          return false;
        }
      });
    },
    closeReworkDialog() {
      this.reworkDialogVisible = false;
      this.$refs.reworkForm.resetFields();
      this.reworkForm.materialNumbers = [];
      this.reworkForm.stepId = null;
    },
  },
});
</script>
<style lang="scss" scoped>
.manufacturing-job-table {
  word-break: break-word;
}

.rework-name-header {
  min-width: 95px;
  max-width: 95px;
}

.job-step-item {
  border: 0px;
  height: 30px;
}

.job-step-progress-bar,
job-step-planned-time-placeholder {
  padding-right: 0px;
  margin-right: 0px;
}
</style>

export enum Resources {
  DocumentEditSuccess = "Document details changed successfully.",
  DocumentEditError = "Error occurred during editing document.",
  AddDocumentSuccess = "Document added successfully.",
  AddDocumentError = "Error occurred during adding document.",
  AddDocumentNumberNotUniqueError = "Document number already exists.",
  AddDocumentNumberDisallowedCharacters = "Document number contains disallowed characters.",
  AddRevisionSuccess = "Revision added successfully.",
  AddRevisionError = "Error occurred during adding revision.",
  AddRevisionNameNotUniqueError = "Revision name already exists.",
  AddRevisionVersionNameNotUniqueError = "Version name already exists.",
  EditRevisionSuccess = "Revision edited successfully.",
  EditRevisionError = "Error occurred during editing revision.",
  EditRevisionNameNotUniqueError = "Revision name already exists.",
  EditRevisionVersionNameNotUniqueError = "Version name already exists.",
  AddDocumentFileSuccess = "Document file added successfully.",
  AddDocumentFileError = "Error occurred during adding document file.",
  AddDocumentFileInvalidLocation = "Location is not valid uri.",
  EditDocumentFileSuccess = "Document file edited successfully.",
  EditDocumentFileError = "Error occurred during editing document file.",
  EditDocumentFileInvalidLocation = "Location is not valid uri.",
  DeleteDocumentSuccess = "Document deleted successfully.",
  DeleteDocumentError = "Error occurred during deleting document.",
  DeleteDocumentRevisionSuccess = "Revision deleted successfully.",
  DeleteDocumentRevisionError = "Error occurred during deleting revision.",
  DeleteDocumentFileSuccess = "File deleted successfully.",
  DeleteDocumentFileError = "Error occurred during deleting file.",

  AddProductSuccess = "Product added successfully.",
  AddProductError = "Error occurred during adding product.",
  AddProductCatalogNumberAndGtinNotFilledError = "Catalog Number or GTIN must be filled.",
  AddProductCatalogNumberNotUniqueError = "Catalog number already exists.",
  AddProductGtinNotUniqueError = "GTIN already exists.",
  AddProductVersionNameNotUniqueError = "Version name already exists.",
  DeleteProductSuccess = "Product deleted successfully.",
  DeleteProductError = "Error occurred during deleting product.",
  EditProductVersionNameNotUniqueError = "Version name already exists.",
  ProductEditSuccess = "Product details changed successfully.",
  ProductEditError = "Error occurred during editing product.",
  ImportProductsError = "Error occured during file import.",
  AssignKeyCodeSuccess = "Keycode assigned successfully.",
  AssignKeyCodeError = "Error occured during keycode assignment.",
  AddProductVersionSuccess = "Product version added successfully.",
  AddProductVersionError = "Error occurred during adding product version.",
  EditProductVersionSuccess = "Product version edited successfully.",
  EditProductVersionError = "Error occurred during editing product version.",
  AddAssociationSuccess = "Document associated successfully.",
  AddAssociationDocumentAlreadyAdded = "One of selected documents is already associated.",
  EditAssociationSuccess = "Document association edited successfully.",
  DeleteAssociationSuccess = "Association deleted successfully.",
  AddVersionsAssociationSuccess = "Versions associated successfully.",
  EditVersionsAssociationSuccess = "Versions association edited successfully.",
  DeleteVersionsAssociationSuccess = "Versions association deleted successfully.",
  AddAssociationError = "Error occurred during associating document.",
  EditAssociationError = "Error occurred during associating document.",
  DeleteAssociationError = "Error occurred during deleting association.",
  AddVersionsAssociationError = "Error occurred during associating versions.",
  EditVersionsAssociationError = "Error occurred during associating versions.",
  DeleteVersionsAssociationError = "Error occurred during deleting versions association.",
  AddVersionsAssociationAssociationNotUnique = "Same association already exists.",
  EditVersionsAssociationAssociationNotUnique = "Same association already exists.",
  DeleteProductVersionSuccess = "Product version deleted successfully.",
  DeleteProductVersionError = "Error occurred during deleting product version.",

  SaveFilterSuccess = "Filter saved successfully.",
  SaveFilterError = "Error occurred during saving filter.",
  EditStoredQueryError = "Error occurred during editing filter.",
  DeleteStoredQueryError = "Error occurred during deleting filter.",
  KeyCodeViolationException = "Document Version being part of Keycode would be removed as result of this operation.",
  AddKeyCodeSuccess = "Keycode added successfully.",
  AddKeyCodeError = "Error occurred during adding keycode.",
}

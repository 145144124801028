import { HTTP } from "./HttpCommon";
import { LeadTimeModificationItemModel } from "../models/LeadTimeModificationItemModel";

class LeadTimeModificationsService {
  addLeadTimeModification(model: LeadTimeModificationItemModel) {
    return HTTP.post(
      "LeadTimeModifications/AddDefaultLeadTimeModification",
      model
    );
  }

  updateLeadTimeModification(model: LeadTimeModificationItemModel) {
    return HTTP.put(
      "LeadTimeModifications/UpdateDefaultLeadTimeModification",
      model
    );
  }

  deleteLeadTimeModification(id: number) {
    return HTTP.delete(
      "LeadTimeModifications/DeleteDefaultLeadTimeModification/" + id
    );
  }

  getLeadTimeModifications() {
    return HTTP.get("LeadTimeModifications/GetDefaultLeadTimeModifications");
  }

  getMilestones() {
    return HTTP.get("LeadTimeModifications/GetMilestones");
  }

  getProductTypes() {
    return HTTP.get("LeadTimeModifications/GetProductTypes");
  }
}

export const leadTimeModificationsService = new LeadTimeModificationsService();

import { DesignHttpClient } from "../../shared/DesignHttpClient";

class DesignerService {
  getDesignerDetails(designerId: number) {
    return DesignHttpClient.get("designers/" + designerId);
  }

  getDesigners() {
    return DesignHttpClient.get("designers");
  }

  editDesignerProperties(designerDetails) {
    return DesignHttpClient.patch(`designers/${designerDetails.id}`, [
      {
        op: "replace",
        path: "/locationId",
        value: designerDetails.locationId,
      },
      {
        op: "replace",
        path: "/sessionsPerDay",
        value: designerDetails.sessionsPerDay,
      },
      {
        op: "replace",
        path: "/bookableViaApi",
        value: designerDetails.bookableViaApi,
      },
      {
        op: "replace",
        path: "/productTypeIds",
        value: designerDetails.productIds,
      },
      {
        op: "replace",
        path: "/timezone",
        value: designerDetails.locationTimezoneName,
      },
    ]);
  }
}

export const designerService = new DesignerService();

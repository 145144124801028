<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row>
      <div class="fleft">
        <h1 class="details-header no-margin">IMPORT LOG</h1>
      </div>
    </el-row>

    <el-form label-position="top" inline>
      <el-form-item label="PLAN ID">
        <el-autocomplete
          v-model="filters.requestId"
          :fetch-suggestions="querySearchAsync"
          placeholder="None selected"
          clearable
          style="width: 100%"
          @select="handlePlanIdSelect"
          @clear="handlePlanIdClear"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="STATUS">
        <el-select
          v-model="chosenStatuses"
          multiple
          clearable
          collapse-tags
          placeholder="None selected"
        >
          <el-option
            v-for="(status, index) in statuses"
            :key="index"
            :label="status"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <v-server-table ref="table" :columns="columns" :options="options">
      <div slot="date" slot-scope="props">{{ props.row.date | datetime }}</div>
      <div slot="status" slot-scope="props">
        {{ statuses[props.row.status] }}
      </div>
      <div slot="requestId" slot-scope="props">
        <router-link
          v-if="props.row.fulfilmentPlanId !== null"
          :to="'/FulfilmentPlan/Details/' + props.row.fulfilmentPlanId"
          >{{ props.row.requestId }}</router-link
        >
        <div v-else>{{ props.row.requestId }}</div>
      </div>
      <div slot="updatedFields" slot-scope="props">
        <template v-if="props.row.updatedFields != null">
          <div
            v-for="field in props.row.updatedFields"
            :key="field.milestoneName"
          >
            {{
              field.milestoneName == null
                ? field.updatedFieldText
                : field.milestoneName
            }}
            ({{ field.oldValue !== null ? field.oldValue : "Empty" }} ➜
            {{ field.newValue !== null ? field.newValue : "Empty" }})
          </div>
        </template>
      </div>
    </v-server-table>
  </div>
</template>
<script>
import Vue from "vue";
import { Event } from "vue-tables-2";
import DateTime from "../../mixins/DateTime";

import _ from "lodash";

import { requestImportService } from "../../services/requestimport.service";

import { fulfilmentPlanListService } from "../../services/fulfilmentplanlist.service";

export default Vue.extend({
  mixins: [DateTime],
  mounted() {
    Event.$on("vue-tables.loading", () => {
      this.loading = true;
    });

    Event.$on("vue-tables.loaded", () => {
      this.loading = false;
    });
  },
  computed: {
    chosenStatuses: {
      get: function () {
        return this.filters.chosenStatuses;
      },
      set: function (newValue) {
        this.filters.chosenStatuses = newValue;
        this.$refs.table.getData();
      },
    },
  },
  data() {
    return {
      loading: false,
      statuses: ["Imported", "Updated", "Skipped", "Staged"],
      filters: {
        chosenStatuses: [],
        chosenRequestId: "",
      },
      imports: [],
      columns: [
        "id",
        "date",
        "requestId",
        "status",
        "skipReason",
        "additionalInfo",
        "updatedFields",
      ],
      options: {
        headings: {
          requestId: "Request Id",
          status: "Status",
          skipReason: "Skip reason",
          additionalInfo: "Additional Info",
          updatedFields: "Updated Fields (Old value ➜ New Value)",
        },
        sortable: [],
        requestFunction: (data) => {
          data.chosenRequestId = this.filters.chosenRequestId;
          data.chosenStatuses = this.filters.chosenStatuses;

          return requestImportService
            .getRequestImports(data)
            .catch(function (e) {
              this.dispatch("error", e);
            });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
        filterable: false,
        customFilters: ["chosenRequestId", "chosenStatuses"],
        initFilters: {
          chosenRequestId: this.filter && this.filters.chosenRequestId,
          chosenStatuses: (this.filters && this.filters.chosenStatuses) || [],
        },
      },
    };
  },
  methods: {
    querySearchAsync(queryString, cb) {
      fulfilmentPlanListService.filterRequests(queryString).then((response) => {
        const result = response.data;
        cb(
          result.data.map(function (x) {
            return { value: x };
          })
        );
      });
    },
    handlePlanIdSelect(item) {
      this.filters.chosenRequestId = item.value;
      this.$refs.table.getData();
    },
    handlePlanIdClear() {
      this.filters.chosenRequestId = "";
      this.$refs.table.getData();
    },
  },
});
</script>

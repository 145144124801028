import FulfilmentPlanAdditionalFilterModel from "./FulfilmentPlanAdditionalFilterModel";
import _ from "lodash";
export default class FulfilmentPlanAdditionalFiltersModel {
  filters: FulfilmentPlanAdditionalFilterModel[] = [];

  constructor(model: any) {
    if (model != null) {
      model.forEach((item: any) => {
        this.filters.push(new FulfilmentPlanAdditionalFilterModel(item));
      });
    }
  }
}

export class ChangeTypeFilterModel {
  changeType: string = null;
  oldValue: string = null;
  newValue: string = null;

  constructor(model: any) {
    if (model) {
      this.changeType = model.changeType;
      this.oldValue = model.oldValue;
      this.newValue = model.newValue;
    }
  }
}

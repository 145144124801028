import moment from "moment";
import "moment-timezone";
export default class PlanDataModel {
  id: number;
  isProductTypePrioritizable = false;
  isProductManufacturingAtRisk = false;
  priority: boolean;
  manufacturingAtRisk: boolean;
  designSessionDate: string;
  category: number;
  hasSession: boolean;
  designSessionRequired: boolean;
  designerId: number;
  requestId: string;
  trackFileUploaded: boolean;
  fileUploadedDate: string;
  overflowDays: number;
  leadTimeModifications: any[] = [];
  additionalEmails: any[] = [];
  surgeryDate: string;
  patientName: string;
  showOverflowDays: boolean;

  constructor(data) {
    if (data !== null) {
      this.category = data.model.category;
      this.id = data.model.id;
      this.priority = data.model.priority;
      this.designerId = data.model.designerId;
      this.requestId = data.model.requestId;
      this.hasSession = data.model.hasSession;
      if (this.hasSession) {
        this.designSessionDate = data.model.designSessionDate
          ? moment(data.model.designSessionDate).format("YYYY-MM-DD HH:mm:ss")
          : null;
      } else {
        this.designSessionDate = data.model.designSessionDate
          ? moment(data.model.designSessionDate).format("YYYY-MM-DD")
          : null;
      }
      this.designSessionRequired = data.model.designSessionRequired;
      this.isProductTypePrioritizable = data.model.isProductTypePrioritizable;
      this.isProductManufacturingAtRisk =
        data.model.isProductManufacturingAtRisk;
      this.manufacturingAtRisk = data.model.manufacturingAtRisk;
      this.trackFileUploaded = data.model.trackFileUploaded;
      this.fileUploadedDate = data.model.fileUploadedDate
        ? moment(data.model.fileUploadedDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      this.overflowDays = data.model.overflowDays;
      this.additionalEmails = data.model.additionalEmails;
      this.surgeryDate = data.model.surgeryDate
        ? moment(data.model.surgeryDate).format("YYYY-MM-DD")
        : null;
      this.patientName = data.model.patientName;
      this.showOverflowDays = data.model.showOverflowDays;
    }
  }
}

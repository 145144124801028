






































































import { HTTP } from "../../services/HttpCommon";
import DateTime from "../../mixins/DateTime";
import { mapState, mapMutations } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import _ from "lodash";
import { RepeatOrderModel } from '@/models/RepeatOrderModel';
export default {
  mixins: [DateTime],
  async mounted() {
    this.getRepeatOrders();
  },
  watch: {
    "$route.params.id": function (id) {
      this.tableData = [];
      this.getRepeatOrders();
    },
  },
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    getRepeatOrders(): void {
      this.showLoading();

      const id = this.$route.params.id;
      HTTP.post("repeatOrder/getRepeatOrders/" + id)
        .then((response) => {
          var record = new RepeatOrderModel(response.data)
          this.tableData.push(record);
        });

      this.hideLoading();
    },
  },
};
